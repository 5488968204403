import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonListHeader, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const MitarbeiterIn: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appMitarbeiterListeFetchanewChange , appMitarbeiterId , appMitarbeiterIdChange ,appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ MitarbeiterId, setMitarbeiterId ] = useState( appMitarbeiterId )
  const [ MitarbeiterPersonalnummer, setMitarbeiterPersonalnummer ] = useState( '' )
  const [ MitarbeiterAktiv, setMitarbeiterAktiv ] = useState( 'aktiv' )
  const [ MitarbeiterAktivBool, setMitarbeiterAktivBool ] = useState( true )
  const [ MitarbeiterNachname, setMitarbeiterNachname ] = useState( '' )
  const [ MitarbeiterVorname, setMitarbeiterVorname ] = useState( '' )
  const [ MitarbeiterGeburtsdatum, setMitarbeiterGeburtsdatum ] = useState( '' )
  const [ MitarbeiterTaetigkeit, setMitarbeiterTaetigkeit ] = useState( '' )
  const [ MitarbeiterAbteilung, setMitarbeiterAbteilung ] = useState( '' )
  const [ MitarbeiterVertragsart, setMitarbeiterVertragsart ] = useState( '' )
  const [ MitarbeiterVertragsbeginn, setMitarbeiterVertragsbeginn ] = useState( '' )
  const [ MitarbeiterVertragsende, setMitarbeiterVertragsende ] = useState( '' )
  const MitarbeiterAktivStringToBool = ( val:string ) => {
    if( val === 'aktiv' ) {
      setMitarbeiterAktiv( 'aktiv' )
      setMitarbeiterAktivBool( true )
    } else {
      setMitarbeiterAktiv( 'inaktiv' )
      setMitarbeiterAktivBool( false )
    }
  }
  const getData = ( MitarbeiterId:string ) => {
    if (appMitarbeiterId === 'neu') {
      setMitarbeiterId( 'neu' ) ;
      setMitarbeiterPersonalnummer( '' ) ;
      MitarbeiterAktivStringToBool( 'aktiv' ) ;
      setMitarbeiterNachname( '' ) ;
      setMitarbeiterVorname( '' ) ;
      setMitarbeiterGeburtsdatum( '' ) ;
      setMitarbeiterTaetigkeit( '' ) ;
      setMitarbeiterAbteilung( '' ) ;
      setMitarbeiterVertragsart( '' ) ;
      setMitarbeiterVertragsbeginn( '' ) ;
      setMitarbeiterVertragsende( '' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/mitarbeiter/get_mitarbeiterin.php?mitarbeiter_id=` + MitarbeiterId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((mitarbeiter: any) => {
          if (mitarbeiter.MitarbeiterId === appMitarbeiterId) {
            setMitarbeiterId( mitarbeiter.MitarbeiterId ) ;
            setMitarbeiterPersonalnummer( mitarbeiter.MitarbeiterPersonalnummer ) ;
            MitarbeiterAktivStringToBool( mitarbeiter.MitarbeiterAktiv ) ;
            setMitarbeiterNachname( mitarbeiter.MitarbeiterNachname ) ;
            setMitarbeiterVorname( mitarbeiter.MitarbeiterVorname ) ;
            setMitarbeiterGeburtsdatum( mitarbeiter.MitarbeiterGeburtsdatum ) ;
            setMitarbeiterTaetigkeit( mitarbeiter.MitarbeiterTaetigkeit ) ;
            setMitarbeiterAbteilung( mitarbeiter.MitarbeiterAbteilung ) ;
            setMitarbeiterVertragsart( mitarbeiter.MitarbeiterVertragsart ) ;
            setMitarbeiterVertragsbeginn( mitarbeiter.MitarbeiterVertragsbeginn ) ;
            setMitarbeiterVertragsende( mitarbeiter.MitarbeiterVertragsende ) ;
                }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var raw = JSON.stringify({
      MitarbeiterId: `${MitarbeiterId}`,
      MitarbeiterPersonalnummer: `${MitarbeiterPersonalnummer}`,
      MitarbeiterAktiv: `${MitarbeiterAktiv}`,
      MitarbeiterNachname: `${MitarbeiterNachname}`,
      MitarbeiterVorname: `${MitarbeiterVorname}`,
      MitarbeiterGeburtsdatum: `${MitarbeiterGeburtsdatum}`,
      MitarbeiterVertragsart: `${MitarbeiterVertragsart}`,
      MitarbeiterVertragsbeginn: `${MitarbeiterVertragsbeginn}`,
      MitarbeiterVertragsende: `${MitarbeiterVertragsende}`,
      MitarbeiterTaetigkeit: `${MitarbeiterTaetigkeit}`,
      MitarbeiterAbteilung: `${MitarbeiterAbteilung}`,
      betriebeId: appBetriebeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/mitarbeiter/set_mitarbeiterin.php', requestOptions)
    .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appMitarbeiterIdChange( json.entryId ) ;
           setMitarbeiterId( json.entryId ) ;
         }
       }
      )
}
  function onValueMitarbeiterAktivChange(e: boolean) {
    e
      ?
        MitarbeiterAktivStringToBool( 'aktiv' )
      :
        MitarbeiterAktivStringToBool( 'inaktiv' )
  }
  function onValueMitarbeiterNachnameChange(e: any) {
    setMitarbeiterNachname(e.target.value);
  }
  function onValueMitarbeiterVornameChange(e: any) {
    setMitarbeiterVorname(e.target.value);
  }
  function onValueMitarbeiterPersonalnummerChange(e: any) {
    setMitarbeiterPersonalnummer(e.target.value);
  }
  function onValueMitarbeiterGeburtsdatumChange(e: any) {
    setMitarbeiterGeburtsdatum(e.target.value);
  }
  function onValueMitarbeiterVertragsart(e: any) {
    setMitarbeiterVertragsart(e.target.value);
  }
  function onValueMitarbeiterVertragsbeginn(e: any) {
    setMitarbeiterVertragsbeginn(e.target.value);
  }
  function onValueMitarbeiterVertragsende(e: any) {
    setMitarbeiterVertragsende(e.target.value);
  }
  function onValueMitarbeiterTaetigkeitChange(e: any) {
    setMitarbeiterTaetigkeit(e.target.value);
  }
  function onValueMitarbeiterAbteilungChange(e: any) {
    setMitarbeiterAbteilung(e.target.value);
  }
  useEffect(
    () => {
      if( history.location.pathname.includes( '/mitarbeiterIn/') ) {
          /**
           * !! Vgl. "Aufgabe": Problem richtige Einweisungsthemen-ID bei jeder Navigation
           *  - ABER: bei "Aufgabe" FALSCHER bzw. nicht verallgemeinerbarer Ansatz
           */
        getData( appMitarbeiterId ) ;
      };
    },
    [ appMitarbeiterId , history.location.pathname ]
  );
  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für eine Mitarbeiterin oder einen Mitarbeiter einsehen und bearbeiten oder für eine neue Mitarbeiterin oder Mitarbeiter festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Mitarbeiter/in { MitarbeiterVorname ? MitarbeiterNachname + ', ' + MitarbeiterVorname : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
        <IonCardHeader>
      <IonCardSubtitle>Stammdaten</IonCardSubtitle>
    </IonCardHeader>
    <IonCardContent>
    <IonItem lines="none" className='item'>
      <IonLabel slot="">aktiv</IonLabel>
      <IonCheckbox slot="start" checked={ MitarbeiterAktivBool } onIonChange={e => onValueMitarbeiterAktivChange( e.detail.checked ) } />
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Personalnummer</IonLabel>
        <IonInput required value={MitarbeiterPersonalnummer}  onIonChange={(e) => onValueMitarbeiterPersonalnummerChange(e)}/>
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Nachname</IonLabel>
        <IonInput required  value={MitarbeiterNachname} onIonChange={(e) => onValueMitarbeiterNachnameChange(e)}/>
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Vorname</IonLabel>
        <IonInput required value={MitarbeiterVorname}  onIonChange={(e) => onValueMitarbeiterVornameChange(e)}/>
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Geburtsdatum</IonLabel>
        <IonInput type="date" placeholder = "bitte auswählen" required value={MitarbeiterGeburtsdatum}  onIonChange={(e) => onValueMitarbeiterGeburtsdatumChange(e)} />
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Tätigkeit</IonLabel>
        <IonInput required value={MitarbeiterTaetigkeit}  onIonChange={(e) => onValueMitarbeiterTaetigkeitChange(e)} />
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Abteilung</IonLabel>
        <IonInput required value={MitarbeiterAbteilung}  onIonChange={(e) => onValueMitarbeiterAbteilungChange(e)} />
    </IonItem>
    <IonItem>
      <IonLabel position="stacked">Vertragsart</IonLabel>
      <IonSelect
        cancelText="Abbrechen"
        interface = 'action-sheet'
        placeholder = "bitte auswählen"
        value = { MitarbeiterVertragsart }
        onIonChange =
          {
            ( e ) => onValueMitarbeiterVertragsart( e )
          }
      >
        <IonSelectOption
          key = { 'unbefristet' }
          value = { 'unbefristet' }
        >
          unbefristet
        </IonSelectOption>
        <IonSelectOption
          key = { 'befristet' }
          value = { 'befristet' }
        >
          befristet
        </IonSelectOption>
      </IonSelect>
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Vertragsbeginn</IonLabel>
        <IonInput type="date" placeholder = "bitte auswählen" required value={MitarbeiterVertragsbeginn}  onIonChange={(e) => onValueMitarbeiterVertragsbeginn(e)} />
    </IonItem>
    <IonItem className='item'>
        <IonLabel position="stacked">Vertragsende</IonLabel>
        <IonInput type="date" placeholder = "bitte auswählen" required value={MitarbeiterVertragsende}  onIonChange={(e) => onValueMitarbeiterVertragsende(e)} />
    </IonItem>
        <ButtonsSaveActions
              myExec = {
                () =>
                  {
                    if( MitarbeiterGeburtsdatum ) {
                      appMitarbeiterListeFetchanewChange( true) ;
                      sendJSON() ;
                    }
                    else {
                      alert( 'Bitte das Geburtsdatum angeben' ) ;
                      return false ;
                    }
                  }
              }
            />
        <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
    </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
