/**
 * View Schulung
 * 
 * Einzelnen Schulung einsehen, bearbeiten oder erstellen
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp , getDokumentDatei , PageHelp, ButtonAdd, ButtonInaktive, defColumn, defColumnRenderCell, exportTableData, readableFileSize } from '../components/_HelperFunctions';
import { useDokumenteEinweisungsthema } from '../components/_CustomHooks';
import { AppContext } from '../contexts/app_context';
import TableGeraeteEinweisungsthemen from '../components/table_geraete_einweisungsthemen';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';

export function Schulung() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appEinweisungsthemaDokumenteFetchanewChange , appEinweisungsthemaDokumente , appEinweisungsthemaDokumenteId , appEinweisungsthemaDokumenteIdChange , appKundeId , appSchulungId , appSchulungIdChange , appSchulungAddedChange , appEinweisungsThemenFetchanew , appEinweisungsThemenFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ EinweisungsthemenId, setEinweisungsthemenId ] = useState( appSchulungId )
  const [ EinweisungsthemenTitel, setEinweisungsthemenTitel ] = useState( '' )
  const [ EinweisungsthemenInhalt, setEinweisungsthemenInhalt ] = useState( 'aktiv' )
  const [ EinweisungsthemenAktiv, setEinweisungsthemenAktiv ] = useState( '' )
  const [ EinweisungsthemenPflicht, setEinweisungsthemenPflicht ] = useState( '' )
  const [ EinweisungsthemenTurnus, setEinweisungsthemenTurnus] = useState( '0' )
  //#region Dialog-Dokument
    const [ localDokumenteEinweisungsthema , localDokumenteEinweisungsthemaChange ] = useState( Array() ) ;
    const [ existInaktive , setExistInaktive ] = useState( false ) ;
    const [ showInaktive , setShowInaktive ] = useState( false ) ;
      /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
      //#endregion Dialog-Dokument
  const [pageSize, setPageSize] = useState(10);

  const getDataEinweisungsthema = ( EinweisungsthemenId:string ) => {
    if (appSchulungId === 'neu') {
      setEinweisungsthemenId( 'neu' ) ;
      setEinweisungsthemenTitel( '' ) ;
      setEinweisungsthemenInhalt( '' ) ;
      setEinweisungsthemenAktiv( 'aktiv' ) ;
      setEinweisungsthemenPflicht( '' ) ;
      setEinweisungsthemenTurnus( '0' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/themen/get_thema.php?schulungen_id=` + EinweisungsthemenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((schulungen: any) => {
          if (schulungen.SchulungenId === EinweisungsthemenId) {
            setEinweisungsthemenId( schulungen.SchulungenId ) ;
            setEinweisungsthemenTitel( schulungen.SchulungenTitel ) ;
            setEinweisungsthemenInhalt( schulungen.SchulungenInhalt ) ;
            setEinweisungsthemenAktiv( schulungen.SchulungenAktiv ) ;
            setEinweisungsthemenPflicht( schulungen.SchulungenPflicht ) ;
            setEinweisungsthemenTurnus( schulungen.SchulungenTurnus ) ;
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    appSchulungAddedChange( EinweisungsthemenTitel ) ;
    var raw = JSON.stringify({
      SchulungenId: EinweisungsthemenId ,
      SchulungenTitel: EinweisungsthemenTitel ,
      SchulungenInhalt: EinweisungsthemenInhalt ,
      SchulungenAktiv: EinweisungsthemenAktiv ,
      SchulungenPflicht: EinweisungsthemenPflicht ,
      SchulungenTurnus: EinweisungsthemenTurnus ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch( 'https://datamehr-backend.saint-online.de/api/themen/set_thema.php' , requestOptions )
    .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appSchulungIdChange( json.entryId ) ;
           setEinweisungsthemenId( json.entryId ) ;
         }
       }
      )
  }
  function onValueEinweisungsthemenTitel( e:any ) { setEinweisungsthemenTitel( e.target.value ) ; }
  function onValueEinweisungsthemenInhalt( e:any ) { setEinweisungsthemenInhalt( e.target.value ) ; }
  function onValueEinweisungsthemenAktiv( e:any ) { setEinweisungsthemenAktiv( e.target.value ) ; }
  function onValueEinweisungsthemenPflicht( e:any ) { setEinweisungsthemenPflicht( e.target.value ) ; }
  function onValueEinweisungsthemenTurnus( e:any ) { setEinweisungsthemenTurnus( e.target.value ) ; }

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteEinweisungsthemaChange( appEinweisungsthemaDokumente )
      }
      else {
        localDokumenteEinweisungsthemaChange(
          appEinweisungsthemaDokumente
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }
  useEffect(
    () => {
      if( appEinweisungsthemaDokumente
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appEinweisungsthemaDokumente ]
  )
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )   
  useDokumenteEinweisungsthema() ;
  
  useEffect(
    () => {
      if( history.location.pathname.includes( '/schulung/') ) {
          /**
           * !! Vgl. "Aufgabe": Problem richtige Einweisungsthemen-ID bei jeder Navigation
           *  - ABER: bei "Aufgabe" FALSCHER bzw. nicht verallgemeinerbarer Ansatz
           */
        getDataEinweisungsthema( appSchulungId ) ;
        appEinweisungsthemaDokumenteFetchanewChange( true ) ;
      };
    },
    [ appSchulungId , history.location.pathname ]
  );
  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für ein Einweisungsthema einsehen und bearbeiten oder für ein neues Einweisungsthema festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Einweisungsthema { EinweisungsthemenTitel ? EinweisungsthemenTitel  : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
             <IonItem className='item'>
                <IonLabel position="stacked">Thema</IonLabel>
                  <IonInput required value={ EinweisungsthemenTitel } onIonChange={ ( e )=> onValueEinweisungsthemenTitel( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Inhalt</IonLabel>
                  <IonInput required value={ EinweisungsthemenInhalt } onIonChange={ ( e )=> onValueEinweisungsthemenInhalt( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Aktiv</IonLabel>
                  <IonSelect value={ EinweisungsthemenAktiv } onIonChange={ ( e )=> onValueEinweisungsthemenAktiv( e ) } >
                    <IonSelectOption value = { 'aktiv' } >aktiv</IonSelectOption>
                    <IonSelectOption value = { 'inaktiv' } >inaktiv</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Pflicht</IonLabel>
                  <IonSelect value={ EinweisungsthemenPflicht } onIonChange={ ( e )=> onValueEinweisungsthemenPflicht( e ) } >
                    <IonSelectOption value = { 'ja' } >ja</IonSelectOption>
                    <IonSelectOption value = { 'nein' } >nein</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Turnus</IonLabel>
                  <IonSelect value={ EinweisungsthemenTurnus } onIonChange={ ( e )=> onValueEinweisungsthemenTurnus( e ) } >
                    <IonSelectOption value = { "0" } >nicht festgelegt</IonSelectOption>
                    <IonSelectOption value = { "1" } >Ersteinweisung</IonSelectOption>
                    <IonSelectOption value = { "90" } >vierteljährlich</IonSelectOption>
                    <IonSelectOption value = { "180" } >halbjährlich</IonSelectOption>
                    <IonSelectOption value = { "365" } >jährlich</IonSelectOption>
                    <IonSelectOption value = { "730" } >zwei Jahre</IonSelectOption>
                  </IonSelect>
                </IonItem>
          </IonCardContent>
            {
              appSchulungId !== 'neu'
                ?
                  <IonCardContent class="Dokumente">
                    <IonAlert
                      isOpen = { dokumentToActOn ? true : false }
                      backdropDismiss = { true }
                      header = 'Gewählte Datei ...'
                      message = ''
                      buttons = {
                        [
                          {
                            text: 'Herunterladen' ,
                            handler: () => {
                              getDokumentDatei( dokumentToActOn ) ;
                              setDokumentToActOn( '' ) ;
                            }
                          } ,
                          {
                            text: 'Listeneintrag bearbeiten' ,
                            handler: () => {
                              appViewSwitchChange( true ) ;
                              appEinweisungsthemaDokumenteIdChange( dokumentToActOn ) ;
                              history.push( '/dokument-einweisungsthema/' + dokumentToActOn + '/' + appSchulungId )
                              setDokumentToActOn( '' ) ;
                            }
                          } ,
                          {
                            text: 'Abbrechen' ,
                            handler: () => {
                              setDokumentToActOn( '' ) ;
                            }
                          }
                        ]
                      }
                    />
                    <IonLabel position="stacked">
                      Dokumente für Einweisung
                    </IonLabel>
                    {
                      appEinweisungsthemaDokumente.length > 0
                        ?
                          <Box width="100vh"
                            sx={{
                              height: 300,
                              width: 1,
                              "& .super-app-theme--header": {
                                backgroundColor: "rgb(211,211,211)",
                              },
                            }}
                          >
                            <DataGrid
                              disableVirtualization 
                              initialState={{
                                sorting: {
                                  sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                                },
                              }}
                              getRowId={(row) => row.DokumenteId}
                              getRowClassName =
                              {
                                ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                              }
                              rows = { localDokumenteEinweisungsthema }
                              columns = {
                                [
                                  defColumn( "DokumenteId" , "Id" , 0 ) ,
                                  defColumnRenderCell(
                                    defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                    ( data:any ) =>
                                      new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                  ) ,
                                  defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                  defColumnRenderCell(
                                    defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                    ( data:any ) =>
                                      readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                  ) ,
                                  defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                                  ]
                              } 
                              pageSize={pageSize}
                              rowsPerPageOptions={[10,50,100]}
                              disableSelectionOnClick
                              onCellClick =
                                {
                                  ( e:any ) =>
                                    {
                                      /** Browser / Web vs. App nativ */
                                      if( navigator ) {
                                        setDokumentToActOn( e.id ) ;
                                      } else {
                                        appEinweisungsthemaDokumenteIdChange( e.id ) ;
                                        appViewSwitchChange( true ) ;
                                        history.push( '/dokument-einweisungsthema/' + e.id + '/' + appSchulungId )
                                      }
                                    }
                                }
                              sortingOrder={["desc", "asc"]}
                              onPageSizeChange={(newPage) => setPageSize(newPage)}
                              pagination
                              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            />
                          </Box>
                        :
                          ""
                    }
                    <ButtonInaktive
                      disabled = { existInaktive ? false : true }
                      myExec = {
                        () =>
                          {
                            setShowInaktive( showInaktive ? false : true )
                          }
                      }
                      label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                    />
                    <ButtonAdd
                      myExec = { () => { appEinweisungsthemaDokumenteIdChange( 'neu' ) ; } }
                      routeUrl = { '/dokument-einweisungsthema/neu/' + appSchulungId }
                    />
                  </IonCardContent>
                :
                  ''
            }
          <IonCardContent>
                <ButtonsSaveActions
                  myExec = {
                    () => { appEinweisungsThemenFetchanewChange( true ) ; sendJSON() ; }
                  }
                />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
        <TableGeraeteEinweisungsthemen themaId =  { EinweisungsthemenId } themaTitle = { EinweisungsthemenTitel }/>
      </IonContent>
    </IonPage>
  );
};

