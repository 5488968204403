import { IonButton, IonCard, IonCardContent, IonModal, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../contexts/app_context";
import './table_geraete_grid.css';
import { useArticles } from "./_CustomHooks";
import { ButtonAdd, ButtonInaktive, defColumn , getLoadErr } from './_HelperFunctions' ;

function ArticlesTable() {
  const { appBetriebeId , appArticleIdChange , appArticles , appArticlesFetchstate , appArticlesFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [pageSize, setPageSize] = useState(50);
  const [ importDialog , setImportDialog ] = useState( 'hide')
  const [ importdatei , setImportdatei ]:any = useState() ;
  const [ importstatus , setImportstatus ]:any = useState( '' ) ;
    /** Kopie appArticles für destruktive Filterung */
  const [ localArticles , localArticlesChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const setColumns = ( tablePurpose:string ):any => {
    if( tablePurpose === 'Articles' ) {
      return (
        [
          defColumn( 'lieferanten_artikel_nr' , 'Artikelnr.' , 40 , 0 ) ,
          defColumn( 'kategorie' , 'Kategorie' , 120 , 0 ) ,
          defColumn( 'bezeichnung' , 'Bezeichnung' , 420 , 0 ) ,
          defColumn( 'bestelleinheit' , 'Bestelleinheit' , 80 , 0 ) ,
          defColumn( 'inhalt_bestelleinheit' , 'Inhalt' , 10 , 0 ) ,
          defColumn( 'basiseinheit' , 'Basiseinheit' , 80 , 0 ) ,
        ]
      )
    }
  }
  useArticles() ;
  
  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localArticlesChange( appArticles )
      }
      else {
        localArticlesChange(
          appArticles
            .filter(
              ( entry:any ) => entry.aktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appArticles
          .findIndex(
            ( entry:any ) => entry.aktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appArticles ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  const exportButton = () =>
    <IonButton
      disabled = { true }
      onClick = {
        () => 
          { 
            window.open( 'https://datamehr-backend.saint-online.de/api/mitarbeiter/export_mitarbeiter.php?betriebe_id=' + appBetriebeId )
          } 
      }
    >
      Exportieren
    </IonButton>

  async function sendImportdatei() {
    const doFetch = async () => {
      setImportstatus( 'running' ) ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://datamehr-backend.saint-online.de/api/dokumente/set_dokument.php', requestOptions)
      .then( response => response.json() )
      .then(
        json =>
          {
            if( json.entryId > 0 ) {
              sendImportStart( json.entryId )
            }
          }
        )
    }
    let data = {
      DokumenteDateidatum: new Date( importdatei.lastModified ).toISOString() ,
      DokumenteDateigroesse: importdatei.size ,
      DokumenteDateiname: importdatei.name ,
      DokumenteDateitypKurz: 'XLSX' ,
      DokumenteDateitypMime: importdatei.type ,
      DokumenteEinweisung: 0 ,
      DokumenteEinweisungsthema: 0 ,
      DokumenteGeraet: 0 ,
      DokumenteUnterweisung: 0 ,
      DokumenteUnterweisungsthema: 0 ,
      DokumenteId: 'neu' ,
      DokumenteThema: 'Artikelliste für Import' ,
      DokumenteUniquename: appBetriebeId + '_' + Date.now().toString() ,
      DokumenteDateiToUpload: '' ,
      betriebeId: appBetriebeId
    }
    if( importdatei ) {
      const reader:any = new FileReader() ;
      reader.readAsDataURL( importdatei ) ;
      reader.onload = () => {
        data.DokumenteDateiToUpload = reader.result.replace(/^data:[^,]+,/, '') ;
        doFetch() ;
      }
    }
    else {
        // hl 22apr13 quick'n'dirty: einmal "doFetch" für asynchronen reader in reader.onload, einmal hier
      doFetch() ;
    }
  }

  async function sendImportStart( importdokumentId:String ) {
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: ''
      };
      await fetch( 'https://datamehr-backend.saint-online.de/api/mitarbeiter/import_mitarbeiter.php?betriebe_id=' + appBetriebeId + '&dokument_id=' + importdokumentId , requestOptions)
        .then(
          response =>
            {
              if( response.status !== 200 ) {
                setImportstatus( 'Fehler' ) ;
              }
              else {
                appArticlesFetchanewChange( true ) ;
                setImportstatus( 'done' ) ;
              }
            }
        )
    }
    doFetch() ;
  }

  const mkTable = ( tablePurpose:string , singleGUI:string ) =>  {
    return (
      <>
        <IonModal
          isOpen = { importDialog === 'show' ? true : false }
          backdropDismiss = { false }
        >                            
          <IonCard>
            <IonCardContent>
              <p
                style = {
                  {
                    borderLeft: 'solid 0.4em gray' ,
                    fontSize: '0.8em' ,
                    paddingLeft: '0.5em'
                  }
                }
              >
                Mittels "Importieren" können Sie mehrere Artikel auf einmal aus einer Excel-Tabelle hinzufügen oder aktualisieren. Hierzu können Sie mit dem Button "Exportieren" eine Excel-Vorlage herunterladen, die Sie nach entsprechenden Hinzufügungen oder Änderungen hier wieder hochladen können.
                <br/>
                { exportButton() }
              </p>
              <br/>
              <input
                id="select_xlsx_dialog"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange = {
                  ( e:any ) => {
                    setImportdatei( e.target.files[0] )
                  }
                }
                style = {
                  {
                    display: 'none'
                  }
                }
              />
              <IonButton
                onClick={
                  () =>
                    {
                      // @ts-ignore
                      document.querySelector( '#select_xlsx_dialog' ).click() ;
                    }
                }
              >
                Zu importierende Excel-Datei { importdatei ? 'ändern' : 'auswählen' }
              </IonButton>
              <div
                style = {
                  {
                    paddingLeft: '1em'
                  }
                }
              >
                {
                  importdatei
                    ?
                      <>
                        <p
                          style = {
                            {
                              fontSize: '1.2em' ,
                              fontWeight: 'bold'
                            }
                          }
                        >
                        { importdatei.name }
                        </p>
                        <IonButton
                          onClick =
                            {
                              () => 
                                {
                                  sendImportdatei() ;
                                }
                            }
                        >
                          Importieren
                        </IonButton>
                      </>
                    :
                      <i>Keine Datei ausgewählt</i>
                }
              </div>
              <br/>
              {
                importstatus === 'Fehler'
                  ?
                    <p style = { { color: 'red' } }>
                      <b>Fehler:</b> Die Daten konnten nicht importiert werden. Prüfen Sie die gewählte Excel-Datei und versuchen Sie ggf. erneut zu importieren
                    </p>
                  :
                    importstatus === 'done'
                      ?
                        <p>
                          Die Daten wurden erfolgreich importiert.
                        </p>
                      :
                        ''
              }
              {
                importstatus === 'running'
                  ?
                    'Daten werden importiert ...'
                  :
                    <IonButton
                      onClick = {
                        () =>
                          {
                            setImportdatei() ;
                            setImportstatus( '' ) ;
                            setImportDialog( 'hide' ) ;
                          }
                      }
                    >
                      {
                        importstatus === 'done'
                          ?
                            'Schließen'
                          :
                            'Abbrechen'
                        }
                    </IonButton>
              }
            </IonCardContent>
          </IonCard>
        </IonModal>
        <Box
          sx={{
            height: 600,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "rgb(211,211,211)",
            },
          }}
        >
          {
            appArticlesFetchstate !== 200 && appArticlesFetchstate !== 204 ?
            (
              appArticlesFetchstate === 100
                ? <IonSpinner name="bubbles" />
                : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                    { getLoadErr( appArticlesFetchstate ) }
                  </div> 
            ) : (
              <>
                <DataGrid
                  disableVirtualization 
                  componentsProps = {{
                    toolbar: {
                      tablePurpose: tablePurpose
                    }
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [ { field: 'ArticlesPersonalnummer' , sort: 'asc' } ] ,
                    },
                  }}
                  getRowId={(row:any) => row.id} 
                  getRowClassName =
                    {
                      ( data:any ) => data.row.aktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                    }
                  rows={ localArticles }
                  columns={ setColumns( tablePurpose ) } 
                  rowsPerPageOptions={[50,100]}
                  disableSelectionOnClick
                  sortingOrder={["desc", "asc"]}
                  onCellClick = {
                    (e) =>
                      {
                        appViewSwitchChange( true ) ;
                        appArticleIdChange( e.id ) ;
                        history.push( '/' + singleGUI + '/' + e.id + '/' + appBetriebeId ) ; 
                      }
                  }
                  // checkboxSelection
                  pageSize={pageSize}
                  onPageSizeChange={(newPage) => setPageSize(newPage)}
                  //onCellClick={(e) => tryme(e.row)}
                  // rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
                <ButtonInaktive
                  disabled = { existInaktive ? false : true }
                  myExec = {
                    () =>
                      {
                        setShowInaktive( showInaktive ? false : true )
                      }
                   }
                  label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                />
                <ButtonAdd
                  myExec = { () => { appArticleIdChange( 'neu' ) ; } }
                  routeUrl = { '/' + singleGUI + '/neu/' + appBetriebeId }
                  disabled = { true }
                />
                {
                  localArticles.length > 0
                    ?
                      exportButton()
                    :
                      ''
                }
                <IonButton
                  disabled = { true }
                  onClick = {
                    () => setImportDialog( 'show' ) 
                  }
                >
                  Importieren
                </IonButton>
                {
                  showInaktive
                    ?
                      <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                        Inaktive Artikel sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                      </p>
                    :
                      ''
                }
              </>
            )
          }
        </Box>
      </>
    )
  }
  return (
    <>
      { mkTable( 'Articles' , 'article' ) }
    </>
  );
}
export default ArticlesTable;
