import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GefahrstoffschulungenListen from '../components/table_gefahrstoffschulungen_grid';
import { AppContext } from '../contexts/app_context';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import GefahrstoffschulungenMatrix from '../components/table_gefahrstoffschulungen_matrix';

export const Gefahrstoffschulungen: React.FC = () => {
  useIonViewDidEnter(() => {
    appViewSwitchChange( false ) ;
  });
  const { appBetriebeName , appGefahrstoffschulungNeuThemaChange , appArticleIdChange , appViewSwitchChange} = useContext( AppContext ) ;
  const history = useHistory();
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313ivVFHJ' ;
  useEffect(
    () =>
      {
        if( history.location.pathname.includes( '/gefahrstoffschulungen/' ) ) {
          appGefahrstoffschulungNeuThemaChange( '' ) ;
          appArticleIdChange( 'neu' ) ;
        }
      } ,
    [ history.location.pathname ]
  ) ;
    return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie offene und abgeschlossene Gefahrstoffschulungen einsehen und bearbeiten.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Gefahrstoffschulungen { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <GefahrstoffschulungenMatrix/>
        <GefahrstoffschulungenListen />
      </IonContent>
    </IonPage>
  );
};
