/**
 * Article - Einzel-Ansicht Artikel für Neuanlegen Artikel oder Änderung / Einsicht Artikel-Daten
 * 
 *  - keine "Aufgaben" - diese in datamehr zu sehr an Geräte gebunden
 *    - für Artikel eigentlich nur "Bestellen" eine denkbare Aufgabe, hierfür anderer Mechanismus sinnvoll
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import Box from "@mui/material/Box";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ButtonAdd, ButtonCancelBack, ButtonInaktive , ButtonsSaveActions, defColumn, defColumnRenderCell, exportTableData, getDokumentDatei, ButtonPageHelp, PageHelp,readableFileSize, FieldHelpCE } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import { useArticleInternDokumente, useGefahrstoffschulungen, useArticlesGefahrstoffschulungenThemen, useGefahrstoffschulungenThemen,  useLieferantenListe, useMitarbeiterListe } from '../components/_CustomHooks';

export function ArticleIntern() {
  useIonViewDidEnter(
    () => {
      appViewSwitchChange(false);
    }
  );
  useIonViewWillLeave(
    () => {
      appArticlesInternFetchanewChange(true) ;
    }
  );
  const { appArticleInternDokumenteFetchanewChange, appBetriebeId, appArticleInternDokumente, appArticleInternDokumenteIdChange , appGefahrstoffschulungIdChange, appArticleInternGefahrstoffschulungenFetchanewChange, appArticlesGefahrstoffschulungenThemen , appGefahrstoffschulungNeuThemaChange ,appArticlesGefahrstoffschulungenThemenFetchanewChange, appGefahrstoffschulungenFetchanewChange , appArticleInternIdChange , appArticlesInternFetchanewChange, appArticleInternId, appArticleInternLieferantChange , appArticleInternLieferantAdded , appArticleInternLieferantAddedChange, appKundeId , appMitarbeiterListe, appGefahrstoffschulungen, appGefahrstoffschulungenThemen, appUserIstEinweiser, appViewSwitchChange } = useContext(AppContext);
  const [pageHelpShow, setPageHelpShow] = useState(false);
  const [ fieldHelpCEShow , setFieldHelpCEShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [dataError, setDataError] = useState(false);
  const [ localArticleInternDokumente , localArticleInternDokumenteChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  /** Eintrag bearbeiten vs. Dokument herunterladen */
  const [dokumentToActOn, setDokumentToActOn] = useState('');
  /** State-Object (statt einzelner States) */
  const [articleIntern, setArticleIntern] = useState(
    {
      id: appArticleInternId,
      aktiv: '',
      lieferanten_artikel_nr: '',
      basiseinheit: '',
      bestelleinheit: '',
      bezeichnung: '',
      inhalt_bestelleinheit: '',
      kategorie: '',
      lieferant: '',
    }
  );
  /** Hilfsfunktion für Direktzugriff auf Property des State-Objects  */
  const articleInternSet = (prop: string, val: any) => {
    if (val === null) {
      val = '';
    }
    return Object.defineProperty(articleIntern, prop, { value: val });
  }
  /** Flag für evtl. nicht gesicherte Änderungen */
  const [ unsavedChanges, setUnsavedChanges ] = useState( Array() ) ;
  const noteUnsavedChanges = ( fieldName:string ) => {
    let myUnsavedChanges = unsavedChanges ;
    if( ! myUnsavedChanges.includes( fieldName ) ) {
      myUnsavedChanges.push( fieldName ) ;
      setUnsavedChanges( myUnsavedChanges ) ;
    }
    return true ;
  }
  /** checkboxSelectGefahrstoffschulungsThema */
  const [Gefahrstoffschulungsthemen, setGefahrstoffschulungsthemen] = useState( Array() )
  const [addAllThemen, setAddAllThemen] = useState(false);
  /** ! "lastAddedThema" auch als Trigger, damit Ionic re-rendert */
  const [lastAddedThema, setLastAddedThema] = useState('');
  /** ! "anzahlThemen" auch als Trigger, damit Ionic re-rendert */
  const [anzahlThemen, setAnzahlThemen] = useState(0);
  /** Auf den internen Artikel bezogene Gefahrstoffschulungen */
  const [Gefahrstoffschulungen, setGefahrstoffschulungen] = useState( Array() )
  const [pageSize, setPageSize] = useState(10);
  useEffect(
    () => {
      if (history.location.pathname.includes('/article-intern/')) {
        if (appArticleInternId !== 'neu') {
          appArticleInternDokumenteFetchanewChange(true);
          appArticleInternGefahrstoffschulungenFetchanewChange(true);
          appArticlesGefahrstoffschulungenThemenFetchanewChange(true);
        }
      }
    },
    [appArticleInternId]
  );
  useGefahrstoffschulungenThemen();
  const getDataArticleIntern = () => {
    if (appArticleInternId === 'neu') {
      setArticleIntern( articleInternSet('id', 'neu'));
      setArticleIntern(articleInternSet('aktiv', 'aktiv'));
      setArticleIntern(articleInternSet('lieferanten_artikel_nr', ''));
      setArticleIntern(articleInternSet('basiseinheit', ''));
      setArticleIntern(articleInternSet('bestelleinheit', ''));
      setArticleIntern(articleInternSet('bezeichnung', ''));
      setArticleIntern(articleInternSet('inhalt_bestelleinheit', ''));
      setArticleIntern(articleInternSet('kategorie', ''));
      setArticleIntern(articleInternSet('lieferant', ''))
      if (appArticleInternLieferantAdded === '') {
        appArticleInternLieferantChange('');
      }
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/articles/get_article.php?my_table=interne_artikel&article_id=` + appArticleInternId,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        },
      });
      api.get("")
        .then(
          (res) => {
            setDataError(false);
            let data = res.data;
            data.forEach(
              (entry: any) => {
                if (entry.id === appArticleInternId) {
                  setArticleIntern(articleInternSet('id', entry.id));
                  setArticleIntern(articleInternSet('aktiv', entry.aktiv));
                  setArticleIntern(articleInternSet('lieferanten_artikel_nr', entry.lieferanten_artikel_nr));
                  setArticleIntern(articleInternSet('basiseinheit', entry.basiseinheit));
                  setArticleIntern(articleInternSet('bestelleinheit', entry.bestelleinheit));
                  setArticleIntern(articleInternSet('bezeichnung', entry.bezeichnung));
                  setArticleIntern(articleInternSet('inhalt_bestelleinheit', entry.inhalt_bestelleinheit));
                  setArticleIntern(articleInternSet('kategorie', entry.kategorie));
                  setArticleIntern(articleInternSet('lieferant', entry.lieferant))
                  if (appArticleInternLieferantAdded === '') {
                    appArticleInternLieferantChange(entry.lieferant);
                  }
                }
              }
            )
          }
        )
        .catch(
          () => setDataError(true)
        );
    }
  }
  useEffect(
    () =>
      {
        getDataArticleIntern()
        appGefahrstoffschulungenFetchanewChange( true) ;
      }
    ,
    [appBetriebeId, appArticleInternId]
  );
  useEffect(
    () => {
      let myGefahrstoffschulungsthemen = Array() ;
      appArticlesGefahrstoffschulungenThemen
        .forEach(
          (thema: any) => {
            if( thema.ArticleInternId === appArticleInternId ) {
              if( thema.ThemaId !== '' ) {
                myGefahrstoffschulungsthemen.push( thema.ThemaId ) ;
              }
            }
          }
        )
        setGefahrstoffschulungsthemen( myGefahrstoffschulungsthemen );
        setAnzahlThemen(myGefahrstoffschulungsthemen.length ) ;  
      },
    [ appArticlesGefahrstoffschulungenThemen , appArticleInternId ]
  );
  useEffect(
    () => {
      let myGefahrstoffschulungen = appGefahrstoffschulungen
           .filter(
            ( entry:any ) =>
              {
                if(
                  appGefahrstoffschulungenThemen
                    .findIndex(
                      ( thema:any ) => {
                        if( thema.SchulungenId === entry.GefahrstoffschulungenThema ) {
                          if( thema.SchulungenAktiv === 'aktiv' ) {
                            return true
                          }
                        }
                      }
                    ) > -1
                )
                  { 
                    return true ;
                  }
              }
           )
        .filter(
          ( entry:any ) =>
            { 
              return Gefahrstoffschulungsthemen.includes( entry.GefahrstoffschulungenThema )
            }
        )
      setGefahrstoffschulungen( myGefahrstoffschulungen ) ;
      },
    [ appGefahrstoffschulungen , appGefahrstoffschulungenThemen , Gefahrstoffschulungsthemen ]
  );
  useArticleInternDokumente();

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localArticleInternDokumenteChange( appArticleInternDokumente )
      }
      else {
        localArticleInternDokumenteChange(
          appArticleInternDokumente
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appArticleInternDokumente
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appArticleInternDokumente ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  useArticlesGefahrstoffschulungenThemen() ;
  useLieferantenListe();
  useMitarbeiterListe();
  useGefahrstoffschulungen();
  async function sendChangesArticleIntern() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${window.localStorage.getItem('jwt')}`);
    var raw = JSON.stringify({
      id: articleIntern.id,
      aktiv: articleIntern.aktiv || 'aktiv',
      lieferanten_artikel_nr: articleIntern.lieferanten_artikel_nr || '',
      basiseinheit: articleIntern.basiseinheit || '',
      bestelleinheit: articleIntern.bestelleinheit || '',
      bezeichnung: articleIntern.bezeichnung || '(k.A.)',
      inhalt_bestelleinheit: articleIntern.inhalt_bestelleinheit || '',
      kategorie: articleIntern.kategorie || '',
      lieferant: articleIntern.lieferant || '',
      betriebeId: appBetriebeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/articles/set_article.php?my_table=interne_artikel', requestOptions)
    .then( response => response.json() )
    .then(
       json =>
        {
          if( json.entryId > 0 ) {
            return json.entryId
          }
          else {
            return appArticleInternId
          }
        }
       )
    .then(
       ( myArticleId ) =>
        {
          saveChangesGefahrstoffschulungsthemen( myArticleId )
          return myArticleId ;
        }
       )
    .then(
      ( myArticleId ) =>
        {
          appArticleInternIdChange( myArticleId ) ;
          setArticleIntern( articleInternSet( 'id' , myArticleId ) );
        }
      )
  }
  async function saveChangesGefahrstoffschulungsthemen( myArticleIntern:any ) {
    /** ! Möglich: Sowohl neue Zuordnung dazu wie bestehende löschen */
    let raw = '';
    /** Mutable Gefahrstoffschulungsthemen */
      let chkGefahrstoffschulungsthemen = Gefahrstoffschulungsthemen ;
    /** Existierende Artikel-Themen-Paare abgleichen */
      appArticlesGefahrstoffschulungenThemen
        .filter(
          ( thema:any) =>
            thema.ArticleInternId === appArticleInternId
        )
        .forEach(
          ( thema:any) =>
            {
              /** vorhandenen Eintrag löschen oder beibehalten */
                if ( ! chkGefahrstoffschulungsthemen.includes( thema.ThemaId) ) {
                  /** vorhandener Eintrag nicht in chkGefahrstoffschulungsthemen => ThemaId löschen
                   *  - Backend entscheidet, ob der Eintrag insgesamt gelöscht oder re-used wird
                   */
                    /** bereits "leere" Einträge nicht nochmals schreiben */
                      if ( thema.ThemaId !== '' ) {
                        raw = JSON.stringify({
                          EntryId: thema.EntryId ,
                          KundeId: appKundeId ,
                          BetriebId: appBetriebeId ,
                          ArticleInternId: myArticleIntern ,
                          ThemaId: ''
                          });
                        sendChangesGefahrstoffschulungsThema(raw) ;
                      }
                }
                else {
                  /** vorhandenen Eintrag von chkGefahrstoffschulungsthemen nehmen */
                    chkGefahrstoffschulungsthemen = chkGefahrstoffschulungsthemen
                      .filter(
                        ( entry:any ) => entry !== thema.ThemaId
                      )
                }
              }
        )
      /** Etwaige verbleibende neue Artikel-Themen-Paare schreiben */
        chkGefahrstoffschulungsthemen
          .forEach(
            ( entry:any ) =>
              {
                raw = JSON.stringify(
                  {
                    KundeId: appKundeId ,
                    BetriebId: appBetriebeId ,
                    ArticleInternId: myArticleIntern ,
                    ThemaId: entry
                  }
                );
                sendChangesGefahrstoffschulungsThema(raw);
                appGefahrstoffschulungenFetchanewChange( true ) ;
                appArticlesGefahrstoffschulungenThemenFetchanewChange( true ) ;
              }
          )
  }
  async function sendChangesGefahrstoffschulungsThema(JSON: any) {
    alert( 'Hier kein Speichern von Gefahrstoffschulungen / Themen => Einstellungen > Gefahrstoffschulungsthemen' ) ;
  }
  const form = {
    lieferanten_artikel_nr: (e: any) => setArticleIntern(articleInternSet('lieferanten_artikel_nr', e.target.value)),
    aktiv: (e: any) => {
      setArticleIntern(
        articleInternSet(
          'aktiv',
          e.detail.checked
          ? 
            'aktiv'
          :
            'inaktiv'
        )
      )
    },
    basiseinheit: (e: any) => setArticleIntern(articleInternSet('basiseinheit', e.target.value)),
    bestelleinheit: (e: any) => setArticleIntern(articleInternSet('bestelleinheit', e.target.value)),
    bezeichnung: (e: any) => setArticleIntern(articleInternSet('bezeichnung', e.target.value)),
    inhalt_bestelleinheit: (e: any) => setArticleIntern(articleInternSet('inhalt_bestelleinheit', e.target.value)),
    kategorie: (e: any) => setArticleIntern(articleInternSet('kategorie', e.target.value)),
    lieferant: ( e:any ) => {
      /** Lieferant neu anlegen oder auswählen
       *  @param  appArticleInternLieferant        Wörtlicher Wert für "Lieferant" in Formular und sendJSON
       *                                    - app-State für garantierte Aktualisierung
       *  @param  appArticleInternLieferantAdded   Wörtlicher Wert für "Lieferant" nach Neuanlegen in
       *                                  Lieferant-Einzel-Ansicht
       *                                    - wenn leer, dann Neuanlegen Lieferant z.B. abgebrochen
      */
       if ( e.target.value === '<neu anlegen>' ) {
        appArticleInternLieferantAddedChange( '' ) ;
        history.push( '/lieferant/neu/' + appKundeId ) ;
      }
      else {
        if ( appArticleInternLieferantAdded !== '' ) {
          appArticleInternLieferantChange( appArticleInternLieferantAdded )
        } else {
          appArticleInternLieferantChange( e.target.value )
        }
      }
    },
    standort: (e: any) => setArticleIntern(articleInternSet('standort', e.target.value)),
  }
  function onValueThemaListe(schulungenId: any) {
    let myGefahrstoffschulungsthemen = Gefahrstoffschulungsthemen;
    if (schulungenId === 'alle') {
      myGefahrstoffschulungsthemen = [];
      setLastAddedThema('');
    } else {
      myGefahrstoffschulungsthemen = myGefahrstoffschulungsthemen.filter((entry: any) => entry !== schulungenId);
    }
    setGefahrstoffschulungsthemen(myGefahrstoffschulungsthemen);
    setAnzahlThemen(myGefahrstoffschulungsthemen.length);
    setAddAllThemen(false);
  }
  function onValueThemaDazu(schulungenId: any, schulungenTitel: string) {
    let myGefahrstoffschulungsthemen = Gefahrstoffschulungsthemen;
    if (schulungenId === 'alle') {
      setAddAllThemen(true);
    } else {
      /** vermutlich wg. State / Rerender wird die Anweisung hier mehrfach durchlaufen, deshalb Check auf "schon gesetzt" */
      if (!myGefahrstoffschulungsthemen.includes(schulungenId)) {
        myGefahrstoffschulungsthemen.push(schulungenId);
      }
    }
    setGefahrstoffschulungsthemen(myGefahrstoffschulungsthemen);
    setAnzahlThemen(myGefahrstoffschulungsthemen.length);
  }
  /** Komplex und / oder für Reuse */
  const checkboxSelectGefahrstoffschulungsThema = () => {
    if (appUserIstEinweiser === 'ja' ) {
      if ( appArticleInternId === 'neu' ) {
        return (
            <IonItem>
              <IonLabel position="stacked">Gefahrstoffschulung (Thema)</IonLabel>
              <p>
                Sie müssen eine neuen Artikel zuerst <b>anlegen</b>, bevor Sie Gefahrstoffschulungsthemen hinzufügen können
              </p>
            </IonItem>
        )
      }
      else {
        return (
          <>
            <IonItem>
              <IonLabel position="stacked">Gefahrstoffschulung (Thema) ({anzahlThemen} gewählt)</IonLabel>
              <div style={{ height: '200px', overflowY: 'scroll', width: '100%' }}>
                {
                  (Gefahrstoffschulungsthemen.toString() !== '')
                    ?
                    <IonItem lines="none" className='item'>
                      <IonLabel slot="">alle entfernen</IonLabel>
                      <IonCheckbox slot="start" key='alle' checked={false} onIonChange={() => noteUnsavedChanges( 'Gefahrstoffschulung (Thema)' ) && onValueThemaListe('alle')} />
                    </IonItem>
                    :
                    ''
                }
                {
                  appGefahrstoffschulungenThemen
                    .filter(
                      (entry: any) => Gefahrstoffschulungsthemen.includes(entry.SchulungenId)
                    )
                    .filter(
                      (entry: any) => entry.SchulungenAktiv !== 'inaktiv'
                    )
                    .map(
                      (entry: any) => {
                        /* !!! Für "neu" inaktive Gefahrstoffschulungsthemen ausblenden, für existierende Gefahrstoffschulungen als inaktiv markieren */
                        let disabled = false;
                        let disabledNote = '';
                        if (entry.SchulungenAktiv === 'inaktiv') {
                          disabled = true;
                          disabledNote = ' (inaktiv )';
                        }
                        return (
                          <IonItem lines="none" className='item'>
                            <IonLabel slot="">{entry.SchulungenTitel + disabledNote}</IonLabel>
                            <IonCheckbox slot="start" key={entry.SchulungenId + '_onList'} disabled={disabled} checked={anzahlThemen > 0 ? true : false} onIonChange={() => noteUnsavedChanges( 'Gefahrstoffschulung (Thema)' ) && onValueThemaListe(entry.SchulungenId)} />
                          </IonItem>
                        )
                      }
                    )
                }
              </div>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Thema hinzufügen {lastAddedThema !== '' ? '(zuletzt hinzugefügt:' + lastAddedThema + ')' : ''}</IonLabel>
              <div style={{ height: '200px', overflowY: 'scroll', width: '100%' }}>
                {/* {
                            ! addAllThemen
                              ?
                                <IonItem lines="none" className='item'>
                                  <IonLabel slot="">alle hinzufügen</IonLabel>
                                  <IonCheckbox slot="start" key = 'alle' checked={ false } onIonChange={() => onValueThemaDazu( 'alle' , '' ) } />
                                </IonItem>
                              :
                                ''
                          } */}
                {
                  appGefahrstoffschulungenThemen
                    .filter(
                      (entry: any) => !Gefahrstoffschulungsthemen.includes(entry.SchulungenId)
                    )
                    .filter(
                      (entry: any) => entry.SchulungenAktiv !== 'inaktiv'
                    )
                    .map(
                      (entry: any) => {
                        /* !!! Für "neu" inaktive Gefahrstoffschulungsthemen ausblenden, für existierende Gefahrstoffschulungen als inaktiv markieren */
                        let disabled = false;
                        let disabledNote = '';
                        if (entry.SchulungenAktiv === 'inaktiv') {
                          disabled = true;
                          disabledNote = ' (inaktiv )';
                        }
                        return (
                          <IonItem lines="none" className='item'>
                            <IonLabel slot="">{entry.SchulungenTitel + disabledNote}</IonLabel>
                            <IonCheckbox slot="start" key={entry.SchulungenId + '_offList'} disabled={disabled} checked={addAllThemen} onIonChange={() => noteUnsavedChanges( 'Gefahrstoffschulung (Thema)' ) && onValueThemaDazu(entry.SchulungenId, entry.SchulungenTitel)} />
                          </IonItem>
                        )
                      }
                    )
                }
              </div>
            </IonItem>
          </>
        )
      }
    }
  }
  return (
    <IonPage>
      <PageHelp page='Artikel' state={pageHelpShow} set={setPageHelpShow} >
        <p>
          Auf dieser Seite können Sie Details für einen Artikel einsehen und bearbeiten oder einen neuen Artikel hinzufügen.
        </p>
      </PageHelp>
      <FieldHelpCE state = { fieldHelpCEShow } set = { setFieldHelpCEShow } />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Artikel { articleIntern.bezeichnung }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {
          dataError
            ?
            (
              <IonCard>
                <IonCardContent>
                  Fehler: keine Daten
                </IonCardContent>
                <ButtonCancelBack
                  label = 'Abbrechen'
                  myExec =
                    {
                      () =>
                        {
                          appArticleInternLieferantAddedChange('');
                        }
                    }
                />
              </IonCard>
            )
            :
            <>
                    <IonCard>
                      <IonCardContent>
                        <IonItem className='item'>
                          <IonLabel slot="">aktiv</IonLabel>
                          <IonCheckbox slot="start" checked={ articleIntern.aktiv === 'aktiv' ? true : false } onIonChange={(e) => form.aktiv(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Artikelnummer</IonLabel>
                          <IonInput required value={articleIntern.lieferanten_artikel_nr} onIonChange={(e) => form.lieferanten_artikel_nr(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Kategorie</IonLabel>
                          <IonInput required value={articleIntern.kategorie} onIonChange={(e) => form.kategorie(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Bezeichnung</IonLabel>
                          <IonInput required value={articleIntern.bezeichnung} onIonChange={(e) => form.bezeichnung(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Bestelleinheit</IonLabel>
                          <IonInput required value={articleIntern.bestelleinheit} onIonChange={(e) => form.bestelleinheit(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Inhalt</IonLabel>
                          <IonInput required value={articleIntern.inhalt_bestelleinheit} onIonChange={(e) => form.inhalt_bestelleinheit(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Basiseinheit</IonLabel>
                          <IonInput required value={articleIntern.basiseinheit} onIonChange={(e) => form.basiseinheit(e)} />
                        </IonItem>
                        {/*
                            ! erstmal kein wählbarer Lieferant
                        
                        <IonItem className='item'>
                          <IonLabel position="stacked">Lieferant</IonLabel>
                              <IonSelect interface="action-sheet" placeholder="bitte auswählen" value={appArticleInternLieferant} onIonChange={(e) => form.lieferant(e)} >
                                <IonSelectOption key="<neu anlegen>" value="<neu anlegen>">&lt;neu anlegen&gt;</IonSelectOption>
                                {articleIntern.lieferant
                                  ?
                                  appLieferantenListe
                                    .filter(
                                      ({ LieferantenName }: any) => LieferantenName === articleIntern.lieferant
                                    )
                                    .length === 0
                                    ?
                                    <IonSelectOption style = { { backgroundColor: 'lime' } } key={articleIntern.lieferant} value={articleIntern.lieferant}>{articleIntern.lieferant}</IonSelectOption>
                                    :
                                    ''
                                  :
                                  ''
                                }
                                {
                                  appLieferantenListe
                                    .map(
                                      (entry: any) => {
                                        let disabled = false;
                                        let disabledNote = '';
                                        if (entry.LieferantenStatus === 'inaktiv') {
                                          disabled = true;
                                          disabledNote = ' (inaktiv )';
                                        }
                                        return <IonSelectOption key={entry.LieferantenName+entry.LieferantenId} disabled={disabled} value={entry.LieferantenName} >{entry.LieferantenName + disabledNote}</IonSelectOption>
                                      }
                                    )
                                }
                              </IonSelect>
                        </IonItem> */}
                        {
                          checkboxSelectGefahrstoffschulungsThema()
                        }
                        {/* !? für neue Artikel einen "ButtonSaveClose" = kein "Speichern" ohne "Schließen" */}
                        <ButtonsSaveActions
                          myExec={
                            () =>
                              {
                                appArticleInternLieferantAddedChange('');
                                setUnsavedChanges( Array() ) ;
                                sendChangesArticleIntern();
                               }
                          }
                        />
                        <ButtonCancelBack
                          label='Abbrechen'
                          myExec =
                            {
                              () =>
                                {
                                  appArticleInternLieferantAddedChange('');
                                  if( unsavedChanges.toString() !== '' ) {
                                    if(
                                      ! window.confirm(
                                        '\n'
                                        + 'In den folgenden Feldern liegen nicht gespeicherte Änderungen vor:'
                                        + '\n\n' + unsavedChanges.join( ', ' )
                                        + '\n\n'
                                      )
                                    )
                                      {
                                        return false ;
                                      }
                                  }
                                }
                            }
                        />
                      </IonCardContent>
                    </IonCard>
                  </>
              }
              {
                appArticleInternId === "neu"
                  ? 
                    <>
                      <IonCard class="Dokumente">
                        <IonCardHeader>
                          <IonCardTitle>Dokumente</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          Sie müssen einen neuen Artikel zuerst <b>anlegen</b>, bevor Sie Dokumente hinzufügen können
                        </IonCardContent>
                      </IonCard>
                    </>
                  : (
                    <>
                      {
                        appUserIstEinweiser === 'ja'
                          ?
                          <IonCard class="Gefahrstoffschulungen">
                            <IonCardHeader>
                              <IonCardTitle>Gefahrstoffschulungen</IonCardTitle>
                            </IonCardHeader>
                            {
                              Gefahrstoffschulungen.length > 0
                                ?
                                <Box width="100vh"
                                  sx={{
                                    height: 300,
                                    width: 1,
                                    "& .super-app-theme--header": {
                                      backgroundColor: "rgb(211,211,211)",
                                    },
                                  }}
                                >
                                  <DataGrid
                                    disableVirtualization
                                    initialState={{
                                      sorting: {
                                        sortModel: [{ field: 'GefahrstoffschulungenTermin', sort: 'asc' }],
                                      },
                                    }}
                                    getRowId={(row) => row.GefahrstoffschulungenId}
                                    rows={Gefahrstoffschulungen}
                                    columns={
                                      [
                                        defColumn("GefahrstoffschulungenId", "Id", 0),
                                        defColumn("GefahrstoffschulungenStatus", "Status", 90, 0),
                                        /** "GefahrstoffschulungenMitarbeiterId"
                                        *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                                        *        => wg. lokaler Daten-States statt useContext vorerst via
                                        *           Copy & Paste zu handlen
                                        */
                                        defColumnRenderCell(
                                          defColumn("GefahrstoffschulungenTermin", "Termin", 100, 0),
                                          (data: any) => data.row.GefahrstoffschulungenTermin === '0000-00-00'
                                            ?
                                            '(offen)'
                                            :
                                            new Date(data.row.GefahrstoffschulungenTermin).toLocaleDateString()
                                        ),
                                        defColumn('GefahrstoffschulungenDozent', 'Dozent/in', 140, 0),
                                        defColumnRenderCell(
                                          defColumn('GefahrstoffschulungenThema', 'Thema', 300, 1, 300),
                                          (params: any) => {
                                            if (params.row.GefahrstoffschulungenThema) {
                                              let matchesGefahrstoffschulungenTitel = appGefahrstoffschulungenThemen
                                                .find(
                                                  (entry: any) => entry.SchulungenId === params.row.GefahrstoffschulungenThema
                                                )
                                              if (matchesGefahrstoffschulungenTitel) {
                                                return matchesGefahrstoffschulungenTitel.SchulungenTitel;
                                              }
                                            }
                                          }
                                        ),
                                        /** "GefahrstoffschulungenMitarbeiterId"
                                         *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                                         *        => wg. lokaler Daten-States statt useContext vorerst via
                                         *           Copy & Paste zu handlen
                                         */
                                        defColumnRenderCell(
                                          defColumn("GefahrstoffschulungenMitarbeiterId", "Teilnehmer"),
                                          (params: any) => {
                                            if (params.row.GefahrstoffschulungenMitarbeiterId) {
                                              let matchesMitarbeiterDB = appMitarbeiterListe
                                                .filter(
                                                  ({ MitarbeiterId }: { MitarbeiterId: any }) =>
                                                    /** Präfigierung mit ',' für z.B. "89" vs. "189" (",89" vs. ",189")
                                                     *  - in Klammern wg. "( ',' xy).includes" vs. "',' + (xy.includes)"  
                                                     */
                                                    (',' + params.row.GefahrstoffschulungenMitarbeiterId)
                                                      .includes(',' + MitarbeiterId)
                                                )
                                                .map(
                                                  ({ MitarbeiterNachname }: { MitarbeiterNachname: any }) => MitarbeiterNachname
                                                )
                                              if (matchesMitarbeiterDB) {
                                                return matchesMitarbeiterDB.toString();
                                              }
                                            }
                                          }
                                        )
                                      ]
                                    }
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    disableSelectionOnClick
                                    onCellClick={
                                      (e: any) => {
                                        appViewSwitchChange(true);
                                        appGefahrstoffschulungIdChange(e.id);
                                        history.push('/gefahrstoffschulung/' + e.id )
                                      }
                                    }
                                    sortingOrder={["desc", "asc"]}
                                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                                    pagination
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                  />
                                </Box>
                                :
                                ""
                            }
                            <ButtonAdd
                              myExec = 
                                {
                                  () =>
                                    {
                                      if( unsavedChanges.includes( 'Gefahrstoffschulung (Thema)' ) ) {
                                        alert(
                                          'Die Artikel-Eigenschaft "Gefahrstoffschulung (Thema)" wurde geändert. Das Planen von neuen Gefahrstoffschulungen ist erst nach Speichern der Änderungen möglich.\n'
                                        ) ;
                                        return false ;
                                      }
                                      if( Gefahrstoffschulungsthemen.toString() === '' ) {
                                        alert(
                                          'Sie müssen dem Artikel ein Thema für Gefahrstoffschulungen zuordnen, damit neue Gefahrstoffschulungen geplant werden können.'
                                        ) ;
                                        return false ;
                                      }
                                      appGefahrstoffschulungNeuThemaChange( Gefahrstoffschulungsthemen[ 0 ] ) ;
                                      appGefahrstoffschulungIdChange('neu') ;
                                    }
                                }
                              routeUrl={'/gefahrstoffschulung/neu'}
                            />
                            {
                              Gefahrstoffschulungen.length > 0
                                ?
                                <IonButton onClick={() => exportTableData(Gefahrstoffschulungen, 'Gefahrstoffschulungen Artikel ' + appArticleInternId)}>Exportieren</IonButton>
                                :
                                ''
                            }
                          </IonCard>
                          :
                          ''
                      }
                      <IonCard class="Dokumente">
                        <IonAlert
                          isOpen={dokumentToActOn ? true : false}
                          backdropDismiss={true}
                          header='Gewählte Datei ...'
                          message=''
                          buttons={
                            [
                              {
                                text: 'Herunterladen',
                                handler: () => {
                                  getDokumentDatei(dokumentToActOn);
                                  setDokumentToActOn('');
                                }
                              },
                              {
                                text: 'Listeneintrag bearbeiten',
                                handler: () => {
                                  appViewSwitchChange( true ) ;
                                  appArticleInternDokumenteIdChange( dokumentToActOn ) ;
                                  history.push('/dokument/' + dokumentToActOn + '/' + appArticleInternId)
                                  setDokumentToActOn('');
                                }
                              },
                              {
                                text: 'Abbrechen',
                                handler: () => {
                                  setDokumentToActOn('');
                                }
                              }
                            ]
                          }
                        />
                        <IonCardHeader>
                          <IonCardTitle>Dokumente</IonCardTitle>
                        </IonCardHeader>
                        {
                          appArticleInternDokumente.length > 0
                            ?
                            <Box width="100vh"
                              sx={{
                                height: 300,
                                width: 1,
                                "& .super-app-theme--header": {
                                  backgroundColor: "rgb(211,211,211)",
                                },
                              }}
                            >
                              <DataGrid
                                disableVirtualization
                                initialState={{
                                  sorting: {
                                    sortModel: [{ field: 'DokumenteDateidatum', sort: 'desc' }],
                                  },
                                }}
                                getRowId={(row) => row.DokumenteId}
                                getRowClassName =
                                {
                                  ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                                }
                                rows = { localArticleInternDokumente }
                                columns={
                                  [
                                    defColumn( "DokumenteId" , "Id" , 0 ) ,
                                    defColumnRenderCell(
                                      defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                      ( data:any ) =>
                                        new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                    ) ,
                                    defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                    defColumnRenderCell(
                                      defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                      ( data:any ) =>
                                        readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                    ) ,
                                    defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                                  ]
                                }
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 50, 100]}
                                disableSelectionOnClick
                                onCellClick=
                                {
                                  (e: any) => {
                                    /** Browser / Web vs. App nativ */
                                    if (navigator) {
                                      setDokumentToActOn(e.id);
                                    } else {
                                      appArticleInternDokumenteIdChange( e.id ) ;
                                      appViewSwitchChange(true);
                                      history.push('/dokument/' + e.id + '/' + appArticleInternId)
                                    }
                                  }
                                }
                                sortingOrder={["desc", "asc"]}
                                onPageSizeChange={(newPage) => setPageSize(newPage)}
                                pagination
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                              />
                            </Box>
                            :
                            ""
                        }
                        <ButtonInaktive
                          disabled = { existInaktive ? false : true }
                          myExec = {
                            () =>
                              {
                                setShowInaktive( showInaktive ? false : true )
                              }
                          }
                          label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                        />
                        <ButtonAdd
                          myExec={() => { appArticleInternDokumenteIdChange( 'neu') ; }}
                          routeUrl={'/dokument/neu/' + appArticleInternId}
                        />
                        {
                          appArticleInternDokumente.length > 0
                            ?
                            <IonButton onClick={() => exportTableData(appArticleInternDokumente, 'Dokumente Artikel ' + appArticleInternId)}>Exportieren</IonButton>
                            :
                            ''
                        }
                      </IonCard>
                    </>
                  )
              }
      </IonContent>
    </IonPage>
  );
};

