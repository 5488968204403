/**
 * Messgerät zuordnen
 * 
 *  - falls dem aktuellen Gerät noch nie ein Messgerät zugeordnet war
 * 
 */

import { IonButton, IonCardContent } from "@ionic/react";
import { useContext } from "react";
import { AppContext } from "../../contexts/app_context";



export const MessgeraetNeu = () =>
  {
    const {
        appIotMessgeraeteAktionChange
      } = useContext( AppContext ) ;

    return (
      <IonCardContent>
        Das Gerät war bislang noch mit keinen Messgeräten verbunden.
        <br/>
        <IonButton
          onClick = {
            () => appIotMessgeraeteAktionChange( 'assign' )
          }
        >
          Messsgerät zuordnen
        </IonButton>
      </IonCardContent>
    );
 
  }
