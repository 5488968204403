/**
 * Geraet - Einzel-Ansicht Gerät für Neuanlegen Gerät oder Änderung / Einsicht Gerät-Daten inkl. Aufgaben
 * 
 */

//#region Modules
//#region GUI
//#region Ionic-GUI
import { helpOutline, informationCircle } from 'ionicons/icons';
import { Hourglass , ThumbsDown, ThumbsUp } from 'react-ionicons';
import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
//#endregion Ionic-GUI
//#region Material UI (MUI)
import Box from "@mui/material/Box";
import { DataGrid, deDE } from "@mui/x-data-grid";
//#endregion Material UI (MUI)
//#endregion GUI
//#region Routing
import { useHistory } from 'react-router-dom';
//#endregion Routing
//#region Data-Fetch
import axios from 'axios';
//#endregion Data-Fetch
//#region React-Tools
import { useContext, useEffect, useState } from 'react';
//#endregion React-Tools
//#region Hilfs-Funktionen
import { MedmehrAngebotAnnehmenAblehnen, ButtonAdd, ButtonCancelBack, ButtonInaktive , ButtonsSaveActions, defColumn, defColumnRenderCell, einweisungstypKeyToTitle, exportTableData, getDokumentDatei, getMedmehrHostedAngebot, getMedmehrHostedAufgabe, ButtonPageHelp, PageHelp,readableFileSize, FieldHelpCE } from '../components/_HelperFunctions';
//#endregion Hilfs-Funktionen
//#region Komponenten
import { Messgeraete } from '../components/messgeraete/messgeraete';
//#endregion Komponenten
//#region useContext-Providing
import { AppContext } from '../contexts/app_context';
import { useDokumenteGeraet, useEinweisungenListe, useEinweisungGeraeteThemen, useEinweisungsThemen, useGeraetegruppenPossible , useGeraeteklassenListe, useLieferantenListe, useMitarbeiterListe } from '../components/_CustomHooks';
//#endregion useContext-Providing
//#endregion Modules

//#region React-Komponente
export function Geraet() {
  useIonViewWillEnter(
    () => {
      // appEinweisungGeraeteThemenFetchanewChange(true);
    }
  );
  useIonViewDidEnter(
    () => {
      appViewSwitchChange(false);
      // console.log( 'appEinweisungGeraeteThemen:' , appEinweisungGeraeteThemen ) ;
      // appEinweisungGeraeteThemenFetchanewChange(true);
      // console.log( 'appEinweisungGeraeteThemen:' , appEinweisungGeraeteThemen ) ;
    }
  );
  useIonViewWillLeave(
    () => {
      appGeraeteListeFetchanewChange(true) ;
    }
  );
  //#region State-Management
  //#region useContext
  const { appAufgabenIdChange, appAufgabenGeraetFetchanew, appAufgabenGeraetFetchanewChange, appDokumenteGeraetFetchanewChange, appBetriebeId, appDokumenteGeraet, appDokumenteGeraetIdChange , appEinweisungenIdChange, appEinweisungenGeraetFetchanewChange, appEinweisungGeraeteThemen , appEinweisungNeuThemaChange ,appEinweisungGeraeteThemenFetchanewChange, appEinweisungenListeFetchanewChange , appEinweisungsThemenFetchanewChange, appGeraetegruppenPossible , appGeraetegruppenPossibleFetchanewChange , appGeraeteIdChange , appGeraeteklassenIdChange , appGeraeteListeFetchanewChange, appGeraetHasIotMessungChange ,appGeraeteId, appGeraeteklassenListe, appGeraetSchulung, appGeraetSchulungChange, appGeraetetypAdded, appGeraetetypAddedChange, appGeraetTyp, appGeraetTypChange, appGeraetService , appGeraetServiceChange , appGeraetServiceAdded , appGeraetServiceAddedChange, appKundeId , appLieferantenListe, appMitarbeiterListe, appSchulungAdded, appSchulungAddedChange, appEinweisungenListeData, appEinweisungsThemenListe, appUserIstEinweiser, appViewSwitchChange } = useContext(AppContext);
  // TypeScript erlaubt (zurecht) kein direktes ".sort" für Daten in useContext
  //#endregion useContext
  //#region pageHelp
  const [pageHelpShow, setPageHelpShow] = useState(false);
  const [ fieldHelpCEShow , setFieldHelpCEShow ] = useState( false ) ;
  const screencastChannel = '' ;
  //#endregion pageHelp
  //#region Routing
  const history = useHistory();
  //#endregion Routing
  //#region Data-Fetch
  const [dataError, setDataError] = useState(false);
  // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  const [fetchState, setFetchState] = useState(0);
  const [tableData, settableData] = useState(Array({ AufgabenId: "1" }));
  //#endregion Data-Fetch
  const [ localDokumenteGeraet , localDokumenteGeraetChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  //#region Dialog-Dokument
  /** Eintrag bearbeiten vs. Dokument herunterladen */
  const [dokumentToActOn, setDokumentToActOn] = useState('');
  //#endregion Dialog-Dokument
  //#region Formular Geräte-Daten
  /**  - für bearbeitbare Formular-Felder States erforderlich */
  /** "Hilfs-States" variables Rendern einzelner Felder  */
  const [GeraeteSchulungRender, setGeraeteSchulungRender] = useState('auto')
  const [GeraeteServiceRender, setGeraeteServiceRender] = useState('auto')
  /** State-Object (statt einzelner States) */
  const [geraet, setGeraet] = useState(
    {
      id: appGeraeteId,
      baujahr: '',
      CE: '',
      eigentuemer: '',
      gesperrt: '0' ,
      hersteller: '',
      intervall: 0,
      serien_nr: '-' ,
      kunden_id: '-' ,
      mpg_klasse: '',
      name: '',
      service: '',
      standort: '',
      typ: '',
      wartung: '0000-00-00',
    }
  );
  /** Hilfsfunktion für Direktzugriff auf Property des State-Objects  */
  const geraetSet = (prop: string, val: any) => {
    if (val === null) {
      if (prop === 'wartung') {
        val = '0000-00-00';
      } else {
        val = '';
      }
    }
    return Object.defineProperty(geraet, prop, { value: val });
  }
  /** Flag für evtl. nicht gesicherte Änderungen */
  const [ unsavedChanges, setUnsavedChanges ] = useState( Array() ) ;
  const noteUnsavedChanges = ( fieldName:string ) => {
    let myUnsavedChanges = unsavedChanges ;
    if( ! myUnsavedChanges.includes( fieldName ) ) {
      myUnsavedChanges.push( fieldName ) ;
      setUnsavedChanges( myUnsavedChanges ) ;
    }
    return true ;
  }
  /** checkboxSelectEinweisungsThema */
  const [Einweisungsthemen, setEinweisungsthemen] = useState( Array() )
  const [addAllThemen, setAddAllThemen] = useState(false);
  /** ! "lastAddedThema" auch als Trigger, damit Ionic re-rendert */
  const [lastAddedThema, setLastAddedThema] = useState('');
  /** ! "anzahlThemen" auch als Trigger, damit Ionic re-rendert */
  const [anzahlThemen, setAnzahlThemen] = useState(0);
  /** Auf das Gerät bezogene Einweisungen */
  const [Einweisungen, setEinweisungen] = useState( Array() )
  //#endregion Formular Geräte-Daten
  //#region Listen-DataGrids für Attachments
  const [pageSize, setPageSize] = useState(10);
  //#endregion Listen-DataGrids für Attachments
  //#endregion State-Management

  //#region Data-Fetches
  //#region Trigger setzen
  useEffect(
    () => {
      if (history.location.pathname.includes('/geraet/')) {
        if (appGeraeteId !== 'neu') {
          appAufgabenGeraetFetchanewChange(true);
          appDokumenteGeraetFetchanewChange(true);
          appEinweisungenGeraetFetchanewChange(true);
          appEinweisungGeraeteThemenFetchanewChange(true);
        }
      }
    },
    [appGeraeteId]
  );
  useEffect(
    () =>
      {
        appGeraetegruppenPossibleFetchanewChange( true ) ;
      },
      [ appGeraeteklassenListe ]
  );
  //#endregion Trigger setzen
  //#region Gerätegruppen
  /**
   *  - für Auswahlliste bei Neuerstellung oder Bearbeitung Gerät
   *    - irrelevant für nicht änderbare "MM_"-Geräte, dort nur Anzeige aus Einzel-Gerät-Datensatz
   *  - Konzept: Gerätekategorien-Liste bereitstellen, um aus dieser Gerätegruppen abzuleiten (s.u.)
   */
  // useGeraeteklassenListe();
  useGeraetegruppenPossible() ;
  //#endregion Gerätegruppen
  //#region Einweisungs-Themen
  useEinweisungsThemen();
  //#endregion Einweisungs-Themen
  //#region Geräte-Daten
  const getDataGeraet = () => {
    if (appGeraeteId === 'neu') {
      setGeraet( geraetSet('id', 'neu'));
      setGeraet(geraetSet('baujahr', ''));
      setGeraet(geraetSet('CE', ''));
      setGeraet(geraetSet('eigentuemer', ''));
      setGeraet(geraetSet('gesperrt', '0'));
      setGeraet(geraetSet('hersteller', ''));
      setGeraet(geraetSet('intervall', 0));
      setGeraet(geraetSet('serien_nr', '-'));
      setGeraet(geraetSet('kunden_id', '-'));
      setGeraet(geraetSet('mpg_klasse', 'keine'));
      setGeraet(geraetSet('name', ''));
      setGeraet(geraetSet('service', ''))
      if (appGeraetServiceAdded === '') {
        appGeraetServiceChange('');
      }
      setGeraet(geraetSet('standort', ''));
      setGeraet(geraetSet('typ', ''));
      appGeraetTypChange('');
      setGeraet(geraetSet('wartung', '0000-00-00'));
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/geraete/get_geraet.php?geraete_id=` + appGeraeteId,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        },
      });
      api.get("")
        .then(
          (res) => {
            setDataError(false);
            let data = res.data;
            data.forEach(
              (geraete: any) => {
                if (geraete.geraeteId === appGeraeteId) {
                  setGeraet(geraetSet('id', geraete.geraeteId));
                  setGeraet(geraetSet('baujahr', geraete.baujahr));
                  setGeraet(geraetSet('CE', geraete.GeraeteCE));
                  setGeraet(geraetSet('eigentuemer', geraete.GeraeteEigentuemer));
                  setGeraet(geraetSet('gesperrt', geraete.GeraeteGesperrt));
                  setGeraet(geraetSet('hersteller', geraete.GeraeteHersteller));
                  setGeraet(geraetSet('intervall', parseInt(geraete.GeraeteIntervall, 10)));
                  setGeraet(geraetSet('serien_nr', geraete.geraete_seriennummer));
                  setGeraet(geraetSet('kunden_id', geraete.geraete_kunde_id1));
                  setGeraet(geraetSet('mpg_klasse', geraete.GeraeteMPGKlasse));
                  setGeraet(geraetSet('name', geraete.GeraeteName));
                  ( appGeraeteId + '').substring(0, 3) === 'MM_'
                    ?
                      setGeraet(geraetSet('service', 'medmehr'))
                    :
                      setGeraet(geraetSet('service', geraete.GeraeteService))
                  if (appGeraetServiceAdded === '') {
                    appGeraetServiceChange(geraete.GeraeteService);
                  }
                  setGeraet(geraetSet('standort', geraete.GeraeteStandort));
                  setGeraet(geraetSet('typ', geraete.GeraeteTyp));
                  if (appGeraetetypAdded === '') {
                    appGeraetTypChange(geraete.GeraeteTyp);
                  }
                  setGeraet(geraetSet('wartung', geraete.GeraeteWartung));
                  appGeraetHasIotMessungChange( geraete.has_iot_messung ) ;
                }
              }
            )
          }
        )
        .catch(
          () => setDataError(true)
        );
    }
  }
  useEffect(
    () => getDataGeraet(),
    [appBetriebeId, appGeraeteId]
  );
  useEffect(
    () => {
      let myEinweisungsthemen = Array() ;
      appEinweisungGeraeteThemen
        .forEach(
          (thema: any) => {
            if( thema.GeraetId === appGeraeteId ) {
              if( thema.ThemaId !== '' ) {
                myEinweisungsthemen.push( thema.ThemaId ) ;
              }
            }
          }
        )
        setEinweisungsthemen( myEinweisungsthemen );
        setAnzahlThemen(myEinweisungsthemen.length ) ;  
      },
    [ appEinweisungGeraeteThemen , appGeraeteId ]
  );
  useEffect(
    () => {
      let myEinweisungen = appEinweisungenListeData
           .filter(
            ( entry:any ) =>
              {
                if(
                  appEinweisungsThemenListe
                    .findIndex(
                      ( thema:any ) => {
                        if( thema.SchulungenId === entry.EinweisungenThema ) {
                          if( thema.SchulungenAktiv === 'aktiv' ) {
                            return true
                          }
                        }
                      }
                    ) > -1
                )
                  { 
                    return true ;
                  }
              }
           )
        .filter(
          ( einweisung:any ) =>
            { 
              return Einweisungsthemen.includes( einweisung.EinweisungenThema )
            }
        )
      setEinweisungen( myEinweisungen ) ;
      },
    [ appEinweisungenListeData , appEinweisungsThemenListe , Einweisungsthemen ]
  );
  useDokumenteGeraet();

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteGeraetChange( appDokumenteGeraet )
      }
      else {
        localDokumenteGeraetChange(
          appDokumenteGeraet
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appDokumenteGeraet
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appDokumenteGeraet ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  useEinweisungGeraeteThemen() ;
  useLieferantenListe();
  useMitarbeiterListe();
  //#endregion Geräte-Daten
  //#region Aufgaben-Liste
  const getDataAufgaben = (geraeteId: string) => {
    const api = axios.create({
      baseURL: `https://datamehr-backend.saint-online.de/api/aufgaben/get_aufgaben.php?geraete_id=` + geraeteId,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    });
    api.get("")
      .then((res) => {
        if (res.status === 200) {
          settableData(res.data);
        }
      });
  }
  useEffect(
    () => {
      if (appAufgabenGeraetFetchanew) {
        getDataAufgaben(appGeraeteId);
        appAufgabenGeraetFetchanewChange(false);
      }
    },
    [appAufgabenGeraetFetchanew, appGeraeteId]
  );
  //#endregion Aufgaben-Liste
  //#region Einweisungen-Durchführungen
  useEinweisungenListe();
  //#endregion Einweisungen-Durchführungen
  //#endregion Data-Fetches
  //#region Data-Store
  async function sendChangesGeraet() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${window.localStorage.getItem('jwt')}`);
    var raw = JSON.stringify({
      geraeteId: geraet.id,
      // GeraeteTyp: geraet.typ || '(k.A.)' ,
      GeraeteTyp: appGeraetTyp || '(k.A.)',
      GeraeteIntervall: geraet.intervall,
      GeraeteService: appGeraetService || '(k.A.)',
      GeraeteStandort: geraet.standort || '(k.A.)',
      GeraeteEigentuemer: geraet.eigentuemer || '(k.A.)',
      GeraeteCE: geraet.CE || '(k.A.)',
      GeraeteHersteller: geraet.hersteller || '(k.A.)',
      GeraeteWartung: geraet.wartung,
      GeraeteSchulung: appGeraetSchulung,
      geraete_kunde_id1: geraet.kunden_id || '(k.A.)',
      geraete_seriennummer: geraet.serien_nr || '(k.A.)',
      GeraeteMPGKlasse: geraet.mpg_klasse || '(k.A.)',
      GeraeteName: geraet.name || '(k.A.)',
      GeraeteGesperrt: geraet.gesperrt || '0',
      baujahr: geraet.baujahr || '(k.A.)',
      betriebeId: appBetriebeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/geraete/set_geraete.php', requestOptions)
    .then( response => response.json() )
    .then(
       json =>
        {
          if( json.entryId > 0 ) {
            // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
            return json.entryId
          }
          else {
            return appGeraeteId
          }
        }
       )
    .then(
       ( myGeraeteId ) =>
        {
          // appEinweisungGeraeteThemenFetchanewChange(true)
          saveChangesEinweisungsthemen( myGeraeteId )
          // .then(
          //   () =>
          //     appEinweisungGeraeteThemenFetchanewChange(true)
          //   )
          // appEinweisungGeraeteThemenFetchanewChange(true)
          return myGeraeteId ;
        }
       )
    .then(
      ( myGeraeteId ) =>
        {
          // appEinweisungGeraeteThemenFetchanewChange(true)
          appGeraeteIdChange( myGeraeteId ) ;
          setGeraet( geraetSet( 'id' , myGeraeteId ) );
          // appGeraeteListeFetchanewChange(true) ;
          // appEinweisungGeraeteThemenFetchanewChange(true)
          // history.replace( history.location.pathname.replace( 'neu' , myGeraeteId ) ) ;
        }
      )
    // .then(
    //   () =>
    //     appEinweisungGeraeteThemenFetchanewChange(true)
    //   )
  }
  async function saveChangesEinweisungsthemen( myGeraetId:any ) {
    /** ! Möglich: Sowohl neue Zuordnung dazu wie bestehende löschen */
    let raw = '';
    /** Mutable Einweisungsthemen */
      let chkEinweisungsthemen = Einweisungsthemen ;
    /** Existierende Geräte-Themen-Paare abgleichen */
      appEinweisungGeraeteThemen
        .filter(
          ( thema:any) =>
            thema.GeraetId === appGeraeteId
        )
        .forEach(
          ( thema:any) =>
            {
              /** vorhandenen Eintrag löschen oder beibehalten */
                if ( ! chkEinweisungsthemen.includes( thema.ThemaId) ) {
                  /** vorhandener Eintrag nicht in chkEinweisungsthemen => ThemaId löschen
                   *  - Backend entscheidet, ob der Eintrag insgesamt gelöscht oder re-used wird
                   */
                    /** bereits "leere" Einträge nicht nochmals schreiben */
                      if ( thema.ThemaId !== '' ) {
                        raw = JSON.stringify({
                          EntryId: thema.EntryId ,
                          KundeId: appKundeId ,
                          BetriebId: appBetriebeId ,
                          GeraetId: myGeraetId ,
                          ThemaId: ''
                          });
                        sendChangesEinweisungsThema(raw) ;
                      }
                }
                else {
                  /** vorhandenen Eintrag von chkEinweisungsthemen nehmen */
                    chkEinweisungsthemen = chkEinweisungsthemen
                      .filter(
                        ( entry:any ) => entry !== thema.ThemaId
                      )
                }
              }
        )
      /** Etwaige verbleibende neue Geräte-Themen-Paare schreiben */
        chkEinweisungsthemen
          .forEach(
            ( entry:any ) =>
              {
                raw = JSON.stringify(
                  {
                    KundeId: appKundeId ,
                    BetriebId: appBetriebeId ,
                    GeraetId: myGeraetId ,
                    ThemaId: entry
                  }
                );
                sendChangesEinweisungsThema(raw);
                appEinweisungenListeFetchanewChange( true ) ;
                appEinweisungGeraeteThemenFetchanewChange( true ) ;
              }
          )
  }
  async function sendChangesEinweisungsThema(JSON: any) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${window.localStorage.getItem('jwt')}`);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON,
    };
    await fetch('https://datamehr-backend.saint-online.de/api/themen/set_geraete_themen.php', requestOptions)
    // .then(
    //   () =>
    //     {
    //       appEinweisungGeraeteThemenFetchanewChange(true);
    //     }
    // )
  }
  //#endregion Data-Store
  //#region Formular-Funktionen
  //#region Feld-Werte setzen
  const form = {
    baujahr: (e: any) => setGeraet(geraetSet('baujahr', e.target.value)),
    bezeichnung: (e: any) => setGeraet(geraetSet('name', e.target.value)),
    CE: (e: any) => setGeraet(geraetSet('CE', e.target.value)),
    eigentuemer: (e: any) => setGeraet(geraetSet('eigentuemer', e.target.value)),
    gesperrt: (e: any) => {
      setGeraet(
        geraetSet(
          'gesperrt',
          e.detail.checked
          ? 
            '0'
          :
            '1'
        )
      )
    },
    hersteller: (e: any) => setGeraet(geraetSet('hersteller', e.target.value)),
    serien_nr: (e: any) => setGeraet(geraetSet('serien_nr', e.target.value)),
    kunden_id: (e: any) => setGeraet(geraetSet('kunden_id', e.target.value)),
    mpgKlasse: (e: any) => setGeraet(geraetSet('mpg_klasse', e.target.value)),
    servicepartner: ( e:any ) => {
      /** Servicepartner neu anlegen oder auswählen
       *  @param  appGeraetService        Wörtlicher Wert für "Servicepartner" in Formular und sendJSON
       *                                    - app-State für garantierte Aktualisierung
       *  @param  appGeraetServiceAdded   Wörtlicher Wert für "Servicepartner" nach Neuanlegen in
       *                                  Servicepartner-Einzel-Ansicht
       *                                    - wenn leer, dann Neuanlegen Servicepartner z.B. abgebrochen
      */
       if ( e.target.value === '<neu anlegen>' ) {
        appGeraetServiceAddedChange( '' ) ;
        history.push( '/lieferant/neu/' + appKundeId ) ;
      }
      else {
        if ( appGeraetServiceAdded !== '' ) {
          appGeraetServiceChange( appGeraetServiceAdded )
        } else {
          appGeraetServiceChange( e.target.value )
        }
      }
    },
    schulung: (e: any) => {
      if (e.target.value === '<manuell eingeben>') {
        appViewSwitchChange( true ) ;
        appGeraetSchulungChange('')
        setGeraeteSchulungRender('auto')
        history.push('/schulung/neu/' + appBetriebeId)
      }
      else {
        if (appSchulungAdded !== '') {
          appGeraetSchulungChange(appSchulungAdded)
          appSchulungAddedChange('');
        } else {
          appGeraetSchulungChange(e.target.value)
        }
      }
    },
    standort: (e: any) => setGeraet(geraetSet('standort', e.target.value)),
    typ: (e: any) => {
      /** Gruppe neu anlegen oder auswählen
       *  @param  appGeraetTyp        Wörtlicher Wert für "Gruppe" in Formular und sendJSON
       *                                - app-State für garantierte Aktualisierung
       *  @param  appGeraetetypAdded  Wörtlicher Wert für "Gruppe" nach Neuanlegen in Geraeteklasse.tsx
       *                                - wenn leer, dann Geraeteklasse.tsx z.B. abgebrochen
      */
      if (e.target.value === '<neu anlegen>') {
        appGeraetetypAddedChange('');
        appGeraeteklassenIdChange('neu');
        // history.push('/geraeteklasse/neu/' + appBetriebeId);
        history.push('/geraetegruppe/neu/' + appBetriebeId);
      }
      else {
        if (appGeraetetypAdded !== '') {
          // setGeraet( geraetSet( 'typ' , appGeraetetypAdded ) ) ;
          appGeraetTypChange(appGeraetetypAdded)
        } else {
          // setGeraet( geraetSet( 'typ' , e.target.value ) ) ;
          appGeraetTypChange(e.target.value)
        }
      }
    },
    wartungNaechste: (e: any) => setGeraet(geraetSet('wartung', e.target.value)),
    wartungIntervall: (e: any) => setGeraet(geraetSet('intervall', e.target.value)),
  }
  //#endregion Feld-Werte setzen
  //#region Einweisungs-Thema setzen
  function onValueThemaListe(schulungenId: any) {
    let myEinweisungsthemen = Einweisungsthemen;
    if (schulungenId === 'alle') {
      myEinweisungsthemen = [];
      setLastAddedThema('');
    } else {
      myEinweisungsthemen = myEinweisungsthemen.filter((entry: any) => entry !== schulungenId);
    }
    setEinweisungsthemen(myEinweisungsthemen);
    setAnzahlThemen(myEinweisungsthemen.length);
    setAddAllThemen(false);
  }
  function onValueThemaDazu(schulungenId: any, schulungenTitel: string) {
    let myEinweisungsthemen = Einweisungsthemen;
    if (schulungenId === 'alle') {
      setAddAllThemen(true);
    } else {
      /** vermutlich wg. State / Rerender wird die Anweisung hier mehrfach durchlaufen, deshalb Check auf "schon gesetzt" */
      if (!myEinweisungsthemen.includes(schulungenId)) {
        myEinweisungsthemen.push(schulungenId);
      }
      // setLastAddedThema(schulungenTitel);
    }
    setEinweisungsthemen(myEinweisungsthemen);
    setAnzahlThemen(myEinweisungsthemen.length);
  }
  //#region Einweisungs-Thema setzen    
  //#region Komplexe Felder
  /** Komplex und / oder für Reuse */
  //#region Einweisungsthema
  const checkboxSelectEinweisungsThema = () => {
    if (appUserIstEinweiser === 'ja' ) {
      if ( appGeraeteId === 'neu' ) {
        return (
            <IonItem>
              <IonLabel position="stacked">Einweisung (Thema)</IonLabel>
              <p>
                Sie müssen ein neues Gerät zuerst <b>anlegen</b>, bevor Sie Einweisungsthemen hinzufügen können
              </p>
            </IonItem>
        )
      }
      else {
        return (
          <>
            <IonItem>
              <IonLabel position="stacked">Einweisung (Thema) ({anzahlThemen} gewählt)</IonLabel>
              <div style={{ height: '200px', overflowY: 'scroll', width: '100%' }}>
                {
                  (Einweisungsthemen.toString() !== '')
                    ?
                    <IonItem lines="none" className='item'>
                      <IonLabel slot="">alle entfernen</IonLabel>
                      <IonCheckbox slot="start" key='alle' checked={false} onIonChange={() => noteUnsavedChanges( 'Einweisung (Thema)' ) && onValueThemaListe('alle')} />
                    </IonItem>
                    :
                    null
                }
                {
                  appEinweisungsThemenListe
                    .filter(
                      (entry: any) => Einweisungsthemen.includes(entry.SchulungenId)
                    )
                    .filter(
                      (entry: any) => entry.SchulungenAktiv !== 'inaktiv'
                    )
                    .map(
                      (entry: any) => {
                        /* !!! Für "neu" inaktive Einweisungsthemen ausblenden, für existierende Einweisungen als inaktiv markieren */
                        let disabled = false;
                        let disabledNote = '';
                        if (entry.SchulungenAktiv === 'inaktiv') {
                          disabled = true;
                          disabledNote = ' (inaktiv )';
                        }
                        return (
                          <IonItem lines="none" className='item'>
                            <IonLabel slot="">{entry.SchulungenTitel + disabledNote}</IonLabel>
                            <IonCheckbox slot="start" key={entry.SchulungenId + '_onList'} disabled={disabled} checked={anzahlThemen > 0 ? true : false} onIonChange={() => noteUnsavedChanges( 'Einweisung (Thema)' ) && onValueThemaListe(entry.SchulungenId)} />
                          </IonItem>
                        )
                      }
                    )
                }
              </div>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Thema hinzufügen {lastAddedThema !== '' ? '(zuletzt hinzugefügt:' + lastAddedThema + ')' : ''}</IonLabel>
              <div style={{ height: '200px', overflowY: 'scroll', width: '100%' }}>
                {/* {
                            ! addAllThemen
                              ?
                                <IonItem lines="none" className='item'>
                                  <IonLabel slot="">alle hinzufügen</IonLabel>
                                  <IonCheckbox slot="start" key = 'alle' checked={ false } onIonChange={() => onValueThemaDazu( 'alle' \1 null ) } />
                                </IonItem>
                              :
                                null
                          } */}
                {
                  appEinweisungsThemenListe
                    .filter(
                      (entry: any) => !Einweisungsthemen.includes(entry.SchulungenId)
                    )
                    .filter(
                      (entry: any) => entry.SchulungenAktiv !== 'inaktiv'
                    )
                    .map(
                      (entry: any) => {
                        /* !!! Für "neu" inaktive Einweisungsthemen ausblenden, für existierende Einweisungen als inaktiv markieren */
                        let disabled = false;
                        let disabledNote = '';
                        if (entry.SchulungenAktiv === 'inaktiv') {
                          disabled = true;
                          disabledNote = ' (inaktiv )';
                        }
                        return (
                          <IonItem lines="none" className='item'>
                            <IonLabel slot="">{entry.SchulungenTitel + disabledNote}</IonLabel>
                            <IonCheckbox slot="start" key={entry.SchulungenId + '_offList'} disabled={disabled} checked={addAllThemen} onIonChange={() => noteUnsavedChanges( 'Einweisung (Thema)' ) && onValueThemaDazu(entry.SchulungenId, entry.SchulungenTitel)} />
                          </IonItem>
                        )
                      }
                    )
                }
              </div>
            </IonItem>
          </>
        )
      }
    }
  }
    //#endregion Einweisungsthema
  //#endregion Komplexe Felder
  //#endregion Formular-Funktionen

  //#region React-Render
  return (
    <IonPage>
      <PageHelp page='Gerät' state={pageHelpShow} set={setPageHelpShow} >
        <p>
          Auf dieser Seite können Sie Details für eine Gerät einsehen und bearbeiten oder für ein neues Gerät festlegen.
        </p>
      </PageHelp>
      <FieldHelpCE state = { fieldHelpCEShow } set = { setFieldHelpCEShow } />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Gerät {appGeraeteId}
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {
          dataError
            ?
            (
              <IonCard>
                <IonCardContent>
                  Fehler: keine Daten
                </IonCardContent>
                <ButtonCancelBack
                  label = 'Abbrechen'
                  myExec =
                    {
                      () =>
                        {
                          appGeraetetypAddedChange('');
                          appGeraeteklassenIdChange('neu');
                          appGeraetServiceAddedChange('');
                        }
                    }
                />
              </IonCard>
            )
            :
            <>
              {
                ( appGeraeteId + '').substring(0, 3) === 'MM_'
                  ?
                  <>
                    <IonCard>
                      <IonCardContent>
                        <IonItem lines="none" className='item'>
                          <IonLabel slot="">aktiv</IonLabel>
                          <IonCheckbox slot="start" checked={ geraet.gesperrt === '0' ? true : false } disabled = { true } />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Hersteller</IonLabel>
                          {geraet.hersteller}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Typ</IonLabel>
                          {geraet.name}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Seriennummer</IonLabel>
                          {geraet.serien_nr}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Inventarnummer</IonLabel>
                          {geraet.kunden_id}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Klasse nach MPBetreibVO</IonLabel>
                          {geraet.mpg_klasse}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Baujahr</IonLabel>
                          {geraet.baujahr}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Standort</IonLabel>
                          {geraet.standort}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Eigentümer</IonLabel>
                          {geraet.eigentuemer}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">CE Nummer (benannte Stelle)</IonLabel>
                          {geraet.CE}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Gruppe</IonLabel>
                          {appGeraetTyp}
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Wartungsintervall</IonLabel>
                          {
                            geraet.intervall === 365
                              ? '1 Jahr'
                              : geraet.intervall === 180
                                ? '6 Monate'
                                : geraet.intervall === 30
                                  ? '1 Monat'
                                  : geraet.intervall === 0
                                    ? null
                                    : geraet.intervall
                          }
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Servicepartner</IonLabel>
                          {appGeraetService}
                        </IonItem>
                        <IonItem>
                          <IonLabel position="stacked">nächste Wartung</IonLabel>
                          {
                            new Date(geraet.wartung).toLocaleDateString().replace('Invalid Date', '')
                          }
                        </IonItem>
                        {
                          checkboxSelectEinweisungsThema()
                        }
                        {
                          appUserIstEinweiser === 'ja'
                            ?
                              <IonButton
                                onClick ={() => { 
                                  appEinweisungGeraeteThemenFetchanewChange(true);
                                  saveChangesEinweisungsthemen( appGeraeteId ); }
                                }
                              >Einweisung (Thema) speichern</IonButton>
                            :
                              null
                        }
                        <ButtonCancelBack label='Zurück' myExec={() => { }} />
                      </IonCardContent>
                    </IonCard>
                  </>
                  :
                  <>
                    <IonCard>
                      <IonCardContent>
                        <IonItem className='item'>
                          <IonLabel slot="">aktiv</IonLabel>
                          <IonCheckbox slot="start" checked={ geraet.gesperrt === '0' ? true : false } onIonChange={(e) => form.gesperrt(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Hersteller</IonLabel>
                          <IonInput required value={geraet.hersteller} onIonChange={(e) => form.hersteller(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Typ</IonLabel>
                          <IonInput required value={geraet.name} onIonChange={(e) => form.bezeichnung(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Seriennummer</IonLabel>
                          <IonInput required value={geraet.serien_nr} onIonChange={(e) => form.serien_nr(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Inventarnummer</IonLabel>
                          <IonInput required value={geraet.kunden_id} onIonChange={(e) => form.kunden_id(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Klasse nach MPBetreibVO</IonLabel>
                          <IonSelect cancelText="Abbrechen" interface = "action-sheet" placeholder="bitte auswählen" value={geraet.mpg_klasse} onIonChange={(e) => form.mpgKlasse(e)} >
                            {/* <IonSelectOption key={'leer'} value={'(k.A.)'} >&nbsp;</IonSelectOption> */}
                            <IonSelectOption key={'keine'} value={'keine'} >kein Medizinprodukt</IonSelectOption>
                            <IonSelectOption key={'I'} value={'I'} >I</IonSelectOption>
                            <IonSelectOption key={'IIa'} value={'IIa'} >IIa</IonSelectOption>
                            <IonSelectOption key={'IIb'} value={'IIb'} >IIb</IonSelectOption>
                            <IonSelectOption key={'III'} value={'III'} >III</IonSelectOption>
                          </IonSelect>
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Baujahr</IonLabel>
                          <IonInput required value={geraet.baujahr} onIonChange={(e) => form.baujahr(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Standort</IonLabel>
                          <IonInput required value={geraet.standort} onIonChange={(e) => form.standort(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Eigentümer</IonLabel>
                          <IonInput required value={geraet.eigentuemer} onIonChange={(e) => form.eigentuemer(e)} />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">CE Nummer (benannte Stelle)</IonLabel>
                          <IonInput required value={geraet.CE} onIonChange={(e) => form.CE(e)} />
                          <IonIcon style={{fontSize: '24px'}} onClick={ () => { setFieldHelpCEShow( true ) ; }} color="primary" slot="end" icon={informationCircle}/>
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Geräte Gruppe</IonLabel>
                          <IonSelect cancelText="Abbrechen" interface="action-sheet" placeholder="bitte auswählen" value={appGeraetTyp} onIonChange={(e) => form.typ(e)} >
                            <IonSelectOption key="<neu anlegen>" value="<neu anlegen>">&lt;neu anlegen&gt;</IonSelectOption>
                            {
                              /** NB: IonSelect als Quasi-useEffect = Einhaken an der richtigen Stelle für erstmalige / aktualisierte Liste */
                              appGeraetegruppenPossible
                                .map(
                                  (entry: any) => (
                                    <IonSelectOption key={entry} value={entry} >{entry}</IonSelectOption>
                                  )
                                )
                            }
                          </IonSelect>
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Wartungsintervall</IonLabel>
                          <IonSelect placeholder="bitte auswählen" value={geraet.intervall} onIonChange={(e) => form.wartungIntervall(e)} >
                            <IonSelectOption key={365} value={365} >1 Jahr</IonSelectOption>
                            <IonSelectOption key={180} value={180}>6 Monate</IonSelectOption>
                            <IonSelectOption key={30} value={30}>1 Monat</IonSelectOption>
                          </IonSelect>
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Servicepartner</IonLabel>
                          {
                            GeraeteServiceRender !== 'manuell'
                              ?
                              <IonSelect cancelText="Abbrechen" interface="action-sheet" placeholder="bitte auswählen" value={appGeraetService} onIonChange={(e) => form.servicepartner(e)} >
                                <IonSelectOption key="<neu anlegen>" value="<neu anlegen>">&lt;neu anlegen&gt;</IonSelectOption>
                                {geraet.service
                                  ?
                                  appLieferantenListe
                                    .filter(
                                      ({ LieferantenName }: any) => LieferantenName === geraet.service
                                    )
                                    .length === 0
                                    ?
                                    <IonSelectOption key={geraet.service} value={geraet.service}>{geraet.service}</IonSelectOption>
                                    :
                                    null
                                  :
                                  null
                                }
                                {
                                  appLieferantenListe
                                    .map(
                                      (entry: any) => {
                                        let disabled = false;
                                        let disabledNote = '';
                                        if (entry.LieferantenStatus === 'inaktiv') {
                                          disabled = true;
                                          disabledNote = ' (inaktiv )';
                                        }
                                        return <IonSelectOption key={entry.LieferantenName+entry.LieferantenId} disabled={disabled} value={entry.LieferantenName} >{entry.LieferantenName + disabledNote}</IonSelectOption>
                                      }
                                    )
                                }
                              </IonSelect>
                              :
                              <IonInput value={geraet.service} onIonChange={(e) => form.servicepartner(e)} />
                          }
                        </IonItem>
                        <IonItem>
                          <IonLabel position="stacked">nächste Wartung</IonLabel>
                          <IonInput type="date" placeholder="bitte auswählen" required value={geraet.wartung} onIonChange={(e) => form.wartungNaechste(e)} />
                        </IonItem>
                        {
                          checkboxSelectEinweisungsThema()
                        }
                        {/* !? für neue Geräte einen "ButtonSaveClose" = kein "Speichern" ohne "Schließen" */}
                        <ButtonsSaveActions
                          myExec={
                            () =>
                              {
                                appGeraetetypAddedChange('');
                                appGeraetServiceAddedChange('');
                                setUnsavedChanges( Array() ) ;
                                sendChangesGeraet();
                               }
                          }
                        />
                        <ButtonCancelBack
                          label='Abbrechen'
                          myExec =
                            {
                              () =>
                                {
                                  appGeraetetypAddedChange('');
                                  appGeraetServiceAddedChange('');
                                  if( unsavedChanges.toString() !== '' ) {
                                    if(
                                      ! window.confirm(
                                        '\n'
                                        + 'In den folgenden Feldern liegen nicht gespeicherte Änderungen vor:'
                                        + '\n\n' + unsavedChanges.join( ', ' )
                                        + '\n\n'
                                      )
                                    )
                                      {
                                        return false ;
                                      }
                                  }
                                }
                            }
                        />
                      </IonCardContent>
                    </IonCard>
                  </>
              }
              {
                appGeraeteId === "neu"
                  ? 
                    <>
                      <IonCard class="Aufgaben">
                        <IonCardHeader>
                          <IonCardTitle>Aufgaben</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          Sie müssen ein neues Gerät zuerst <b>anlegen</b>, bevor Sie Aufgaben hinzufügen können
                        </IonCardContent>
                      </IonCard>
                      <IonCard class="Dokumente">
                        <IonCardHeader>
                          <IonCardTitle>Dokumente</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          Sie müssen ein neues Gerät zuerst <b>anlegen</b>, bevor Sie Dokumente hinzufügen können
                        </IonCardContent>
                      </IonCard>
                    </>
                  : (
                    <>
                      <IonCard class="Aufgaben">
                        <IonCardHeader>
                          <IonCardTitle>Aufgaben</IonCardTitle>
                        </IonCardHeader>
                        {
                          tableData.length > 0
                            ?
                            <Box width="100vh"
                              sx={{
                                height: 300,
                                width: 1,
                                "& .super-app-theme--header": {
                                  backgroundColor: "rgb(211,211,211)",
                                },
                              }}
                            >
                              <DataGrid
                                disableVirtualization
                                initialState={{
                                  sorting: {
                                    sortModel: [{ field: 'AufgabenAenderung', sort: 'desc' }],
                                  },
                                }}
                                getRowId={(row) => row.AufgabenId}
                                rows={tableData}
                                columns={
                                  [
                                    defColumnRenderCell(
                                      defColumn("AufgabenAenderung", "Stand" , 100 , 0 ),
                                      (data: any) => new Date(data.row.AufgabenAenderung).toLocaleDateString()
                                    ),
                                    defColumnRenderCell(
                                      defColumn("AufgabenGesamt", "Status", 60 , 0 ),
                                      (data: any) => {
                                        if ( data.row.AufgabenTyp === '9' && data.row.AufgabenId.substring( 0, 3 ) === 'MM_' ) {
                                          return (
                                            <MedmehrAngebotAnnehmenAblehnen aufgabenId = { data.row.AufgabenId } aufgabenAenderung = { data.row.AufgabenAenderung } />
                                          )
                                        }
                                        else {
                                          if (data.row.AufgabenGesamt === 0) {
                                            if (data.row.AufgabenStatus !== 100) {
                                              return <Hourglass/>
                                            }
                                            else {
                                              return <ThumbsDown color="red" />
                                            }
                                          }
                                          else {
                                            if (data.row.AufgabenGesamt === 1) {
                                              if (data.row.AufgabenStatus !== 100) {
                                                return <Hourglass/>
                                              }
                                              else {
                                                return <ThumbsUp color="green" />
                                              }
                                            }
                                            else {
                                              if (data.row.AufgabenGesamt === 2) {
                                                if (data.row.AufgabenStatus !== 100) {
                                                  return <Hourglass/>
                                                }
                                                else {
                                                  return <ThumbsUp color="gold" />
                                                }
                                              }
                                            }
                                          }
                                        }
                                    }
                                    ),
                                    defColumn("AufgabenId", "Aufgabe" , 120 , 0 ),
                                    defColumnRenderCell(
                                      defColumn("AufgabenTyp", "Art", 100 , 0 ),
                                      (data: any) => {
                                        switch (data.row.AufgabenTyp) {
                                          case '5': return 'Wartung'
                                          case '5b': return 'Wartung (beauftragt)'
                                          case '4': return 'Reparatur'
                                          case '4b': return 'Reparatur (beauftragt)'
                                          case '9': return 'Angebot'
                                          case '9b': return 'Angebot (beauftragt)'
                                        }
                                      }
                                    ),
                                    defColumnRenderCell(
                                      defColumn("AufgabenStatus", "Inhalt", 400 ),
                                      (data: any) => {
                                        if( data.row.AufgabenStatus !== 100) {
                                          return data.row.AufgabenProblem
                                        }
                                        else {
                                          return data.row.AufgabenLoesung
                                        }
                                      }
                                    )
                                  ]
                                }
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 50, 100]}
                                disableSelectionOnClick
                                onCellClick={
                                  (e: any) => {
                                    // console.log( 'e FULL:' , e ) ;
                                    if( e.field !== "AufgabenGesamt" ) {
                                      let proceed = 'default';
                                      if (e.id.substring(0, 3) === 'MM_') {
                                        if (e.row.AufgabenTyp === '9') {
                                          proceed = 'mm_angebot';
                                        }
                                        else {
                                          if (e.row.AufgabenStatus === 100) {
                                            proceed = 'mm_aufgabe_abgeschlossen';
                                          }
                                        }
                                      }
                                      if (proceed === 'mm_angebot') {
                                        getMedmehrHostedAngebot(e.id.substring(3))
                                      }
                                      if (proceed === 'mm_aufgabe_abgeschlossen') {
                                        getMedmehrHostedAufgabe(e.id.substring(3))
                                      }
                                      if (proceed === 'default') {
                                        appViewSwitchChange(true);
                                        appAufgabenIdChange(e.id);
                                        history.push('/aufgabe/' + e.id);
                                      }
                                    }
                                  }
                                }
                                sortingOrder={["desc", "asc"]}
                                onPageSizeChange={(newPage) => setPageSize(newPage)}
                                pagination
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                              />
                            </Box>
                            :
                            ""
                        }
                        <ButtonAdd
                          myExec={() => { appAufgabenIdChange('neu'); }}
                          routeUrl={'/aufgabe/' + appGeraeteId + '/neu'}
                        />
                        {
                          tableData.length > 0
                            ?
                            <IonButton onClick={() => exportTableData(tableData, 'Aufgaben Gerät ' + appGeraeteId)}>Exportieren</IonButton>
                            :
                            null
                        }
                      </IonCard>
                      {
                        appUserIstEinweiser === 'ja'
                          ?
                          <IonCard class="Einweisungen">
                            <IonCardHeader>
                              <IonCardTitle>Einweisungen</IonCardTitle>
                            </IonCardHeader>
                            {
                              Einweisungen.length > 0
                                ?
                                <Box width="100vh"
                                  sx={{
                                    height: 300,
                                    width: 1,
                                    "& .super-app-theme--header": {
                                      backgroundColor: "rgb(211,211,211)",
                                    },
                                  }}
                                >
                                  <DataGrid
                                    disableVirtualization
                                    initialState={{
                                      sorting: {
                                        sortModel: [{ field: 'EinweisungenTermin', sort: 'asc' }],
                                      },
                                    }}
                                    getRowId={(row) => row.EinweisungenId}
                                    rows={Einweisungen}
                                    columns={
                                      [
                                        defColumn("EinweisungenId", "Id", 0),
                                        defColumn("EinweisungenStatus", "Status", 90, 0),
                                        //#region EinweisungenMitarbeiterTermin
                                        /** "EinweisungenMitarbeiterId"
                                        *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                                        *        => wg. lokaler Daten-States statt useContext vorerst via
                                        *           Copy & Paste zu handlen
                                        */
                                        defColumnRenderCell(
                                          defColumn("EinweisungenTermin", "Termin", 100, 0),
                                          (data: any) => data.row.EinweisungenTermin === '0000-00-00'
                                            ?
                                            '(offen)'
                                            :
                                            new Date(data.row.EinweisungenTermin).toLocaleDateString()
                                        ),
                                        //#endregion EinweisungenMitarbeiterTermin
                                        defColumn('EinweisungenDozent', 'Dozent/in', 140, 0),
                                        defColumnRenderCell(
                                          defColumn("EinweisungenTyp", "Typ", 120, 0),
                                          (data: any) => einweisungstypKeyToTitle(data.row.EinweisungenTyp)
                                        ),
                                        defColumnRenderCell(
                                          defColumn('EinweisungenThema', 'Thema', 300, 1, 300),
                                          (params: any) => {
                                            if (params.row.EinweisungenThema) {
                                              let matchesEinweisungenTitel = appEinweisungsThemenListe
                                                .find(
                                                  (entry: any) => entry.SchulungenId === params.row.EinweisungenThema
                                                )
                                              if (matchesEinweisungenTitel) {
                                                return matchesEinweisungenTitel.SchulungenTitel;
                                              }
                                            }
                                          }
                                        ),
                                        //#region EinweisungenMitarbeiterId
                                        /** "EinweisungenMitarbeiterId"
                                         *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                                         *        => wg. lokaler Daten-States statt useContext vorerst via
                                         *           Copy & Paste zu handlen
                                         */
                                        defColumnRenderCell(
                                          defColumn("EinweisungenMitarbeiterId", "Teilnehmer"),
                                          (params: any) => {
                                            if (params.row.EinweisungenMitarbeiterId) {
                                              let matchesMitarbeiterDB = appMitarbeiterListe
                                                .filter(
                                                  ({ MitarbeiterId }: { MitarbeiterId: any }) =>
                                                    /** Präfigierung mit ',' für z.B. "89" vs. "189" (",89" vs. ",189")
                                                     *  - in Klammern wg. "( ',' xy).includes" vs. "',' + (xy.includes)"  
                                                     */
                                                    (',' + params.row.EinweisungenMitarbeiterId)
                                                      .includes(',' + MitarbeiterId)
                                                )
                                                .map(
                                                  ({ MitarbeiterNachname }: { MitarbeiterNachname: any }) => MitarbeiterNachname
                                                )
                                              if (matchesMitarbeiterDB) {
                                                return matchesMitarbeiterDB.toString();
                                              }
                                            }
                                          }
                                        )
                                        //#endregion EinweisungenMitarbeiterId
                                      ]
                                    }
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    disableSelectionOnClick
                                    onCellClick={
                                      (e: any) => {
                                        appViewSwitchChange(true);
                                        appEinweisungenIdChange(e.id);
                                        history.push('/einweisung/' + e.id + '/' + appGeraeteId)
                                      }
                                    }
                                    sortingOrder={["desc", "asc"]}
                                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                                    pagination
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                  />
                                </Box>
                                :
                                ""
                            }
                            <ButtonAdd
                              myExec = 
                                {
                                  () =>
                                    {
                                      // alert(
                                      //   ''
                                      //   + 'Einweisungsthemen: ' + Einweisungsthemen
                                      //   + '\n'
                                      //   + 'unsavedChanges: ' + unsavedChanges
                                      // ) ;
                                      if( unsavedChanges.includes( 'Einweisung (Thema)' ) ) {
                                        alert(
                                          'Die Geräte-Eigenschaft "Einweisung (Thema)" wurde geändert. Das Planen von neuen Einweisungen ist erst nach Speichern der Änderungen möglich.\n'
                                        ) ;
                                        return false ;
                                      }
                                      if( Einweisungsthemen.toString() === '' ) {
                                        alert(
                                          'Sie müssen dem Gerät ein Thema für Einweisungen zuordnen, damit neue Einweisungen geplant werden können.'
                                        ) ;
                                        return false ;
                                      }
                                      appEinweisungNeuThemaChange( Einweisungsthemen[ 0 ] ) ;
                                      appEinweisungenIdChange('neu') ;
                                    }
                                }
                              routeUrl={'/einweisung/neu'}
                            />
                            {
                              Einweisungen.length > 0
                                ?
                                <IonButton onClick={() => exportTableData(Einweisungen, 'Einweisungen Gerät ' + appGeraeteId)}>Exportieren</IonButton>
                                :
                                null
                            }
                          </IonCard>
                          :
                          null
                      }
                      <IonCard class="Dokumente">
                        <IonAlert
                          isOpen={dokumentToActOn ? true : false}
                          backdropDismiss={true}
                          header='Gewählte Datei ...'
                          message=''
                          buttons={
                            [
                              {
                                text: 'Herunterladen',
                                handler: () => {
                                  getDokumentDatei(dokumentToActOn);
                                  setDokumentToActOn('');
                                }
                              },
                              {
                                text: 'Listeneintrag bearbeiten',
                                handler: () => {
                                  appViewSwitchChange( true ) ;
                                  appDokumenteGeraetIdChange( dokumentToActOn ) ;
                                  history.push('/dokument/' + dokumentToActOn + '/' + appGeraeteId)
                                  setDokumentToActOn('');
                                }
                              },
                              {
                                text: 'Abbrechen',
                                handler: () => {
                                  setDokumentToActOn('');
                                }
                              }
                            ]
                          }
                        />
                        <IonCardHeader>
                          <IonCardTitle>Dokumente</IonCardTitle>
                        </IonCardHeader>
                        {
                          appDokumenteGeraet.length > 0
                            ?
                            <Box width="100vh"
                              sx={{
                                height: 300,
                                width: 1,
                                "& .super-app-theme--header": {
                                  backgroundColor: "rgb(211,211,211)",
                                },
                              }}
                            >
                              <DataGrid
                                disableVirtualization
                                initialState={{
                                  sorting: {
                                    sortModel: [{ field: 'DokumenteDateidatum', sort: 'desc' }],
                                  },
                                }}
                                getRowId={(row) => row.DokumenteId}
                                getRowClassName =
                                {
                                  ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                                }
                                rows = { localDokumenteGeraet }
                                columns={
                                  [
                                    defColumn( "DokumenteId" , "Id" , 0 ) ,
                                    defColumnRenderCell(
                                      defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                      ( data:any ) =>
                                        new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                    ) ,
                                    defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                    defColumnRenderCell(
                                      defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                      ( data:any ) =>
                                        readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                    ) ,
                                    defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                                      ]
                                }
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 50, 100]}
                                disableSelectionOnClick
                                onCellClick=
                                {
                                  (e: any) => {
                                    /** Browser / Web vs. App nativ */
                                    if (navigator) {
                                      setDokumentToActOn(e.id);
                                    } else {
                                      appDokumenteGeraetIdChange( e.id ) ;
                                      appViewSwitchChange(true);
                                      history.push('/dokument/' + e.id + '/' + appGeraeteId)
                                    }
                                  }
                                }
                                sortingOrder={["desc", "asc"]}
                                onPageSizeChange={(newPage) => setPageSize(newPage)}
                                pagination
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                              />
                            </Box>
                            :
                            ""
                        }
                        <ButtonInaktive
                          disabled = { existInaktive ? false : true }
                          myExec = {
                            () =>
                              {
                                setShowInaktive( showInaktive ? false : true )
                              }
                          }
                          label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                        />
                        <ButtonAdd
                          myExec={() => { appDokumenteGeraetIdChange( 'neu') ; }}
                          routeUrl={'/dokument/neu/' + appGeraeteId}
                        />
                        {
                          appDokumenteGeraet.length > 0
                            ?
                            <IonButton onClick={() => exportTableData(appDokumenteGeraet, 'Dokumente Gerät ' + appGeraeteId)}>Exportieren</IonButton>
                            :
                            null
                        }
                      </IonCard>
                      {
                        appUserIstEinweiser === 'ja' && appGeraeteId.indexOf( 'MM_' ) < 0 && [ '8' , '84' , '193' ].includes( appKundeId )
                          ?
                            <Messgeraete/>
                          :
                            null
                      }
                    </>
                  )
              }
            </>
        }
      </IonContent>
    </IonPage>
  );
  //#endregion React-Render
};
//#endregion React-Komponente

