/** Welcome.jsx => VersionInfo
 *
 *  Die Gitlab-CI-Pipeline erwartet in jedem Projekt eine Datei "src/pages/Welcome.jsx" mit einem String
 *  "Version [0-9.]+", der von der Pipeline als Release-Version manipuliert wird.
 *
 *  Die Funktion von "Welcome.jsx" im betreffenden Projekt spielt für die Pipeline keine Rolle, die in
 *  "Welcome.jsx" von der Pipeline eingetragene Version sollte allerdings in Releases des Projekts auf
 *  der Start- oder Anmeldeseite angezeigt werden. Dies geschieht hier, indem der Versions-String Teil
 *  der Komponente "VersionInfo" ist, die auf der Anmeldeseite (Datei Login.tsx) verwendet wird.
 *
 *  Für Development-Views wird statt des evtl. irreführenden Versions-Strings (der den letzten Release
 *  angibt, der sich von der aktuellen Development-Version höchstwahrscheinlich unterscheidet) die
 *  Information "## DEVELOPMENT-CURRENT-VERSION ##" ausgegeben.
  * 
 */


import { IonItem , IonLabel } from '@ionic/react';

export const VersionInfo = () => {
  return(
    <span>
      {
        process.env.NODE_ENV === 'development'
          ?
            <>
              ## DEVELOPMENT-CURRENT-VERSION ##
            </>
          :
            <>
              Version 1.06.01.3680
            </>
      }
    </span>
  ) ;
}
