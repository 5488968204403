import { IonButton , IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext , useState } from "react";
import { useHistory } from 'react-router-dom';
import { defColumn , defColumnRenderCell , exportTableData } from './_HelperFunctions' ;
import { useUnterweisungen , useUnterweisungsthemen, useMitarbeiterListe } from '../components/_CustomHooks';
import { AppContext } from "../contexts/app_context";
import './table_einweisungen_grid.css'

function UnterweisungenListe() {
  /** Component Environment */
    const { appUnterweisungIdChange , appUnterweisungen , appUnterweisungenListeFetchState , appUnterweisungsthemen , appMitarbeiterListe , appViewSwitchChange } = useContext( AppContext ) ;
    const history = useHistory();
    const [pageSize, setPageSize] = useState(50);
  /** Component Helpers */
    const setTitle = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) { return 'Offen' ; }
      if( tablePurpose === 'abgeschlossen' ) { return 'Abgeschlossen' ; }
    }
    const setFieldForRowId = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) { return 'UnterweisungenId' ; }
      if( tablePurpose === 'abgeschlossen' ) { return 'UnterweisungenId' ; }
    }
    const dataForPurpose = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return appUnterweisungen
          .filter(
            ( entry ) => entry.UnterweisungenStatus === 'offen'
           )
          .filter(
            ( entry ) =>
              {
                if(
                  appUnterweisungsthemen
                    .findIndex(
                      ( thema ) => {
                        if( thema.UnterweisungsthemenId === entry.UnterweisungenThema ) {
                          if( thema.UnterweisungsthemenAktiv === 'aktiv' ) {
                            return true
                          }
                        }
                      }
                    ) > -1
                )
                  { 
                    return true ;
                  }
              }
           )
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return appUnterweisungen
          .filter(
            ( entry ) => entry.UnterweisungenStatus === 'abgeschlossen'
           );
      }
    }
    const setSorting = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return { field: 'UnterweisungenTermin' , sort: 'asc' }
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return { field: 'UnterweisungenTermin' , sort: 'desc' }
      }
    }
    const mkTable = ( tablePurpose ) =>  {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              { setTitle( tablePurpose ) }
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Box
                sx={{
                  height: 300 ,
                  width: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(211,211,211)",
                  },
                }}
              >
                {
                  appUnterweisungenListeFetchState === 100 ? (
                    <IonSpinner name="bubbles" />
                  ) : (
                    <DataGrid
                      headerHeight = { 56 }
                      // "loading" muss offenbar anderweitig wieder auf "false" gesetzt werden
                      // loading = { true }
                      disableVirtualization 
                      componentsProps = {{
                        toolbar: {
                          tablePurpose: tablePurpose
                        }
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [ setSorting( tablePurpose ) ] ,
                        },
                      }}
                      getRowId = { (row) => row[ setFieldForRowId( tablePurpose ) ] }
                      rows={ dataForPurpose( tablePurpose) }
                      columns =
                        {
                          [
                            {
                              field: 'UnterweisungenId' ,
                              hide: true
                            } ,
                            //#region UnterweisungenTermin
                              /** "UnterweisungenTermin" */
                                defColumnRenderCell(
                                  defColumn( "UnterweisungenTermin" , "Termin" , 100 , 0 ) ,
                                  ( data ) => data.row.UnterweisungenTermin === '0000-00-00'
                                      ?
                                        '(offen)'
                                      : 
                                        new Date( data.row.UnterweisungenTermin ).toLocaleDateString()
                                ) ,
                              //#endregion UnterweisungenTermin
                              defColumn( 'UnterweisungenDozent' , 'Dozent/in' , 140 , 0 ) ,
                            defColumnRenderCell(
                              defColumn( 'UnterweisungenThema' , 'Thema' , 300 , 1 , 300 ) ,
                              ( params ) => {
                                if( params.row.UnterweisungenThema ) {
                                  let matchesUnterweisungsthemenTitel = appUnterweisungsthemen
                                  .find(
                                      ( entry ) => entry.UnterweisungsthemenId === params.row.UnterweisungenThema
                                    )
                                  if( matchesUnterweisungsthemenTitel ) {
                                    return matchesUnterweisungsthemenTitel.UnterweisungsthemenTitel ;
                                  }
                                }
                              }
                            ) ,
                            //#region UnterweisungenMitarbeiter
                              /** "UnterweisungenMitarbeiter"
                               *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                               *        => wg. lokaler Daten-States statt useContext vorerst via
                               *           Copy & Paste zu handlen
                               */
                                defColumnRenderCell(
                                  defColumn( "UnterweisungenMitarbeiter" , "Teilnehmer" ) ,
                                  ( params ) => {
                                    if( params.row.UnterweisungenMitarbeiter ) {
                                      let matchesMitarbeiterDB = appMitarbeiterListe
                                        .filter(
                                          ( { MitarbeiterId } ) =>
                                          /** Präfigierung mit ',' für z.B. "89" vs. "189" (",89" vs. ",189")
                                           *  - in Klammern wg. "( ',' xy).includes" vs. "',' + (xy.includes)"  
                                           */
                                            ( ',' + params.row.UnterweisungenMitarbeiter + ',' )
                                              .includes( ',' + MitarbeiterId + ',' )
                                          )
                                        .map(
                                          ( { MitarbeiterNachname } ) => MitarbeiterNachname
                                        )
                                      if( matchesMitarbeiterDB ) {
                                        return matchesMitarbeiterDB.toString() ;
                                      }
                                    }
                                  }
                                )
                              //#endregion UnterweisungenMitarbeiter
                          ]
                        } 
                      rowsPerPageOptions={[50,100]}
                      disableSelectionOnClick
                      onCellClick = {
                        ( e ) => { 
                          appViewSwitchChange( true ) ;
                          appUnterweisungIdChange ( e.id ) ;
                          history.push( '/unterweisung/' + e.id ) ;
                          return ;
                          }
                        }
                      pageSize={pageSize}
                      onPageSizeChange={(newPage) => setPageSize(newPage)}
                      pagination
                      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                  )
                }
            </Box>
            {
              appUnterweisungen.length > 0
                ?
                  <IonButton onClick={ () => exportTableData( dataForPurpose( tablePurpose) , 'Unterweisungen ' + tablePurpose ) }>Exportieren</IonButton>
                :
                  ''
            }
            <IonButton onClick={ () => { appUnterweisungIdChange( 'neu' ) ; history.push( "/unterweisung/neu/" ) ; } }>Neue Schulung</IonButton>
          </IonCardContent>
        </IonCard>
      )
    }
  /** Component Mount-Update */
    useUnterweisungen() ;
    useUnterweisungsthemen() ;
    useMitarbeiterListe() ;

/** Component UI */
    return (
      <>
        {/* { mkTable( 'matrix' ) } */}
        {/* <UnterweisungenMatrix/> */}
        { mkTable( 'offen' ) }
        { mkTable( 'abgeschlossen' ) }
      </>
    );
}
export default UnterweisungenListe;
