/**
 * View Gefahrstoffschulungsthema
 * 
 * Einzelnes Gefahrstoffschulungsthema einsehen, bearbeiten oder erstellen
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp , getDokumentDatei , PageHelp, ButtonAdd, ButtonInaktive, defColumn, defColumnRenderCell, exportTableData, readableFileSize } from '../components/_HelperFunctions';
import { useGefahrstoffschulungenThemaDokumente } from '../components/_CustomHooks';
import { AppContext } from '../contexts/app_context';
import TableArticlesGefahrstoffschulungsthemen from '../components/table_articles_gefahrstoffschulungsthemen';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';

export function Gefahrstoffschulungsthema() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appGefahrstoffschulungenThemaDokumenteFetchanewChange , appGefahrstoffschulungenThemaDokumente , appGefahrstoffschulungenThemaDokumenteId , appGefahrstoffschulungenThemaDokumenteIdChange , appKundeId , appGefahrstoffschulungenThemaId , appGefahrstoffschulungenThemaIdChange , appGefahrstoffschulungenThemaAddedChange , appGefahrstoffschulungenThemenFetchanew , appGefahrstoffschulungenThemenFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ GefahrstoffschulungsthemenId, setGefahrstoffschulungsthemenId ] = useState( appGefahrstoffschulungenThemaId )
  const [ GefahrstoffschulungsthemenTitel, setGefahrstoffschulungsthemenTitel ] = useState( '' )
  const [ GefahrstoffschulungsthemenInhalt, setGefahrstoffschulungsthemenInhalt ] = useState( '' )
  const [ GefahrstoffschulungsthemenAktiv, setGefahrstoffschulungsthemenAktiv ] = useState( '' )
  const [ GefahrstoffschulungsthemenPflicht, setGefahrstoffschulungsthemenPflicht ] = useState( '' )
  const [ GefahrstoffschulungsthemenTurnus, setGefahrstoffschulungsthemenTurnus] = useState( '0' )
  //#region Dialog-Dokument
    const [ localDokumenteGefahrstoffschulungsthema , localDokumenteGefahrstoffschulungsthemaChange ] = useState( Array() ) ;
    const [ existInaktive , setExistInaktive ] = useState( false ) ;
    const [ showInaktive , setShowInaktive ] = useState( false ) ;
      /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
      //#endregion Dialog-Dokument
  const [pageSize, setPageSize] = useState(10);

  const getDataGefahrstoffschulungsthema = ( GefahrstoffschulungsthemenId:string ) => {
    if (appGefahrstoffschulungenThemaId === 'neu') {
      setGefahrstoffschulungsthemenId( 'neu' ) ;
      setGefahrstoffschulungsthemenTitel( '' ) ;
      setGefahrstoffschulungsthemenInhalt( '' ) ;
      setGefahrstoffschulungsthemenAktiv( 'aktiv' ) ;
      setGefahrstoffschulungsthemenPflicht( '' ) ;
      setGefahrstoffschulungsthemenTurnus( '0' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/themen/get_thema.php?schulungen_id=` + GefahrstoffschulungsthemenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((entry: any) => {
          if (entry.SchulungenId === GefahrstoffschulungsthemenId) {
            setGefahrstoffschulungsthemenId( entry.SchulungenId ) ;
            setGefahrstoffschulungsthemenTitel( entry.SchulungenTitel ) ;
            setGefahrstoffschulungsthemenInhalt( entry.SchulungenInhalt ) ;
            setGefahrstoffschulungsthemenAktiv( entry.SchulungenAktiv ) ;
            setGefahrstoffschulungsthemenPflicht( entry.SchulungenPflicht ) ;
            setGefahrstoffschulungsthemenTurnus( entry.SchulungenTurnus ) ;
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    appGefahrstoffschulungenThemaAddedChange( GefahrstoffschulungsthemenTitel ) ;
    var raw = JSON.stringify({
      SchulungenId: GefahrstoffschulungsthemenId ,
      SchulungenTitel: GefahrstoffschulungsthemenTitel ,
      SchulungenInhalt: GefahrstoffschulungsthemenInhalt ,
      SchulungenAktiv: GefahrstoffschulungsthemenAktiv ,
      SchulungenPflicht: GefahrstoffschulungsthemenPflicht ,
      SchulungenTurnus: GefahrstoffschulungsthemenTurnus ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId ,
      themenBezug: 2
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch( 'https://datamehr-backend.saint-online.de/api/themen/set_thema.php' , requestOptions )
    .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appGefahrstoffschulungenThemaIdChange( json.entryId ) ;
           setGefahrstoffschulungsthemenId( json.entryId ) ;
         }
       }
      )
  }
  function onValueGefahrstoffschulungsthemenTitel( e:any ) { setGefahrstoffschulungsthemenTitel( e.target.value ) ; }
  function onValueGefahrstoffschulungsthemenInhalt( e:any ) { setGefahrstoffschulungsthemenInhalt( e.target.value ) ; }
  function onValueGefahrstoffschulungsthemenAktiv( e:any ) { setGefahrstoffschulungsthemenAktiv( e.target.value ) ; }
  function onValueGefahrstoffschulungsthemenPflicht( e:any ) { setGefahrstoffschulungsthemenPflicht( e.target.value ) ; }
  function onValueGefahrstoffschulungsthemenTurnus( e:any ) { setGefahrstoffschulungsthemenTurnus( e.target.value ) ; }

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteGefahrstoffschulungsthemaChange( appGefahrstoffschulungenThemaDokumente )
      }
      else {
        localDokumenteGefahrstoffschulungsthemaChange(
          appGefahrstoffschulungenThemaDokumente
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }
  useEffect(
    () => {
      if( appGefahrstoffschulungenThemaDokumente
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appGefahrstoffschulungenThemaDokumente ]
  )
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )   
  useGefahrstoffschulungenThemaDokumente() ;
  
  useEffect(
    () =>
      {
        getDataGefahrstoffschulungsthema( appGefahrstoffschulungenThemaId ) ;
        appGefahrstoffschulungenThemaDokumenteFetchanewChange( true ) ;
      }
    ,
    [ appGefahrstoffschulungenThemaId ]
  );
  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für ein Gefahrstoffschulungsthema einsehen und bearbeiten oder für ein neues Gefahrstoffschulungsthema festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Gefahrstoffschulungsthema { GefahrstoffschulungsthemenTitel ? GefahrstoffschulungsthemenTitel  : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
             <IonItem className='item'>
                <IonLabel position="stacked">Thema</IonLabel>
                  <IonInput required value={ GefahrstoffschulungsthemenTitel } onIonChange={ ( e )=> onValueGefahrstoffschulungsthemenTitel( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Inhalt</IonLabel>
                  <IonInput required value={ GefahrstoffschulungsthemenInhalt } onIonChange={ ( e )=> onValueGefahrstoffschulungsthemenInhalt( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Aktiv</IonLabel>
                  <IonSelect value={ GefahrstoffschulungsthemenAktiv } onIonChange={ ( e )=> onValueGefahrstoffschulungsthemenAktiv( e ) } >
                    <IonSelectOption value = { 'aktiv' } >aktiv</IonSelectOption>
                    <IonSelectOption value = { 'inaktiv' } >inaktiv</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Pflicht</IonLabel>
                  <IonSelect value={ GefahrstoffschulungsthemenPflicht } onIonChange={ ( e )=> onValueGefahrstoffschulungsthemenPflicht( e ) } >
                    <IonSelectOption value = { 'ja' } >ja</IonSelectOption>
                    <IonSelectOption value = { 'nein' } >nein</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Turnus</IonLabel>
                  <IonSelect value={ GefahrstoffschulungsthemenTurnus } onIonChange={ ( e )=> onValueGefahrstoffschulungsthemenTurnus( e ) } >
                    <IonSelectOption value = { "0" } >nicht festgelegt</IonSelectOption>
                    <IonSelectOption value = { "1" } >Erstschulung</IonSelectOption>
                    <IonSelectOption value = { "90" } >vierteljährlich</IonSelectOption>
                    <IonSelectOption value = { "180" } >halbjährlich</IonSelectOption>
                    <IonSelectOption value = { "365" } >jährlich</IonSelectOption>
                    <IonSelectOption value = { "730" } >zwei Jahre</IonSelectOption>
                  </IonSelect>
                </IonItem>
          </IonCardContent>
            {
              appGefahrstoffschulungenThemaId !== 'neu'
                ?
                  <IonCardContent class="Dokumente">
                    <IonAlert
                      isOpen = { dokumentToActOn ? true : false }
                      backdropDismiss = { true }
                      header = 'Gewählte Datei ...'
                      message = ''
                      buttons = {
                        [
                          {
                            text: 'Herunterladen' ,
                            handler: () => {
                              getDokumentDatei( dokumentToActOn ) ;
                              setDokumentToActOn( '' ) ;
                            }
                          } ,
                          {
                            text: 'Listeneintrag bearbeiten' ,
                            handler: () => {
                              appViewSwitchChange( true ) ;
                              appGefahrstoffschulungenThemaDokumenteIdChange( dokumentToActOn ) ;
                              history.push( '/dokument-gefahrstoffschulungsthema/' + dokumentToActOn + '/' + appGefahrstoffschulungenThemaId )
                              setDokumentToActOn( '' ) ;
                            }
                          } ,
                          {
                            text: 'Abbrechen' ,
                            handler: () => {
                              setDokumentToActOn( '' ) ;
                            }
                          }
                        ]
                      }
                    />
                    <IonLabel position="stacked">
                      Dokumente für Gefahrstoffschulung
                    </IonLabel>
                    {
                      appGefahrstoffschulungenThemaDokumente.length > 0
                        ?
                          <Box width="100vh"
                            sx={{
                              height: 300,
                              width: 1,
                              "& .super-app-theme--header": {
                                backgroundColor: "rgb(211,211,211)",
                              },
                            }}
                          >
                            <DataGrid
                              disableVirtualization 
                              initialState={{
                                sorting: {
                                  sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                                },
                              }}
                              getRowId={(row) => row.DokumenteId}
                              getRowClassName =
                              {
                                ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                              }
                              rows = { localDokumenteGefahrstoffschulungsthema }
                              columns = {
                                [
                                  defColumn( "DokumenteId" , "Id" , 0 ) ,
                                  defColumnRenderCell(
                                    defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                    ( data:any ) =>
                                      new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                  ) ,
                                  defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                  defColumnRenderCell(
                                    defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                    ( data:any ) =>
                                      readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                  ) ,
                                  defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                                ]
                              } 
                              pageSize={pageSize}
                              rowsPerPageOptions={[10,50,100]}
                              disableSelectionOnClick
                              onCellClick =
                                {
                                  ( e:any ) =>
                                    {
                                      /** Browser / Web vs. App nativ */
                                      if( navigator ) {
                                        setDokumentToActOn( e.id ) ;
                                      } else {
                                        appGefahrstoffschulungenThemaDokumenteIdChange( e.id ) ;
                                        appViewSwitchChange( true ) ;
                                        history.push( '/dokument-gefahrstoffschulungsthema/' + e.id + '/' + appGefahrstoffschulungenThemaId )
                                      }
                                    }
                                }
                              sortingOrder={["desc", "asc"]}
                              onPageSizeChange={(newPage) => setPageSize(newPage)}
                              pagination
                              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            />
                          </Box>
                        :
                          ""
                    }
                    <ButtonInaktive
                      disabled = { existInaktive ? false : true }
                      myExec = {
                        () =>
                          {
                            setShowInaktive( showInaktive ? false : true )
                          }
                      }
                      label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                    />
                    <ButtonAdd
                      myExec = { () => { appGefahrstoffschulungenThemaDokumenteIdChange( 'neu' ) ; } }
                      routeUrl = { '/dokument-gefahrstoffschulungsthema/neu/' + appGefahrstoffschulungenThemaId }
                    />
                  </IonCardContent>
                :
                  ''
            }
          <IonCardContent>
                <ButtonsSaveActions
                  myExec = {
                    () => { appGefahrstoffschulungenThemenFetchanewChange( true ) ; sendJSON() ; }
                  }
                />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
        <TableArticlesGefahrstoffschulungsthemen themaId =  { GefahrstoffschulungsthemenId } themaTitle = { GefahrstoffschulungsthemenTitel }/>
      </IonContent>
    </IonPage>
  );
};

