import { IonCol , IonContent, IonIcon, IonGrid , IonHeader , IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonNote, IonRow , IonSelect, IonSelectOption, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import { basket , book , bag , bagOutline , checkboxOutline, cogOutline, people , homeOutline, logOut, pieChartOutline, school , schoolOutline, bulbOutline, bulb, build, warning , warningOutline } from "ionicons/icons";
import React, { useContext , useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Menu.css";
import { AppContext } from '../contexts/app_context';
import { SpinnerViewSwitch } from "./_HelperFunctions";
import * as BRANDING from '../BRANDING' ;

interface appSetting {
  baseUrl?: string
  url: string;
  icon: string;
  title: string;
}
const Menu: React.FC = () => {
  // console.log( 'Menu.tsx' ) ;
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const {  appArticleInternGefahrstoffschulungenFetchanewChange , appArticlesFetchanewChange , appArticlesInternFetchanewChange , appBetriebeId , appKundeId , appKundeName , appBetriebeIdChange , appBetriebeListe , appBetriebeName , appBetriebeNameChange , appAufgabenGeraetFetchanewChange , appAufgabenListeFetchanewChange , appEinweisungenGeraetFetchanewChange , appEinweisungenListeFetchanewChange , appGeraeteListeFetchanewChange , appGeraeteklassenListeFetchanewChange , appLieferantenListeFetchanewChange , appMitarbeiterListeFetchanewChange , appDokumenteGeraetFetchanewChange , appEinweisungsThemenFetchanewChange , appGefahrstoffschulungenFetchanewChange , appGefahrstoffschulungenThemenFetchanewChange , appUserIstBasic , appUserIstEinweiser , appViewSwitchChange , appUnterweisungenFetchanewChange , appUnterweisungsthemenFetchanewChange } = useContext( AppContext ) ;
  const [betriebe, setBetriebe] = useState([{
    betriebe_name: "Keine Organisationseinheit vorhanden",
    betriebe_id: "0",
  }]);
  const [ einstellungsMenue , setEinstellungsMenue ] = useState( <span></span> ) ;
  const [ hauptMenue , setHauptMenue ] = useState( <span></span> ) ;
  const [username, setUserName] = useState("Benutzername");
  const [ pendingSettingPage , setPendingSettingPage ]:any = useState( '' ) ;

  useEffect(() => {
    const getstoragetoken = () => {
      let storagetoken=window.localStorage.getItem("token")
      if (storagetoken != null) {
        let tokenUserName = JSON.parse(storagetoken).usr_name;
        let tokenBetriebe = JSON.parse(storagetoken).betriebe;
        setUserName(tokenUserName);
        setBetriebe(
          tokenBetriebe
            .sort(
              ( a:any , b:any ) =>
                {
                  if ( a.betriebe_name > b.betriebe_name ) { return 1 ; }
                  if ( a.betriebe_name < b.betriebe_name ) { return -1 ; }
                  return 0 ;
                }
            )
        );
      }
    };
    getstoragetoken();
  }, []);
  useEffect(
    () =>
      {
        if( document.getElementById( 'betrieb_' + appBetriebeId ) ) {
          // @ts-ignore
          document.getElementById( 'betrieb_' + appBetriebeId ).scrollIntoView() ;
        }
      } ,
    [ appBetriebeId ]
  )
  const history = useHistory();
  //#region appSettings
    const appSettings: appSetting[] = [] ;
    if( appUserIstBasic === 'nein' ) {
      appSettings.push(
        {
          title: "Servicepartner",
          url: '/lieferanten/' + appKundeId + '/' +  appKundeName ,
          icon: basket
        }
      ) ;
      appSettings.push(
        {
          title: "Gerätekategorien",
          url: '/geraetekategorien/' + appKundeId + '/' +  appKundeName ,
          icon: book
        }
      ) ;
      if( appBetriebeId === '70' || betriebe[0].betriebe_id === '70' ) {
        appSettings.push(
          {
            title: "Artikel-Katalog",
            baseUrl: 'artikel-katalog' ,
            url: '/artikel-katalog/' + appKundeId + '/' +  appKundeName ,
            icon: bag,
          }
        ) ;
          }
    }
    if( appUserIstEinweiser === 'ja' ) {
      appSettings.push(
        {
          title: "Einweisungsthemen",
          baseUrl: 'einweisungsthemen' ,
          url: '/einweisungsthemen/' + appKundeId + '/' +  appKundeName ,
          icon: school
        }
      ) ;
    }
    if( appUserIstEinweiser === 'ja' ) {
      appSettings.push(
        {
          title: "Schulungsthemen",
          url: '/unterweisungsthemen/' + appKundeId + '/' +  appKundeName ,
          icon: bulb
        }
      ) ;
    }
    if( BRANDING.SETTINGS && BRANDING.SETTINGS.SETTINGS_ITEMS.includes( 'Gefahrstoffschulungsthemen' ) ) {
      if( appUserIstEinweiser === 'ja' ) {
        appSettings.push(
          {
            title: "Gefahrstoffschulungsthemen",
            baseUrl: 'gefahrstoffschulungsthemen' ,
            url: '/gefahrstoffschulungsthemen/' + appKundeId + '/' +  appKundeName ,
            icon: warning
          }
        ) ;
      }
    }
    if( appUserIstEinweiser === 'ja' ) {
      appSettings.push(
        {
          title: "Mitarbeiter",
          baseUrl: 'mitarbeiterInnen' ,
          url: '/mitarbeiterInnen/' + appBetriebeId + '/' +  appBetriebeName ,
          icon: people
        }
      ) ;
    }
    if( appUserIstEinweiser === 'ja' ) {
      appSettings.push(
        {
          title: "System",
          url: '/system/' + appKundeId + '/' +  appKundeName ,
          icon: build
        }
      ) ;
    }
    appSettings.push(
      {
        title: "Ausloggen",
        url: "/logout/",
        icon: logOut
      }
    ) ;
      //#endregion appSettings
  const switchMenu = ( key:string , betriebe_id:any , betriebe_name:string ) => {
    console.log( 'switchMenu' ) ;
    appViewSwitchChange( true ) ; 
    if( appBetriebeId !== betriebe_id ) {
      console.log( 'switchMenu Betriebewechsel' ) ;
      appArticleInternGefahrstoffschulungenFetchanewChange( true ) ;
      appArticlesFetchanewChange( true ) ;
      appArticlesInternFetchanewChange( true ) ;
      appAufgabenGeraetFetchanewChange( true ) ;
      appAufgabenListeFetchanewChange( true ) ;
      appEinweisungenGeraetFetchanewChange( true ) ;
      appEinweisungenListeFetchanewChange( true ) ;
      appGeraeteklassenListeFetchanewChange( true ) ;
      appGeraeteListeFetchanewChange( true ) ;
      appLieferantenListeFetchanewChange( true ) ;
      appMitarbeiterListeFetchanewChange( true ) ;
      appDokumenteGeraetFetchanewChange( true ) ;
      appEinweisungsThemenFetchanewChange( true ) ;
      appGefahrstoffschulungenFetchanewChange( true ) ;
      appGefahrstoffschulungenThemenFetchanewChange( true ) ;
      appUnterweisungenFetchanewChange( true ) ;
      appUnterweisungsthemenFetchanewChange( true ) ;
      appBetriebeIdChange( betriebe_id ) ;
      appBetriebeNameChange( betriebe_name ) ;
    }
    history.push( '/' + key + '/' + betriebe_id + '/' + betriebe_name ) ;
  }
  //#region mkMenuEntry
    /** FAST exakte Kopie hiervon in Home.tsx (dort "Mini-Menü" für einrichtungsbezogene Dashboards am Anfang)
     *  - !! HIER bearbeiten und nach Home.tsx kopieren
     *  - NB: wg. involvierter Hooks nicht auslagerbar, deshalb Copy & Paste für Identität
    */
    const mkMenuEntry =
      ( topic:string , key:string , betriebe_id:any , betriebe_name:string , icon:any ) =>
        { 
          return (
            <IonItem
              className={
                window.location.pathname.split( '/' )[ 1 ] === key ? "selected" : ""
              }
              disabled = { betriebe_id ? false : true }
              key= { key + betriebe_id }
              onClick =
                {
                  () => switchMenu( key , betriebe_id , betriebe_name ) 
                } 
              routerDirection = "none"
              lines = "none"
              detail = { false }
            >
              <IonIcon
                slot="start"
                icon = { icon }
              />
              { topic }
            </IonItem>
          )
        }
      //#endregion mkMenuEntry
  const changeBetrieb = ( betriebe_id:any , betriebe_name:string ) =>
    {
      if( betriebe_id !== appBetriebeId ) {
        appBetriebeIdChange( betriebe_id ) ;
        if( pendingSettingPage !== '' ) {
          switchMenu( pendingSettingPage , betriebe_id , betriebe_name ) ;
          setPendingSettingPage( '' ) ;
        }
        else {
          if( window.location.pathname === '/' ) {
            switchMenu( 'dashboard' , betriebe_id , betriebe_name ) ;
          }
          else {
            let pathParts = window.location.pathname.split( '/' ) ;
            if( [ 'dashboard' , 'geraete' , 'aufgaben' , 'einweisungen' , 'gefahrstoffschulungen' , 'schulungen' , 'mitarbeiterInnen' ].includes( pathParts[ 1 ] ) ) {
              switchMenu( pathParts[ 1 ] , betriebe_id , betriebe_name ) ;
            }
          }
        }
      }
    }

  useEffect(
    function makeHauptMenue()
      {
        // console.log( 'makeHauptMenue' ) ;
        let my_hauptMenue =
          <IonList key="hauptmenue" id="hauptmenue">
            {
              betriebe.length > 1
                ?
                  <>
                    <IonItem
                      className='item'
                    >
                      <IonIcon
                        slot="start"
                        icon={homeOutline}
                      />
                      <IonLabel
                        position = "stacked"
                      >
                        Auswahl Einrichtung
                      </IonLabel>
                      <IonSelect
                        cancelText="Abbrechen"
                        interface = 'action-sheet'
                        value = { JSON.stringify( { id: appBetriebeId , name: appBetriebeName } ) }
                        onIonChange =
                          { 
                            ( e:any ) =>
                              {
                                let betrieb = JSON.parse( e.target.value ) ;
                                if( betrieb.id ) {
                                  changeBetrieb( betrieb.id , betrieb.name ) ;
                                } else {
                                  history.push( '/' ) ;
                                }
                              }
                          }
                          style = {
                            {
                              fontWeight: 'bold'
                            }
                          }
                      >
                        <IonSelectOption
                          key = 'alle'
                          value = { JSON.stringify( { id: '' , name: appBetriebeName } ) }
                        >
                          {
                            appBetriebeId === ''
                              ?
                                'Einrichtung auswählen ...'
                              :
                                'alle Einrichtungen'
                          }
                        </IonSelectOption>
                        {
                          betriebe
                            .map(
                              ( appPage ) =>
                                {
                                  return (
                                    <IonSelectOption
                                      key = { appPage.betriebe_id }
                                      value = { JSON.stringify( { id: appPage.betriebe_id , name: appPage.betriebe_name } ) }
                                    >
                                      { appPage.betriebe_name }
                                    </IonSelectOption>
                                  );
                                }
                            )
                          }
                      </IonSelect>
                    </IonItem>
                      <IonList>
                        { mkMenuEntry( 'Dashboard' , 'dashboard' , appBetriebeId , appBetriebeName , pieChartOutline ) }
                        { mkMenuEntry( 'Geräte' , 'geraete' , appBetriebeId , appBetriebeName , cogOutline ) }
                        {
                          BRANDING.SETTINGS && BRANDING.SETTINGS.MENU_ITEMS.includes( 'Artikel' )
                            ?
                              mkMenuEntry( 'Artikel' , 'articles-intern' , appBetriebeId , appBetriebeName , bagOutline )
                            :
                              ''
                        }
                        { mkMenuEntry( 'Aufträge/Angebote' , 'aufgaben' , appBetriebeId , appBetriebeName , checkboxOutline ) }
                        {
                          appUserIstEinweiser === 'ja'
                            ?
                              mkMenuEntry( 'Einweisungen' , 'einweisungen' , appBetriebeId , appBetriebeName , schoolOutline )
                            :
                              ''
                        }
                        {
                          appUserIstEinweiser === 'ja'
                            ?
                              mkMenuEntry( 'Schulungen' , 'unterweisungen' , appBetriebeId , appBetriebeName , bulbOutline )
                            :
                              ''
                        }
                        {
                          BRANDING.SETTINGS && BRANDING.SETTINGS.MENU_ITEMS.includes( 'Gefahrstoffschulungen' )
                            ?
                              appUserIstEinweiser === 'ja'
                                ?
                                  mkMenuEntry( 'Gefahrstoffschulungen' , 'gefahrstoffschulungen' , appBetriebeId , appBetriebeName , warningOutline )
                                :
                                  ''
                            :
                              ''
                        }
                      </IonList>
                  </>
                :
                  <>
                    <IonItem
                      onClick = {
                        () => changeBetrieb( betriebe[0].betriebe_id , betriebe[0].betriebe_name )
                      }
                    >
                      <IonIcon
                        slot="start"
                        icon={homeOutline}
                      />
                      <IonLabel
                        style = { { fontWeight: 'bold' } }
                      >
                        {betriebe[0].betriebe_name}
                      </IonLabel>
                    </IonItem>
                    <IonList>
                      { mkMenuEntry( 'Dashboard' , 'dashboard' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , pieChartOutline ) }
                      { mkMenuEntry( 'Geräte' , 'geraete' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , cogOutline ) }
                      {
                        BRANDING.SETTINGS && BRANDING.SETTINGS.MENU_ITEMS.includes( 'Artikel' )
                          ?
                            mkMenuEntry( 'Artikel' , 'articles-intern' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , bagOutline )
                          :
                            ''
                      }
                      { mkMenuEntry( 'Aufträge/Angebote' , 'aufgaben' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , checkboxOutline ) }
                      {
                        appUserIstEinweiser === 'ja'
                          ?
                            mkMenuEntry( 'Einweisungen' , 'einweisungen' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , schoolOutline )
                          :
                            ''
                      }
                      {
                        appUserIstEinweiser === 'ja'
                          ?
                            mkMenuEntry( 'Schulungen' , 'unterweisungen' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , bulbOutline )
                          :
                            ''
                      }
                      {
                        BRANDING.SETTINGS && BRANDING.SETTINGS.MENU_ITEMS.includes( 'Gefahrstoffschulungen' )
                          ?
                            appUserIstEinweiser === 'ja'
                              ?
                                mkMenuEntry( 'Gefahrstoffschulungen' , 'gefahrstoffschulungen' , betriebe[0].betriebe_id , betriebe[0].betriebe_name , warningOutline )
                              :
                                ''
                          :
                            ''
                      }
                    </IonList>
                  </>
            }
          </IonList>
        ;
        setHauptMenue( my_hauptMenue ) ;
      }
    ,
    [ betriebe , appBetriebeId , window.location.pathname ]
  ) ;
  
        
  useEffect(
    function makeEinstellungsMenue()
      {
        // console.log( 'makeEinstellungsMenue' ) ;
        let my_einstellungsMenue =
          <IonList key="einstellungen" id="einstellungen">
            {
              appUserIstBasic === 'nein'
                ?
                  <IonListHeader key="header">
                    Einstellungen
                  </IonListHeader>
                :
                  null
            }
            {
              appSettings
                .map(
                  ( appSetting , indexSet ) =>
                    {
                      let my_onClick = () => {} ;
                      let my_routerLink = '' ;
                      switch( appSetting.title !== 'Mitarbeiter' && appSetting.title !== 'Einweisungsthemen' ) {
                        case true:
                          my_onClick = () =>
                            {
                              appViewSwitchChange( true ) ;
                            }
                          ;
                          my_routerLink = appSetting.url ;
                          break ;
                        default:
                          switch( appBetriebeId !== '' ) {
                            case true:
                              my_onClick = () =>
                                {
                                  appViewSwitchChange( true ) ;
                                }
                              ;
                              my_routerLink = '/' + appSetting.baseUrl + '/' + appBetriebeId + '/' +  appBetriebeName ;
                              break ;
                            default:
                              switch( appBetriebeListe.length < 2 ) {
                                case true:
                                  my_onClick = () =>
                                    {
                                      appViewSwitchChange( true ) ;
                                      appBetriebeIdChange( appBetriebeListe[ 0 ].betriebe_id ) ;
                                      appBetriebeNameChange( appBetriebeListe[ 0 ].betriebe_name ) ;
                                    }
                                  ;
                                  my_routerLink = '/' + appSetting.baseUrl + '/' + appBetriebeListe[ 0 ].betriebe_id + '/' +  appBetriebeListe[ 0 ].betriebe_name ;
                                  break ;
                                default:
                                  my_routerLink = window.location.pathname ;
                                  my_onClick = () =>
                                    {
                                      setPendingSettingPage( appSetting.baseUrl ) ;
                                      alert( 'Für die Einstellung "' + appSetting.title + '" müssen Sie zuerst eine einzelne Einrichtung wählen' ) ;
                                    }
                                  ;
                              }
                          }
                        }
                      return (
                          <IonItem
                            onClick = { my_onClick }
                            key= { 'einstellungen' + indexSet }
                            className={
                              window.location.pathname.split( '/' )[ 1 ] === appSetting.url.split( '/' )[ 1 ] ? "selected" : ""
                            }
                            routerLink = { my_routerLink }
                            routerDirection="none"
                            lines="none"
                            detail={false}
                          >
                            <IonIcon
                              slot="start"
                              icon={appSetting.icon}
                            />
                            <IonLabel>{appSetting.title}</IonLabel>
                          </IonItem>
                      );
                    }
                  )
            }
          </IonList>
        ;
        setEinstellungsMenue( my_einstellungsMenue ) ;
      }
    ,
    [ betriebe , appBetriebeId , window.location.pathname ]
  ) ;
  
  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar
          style = { { cursor: 'pointer' } }
          title = { 'Zur Startseite (Übersicht aller Einrichtungen)' }
        >
          <IonItem
            detail = { false }
            lines = "none"
            routerDirection="none"
            routerLink="/"
            style = { { backgroundColor: 'white' } }
          >
            <div>
              <IonImg
                key="logolink"
                alt="... Anwendung wird geladen"
                src="./env/logo.png"
                style={{ width: 100 }}
              />
              <IonNote
                key="username"
              >
                {username}
              </IonNote>
            </div>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <SpinnerViewSwitch />
      <IonContent key="menu">
          { hauptMenue }
          { einstellungsMenue }
      </IonContent>
    </IonMenu>
  );
};
export default Menu;
