/**
 * Messgerät zuordnen
 * 
 *  - falls dem aktuellen Gerät noch nie ein Messgerät zugeordnet war
 * 
 */

import { IonButton, IonCardContent, IonCheckbox, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/app_context";
import { useIotGeraete } from "../_CustomHooks";
import { get_geraet_by_id } from "../_HelperFunctions";

export const Messgeraet_Zuordnen = ( ) =>
  {
    const {
        appGeraeteId ,
        appGeraeteListeData ,
        appGeraetHasIotMessungChange ,
        appIotGeraete ,
        appIotGeraeteFetchanewChange ,
        appIotMessgeraeteAktionChange
      } = useContext( AppContext ) ;

    const [ localIotGeraete , setLocalIotGeraete ] = useState( appIotGeraete ) ;
    const [ messpunkte_add , setMesspunkte_add ] = useState( Array() ) ;
    const [ messpunkte_del , setMesspunkte_del ] = useState( Array() ) ;

    async function sendJSON() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders
      };
        fetch(
          'https://datamehr-backend.saint-online.de/api/geraete/set_geraet_iot_messpunkte.php'
            + '?geraete_id=' + appGeraeteId
            + '&messpunkte_ids_add=' + messpunkte_add.join( ',' )
            + '&messpunkte_ids_del=' + messpunkte_del.join( ',' )
          ,
          requestOptions
        )
      .then(
        () =>
          {
            if( messpunkte_add.length > 0 ) {
              appGeraetHasIotMessungChange( 1 ) ;
            } else {
              if( messpunkte_del.length > 0 ) {
                appGeraetHasIotMessungChange( new Date().toISOString().substring( 0 , 10 ) ) ;
              }
            }
            setMesspunkte_add( [] ) ;
            setMesspunkte_del( [] ) ;
            appIotGeraeteFetchanewChange( true )
          }
        )
    }

    const onValueMesspunkteChange = ( messpunkt_id:string , checked:Boolean , addOrDel:string ) =>
      {
        let my_messpunkte_add = Array()
        let my_messpunkte_del = Array()
        if( addOrDel === 'add' ) {
          if( checked ) {
            my_messpunkte_add = messpunkte_add ;
            my_messpunkte_add.push( messpunkt_id ) ;
          }
          else {
            my_messpunkte_add = messpunkte_add.filter( ( entry:any ) => entry !== messpunkt_id ) ; 
          }
          console.log( 'my_messpunkte_add: ' , my_messpunkte_add ) ;
          setMesspunkte_add( my_messpunkte_add ) ;
        } 
        if( addOrDel === 'del' ) {
          if( checked ) {
            my_messpunkte_del = messpunkte_del.filter( ( entry:any ) => entry !== messpunkt_id ) ; 
          }
          else {
            my_messpunkte_del = messpunkte_del ;
            my_messpunkte_del.push( messpunkt_id ) ;
          }
          console.log( 'my_messpunkte_del: ' , my_messpunkte_del ) ;
          setMesspunkte_del( my_messpunkte_del ) ;
        } 
      }

    const mkEntry = ( geraet:any ) =>
      {
        let messpunkte:Array<any> = [] ;
        let iot_geraet_aktuelle_zuordnung = '' ;
        geraet.messpunkte
          .forEach(
            ( messpunkt:any ) =>
              {
                messpunkte.push( [ messpunkt.id , messpunkt.bezeichnung , messpunkt.einheit , messpunkt.aktuelle_zuordnung ] )
                if( messpunkt.aktuelle_zuordnung !==  '' ) {
                  iot_geraet_aktuelle_zuordnung = messpunkt.aktuelle_zuordnung ;
                }
              }
          )

        return (
          <>
            <IonItemGroup
              key = { 'id' + geraet.id }
              style =
                {
                  {
                    cursor: 'text' ,
                    margin: '1em'
                  }
                }
            >
              <IonItemDivider
                style =
                  {
                    {
                      padding: '0 0 0 0.2em'
                    }
                  }
              >
                <IonLabel>
                  Gerät { geraet.bezeichnung } (S/N { geraet.seriennummer })
                </IonLabel>
              </IonItemDivider>
              {
                iot_geraet_aktuelle_zuordnung !== ''
                  ? 
                    <div
                      style =
                        {
                          {
                            fontStyle: 'italic' ,
                            color: 'orange'
                          }
                        }
                    >
                      bereits zugeordnet zu
                        { 
                          iot_geraet_aktuelle_zuordnung === appGeraeteId
                            ?
                              ' aktuellem Gerät (Haken entfernen für Aufhebung der Zuordnung)'
                            :
                              ' Gerät ' + iot_geraet_aktuelle_zuordnung + ' ' + get_geraet_by_id( appGeraeteListeData , iot_geraet_aktuelle_zuordnung ).GeraeteName + ' (Zuordnen hier überschreibt bestehende Zuordnung)'
                        }
                    </div>
                  :
                    null
              }
              <div
                style =
                  {
                    {
                      fontStyle: 'italic' ,
                      marginLeft: '0.5em'
                    }
                  }
              >
                Optionen
              </div>
              {
                messpunkte
                  .map(
                    ( messpunkt:any ) =>
                      <IonItem
                        key = { 'g' + geraet.id + 'm' + messpunkt[ 0 ] }
                        lines = 'none'
                      >
                        <IonCheckbox
                          checked =
                            {
                              messpunkt[ 3 ] === appGeraeteId
                                ?
                                  true
                                :
                                  false
                            }
                          onIonChange =
                            {
                              ( e ) =>
                                {
                                  onValueMesspunkteChange(
                                    messpunkt[ 0 ] ,
                                    e.detail.checked ,
                                    iot_geraet_aktuelle_zuordnung !== appGeraeteId
                                      ?
                                        'add'
                                      :
                                        messpunkt[ 3 ] === appGeraeteId
                                          ?
                                            'del'
                                          :
                                            'add'
                                  )
                                }
                            }
                          slot="start"
                        />
                        <IonLabel>
                          { messpunkt[ 1 ] } ({ messpunkt[ 2 ] })
                        </IonLabel>
                      </IonItem>
                  )
              }
            </IonItemGroup>
          </>
        )
      }

    const mkList = () =>
      {
        if( Array.isArray( localIotGeraete ) && localIotGeraete.length > 0 ) {
          return (
            <>
              <p
                style =
                  {
                    {
                      fontWeight: 'bold'
                    }
                  }
              >
                Messgeräte zuordnen
              </p>
              <IonList>
                {
                  localIotGeraete
                    .map(
                      ( geraet:any ) =>
                        mkEntry( geraet )
                    )
                }
              </IonList>
            </>
          )
        } else {
          return (
            <div>
              Sie haben derzeit keine Messgeräte gebucht. Wenden Sie sich an den Support, wenn Sie Messgeräte buchen möchten.
            </div>
          )
        }
      }

    useIotGeraete()

    useEffect(
      () =>
        {
          appIotGeraeteFetchanewChange( true )
        } ,
        []
    )
    
    useEffect(
      () =>
        {
          setLocalIotGeraete( appIotGeraete ) ;
        } ,
        [ appIotGeraete ]
    )
    
    return (
      <IonCardContent>
          {
            mkList()
          }
        <IonButton
          onClick = {
            () =>
              {
                sendJSON() ;
              }
          }
        >
          Speichern
        </IonButton>
        <IonButton
          onClick = {
            () =>
              {
                sendJSON() ;
                appIotMessgeraeteAktionChange( 'info' ) ;
              }
          }
        >
          Speichern und Schließen
        </IonButton>
        <IonButton
          onClick = {
            () =>
              {
                setMesspunkte_add( [] ) ;
                setMesspunkte_del( [] ) ;
                appIotMessgeraeteAktionChange( 'info' )
              }
          }
        >
          Abbrechen
        </IonButton>
      </IonCardContent>
    );
 
  }
