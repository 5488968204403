import { IonButton , IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import axios from 'axios';
import { useContext , useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { ThumbsDown , ThumbsUp } from 'react-ionicons';
import { defColumn , defColumnRenderCell , exportTableData , getMedmehrHostedAngebot , getMedmehrHostedAufgabe , MedmehrAngebotAnnehmenAblehnen } from './_HelperFunctions' ;
import { AppContext } from '../contexts/app_context';

function TableAufgabe() {
  const { appBetriebeId , appAufgabenListeFetchanew , appAufgabenListeFetchanewChange , appAufgabenIdChange , appGeraeteIdChange , appViewSwitchChange } = useContext( AppContext ) ;
  /** Component Environment */
    const history = useHistory();
    const [tableData, settableData] = useState([]);
    const [isLoading, setLoading] = useState() ;
    const [pageSize, setPageSize] = useState(50);
    const getData = async () => {
      setLoading( true ) ;
      const response = await axios(
        {
          method: 'get',
          url: 'https://datamehr-backend.saint-online.de/api/aufgaben/get_aufgaben.php?betriebe_id=' + appBetriebeId + '&test=y',
          headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${ window.localStorage.getItem( 'jwt' ) }`,
          },
        }
      ) ;
      settableData( response.data ) ;
      setLoading( false ) ;
    }
  /** Component Helpers */
    const setTitle = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) { return 'Offene Aufträge' ; }
      if( tablePurpose === 'angebote' ) { return 'Angebote' ; }
      if( tablePurpose === 'faellig' ) { return 'Instandhaltungsplanung (Wartung, STK, MTK)' ; }
      if( tablePurpose === 'abgeschlossen' ) { return 'Abgeschlossene Aufträge' ; }
    }
    const setColumns = ( tablePurpose ) => {
      if( tablePurpose === 'DEFAULT' ) {
        return (
          [
            defColumn( 'GeraeteTyp' , 'Geräte Gruppe' , 200 , 1  ) ,
            defColumn( 'geraeteId' , 'Geräte-ID' , 140 , 0 ) ,
            defColumn( 'GeraeteHersteller' , 'Geräte-Hersteller' , 200 , 1  ) ,
            defColumn( 'GeraeteName' , 'Geräte-Typ' , 200 , 1  ) ,
            defColumn( 'AufgabenProblem' , 'Problem' , 300 , 0 ) ,
          ]
        )
      }
      if( tablePurpose === 'offen' ) {
        return [ defColumn( 'AufgabenId' , 'Aufgaben-ID' , 140 , 0 ) ].concat( setColumns( 'DEFAULT' ) ).concat( [ defColumn( 'AufgabenErstellung' , 'erstellt am' , 140 , 0 ) ] )  ;
        // return (
        //   [
        //     {
        //       field: "AufgabenTyp",
        //       minWidth: 100 ,
        //       flex: 0 ,
        //       headerName: "Typ",
        //       headerClassName: "super-app-theme--header",
        //       renderCell: ( params ) => {
        //         if ( params.row.AufgabenTyp.toString() === "5" ) {
        //           return 'Wartung'
        //         }
        //         else {
        //           if ( params.row.AufgabenTyp.toString() === "4" ) {
        //             return 'Reparatur'
        //           }
        //         }
        //       }
        //     },       
        //   ]
        // )
      }
      if( tablePurpose === 'angebote' ) {
        return (
          [
            defColumn( 'AufgabenId' , 'ID' , 140 , 0 ) ,
            defColumnRenderCell(
              defColumn( "Annehmen_Ablehnen", "Annehmen?", 60 , 0 ),
              ( data ) =>
                {
                  if ( data.row.AufgabenId.substring( 0, 3 ) === 'MM_' ) {
                    return (
                      <MedmehrAngebotAnnehmenAblehnen aufgabenId = { data.row.AufgabenId } aufgabenAenderung = { data.row.AufgabenAenderung } />
                    )
                  }
                }
            )
          ]
          .concat( setColumns( 'DEFAULT' ) )
          .concat(
            [
              defColumn( 'AufgabenErstellung' , 'erstellt am' , 140 , 0 )
            ]
          )
        ) ;
      }
      /** 
       * !! für "faellig" erstmal nur Geräte, für die auch eine Aufgabe existiert
       *    - erstmal nur Funktionalität, später Datenabdeckung
       *    => für Geräte ohne Aufgabe separater Fetch erforderlich
       *      - das wiederum in Zusammenhang mit genereller Änderung zu useContext zu sehen
       */
      if( tablePurpose === 'faellig' ) {
        return [ defColumn( 'GeraeteWartung' , 'Termin' , 140 , 0 ) ].concat( setColumns( 'DEFAULT' ) ) ;
      }
      if( tablePurpose === 'abgeschlossen' ) {
        /** bei "abgeschlossen" ist "AufgabenAenderung" = "AufgabenErledigung"
         *  - grundsätzlich gibt es explizites "AufgabenErledigung" nur in medmehr-DB, bei datamehr nur "AufgabenAenderung",
         *    was in Kombination mit "AufgabenStatus" = 100 logischerweise das Erledigungs-Datum ist
         *  - realiter haben auch nicht alle erledigten medmehr-Einträge die "AufgabenErledigung" explizit gesetzt, aber
         *    "AufgabenAenderung" und "AufgabenStatus" = 100
         */
        return (
          [
            defColumnRenderCell(
              defColumn( 'AufgabenGesamt' , 'Status' , 60 ) ,
              ( data ) => {
                if ( data.row.AufgabenGesamt === 0 ) {
                  return <ThumbsDown  color="red"  />
                }
                else {
                  if ( data.row.AufgabenGesamt === 1 ) {
                    return <ThumbsUp  color="green" />
                  }
                  else {
                    if ( data.row.AufgabenGesamt === 2 ) {
                      return <ThumbsUp  color="gold" />
                    }
                  }
                }
              }
            ) ,
            defColumn( 'AufgabenId' , 'Aufgaben-ID' , 140 , 0 )
          ]
          .concat( setColumns( 'DEFAULT' ).concat( [ defColumn( 'AufgabenAenderung' , 'erledigt am' , 140 , 0 ) ] ) )
        )
        // return (
        //   [
        //     {
        //       field: "AufgabenGesamt",
        //       minWidth: 80 ,
        //       flex: 0 ,
        //       headerName: "Status",
        //       headerClassName: "super-app-theme--header",
        //       renderCell: ( params ) => {
        //         if ( params.row.AufgabenGesamt === 0 ) {
        //           if ( params.row.AufgabenStatus !== 60 ) {
        //             return <><Hourglass/><ThumbsDown  color="red" /></>
        //           }
        //           else {
        //             return <ThumbsDown  color="red"  />
        //           }
        //         }
        //         else {
        //           if ( params.row.AufgabenGesamt === 1 ) {
        //             if ( params.row.AufgabenStatus !== 100 ) {
        //               return <><Hourglass/><ThumbsUp  color="green" /></>
        //             }
        //             else {
        //               return <ThumbsUp  color="green" />
        //             }
        //           }
        //           else {
        //             if ( params.row.AufgabenGesamt === 2 ) {
        //               if ( params.row.AufgabenStatus !== 100 ) {
        //                 return <><Hourglass/><ThumbsUp  color="gold" /></>
        //               }
        //               else {
        //                 return <ThumbsUp  color="gold" />
        //               }
        //             }
        //           }
        //         }
        //       }
        //     },       
        //   ]
        // )
      }
    }
    const dataForPurpose = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return tableData
            // NB: im Endpoint werden durch PHP-TypeCasting "(int)" etwaige nicht-numerische Werte zu '0'
          .filter( ( { AufgabenStatus } ) => ( AufgabenStatus === 0 ) )
          // .filter( ( { AufgabenGesamt } ) => ( AufgabenGesamt === 2 ) )
          .filter( ( { AufgabenTyp } ) => ( AufgabenTyp !== '9' ) );
      }
      if( tablePurpose === 'angebote' ) {
        //  !!! medmehr-Angebote erlöschen nach 90 Tagen, ältere "unbeantwortete" medmehr-Angebote werden bereits im Backend aussortiert
        return tableData
          .filter( ( { AufgabenTyp } ) => ( AufgabenTyp == '9' ) )
          .filter(
            ( { AufgabenStatus } ) =>
              {
                if( ( AufgabenStatus === 200 ) || ( AufgabenStatus === 0 ) ) {
                  return true ;
                }
              }
          ) ;
      }
      if( tablePurpose === 'faellig' ) {
          /**
           * Vorlauf-Zeit bis definierte faelligkeit: Heute + 90 Tage = 5 Monate
           *  - "60 * 60 * 24 * 1000" = 1 Tag in Millisekunden => Date.parse arbeitet mit Millisekunden
           *  - 150 Tage / 5 Monate, damit mit aktuellen Daten im aktuellen Zeitraum repräsentatives Bild
           */
        const faelligVorlauf = Date.parse( Date() ) + 90 * 60 * 60 * 24 * 1000 ;
        return tableData
                .filter( ( { AufgabenGesamt } ) => parseInt( AufgabenGesamt ) !== 0 )
                .filter( ( { GeraeteWartung } ) => Date.parse( GeraeteWartung ) <= faelligVorlauf )
        ;
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return tableData.filter( ( { AufgabenStatus } ) => AufgabenStatus === 100 ) ;
      }
    }
    const setSorting = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return { field: 'AufgabenId' , sort: 'asc' }
      }
      if( tablePurpose === 'angebote' ) {
        return { field: 'AufgabenId' , sort: 'desc' }
      }
      if( tablePurpose === 'faellig' ) {
        return { field: 'GeraeteWartung' , sort: 'asc' }
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return { field: 'AufgabenAenderung' , sort: 'desc' }
      }
    }
    const mkTable = ( tablePurpose ) =>  {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              { setTitle( tablePurpose ) }
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Box
                sx={{
                  height: 300,
                  width: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(211,211,211)",
                  },
                }}
              >
                {
                  isLoading ? (
                    <IonSpinner name="bubbles" />
                  ) : (
                    <DataGrid
                      disableVirtualization 
                      componentsProps = {{
                        toolbar: {
                          tablePurpose: tablePurpose
                        }
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [ setSorting( tablePurpose ) ] ,
                        },
                      }}
                      getRowId = {
                        (row) => {
                          if( tablePurpose === 'faellig' ){
                            return row.geraeteId
                          }
                          else {
                            return row.AufgabenId
                          }
                        }
                      }
                      rows={ dataForPurpose( tablePurpose) }
                      columns={ setColumns( tablePurpose ) } 
                      rowsPerPageOptions={[50,100]}
                      disableSelectionOnClick
                      onCellClick =
                        {
                          ( e ) =>
                            {
                              let proceed = 'default' ;
                              if( tablePurpose === 'faellig' ){
                                proceed = 'geraet' ;
                              }
                              if( tablePurpose === 'angebote' ){
                                if( e.field === "Annehmen_Ablehnen" ) {
                                  proceed = '' ;
                                }
                                else {
                                  if( e.id.substring( 0 , 3 ) === 'MM_' ) {
                                    proceed = 'mm_angebot' ;
                                  }
                                }
                              }
                              if( tablePurpose === 'abgeschlossen' ){
                                if( e.id.substring( 0 , 3 ) === 'MM_' ) {
                                  if( e.row.AufgabenTyp === '9' ) {
                                    proceed = 'mm_angebot' ;
                                  }
                                  else {
                                    proceed = 'mm_aufgabe_abgeschlossen' ;
                                  }
                                 }
                              }
                              if( proceed === 'geraet' ){
                                appViewSwitchChange( true ) ;
                                appGeraeteIdChange( e.id ) ;
                                history.push( '/geraet/' + e.id + '/' + appBetriebeId ) ;
                              }
                              if( proceed === 'mm_angebot' ){
                                getMedmehrHostedAngebot( e.id.substring( 3) )
                              }
                              if( proceed === 'mm_aufgabe_abgeschlossen' ){
                                getMedmehrHostedAufgabe( e.id.substring(3) )
                              }
                              if( proceed === 'default' ) {
                                appViewSwitchChange( true ) ;
                                appAufgabenIdChange( e.id ) ;
                                history.push( '/aufgabe/' + e.id ) ;
                              }
                            }
                        }
                      pageSize={pageSize}
                      onPageSizeChange={(newPage) => setPageSize(newPage)}
                      pagination
                      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                  )
                }
            </Box>
            {
              tableData.length > 0
                ?
                  <IonButton onClick={ () => exportTableData( dataForPurpose( tablePurpose) , 'Aufgaben ' + tablePurpose ) }>Exportieren</IonButton>
                :
                  ''
            }
          </IonCardContent>
        </IonCard>
      )
    }
  /** Component Mount-Update */
    useEffect(
      () => {
        if( appAufgabenListeFetchanew ) {
          getData() ;
          appAufgabenListeFetchanewChange( false ) ;
        }
      },
      [ appAufgabenListeFetchanew ]
    ) ;
  /** Component UI */
    return (
      <>
        { mkTable( 'offen' ) }
        { mkTable( 'angebote' ) }
        { mkTable( 'faellig' ) }
        { mkTable( 'abgeschlossen' ) }
      </>
    );
}
export default TableAufgabe;
