import {
  IonButton ,
  IonCol ,
  IonContent ,
  IonGrid ,
  IonHeader ,
  IonImg ,
  IonInput ,
  IonItem ,
  IonLabel ,
  IonPage ,
  IonRow ,
  IonTitle ,
  IonToolbar
} from '@ionic/react';
import axios from 'axios';
import jwt from 'jwt-decode';
import {
  useEffect ,
  useState
} from 'react';
import { VersionInfo } from "./Welcome";
import * as BRANDING from '../BRANDING' ;

export function Login() {
  const [anmeldename, setAnmeldename] = useState( '' )
  const [password, setPassword] = useState( '' )

  async function setToken( data:string ) {
    let token = JSON.stringify( jwt( data ) ) ;
    window.localStorage.setItem( 'token' , token ) ;
    window.localStorage.setItem( 'jwt' , data ) ;
  }

  const handleLogin = () =>
    {
      const loginData =
        {
          // abwärtskompatibel: Feldname "email" im Backend
          email: anmeldename,
          password: password,
        }
      ;
      const api = axios.create(
        {
          baseURL: BRANDING.SETTINGS.BACKEND_URL + '/api/user/login.php' ,
        }
      )
      ;
      api
        .post( "/login" , loginData )
        .then(
          ( res ) =>
            {
              setToken( res.data ) ;
              window.location.reload();
            }
        )
        .catch(
          ( error ) =>
            {
              alert( 'Login-Fehler - bitte Anmeldedaten prüfen und erneut versuchen' ) ;
            }
        )
      ;
    }
  ;

  const footerSoftwareBy = () =>
    {
      // let BRANDING.SETTINGS.A_SOFTWARE_BY:any = [
      //   [ 'https://solufind.de' , 'itsolutions_transparent.png' ] ,
      //   [ 'https://medmehr.de' , 'medmehrlogokl.png' ]
      // ] ;
      if( BRANDING.SETTINGS ) {
        return (
          BRANDING.SETTINGS.A_SOFTWARE_BY
            .map(
              ( entry:any , index:number ) =>
                {
                  let possibleUnd = index === 0 ? '' : 'und' ;
                  return (
                    <span
                      key = { 'aSoftwareBy' + index }
                    >
                      <span>
                        &nbsp;
                        { possibleUnd }
                      </span>
                      <IonImg
                        onClick={ () => window.open( entry[ 0 ] ) }
                        alt="... Anwendung wird geladen"
                        src = { "./assets/" + entry[ 1 ] }
                        style =
                          {
                            {
                              cursor: 'pointer' ,
                              display: 'inline-block' ,
                              width: 100
                            }
                          }
                      />
                    </span>
                  )
                }
            )
        ) ;
      }
    }

  const input_field = ( type:any , label:string , value:any , handler:Function ) =>
    {
      return(
        <IonItem>
          <IonLabel
            position = "floating"
          >
            { label }
          </IonLabel>
          <IonInput
            title = { label + ' eingeben' }
            type = { type }
            value= { value }
            onIonChange =
              {
                ( e ) => handler( e.detail.value! )
              }
            >
          </IonInput>
        </IonItem>
      ) ;
    }
  ;

  useEffect(
    function makeVortext() {
      if( BRANDING.SETTINGS ) {
        let vortext:any = document.getElementById( 'vortext' ) ;
        vortext.style.fontSize = '0.8em' ;
        vortext.style.padding = '0 1em' ;
        vortext.innerHTML = BRANDING.SETTINGS.VORTEXT ;
      }
    }
    ,
    []
  )

  return (
    <IonPage
     onKeyUp = { ( e ) => { if( e.key === 'Enter' ) { handleLogin() ; } } }
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            { document.title }
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonImg
                onClick = { () => window.location.reload() }
                alt = "... Anwendung wird geladen"
                src= "./env/logo.png"
                style =
                  {
                    {
                      cursor: 'pointer' ,
                      width: 300
                    }
                }
                title = "Anwendung neu laden"
              />
            </IonCol>
          </IonRow>
          <div id="vortext"></div>
          <IonRow>
            <IonCol>
              { 
                input_field( 'text' , 'Anmeldename' , anmeldename , setAnmeldename )
              }
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              { 
                input_field( 'password' , 'Passwort' , password , setPassword )
              }
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                type = 'submit'
                style = { {  height: '6em' } }
                expand="block"
                onClick={handleLogin}
              >
                Einloggen
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <VersionInfo/>
              <p style = { { fontSize: '0.9em' } } >
                eine Software von
                {
                  footerSoftwareBy()
                }
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  ) ;
} ;
