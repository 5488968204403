/**
 * View Unterweisungsthema
 * 
 * Einzelnes Unterweisungsthema einsehen, bearbeiten oder erstellen
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp, PageHelp, ButtonAdd, ButtonInaktive, defColumn, defColumnRenderCell, getDokumentDatei, readableFileSize } from '../components/_HelperFunctions';
import { useUnterweisungsthemaDokumente } from '../components/_CustomHooks';
import { AppContext } from '../contexts/app_context';

export function Unterweisungsthema() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appUnterweisungsthemaDokumenteFetchanewChange , appUnterweisungsthemaDokumente , appUnterweisungsthemaDokumentId , appUnterweisungsthemaDokumentIdChange , appKundeId , appUnterweisungsthemaId , appUnterweisungsthemaIdChange , appUnterweisungsthemenFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ UnterweisungsthemenId, setUnterweisungsthemenId ] = useState( appUnterweisungsthemaId )
  const [ UnterweisungsthemenTitel, setUnterweisungsthemenTitel ] = useState( '' )
  const [ UnterweisungsthemenInhalt, setUnterweisungsthemenInhalt ] = useState( '' )
  const [ UnterweisungsthemenAktiv, setUnterweisungsthemenAktiv ] = useState( 'aktiv' )
  const [ UnterweisungsthemenPflicht, setUnterweisungsthemenPflicht ] = useState( '' )
  const [ UnterweisungsthemenTurnus, setUnterweisungsthemenTurnus] = useState( '0' )
  //#region Dialog-Dokument
    const [ localDokumenteUnterweisungsthema , localDokumenteUnterweisungsthemaChange ] = useState( Array() ) ;
    const [ existInaktive , setExistInaktive ] = useState( false ) ;
    const [ showInaktive , setShowInaktive ] = useState( false ) ;
      /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
      //#endregion Dialog-Dokument
    const [pageSize, setPageSize] = useState(10);

  const getDataUnterweisungsthema = ( UnterweisungsthemenId:string ) => {
    if (appUnterweisungsthemaId === 'neu') {
      setUnterweisungsthemenId( 'neu' ) ;
      setUnterweisungsthemenTitel( '' ) ;
      setUnterweisungsthemenInhalt( '' ) ;
      setUnterweisungsthemenAktiv( 'aktiv' ) ;
      setUnterweisungsthemenPflicht( '' ) ;
      setUnterweisungsthemenTurnus( '0' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/unterweisungen/get_unterweisungsthema.php?unterweisungsthema_id=` + UnterweisungsthemenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((unterweisungen: any) => {
          if (unterweisungen.UnterweisungsthemenId === UnterweisungsthemenId) {
            setUnterweisungsthemenId( unterweisungen.UnterweisungsthemenId ) ;
            setUnterweisungsthemenTitel( unterweisungen.UnterweisungsthemenTitel ) ;
            setUnterweisungsthemenInhalt( unterweisungen.UnterweisungsthemenInhalt ) ;
            setUnterweisungsthemenAktiv( unterweisungen.UnterweisungsthemenAktiv ) ;
            setUnterweisungsthemenPflicht( unterweisungen.UnterweisungsthemenPflicht ) ;
            setUnterweisungsthemenTurnus( unterweisungen.UnterweisungsthemenTurnus ) ;
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var raw = JSON.stringify({
      UnterweisungsthemenId: UnterweisungsthemenId ,
      UnterweisungsthemenTitel: UnterweisungsthemenTitel ,
      UnterweisungsthemenInhalt: UnterweisungsthemenInhalt ,
      UnterweisungsthemenAktiv: UnterweisungsthemenAktiv ,
      UnterweisungsthemenPflicht: UnterweisungsthemenPflicht ,
      UnterweisungsthemenTurnus: UnterweisungsthemenTurnus ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch( 'https://datamehr-backend.saint-online.de/api/unterweisungen/set_unterweisungsthema.php' , requestOptions )
    .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appUnterweisungsthemaIdChange( json.entryId ) ;
           setUnterweisungsthemenId( json.entryId ) ;
         }
       }
      )
  }
  function onValueUnterweisungsthemenTitel( e:any ) { setUnterweisungsthemenTitel( e.target.value ) ; }
  function onValueUnterweisungsthemenInhalt( e:any ) { setUnterweisungsthemenInhalt( e.target.value ) ; }
  function onValueUnterweisungsthemenAktiv( e:any ) { setUnterweisungsthemenAktiv( e.target.value ) ; }
  function onValueUnterweisungsthemenPflicht( e:any ) { setUnterweisungsthemenPflicht( e.target.value ) ; }
  function onValueUnterweisungsthemenTurnus( e:any ) { setUnterweisungsthemenTurnus( e.target.value ) ; }
  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteUnterweisungsthemaChange( appUnterweisungsthemaDokumente )
      }
      else {
        localDokumenteUnterweisungsthemaChange(
          appUnterweisungsthemaDokumente
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }
  useEffect(
    () => {
      if( appUnterweisungsthemaDokumente
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appUnterweisungsthemaDokumente ]
  )
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )   
  useUnterweisungsthemaDokumente() ;
  
  useEffect(
    () => {
      if( history.location.pathname.includes( '/unterweisungsthema/') ) {
          /**
           * !! Vgl. "Aufgabe": Problem richtige Unterweisungsthemen-ID bei jeder Navigation
           *  - ABER: bei "Aufgabe" FALSCHER bzw. nicht verallgemeinerbarer Ansatz
           */
        getDataUnterweisungsthema( appUnterweisungsthemaId ) ;
        appUnterweisungsthemaDokumenteFetchanewChange( true ) ;
      };
    },
    [ appUnterweisungsthemaId , history.location.pathname ]
  );
  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für ein Schulungsthema einsehen und bearbeiten oder für ein neues Schulungsthema festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Schulungsthema { UnterweisungsthemenTitel ? UnterweisungsthemenTitel  : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
            <IonItem className='item'>
                <IonLabel position="stacked">Thema</IonLabel>
                  <IonInput required value={ UnterweisungsthemenTitel } onIonChange={ ( e )=> onValueUnterweisungsthemenTitel( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Inhalt</IonLabel>
                  <IonInput required value={ UnterweisungsthemenInhalt } onIonChange={ ( e )=> onValueUnterweisungsthemenInhalt( e ) } />
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Aktiv</IonLabel>
                  <IonSelect value={ UnterweisungsthemenAktiv } onIonChange={ ( e )=> onValueUnterweisungsthemenAktiv( e ) } >
                    <IonSelectOption value = { 'aktiv' } >aktiv</IonSelectOption>
                    <IonSelectOption value = { 'inaktiv' } >inaktiv</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Pflicht</IonLabel>
                  <IonSelect value={ UnterweisungsthemenPflicht } onIonChange={ ( e )=> onValueUnterweisungsthemenPflicht( e ) } >
                    <IonSelectOption value = { 'ja' } >ja</IonSelectOption>
                    <IonSelectOption value = { 'nein' } >nein</IonSelectOption>
                  </IonSelect>
                </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Turnus</IonLabel>
                  <IonSelect value={ UnterweisungsthemenTurnus } onIonChange={ ( e )=> onValueUnterweisungsthemenTurnus( e ) } >
                    <IonSelectOption value = { "0" } >nicht festgelegt</IonSelectOption>
                    <IonSelectOption value = { "1" } >Ersteinweisung</IonSelectOption>
                    <IonSelectOption value = { "90" } >vierteljährlich</IonSelectOption>
                    <IonSelectOption value = { "180" } >halbjährlich</IonSelectOption>
                    <IonSelectOption value = { "365" } >jährlich</IonSelectOption>
                    <IonSelectOption value = { "730" } >zwei Jahre</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCardContent>
                {
          appUnterweisungsthemaId !== 'neu'
            ?
              <IonCardContent class="Dokumente">
                <IonAlert
                  isOpen = { dokumentToActOn ? true : false }
                  backdropDismiss = { true }
                  header = 'Gewählte Datei ...'
                  message = ''
                  buttons = {
                    [
                      {
                        text: 'Herunterladen' ,
                        handler: () => {
                          getDokumentDatei( dokumentToActOn ) ;
                          setDokumentToActOn( '' ) ;
                        }
                      } ,
                      {
                        text: 'Listeneintrag bearbeiten' ,
                        handler: () => {
                          appViewSwitchChange( true ) ;
                          appUnterweisungsthemaDokumentIdChange( dokumentToActOn ) ;
                          history.push( '/dokument-unterweisungsthema/' + dokumentToActOn + '/' + appUnterweisungsthemaId )
                          setDokumentToActOn( '' ) ;
                        }
                      } ,
                      {
                        text: 'Abbrechen' ,
                        handler: () => {
                          setDokumentToActOn( '' ) ;
                        }
                      }
                    ]
                  }
                />
                <IonLabel position="stacked">
                  Dokumente für Schulung
                </IonLabel>
                {
                  appUnterweisungsthemaDokumente.length > 0
                    ?
                      <Box width="100vh"
                        sx={{
                          height: 300,
                          width: 1,
                          "& .super-app-theme--header": {
                            backgroundColor: "rgb(211,211,211)",
                          },
                        }}
                      >
                        <DataGrid
                          disableVirtualization 
                          initialState={{
                            sorting: {
                              sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                            },
                          }}
                          getRowId={(row) => row.DokumenteId}
                          getRowClassName =
                          {
                            ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                          }
                          rows = { localDokumenteUnterweisungsthema }
                          columns = {
                            [
                              defColumn( "DokumenteId" , "Id" , 0 ) ,
                              defColumnRenderCell(
                                defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                ( data:any ) =>
                                  new Date( data.row.DokumenteDateidatum ).toLocaleString()
                              ) ,
                              defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                              defColumnRenderCell(
                                defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                ( data:any ) =>
                                  readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                              ) ,
                              defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                            ]
                          } 
                          pageSize={pageSize}
                          rowsPerPageOptions={[10,50,100]}
                          disableSelectionOnClick
                          onCellClick =
                            {
                              ( e:any ) =>
                                {
                                  /** Browser / Web vs. App nativ */
                                  if( navigator ) {
                                    setDokumentToActOn( e.id ) ;
                                  } else {
                                    appUnterweisungsthemaDokumentIdChange( e.id ) ;
                                    appViewSwitchChange( true ) ;
                                    history.push( '/dokument-unterweisungsthema/' + e.id + '/' + appUnterweisungsthemaId )
                                  }
                                }
                            }
                          sortingOrder={["desc", "asc"]}
                          onPageSizeChange={(newPage) => setPageSize(newPage)}
                          pagination
                          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        />
                      </Box>
                    :
                      ""
                }
                <ButtonInaktive
                  disabled = { existInaktive ? false : true }
                  myExec = {
                    () =>
                      {
                        setShowInaktive( showInaktive ? false : true )
                      }
                  }
                  label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                />
                <ButtonAdd
                  myExec = { () => { appUnterweisungsthemaDokumentIdChange( 'neu' ) ; } }
                  routeUrl = { '/dokument-unterweisungsthema/neu/' + appUnterweisungsthemaId }
                />
              </IonCardContent>
            :
              ''
        }
          <IonCardContent>
                <ButtonsSaveActions
                  myExec = {
                    () => { appUnterweisungsthemenFetchanewChange( true ) ; sendJSON() ; }
                  }
                />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

