
// console.log( 'arrRoutesData' ) ;

export const arrRoutesData:Array<any> =
    [
    [ 'Lieferanten' , '/lieferanten/:kundeId/:kundeName' ] ,
    [ 'Aufgaben' , '/aufgaben/:betriebeId/:name' ] ,
    [ 'Article' , '/article/:name/:lieferantenId' ] ,
    [ 'ArticleIntern' , '/article-intern/:name/:betriebeId' ] ,
    [ 'ArticlesIntern' , '/articles-intern/:betriebeId/:name' ] ,
    [ 'Dashboard' , '/dashboard/:betriebeId/:name' ] ,
    [ 'Geraete' , '/geraete/:betriebeId/:name' ] ,
    //--  
    [ 'Geraetegruppe' , '/geraetegruppe/:name/:betriebeId' ] ,
    //--  
    [ 'Geraeteklasse' , '/geraetekategorie/:name/:betriebeId' ] ,
    //--  
    [ 'Geraeteklassen' , '/geraetekategorien/:kundeId/:kundeName' ] ,
    //--  
    [ 'Geraet' , '/geraet/:name/:betriebeId' ] ,
    //--  
    [ 'Dokument' , '/dokument/:name/:geraeteId' ] ,
    //-- 
    [ 'DokumentArticle' , '/dokument-article/:name/:articleId' ] ,
    //-- 
    [ 'DokumentAufgabe' , '/dokument-aufgabe/:name/:aufgabenId' ] ,
    //-- 
    [ 'DokumentEinweisung' , '/dokument-einweisung/:name/:einweisungenId' ] ,
    //-- 
    [ 'DokumentEinweisungsthema' , '/dokument-einweisungsthema/:name/:einweisungsthemaId' ] ,
    //-- 
    [ 'DokumentGefahrstoffschulung' , '/dokument-gefahrstoffschulung/:name/:gefahrstoffschulungId' ] ,
    //-- 
    [ 'DokumentGefahrstoffschulungsthema' , '/dokument-gefahrstoffschulungsthema/:name/:gefahrstoffschulungsthemaId' ] ,
    //-- 
    [ 'DokumentUnterweisungsthema' , '/dokument-unterweisungsthema/:name/:unterweisungsthemaId' ] ,
    //-- 
    // nur ":name" wenn aus "Einweisungen" */}
    [ 'Einweisung' , '/einweisung/:name' ] ,
    //-- 
    // mit ":geraeteId" wenn aus "Geraet" */}
    [ 'Einweisung' , '/einweisung/:name/:geraeteId' ] ,
    //-- 
    [ 'Einweisungen' , '/einweisungen/:betriebeId/:name' ] ,
    //-- 
    [ 'Schulung' , '/schulung/:name/:betriebeId' ] ,
    //-- 
    [ 'Einweisungsthemen' , '/einweisungsthemen/:kundeId/:kundeName' ] ,
    //-- 
    [ 'Gefahrstoffschulung' , '/gefahrstoffschulung/:name' ] ,
    //-- 
    [ 'Gefahrstoffschulungen' , '/gefahrstoffschulungen/:betriebeId/:name' ] ,
    //-- 
    [ 'Gefahrstoffschulungsthema' , '/gefahrstoffschulungsthema/:name/:betriebeId' ] ,
    //-- 
    [ 'Gefahrstoffschulungsthemen' , '/gefahrstoffschulungsthemen/:kundeId/:kundeName' ] ,
    //-- 
    [ 'MitarbeiterIn' , '/mitarbeiterIn/:mitarbeiterId/:betriebeId' ] ,
    //-- 
    [ 'MitarbeiterInnen' , '/mitarbeiterInnen/:betriebeId/:name' ] ,
    //-- 
    // nur ":name" wenn in "Aufträge/Angebote" */}
    [ 'Aufgabe' , '/aufgabe/:name' ] ,
    //-- 
    // mit ":geraete" wenn aus "Geraet" */}
    // !! im Backend Besonderheit für "falsche" Reihenfolde ":geraet/:name" !! */}
    [ 'Aufgabe' , '/aufgabe/:geraet/:name' ] ,
    //-- 
    [ 'Lieferant' , '/lieferant/:name/:kundeId' ] ,
    //-- 
    [ 'Logout' , '/logout' ] ,
    [ 'Unterweisung' , '/unterweisung/:name' ] ,
    [ 'Unterweisungen' , '/unterweisungen/:betriebeId/:name' ] ,
    [ 'Unterweisungsthema' , '/unterweisungsthema/:name/:betriebeId' ] ,
    [ 'Unterweisungsthemen' , '/unterweisungsthemen/:kundeId/:kundeName' ] ,
    [ 'DokumentUnterweisung' , '/dokument-unterweisung/:name/:unterweisungenId' ] ,
    [ 'System' , '/system/:kundeId/:kundeName' ] ,
    [ 'Briefpapier' , '/briefpapier/:kundeId/:kundeName' ] ,
    [ 'Home' , '/' ] ,
    [ 'Home' , '/home' ]
]
;