import { IonButton , IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext , useState } from "react";
import { useHistory } from 'react-router-dom';
import { defColumn , defColumnRenderCell , exportTableData } from './_HelperFunctions' ;
import { useGefahrstoffschulungen , useGefahrstoffschulungenThemen, useMitarbeiterListe } from '../components/_CustomHooks';
import { AppContext } from "../contexts/app_context";
import './table_einweisungen_grid.css'

function GefahrstoffschulungenListen() {
  /** Component Environment */
    const { appGefahrstoffschulungIdChange , appGefahrstoffschulungen , appGefahrstoffschulungenFetchState , appGefahrstoffschulungenThemen , appMitarbeiterListe , appViewSwitchChange } = useContext( AppContext ) ;
    const history = useHistory();
    const [pageSize, setPageSize] = useState(50);
      // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  /** Component Helpers */
    const setTitle = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) { return 'Offen' ; }
      if( tablePurpose === 'abgeschlossen' ) { return 'Abgeschlossen' ; }
    }
    const setFieldForRowId = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) { return 'GefahrstoffschulungenId' ; }
      if( tablePurpose === 'abgeschlossen' ) { return 'GefahrstoffschulungenId' ; }
    }
    const dataForPurpose = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return appGefahrstoffschulungen
          .filter(
            ( entry ) => entry.GefahrstoffschulungenStatus === 'offen'
           )
           .filter(
            ( entry ) =>
              {
                if(
                  appGefahrstoffschulungenThemen
                    .findIndex(
                      ( thema ) => {
                        if( thema.SchulungenId === entry.GefahrstoffschulungenThema ) {
                          if( thema.SchulungenAktiv === 'aktiv' ) {
                            return true
                          }
                          return false ;
                        }
                        return false ;
                      }
                    ) > -1
                )
                  { 
                    return true ;
                  }
                  return false ;
                }
           )
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return appGefahrstoffschulungen
          .filter(
            ( entry ) => entry.GefahrstoffschulungenStatus === 'abgeschlossen'
           );
      }
    }
    const setSorting = ( tablePurpose ) => {
      if( tablePurpose === 'offen' ) {
        return { field: 'GefahrstoffschulungenTermin' , sort: 'asc' }
      }
      if( tablePurpose === 'abgeschlossen' ) {
        return { field: 'GefahrstoffschulungenTermin' , sort: 'desc' }
      }
    }
    const mkTable = ( tablePurpose ) =>  {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              { setTitle( tablePurpose ) }
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Box
                sx={{
                  height: 300 ,
                  width: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(211,211,211)",
                  },
                }}
              >
                {
                  appGefahrstoffschulungenFetchState === 100 ? (
                    <IonSpinner name="bubbles" />
                  ) : (
                    <DataGrid
                      headerHeight = { 56 }
                      // "loading" muss offenbar anderweitig wieder auf "false" gesetzt werden
                      // loading = { true }
                      disableVirtualization 
                      componentsProps = {{
                        toolbar: {
                          tablePurpose: tablePurpose
                        }
                      }}
                      initialState={{
                        sorting: {
                          sortModel: [ setSorting( tablePurpose ) ] ,
                        },
                      }}
                      getRowId = { (row) => row[ setFieldForRowId( tablePurpose ) ] }
                      rows={ dataForPurpose( tablePurpose) }
                      columns =
                        {
                          [
                            {
                              field: 'GefahrstoffschulungenId' ,
                              hide: true
                            } ,
                            //#region GefahrstoffschulungenMitarbeiterTermin
                              /** "GefahrstoffschulungenMitarbeiterId"
                               *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                               *        => wg. lokaler Daten-States statt useContext vorerst via
                               *           Copy & Paste zu handlen
                               */
                                defColumnRenderCell(
                                  defColumn( "GefahrstoffschulungenTermin" , "Termin" , 100 , 0 ) ,
                                  ( data ) => data.row.GefahrstoffschulungenTermin === '0000-00-00'
                                      ?
                                        '(offen)'
                                      : 
                                        new Date( data.row.GefahrstoffschulungenTermin ).toLocaleDateString()
                                ) ,
                              //#endregion GefahrstoffschulungenMitarbeiterTermin
                              defColumn( 'GefahrstoffschulungenDozent' , 'Dozent/in' , 140 , 0 ) ,
                            defColumnRenderCell(
                              defColumn( 'GefahrstoffschulungenThema' , 'Thema' , 300 , 1 , 300 ) ,
                              ( params ) => {
                                if( params.row.GefahrstoffschulungenThema ) {
                                  let matchesGefahrstoffschulungenTitel = appGefahrstoffschulungenThemen
                                  .find(
                                      ( entry ) => entry.SchulungenId === params.row.GefahrstoffschulungenThema
                                    )
                                  if( matchesGefahrstoffschulungenTitel ) {
                                    return matchesGefahrstoffschulungenTitel.SchulungenTitel ;
                                  }
                                }
                              }
                            ) ,
                            //#region GefahrstoffschulungenMitarbeiterId
                              /** "GefahrstoffschulungenMitarbeiterId"
                               *    - ! genau so in verschiedenen Komponenten an verschiedenen Stellen
                               *        => wg. lokaler Daten-States statt useContext vorerst via
                               *           Copy & Paste zu handlen
                               */
                                defColumnRenderCell(
                                  defColumn( "GefahrstoffschulungenMitarbeiterId" , "Teilnehmer" ) ,
                                  ( params ) => {
                                    if( params.row.GefahrstoffschulungenMitarbeiterId ) {
                                      let matchesMitarbeiterDB = appMitarbeiterListe
                                        .filter(
                                          ( { MitarbeiterId } ) =>
                                          /** Präfigierung mit ',' für z.B. "89" vs. "189" (",89" vs. ",189")
                                           *  - in Klammern wg. "( ',' xy).includes" vs. "',' + (xy.includes)"  
                                           */
                                            ( ',' + params.row.GefahrstoffschulungenMitarbeiterId + ',' )
                                              .includes( ',' + MitarbeiterId + ',' )
                                          )
                                        .map(
                                          ( { MitarbeiterNachname } ) => MitarbeiterNachname
                                        )
                                      if( matchesMitarbeiterDB ) {
                                        return matchesMitarbeiterDB.toString() ;
                                      }
                                    }
                                  }
                                )
                              //#endregion GefahrstoffschulungenMitarbeiterId
                          ]
                        } 
                      rowsPerPageOptions={[50,100]}
                      disableSelectionOnClick
                      onCellClick = {
                        ( e ) => { 
                          appViewSwitchChange( true ) ;
                          appGefahrstoffschulungIdChange ( e.id ) ;
                          history.push( '/gefahrstoffschulung/' + e.id ) ;
                          return ;
                          }
                        }
                      pageSize={pageSize}
                      onPageSizeChange={(newPage) => setPageSize(newPage)}
                      pagination
                      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                  )
                }
            </Box>
            {
              appGefahrstoffschulungen.length > 0
                ?
                  <IonButton onClick={ () => exportTableData( dataForPurpose( tablePurpose) , 'Gefahrstoffschulungen ' + tablePurpose ) }>Exportieren</IonButton>
                :
                  ''
            }
            <IonButton onClick={ () => { appGefahrstoffschulungIdChange( 'neu' ) ; history.push( "/gefahrstoffschulung/neu/" ) ; } }>Neue Gefahrstoffschulung</IonButton>
          </IonCardContent>
        </IonCard>
      )
    }
  /** Component Mount-Update */
    useGefahrstoffschulungen() ;
    useGefahrstoffschulungenThemen() ;
    useMitarbeiterListe() ;

/** Component UI */
    return (
      <>
        {/* { mkTable( 'matrix' ) } */}
        {/* <GefahrstoffschulungenMatrix/> */}
        { mkTable( 'offen' ) }
        { mkTable( 'abgeschlossen' ) }
      </>
    );
}
export default GefahrstoffschulungenListen;
