//#region Modules
  import { IonButton, IonSpinner, useIonViewWillEnter } from '@ionic/react';
  import Box from "@mui/material/Box";
  import { DataGrid , deDE } from "@mui/x-data-grid";
  import { useContext, useEffect, useState } from 'react';
  import { ThumbsDown , ThumbsUp } from 'react-ionicons';
  import { useHistory } from 'react-router-dom';
  import { AppContext } from '../contexts/app_context';
  import { useEinweisungGeraeteThemen , useEinweisungsThemen , useGeraeteListe } from './_CustomHooks';
  import { defColumn , defColumnRenderCell , getLoadErr } from './_HelperFunctions' ;
    //#endregion Modules



//#region React-Komponente
  type Props = {
    themaId: any ,
    themaTitle: string
  }
  function TableGeraeteEinweisungsthemen( props:Props ) {
    //#region State-Management
      //#region useContext
        const { appBetriebeId , appEinweisungGeraeteThemen , appEinweisungGeraeteThemenFetchanewChange , appEinweisungsThemenListe , appGeraeteIdChange , appGeraeteListeData , appGeraeteListeFetchstate , appViewSwitchChange } = useContext( AppContext ) ;
          //#endregion useContext
      useIonViewWillEnter(
        () => {
          appEinweisungGeraeteThemenFetchanewChange(true);
        }
      );
      //#region Filter-Daten
        /** Kopie Geräte-Daten für destruktive Filterung */
          const getGeraeteListeMitEinweisungsThemen = () =>
            {
              let objEinweisungsThemenListe = {} ;
              appEinweisungsThemenListe
                .forEach(
                  ( entry:any ) => 
                    {
                      // @ts-ignore
                      objEinweisungsThemenListe[ entry.SchulungenId ] =
                        {
                          'Titel' : entry.SchulungenTitel ,
                          'Inhalt': entry.SchulungenInhalt ,
                          'Aktiv': entry.SchulungenAktiv ,
                          'Pflicht': entry.SchulungenPflicht ,
                          'Turnus': entry.SchulungenTurnus
                        }
                      ;
                    }
                )
              return appGeraeteListeData
                .map(
                  ( geraet:any ) =>
                    {
                      geraet[ 'einweisungsThemen' ] = [] ;
                      // @ts-ignore
                      let myEinweisungsThemenLabels = [] ;
                      appEinweisungGeraeteThemen
                        .forEach(
                          ( thema:any ) => {
                            if( thema.GeraetId === geraet.geraeteId ) {
                              // @ts-ignore
                              if( objEinweisungsThemenListe[ thema.ThemaId ].Aktiv === 'aktiv' ) {
                                geraet[ 'einweisungsThemen' ].push( thema.ThemaId ) ;
                                // @ts-ignore
                                myEinweisungsThemenLabels.push( objEinweisungsThemenListe[ thema.ThemaId ].Titel ) ;
                              }
                            }
                          }
                        )
                      // @ts-ignore
                      geraet[ 'einweisungsThemenLabels' ] = myEinweisungsThemenLabels.join( ', ' ) ;
                      return geraet ;
                    }
                )
              ;
            }
          const filterGeraeteListeMitEinweisungsThemen = ( filter:String ) =>
            {
              let filtered = getGeraeteListeMitEinweisungsThemen() ;
              if( filter !== '' ) {
                const appEinweisungsThema =
                  appEinweisungGeraeteThemen
                    .find(
                      ( thema:any ) => thema.ThemaId === filter
                    )
                  if( appEinweisungsThema ) {
                    filtered = filtered.filter(
                      ( geraet:any ) =>
                        geraet[ 'einweisungsThemen' ].includes( appEinweisungsThema.ThemaId )
                    )
                  }
                  else {
                    filtered = Array() ;  
                  }
                }
              return filtered ;
            }
          const [ localGeraeteListeData , localGeraeteListeDataChange ] = useState( Array() ) ;
          //#region Einzel-Filter
          //#region Anzuwendende Filter
            /** Als States erforderlich wg. Rerendering durch Filter */
              const [ filterEinweisungsthema , setFilterEinweisungsthema ] = useState( props.themaId ) ;
            /** Filteranwendung durch Rerendering via useeffect
             *    - Hier die Filtermethoden, getriggert durch State pro Filter, welche durch Filter-Clicks gesetzt = aktiviert / deaktiviert werden
            */
              useEffect(
                () => {
                  localGeraeteListeDataChange( filterGeraeteListeMitEinweisungsThemen( filterEinweisungsthema ) ) ;
                } ,
                [ filterEinweisungsthema ]
              )
              //#endregion Anzuwendende Filter
            //#endregion Einzel-Filter
          //#endregion Filter-Daten
      //#region DataGrid
        const [pageSize , setPageSize] = useState( 50 );
          //#endregion DataGrid
      //#region Routing
        const history = useHistory();
          //#endregion Routing
        //#endregion State-Management
    //#region Data-Fetches
      useGeraeteListe() ;
      useEinweisungGeraeteThemen() ;
      useEinweisungsThemen() ;
          //#endregion Data-Fetches
    useEffect(
      () =>
        {
          localGeraeteListeDataChange( filterGeraeteListeMitEinweisungsThemen( props.themaId ) ) ;
        } ,
        [ appEinweisungGeraeteThemen ]
    )
        //#region React-Render
      return appGeraeteListeFetchstate !== 200 && appGeraeteListeFetchstate !== 204 ?
        (
          appGeraeteListeFetchstate === 100
            ? <IonSpinner name="bubbles" />
            : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                { getLoadErr( appGeraeteListeFetchstate ) }
              </div> 
        ) : (
          <>
            <IonButton disabled = { filterEinweisungsthema === '' } onClick={ () => { setFilterEinweisungsthema( '' ) ; localGeraeteListeDataChange( appGeraeteListeData ) } }  >Alle Geräte anzeigen</IonButton>
            <IonButton disabled = { filterEinweisungsthema !== '' } onClick={ () => { setFilterEinweisungsthema( props.themaId) } }  >Filtern nach "{ props.themaTitle }"</IonButton>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(211,211,211)",
                },
              }}
            >
              <DataGrid
                disableVirtualization 
                componentsProps = {{
                  toolbar: {
                    tablePurpose: 'Geraete'
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'geraeteId' , sort: 'asc' } ] ,
                  },
                }}
                getRowId={(row) => row.geraeteId}
                rows={ localGeraeteListeData }
                /** Columns sollen in chart_geraete und table_geraete gleich sein
                 *  !!!!! Auslagern in zentrale Datei nicht einfach wg. TypeScript, deshalb
                 *        vorerst identische Kopien (bis auf Type-Script-Annotations wg. TSX
                 *        vs. JSX)
                 */
                  columns = {
                    [
                      defColumn( "einweisungsThemenLabels" , "Themen" , 300 ) ,
                      defColumnRenderCell(
                        defColumn( "GeraeteStatus" , "Status" , 60 ) ,
                        ( params:any ) => {
                          if ( params.row.GeraeteStatus === 0 || params.row.Aufgaben.length === 0 ) {
                            return <ThumbsDown  color="red"  />
                          }
                          else {
                            if ( params.row.GeraeteStatus === 1 ) {
                              return <ThumbsUp  color="green"  />
                            }
                            else {
                              if ( params.row.GeraeteStatus === 2 ) {
                                return <ThumbsUp  color="gold"  />
                              }
                            }
                          }
                        }
                      ) ,       
                      defColumn( "geraeteId" , "ID" ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteTyp" , "Geräte Gruppe" ) ,
                      defColumn( "GeraeteHersteller" , "Geräte Hersteller" ) ,
                      defColumn( "GeraeteName" , "Geräte Geräte Typ" ) ,
                      defColumn( "baujahr" , "Baujahr" ) ,
                      defColumn( "GeraeteCE" , "CE" ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteStandort" , "Standort" ) ,
                      defColumn( "GeraeteEigentuemer" , "Eigentümer" ) ,
                      defColumn( "GeraeteWartung" , "nächste Wartung" )
                    ]
                  }
                pageSize={ pageSize }
                rowsPerPageOptions={[50, 100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                onCellClick = { 
                  ( e ) => 
                    {
                      appViewSwitchChange( true ) ;
                      appGeraeteIdChange( e.id ) ;
                      history.push( '/geraet/' + e.id + '/' + appBetriebeId ) ;
                    }
                }
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </>
      )
  }
        //#endregion React-Render
    //#endregion React-Komponente

export default TableGeraeteEinweisungsthemen;
