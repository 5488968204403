import { IonCard, IonCardHeader, IonCardTitle } from "@ionic/react";
import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/app_context";
import { MessgeraetNeu } from "./messgeraet_neu";
import { Messgeraet_Zuordnen } from "./messgeraet_zuordnen";
import { Messwerte } from "./messwerte";
import { Messgeraet_Frueher } from "./messgeraet_frueher";


export const Messgeraete = () =>
  {
    const {
        appGeraetHasIotMessung ,
        appIotMessgeraeteAktion ,
        appIotMessgeraeteAktionChange
      } = useContext( AppContext ) ;

    const fallunterscheidung = ( messgeraet_zuordnung:any ) =>
      {
        if( messgeraet_zuordnung === undefined ) {
          return <div>Fehler: keine Informationen verfügbar</div>
        }
        if( messgeraet_zuordnung === 0 ) {
          return <MessgeraetNeu/> ;
        }
        if( messgeraet_zuordnung === 1 ) {
          return <Messwerte/> ;
        }
        if( ! isNaN( new Date( messgeraet_zuordnung ).valueOf() ) ) {
          return <Messgeraet_Frueher/> ;
        }
        return <div>unbekannter Fehler: Fallunterscheidung</div>
      }
    
    const aktionswahl = ( strAktion:string ) =>
      {
        if( strAktion === 'info' ) {
          return fallunterscheidung( appGeraetHasIotMessung )
        }
        if( strAktion === 'assign' ) {
          return <Messgeraet_Zuordnen/>
        }
        if( strAktion === 'old_values' ) {
          return <Messwerte/>
        }
        return <div>unbekannter Fehler: Aktion</div>
      }
    
    useEffect(
      () =>
        {
          appIotMessgeraeteAktionChange( 'info' )
        } ,
        [ appGeraetHasIotMessung ]
    )
    
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Messgeräte
          </IonCardTitle>
        </IonCardHeader>
        {
          aktionswahl( appIotMessgeraeteAktion )
        }
      </IonCard>
    );
 
  }
