//#region Modules
  import { IonButton, IonSpinner, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
  import Box from "@mui/material/Box";
  import { DataGrid , deDE } from "@mui/x-data-grid";
  import { useContext, useEffect, useState } from 'react';
  import { useHistory } from 'react-router-dom';
  import { AppContext } from '../contexts/app_context';
  import { useArticlesGefahrstoffschulungenThemen , useGefahrstoffschulungenThemen , useArticles } from './_CustomHooks';
  import { ButtonAdd, defColumn , getLoadErr } from './_HelperFunctions' ;
    //#endregion Modules



//#region React-Komponente
  type Props = {
    themaId: any ,
    themaTitle: string
  }
  function TableArticlesGefahrstoffschulungsthemen( props:Props ) {
    //#region State-Management
      //#region useContext
        const { appArticlesGefahrstoffschulungenThemen , appGefahrstoffschulungenThemaId , appArticlesGefahrstoffschulungenThemenFetchanewChange , appGefahrstoffschulungenThemen , appArticleIdChange , appArticles , appArticlesFetchstate , appViewSwitchChange } = useContext( AppContext ) ;
          //#endregion useContext
      // lieferanten_id erstmal fest verdrahtet mit Kleen Purgatis = 160
        const lieferanten_id = 160 ;
      useIonViewWillEnter(
        () => {
          appArticlesGefahrstoffschulungenThemenFetchanewChange(true);
        }
      );
      useIonViewWillLeave(() => {
        setFilterGefahrstoffschulungenThema( appGefahrstoffschulungenThemaId ) ;
      });
      //#region Filter-Daten
        /** Kopie Artikel-Daten für destruktive Filterung */
          const getArticlesMitGefahrstoffschulungenThemen = () =>
            {
              let objGefahrstoffschulungenThemen = {} ;
              appGefahrstoffschulungenThemen
                .forEach(
                  ( entry:any ) => 
                    {
                      // @ts-ignore
                      objGefahrstoffschulungenThemen[ entry.SchulungenId ] =
                        {
                          'Titel' : entry.SchulungenTitel ,
                          'Inhalt': entry.SchulungenInhalt ,
                          'Aktiv': entry.SchulungenAktiv ,
                          'Pflicht': entry.SchulungenPflicht ,
                          'Turnus': entry.SchulungenTurnus
                        }
                      ;
                    }
                )
              return appArticles
                .map(
                  ( article:any ) =>
                    {
                      article[ 'gefahrstoffschulungenThemen' ] = [] ;
                      // @ts-ignore
                      let myGefahrstoffschulungenThemenLabels = [] ;
                      appArticlesGefahrstoffschulungenThemen
                        .forEach(
                          ( entry:any ) => {
                            if( entry.artikel === article.id ) {
                              // @ts-ignore
                              if( objGefahrstoffschulungenThemen[ entry.thema ].Aktiv === 'aktiv' ) {
                                article[ 'gefahrstoffschulungenThemen' ].push( entry.thema ) ;
                                // @ts-ignore
                                myGefahrstoffschulungenThemenLabels.push( objGefahrstoffschulungenThemen[ entry.thema ].Titel ) ;
                              }
                            }
                          }
                        )
                      // @ts-ignore
                      article[ 'gefahrstoffschulungenThemenLabels' ] = myGefahrstoffschulungenThemenLabels.join( ', ' ) ;
                      return article ;
                    }
                )
              ;
            }
          const filterArticlesMitGefahrstoffschulungenThemen = ( filter:String ) =>
            {
              filter = filter.toString() ;
              let filtered = getArticlesMitGefahrstoffschulungenThemen() ;
              if( filter !== '' ) {
                const appGefahrstoffschulungenThema =
                  appArticlesGefahrstoffschulungenThemen
                    .find(
                      ( entry:any ) => entry.thema === filter
                    )
                  if( appGefahrstoffschulungenThema ) {
                    filtered = filtered.filter(
                      ( entry:any ) =>
                        entry[ 'gefahrstoffschulungenThemen' ].includes( appGefahrstoffschulungenThema.thema )
                    )
                  }
                  else {
                    filtered = Array() ;  
                  }
                }
                return filtered ;
            }
          const [ localArticles , localArticlesChange ] = useState( Array() ) ;
          //#region Einzel-Filter
          //#region Anzuwendende Filter
            /** Als States erforderlich wg. Rerendering durch Filter */
              const [ filterGefahrstoffschulungenThema , setFilterGefahrstoffschulungenThema ] = useState( appGefahrstoffschulungenThemaId ) ;
            /** Filteranwendung durch Rerendering via useeffect
             *    - Hier die Filtermethoden, getriggert durch State pro Filter, welche durch Filter-Clicks gesetzt = aktiviert / deaktiviert werden
            */
              useEffect(
                () => {
                  localArticlesChange( filterArticlesMitGefahrstoffschulungenThemen( filterGefahrstoffschulungenThema ) ) ;
                } ,
                [ filterGefahrstoffschulungenThema ]
              )
              //#endregion Anzuwendende Filter
            //#endregion Einzel-Filter
          //#endregion Filter-Daten
      //#region DataGrid
        const [pageSize , setPageSize] = useState( 50 );
          //#endregion DataGrid
      //#region Routing
        const history = useHistory();
          //#endregion Routing
        //#endregion State-Management
    //#region Data-Fetches
      useArticles() ;
      useArticlesGefahrstoffschulungenThemen() ;
      useGefahrstoffschulungenThemen() ;
          //#endregion Data-Fetches
    useEffect(
      () =>
        {
          localArticlesChange( filterArticlesMitGefahrstoffschulungenThemen( appGefahrstoffschulungenThemaId ) ) ;
        } ,
        [ appArticlesGefahrstoffschulungenThemen ]
    )
        //#region React-Render
      return appArticlesFetchstate !== 200 && appArticlesFetchstate !== 204 ?
        (
          appArticlesFetchstate === 100
            ? <IonSpinner name="bubbles" />
            : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                { getLoadErr( appArticlesFetchstate ) }
              </div> 
        ) : (
          <>
            <IonButton disabled = { filterGefahrstoffschulungenThema === '' } onClick={ () => { setFilterGefahrstoffschulungenThema( '' ) ; localArticlesChange( appArticles ) } }  >Alle Artikel anzeigen</IonButton>
            <IonButton disabled = { filterGefahrstoffschulungenThema !== '' } onClick={ () => { setFilterGefahrstoffschulungenThema( appGefahrstoffschulungenThemaId) } }  >Filtern nach "{ props.themaTitle }"</IonButton>
            <ButtonAdd
              myExec = { () => { appArticleIdChange( 'neu' ) ; } }
              routeUrl = { '/article/neu/' + lieferanten_id }
            />
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(211,211,211)",
                },
              }}
            >
              <DataGrid
                disableVirtualization 
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'id' , sort: 'asc' } ] ,
                  },
                }}
                getRowId={(row) => row.id}
                rows={ localArticles }
                  columns = {
                    [
                      defColumn( "gefahrstoffschulungenThemenLabels" , "Themen" , 300 ) ,
                      defColumn( 'lieferanten_artikel_nr' , 'Artikelnr.' , 40 , 0 ) ,
                      defColumn( 'kategorie' , 'Kategorie' , 120 , 0 ) ,
                      defColumn( 'bezeichnung' , 'Bezeichnung' , 420 , 0 ) ,
                    ]
                  }
                pageSize={ pageSize }
                rowsPerPageOptions={[50, 100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                onCellClick = { 
                  ( e ) => 
                    {
                      appViewSwitchChange( true ) ;
                      appArticleIdChange( e.id ) ;
                      setFilterGefahrstoffschulungenThema( appGefahrstoffschulungenThemaId ) ;
                      history.push( '/article/' + e.id + '/' + lieferanten_id ) ;
                    }
                }
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </>
      )
  }
        //#endregion React-Render
    //#endregion React-Komponente

export default TableArticlesGefahrstoffschulungsthemen;
