import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { helpOutline } from 'ionicons/icons';
import { useContext, useState } from 'react';
import UnterweisungenListe from '../components/table_unterweisungen_grid';
import { AppContext } from '../contexts/app_context';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import UnterweisungenMatrix from '../components/table_unterweisungen_matrix';

export const Unterweisungen: React.FC = () => {
  useIonViewDidEnter(() => {
    appViewSwitchChange( false ) ;
  });
  const { appBetriebeId , appBetriebeName , appViewSwitch , appViewSwitchChange} = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313ivVFHd' ;
    return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie offene und abgeschlossene Schulungen einsehen und bearbeiten.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Schulungen { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UnterweisungenMatrix/>
        <UnterweisungenListe />
      </IonContent>
    </IonPage>
  );
};
