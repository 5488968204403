/**
 * View Geraeteklassen
 * 
 * Einzelne Geräteklassen einsehen, bearbeiten oder erstellen
 * 
 */

import { IonButton, IonButtons, IonCard, IonCardContent, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGeraetegruppenPossible , useGeraeteklassenListe } from '../components/_CustomHooks';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import { HexColorInput, HexColorPicker } from "react-colorful";

export function Geraeteklasse() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;
      }
  ) ;
  const { appBetriebeId , appKundeId , appGeraetetypAddedChange , appGeraetegruppenPossible ,appGeraetegruppenPossibleFetchanewChange , appGeraeteklassenId , appGeraeteklassenIdChange , appGeraeteklassenListe , appGeraeteklassenListeFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ GeraeteklassenId, setGeraeteklassenId ] = useState( appGeraeteklassenId )
  const [ GeraeteklassenName, setGeraeteklassenName ] = useState( '' )
  const [ GeraeteklassenAktiv, setGeraeteklassenAktiv ] = useState( 'aktiv' )
  const [ GeraeteklassenAktivBool, setGeraeteklassenAktivBool ] = useState( true )
  const [ GeraeteklassenGeraetetyp, setGeraeteklassenGeraetetyp ] = useState( '' )
  const [ GeraeteklassenNameFarbe, setGeraeteklassenNameFarbe ] = useState("#000000") ;
  const [ GeraeteklassenGeraetetypFarbe, setGeraeteklassenGeraetetypFarbe ] = useState("#000000") ;
  // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  const [ fetchState , setFetchState ] = useState( 0 ) ;
  const [ deDupedGeraeteklassen , setDeDupedGeraeteklassen ] = useState( [] ) ;
  const [ deDupedGeraeteklassenFarben , setDeDupedGeraeteklassenFarben ] = useState( [] ) ;
  const [ geraeteklassenUseDropdown , setGeraeteklassenUseDropdown ] = useState( true ) ;
  const GeraeteklassenAktivStringToBool = ( val:string ) => {
    if( val === 'aktiv' ) {
      setGeraeteklassenAktiv( 'aktiv' )
      setGeraeteklassenAktivBool( true )
    } else {
      setGeraeteklassenAktiv( 'inaktiv' )
      setGeraeteklassenAktivBool( false )
    }
  }
  const getDataDB = async ( GeraeteklassenId:string ) => {
    if (appGeraeteklassenId === 'neu') {
      setGeraeteklassenId( 'neu' ) ;
      setGeraeteklassenName( '' ) ;
      GeraeteklassenAktivStringToBool( 'aktiv' ) ;
      setGeraeteklassenNameFarbe( '#000000' )
      setGeraeteklassenGeraetetyp( '' ) ;
      setGeraeteklassenGeraetetypFarbe( '#000000' )
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/geraeteklassen/get_geraeteklasse.php?geraeteklassen_id=` + GeraeteklassenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      await api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((geraeteklassen: any) => {
          if (geraeteklassen.GeraeteklassenId === appGeraeteklassenId ) {
            setGeraeteklassenId( geraeteklassen.GeraeteklassenId ) ;
            setGeraeteklassenName( geraeteklassen.GeraeteklassenName ) ;
            GeraeteklassenAktivStringToBool( geraeteklassen.GeraeteklassenAktiv ) ;
            setGeraeteklassenNameFarbe( geraeteklassen.GeraeteklassenNameFarbe )
            setGeraeteklassenGeraetetyp( geraeteklassen.GeraeteklassenGeraetetyp ) ;
            setGeraeteklassenGeraetetypFarbe( geraeteklassen.GeraeteklassenGeraetetypFarbe )
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    appGeraetetypAddedChange( GeraeteklassenGeraetetyp ) ;
    var raw = JSON.stringify({
      GeraeteklassenId: GeraeteklassenId ,
      GeraeteklassenName: GeraeteklassenName ,
      GeraeteklassenAktiv: GeraeteklassenAktiv,
      GeraeteklassenNameFarbe: GeraeteklassenNameFarbe ,
      GeraeteklassenGeraetetyp: GeraeteklassenGeraetetyp ,
      GeraeteklassenGeraetetypFarbe: GeraeteklassenGeraetetypFarbe ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/geraeteklassen/set_geraeteklasse.php', requestOptions)
      .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appGeraeteklassenIdChange( json.entryId ) ;
           setGeraeteklassenId( json.entryId ) ;
         }
       }
      )
  }
  function onValueGeraeteklassenAktivChange(e: boolean) {
    e
      ?
        GeraeteklassenAktivStringToBool( 'aktiv' )
      :
        GeraeteklassenAktivStringToBool( 'inaktiv' )
  }
  function onValueGeraeteklassenName( e:any ) {
    if( e.target.value === '<neu anlegen>' ) {
      setGeraeteklassenUseDropdown( false ) ;
      setGeraeteklassenName( '...' ) ;
      setGeraeteklassenNameFarbe( '#000000' ) ;
    } else {
      setGeraeteklassenName( e.target.value ) ;
      // @ts-ignore
      if( deDupedGeraeteklassen.indexOf( e.target.value) > -1 ) {
        // @ts-ignore
        setGeraeteklassenNameFarbe( deDupedGeraeteklassenFarben[ e.target.value ] ) ;
        // @ts-ignore
      } else {
        setGeraeteklassenNameFarbe( '#000000' ) ;
      }
    }
  }
  function onValueGeraeteklassenGeraetetyp( e:any ) { setGeraeteklassenGeraetetyp( e.target.value ) ; }
  function onValueGeraeteklassenGeraetetypFarbe( e:any ) { setGeraeteklassenGeraetetypFarbe( e.target.value ) ; }
  useEffect(
    () => {
      setDeDupedGeraeteklassenFarben( [] ) ;
      getDataDB( appGeraeteklassenId ) ;
      appGeraeteklassenListeFetchanewChange( true ) ;
    },
    [ appGeraeteklassenId ]
  );
  useGeraeteklassenListe() ;
  useGeraetegruppenPossible() ;
  useEffect(
    () =>
      {
        appGeraetegruppenPossibleFetchanewChange( true ) ;
        setDeDupedGeraeteklassen(
          Array.from(
            new Set(
              appGeraeteklassenListe
              .map(
                ( entry:any ) => entry.GeraeteklassenName
              )
              .sort()
            )
          )
        ) ;
        let myDeDupedGeraeteklassenFarben = {} ;
        const tmpSeparator = '______XXXXX______' ;
          Array.from(
            new Set(
              appGeraeteklassenListe
              .map(
                ( entry:any ) => entry.GeraeteklassenName + tmpSeparator + entry.GeraeteklassenNameFarbe
              )
              .sort()
            )
          )
          .forEach(
            ( entry:any ) => 
              {
                // @ts-ignore
                myDeDupedGeraeteklassenFarben[ entry.split( tmpSeparator )[ 0 ] ] = entry.split( tmpSeparator )[ 1 ] ;
              }
          )
          // @ts-ignore
        setDeDupedGeraeteklassenFarben( myDeDupedGeraeteklassenFarben ) ;
      } ,
      [ appGeraeteklassenListe ]
  ) ;
  useEffect(
    () => {
      document.getElementById( 'feldKlassen' )?.click() ;
    },
    [ geraeteklassenUseDropdown ]
  );

  
  // const MOCK_GeraeteklassenNameFarbe:any = {
  //   'Hilfsmittel': '#0000FF' /* blue */ ,
  //   'Hygienetechnik': '#FFFF00' /* yellow */ ,
  //   'Medizinische Kleingeräte' : '#FF0000	' /* red */ ,
  //   'Patientenhandling' : '#00FF00	' /* lime */ ,
  //   'Rehatechnik' : '#000080	' /* navy */ ,
  //   'sonstiges' : '#800000	' /* maroon */ ,
  // }
  // useEffect(
  //   () => {
  //       /** Fallback ""#F5F5F5" (white smoke) für leeren "GeraeteklassenName" = neuer Eintrag */
  //     setGeraeteklassenNameFarbe( MOCK_GeraeteklassenNameFarbe[ GeraeteklassenName ] || '#F5F5F5' ) ;
  //   },
  //   [ GeraeteklassenName ]
  // );
  return (
    <IonPage>
      <PageHelp page = 'Gerätekategorie' state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für eine Gerätekategorie einsehen und bearbeiten oder für eine neue Gerätekategorie festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Zuordnung Gerätegruppe zu Gerätekategorie
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardContent>
            <IonItem lines="none" className='item'>
              <IonLabel slot="">aktiv</IonLabel>
              <IonCheckbox slot="start" checked={ GeraeteklassenAktivBool } onIonChange={e => onValueGeraeteklassenAktivChange( e.detail.checked ) } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Gerätekategorie</IonLabel>
              {
                ! geraeteklassenUseDropdown
                  ?
                    <div style = { { width: '100%' } } >
                      <IonInput id="feldKlassen" style = { { backgroundColor: 'whitesmoke' , float: 'left' , fontWeight: 'bold' , width: '15em' } } required placeholder = '...' value={ GeraeteklassenName } onIonChange={ ( e )=> onValueGeraeteklassenName( e ) } />
                      <IonButton
                        size = 'large'
                        onClick = { () => setGeraeteklassenUseDropdown( true ) }
                      >
                        vorhandene Kategorie wählen
                      </IonButton>
                    </div>
                  :
                    <div style = { { width: '100%' } } >
                      <IonSelect id="feldKlassen" style = { { float: 'left' , paddingLeft: '0' , width: '15em' } }  cancelText="Abbrechen" interface = 'action-sheet' placeholder="bitte auswählen" value = { GeraeteklassenName } onIonChange={(e) => onValueGeraeteklassenName(e)} >
                        <IonSelectOption key = { '<neu anlegen>' } value = { '<neu anlegen>' } >&lt;neu anlegen&gt;</IonSelectOption>
                        {
                          deDupedGeraeteklassen
                            .map(
                              ( entry:any ) =>
                                {
                                  return <IonSelectOption key = { entry } value = { entry } >{ entry }</IonSelectOption>
                                }
                            )
                        }
                      </IonSelect>
                      <IonButton
                        size = 'large'
                        onClick = { () => setGeraeteklassenUseDropdown( false ) }
                      >
                        {
                          GeraeteklassenName
                            ?
                              'Name bearbeiten'
                            :
                              'neue Kategorie anlegen'
                        }
                      </IonButton>
                    </div>
              }
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Gerätegruppe</IonLabel>
                <IonSelect cancelText="Abbrechen" interface = 'action-sheet' placeholder="bitte auswählen" value = { GeraeteklassenGeraetetyp } onIonChange={(e) => onValueGeraeteklassenGeraetetyp(e)} >
                  {
                    appGeraetegruppenPossible
                      .map(
                        ( entry:any ) =>
                          {
                            return <IonSelectOption key = { entry } value = { entry } >{ entry }</IonSelectOption>
                          }
                      )
                  }
                </IonSelect>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Farben für Grafiken</IonLabel>
              <IonGrid>
                <IonRow>
                  <IonCol style = { { margin: '0 1em 0 1em' } }>
                    <span><span style={ { backgroundColor: GeraeteklassenNameFarbe , border: 'thin solid gray' , marginRight: '1em' , paddingRight: '3em' } } ></span>  { GeraeteklassenName || '(Kategorie)' }</span>
                    <HexColorPicker color = { GeraeteklassenNameFarbe } onChange = { setGeraeteklassenNameFarbe } />
                    <HexColorInput
                      color = { GeraeteklassenNameFarbe }
                      onChange = { setGeraeteklassenNameFarbe }
                      style = {
                        {
                          border: 'thin solid gray' ,
                          height: '1.5em' ,
                          marginTop: '0.5em' ,
                          width: '5em' 
                        }
                      }
                    />
                  </IonCol>
                  <IonCol
                    style = { { margin: '0 1em 0 1em' } }
                  >
                    <span>
                      <span
                        style = {
                          {
                            backgroundColor: GeraeteklassenGeraetetypFarbe ,
                            border: 'thin solid gray' ,
                            marginRight: '1em' ,
                            paddingRight: '3em'
                          }
                        }
                      >
                    </span>
                      { GeraeteklassenGeraetetyp || '(Gruppe)' }
                    </span>
                    <HexColorPicker
                      color = { GeraeteklassenGeraetetypFarbe }
                      onChange = { setGeraeteklassenGeraetetypFarbe }
                    />
                    <HexColorInput
                      color = { GeraeteklassenGeraetetypFarbe }
                      onChange = { setGeraeteklassenGeraetetypFarbe }
                      style = {
                        {
                          border: 'thin solid gray' ,
                          height: '1.5em' ,
                          marginTop: '0.5em' ,
                          width: '5em' 
                        }
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <ButtonsSaveActions
              myExec = {
                () => { appGeraeteklassenListeFetchanewChange( true ) ; sendJSON() ; }
              }
            />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
