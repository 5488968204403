import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { helpOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import MitarbeiterTable from '../components/table_mitarbeiter_grid';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const MitarbeiterInnen: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appBetriebeName , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
    // const screencastChannel = 'c313iuVFHG'
  const screencastChannel = 'c313ixVFHL'
  return (
    <IonPage>
      <PageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie die Daten Ihrer Mitarbeiterinnen und Mitarbeiter pflegen oder neue Mitarbeiterinnen und Mitarbeiter anlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Mitarbeiter { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <MitarbeiterTable />
      </IonContent>
    </IonPage>
  );
};
