import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonMenuToggle, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { helpOutline, homeOutline } from 'ionicons/icons';
import React, { useContext , useEffect, useState } from "react";
import { useParams } from 'react-router';
import { useHistory, useLocation } from "react-router-dom";
import ChartGeraete from '../components/chart_geraete';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const Home: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appBetriebeIdChange , appBetriebeNameChange , appGeraeteListeFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313iuVFHG'
  const location = useLocation();
  const { name } = useParams<{ name: string; }>();
  const [betriebe, setBetriebe] = useState([{
    betriebe_name: "Keine Organisationseinheit vorhanden",
    betriebe_id: "0",
  }]);
  const [username, setUserName] = useState("Benutzername");
  //#region mkMenuEntry-KOPIE
    /** hier exakte Kopie von Menu.tsx
     *  - !! in Menu.tsx bearbeiten und hierher kopieren
     *  - NB: wg. involvierter Hooks nicht auslagerbar, deshalb Copy & Paste für Identität
     */
    const mkMenuEntry =
      ( topic:string , key:string , betriebe_id:any , betriebe_name:string , icon:any ) =>
        { 
          return (
            <IonItem
              key= { key + betriebe_id }
              onClick =
                {
                    () => {
                      if( appBetriebeId !== betriebe_id ) {
                        appGeraeteListeFetchanewChange( true ) ;
                        appBetriebeIdChange( betriebe_id ) ;
                        appBetriebeNameChange( betriebe_name ) ;
                      }
                    }
                } 
              routerLink = { '/' + key + '/' + betriebe_id + '/' + betriebe_name }
              routerDirection = "none"
              lines = "none"
              detail = { false }
            >
              <IonIcon
                slot="start"
                ios = { icon }
                md = { icon }
              />
              { topic }
            </IonItem>
          )
        }
      //#endregion mkMenuEntry-KOPIE
  useEffect(() => {
    const getstoragetoken = () => {
      let storagetoken=window.localStorage.getItem("token")
      if (storagetoken != null) {
        let tokenUserName = JSON.parse(storagetoken).usr_name;
        let tokenBetriebe = JSON.parse(storagetoken).betriebe;
        setUserName(tokenUserName);
        setBetriebe(
          tokenBetriebe
            .sort(
              ( a:any , b:any ) =>
                {
                  if ( a.betriebe_name > b.betriebe_name ) { return 1 ; }
                  if ( a.betriebe_name < b.betriebe_name ) { return -1 ; }
                  return 0 ;
                }
            )
        );
      }
    };
    getstoragetoken();
  }, []);
  useEffect( 
   () =>
    {
      if( location.pathname === '/' ) {
        appGeraeteListeFetchanewChange( true ) ;
        appBetriebeIdChange( '' ) ;
      }
    } ,
   [ location ]
  )
  return (
    <IonPage>
      <PageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite erhalten Sie einen Überblick über die Geräte aller Ihrer Einrichtungen. Für Details können Sie von hier direkt zu Einzel-Ansichten wechseln.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            {
              betriebe.length > 1
                ?
                  'Alle Einrichtungen'
                :
                  betriebe[0].betriebe_name
            }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {
          betriebe.length > 1
            ?
              <IonItem
                className='item'
              >
                <IonLabel
                  position = "stacked"
                >
                  Einrichtung auswählen
                </IonLabel>
                <IonSelect
                  cancelText="Abbrechen"
                  interface = 'action-sheet'
                  // placeholder = 'einzelne Einrichtung wählen ...'
                  value = 'alle'
                  onIonChange =
                    { 
                      ( e:any ) =>
                        {
                          if( e.target.value !== 'alle' ) {
                            let betrieb = JSON.parse( e.target.value ) ;
                            appBetriebeIdChange( betrieb.id ) ;
                            appBetriebeNameChange( betrieb.name ) ;
                            appViewSwitchChange( true ) ; 
                            history.push( '/dashboard/' + betrieb.id + '/' + betrieb.name ) ;
                          }
                        }
                    }
                >
                  <IonSelectOption
                    key = 'alle'
                    value = 'alle'
                  >
                    alle
                  </IonSelectOption>
                  {
                    betriebe
                      .map(
                        ( appPage , index ) =>
                          {
                            return (
                              <IonSelectOption
                                key = { appPage.betriebe_id }
                                value = { JSON.stringify( { id: appPage.betriebe_id , name: appPage.betriebe_name } ) }
                              >
                                { appPage.betriebe_name }
                              </IonSelectOption>
                            );
                          }
                      )
                    }
                </IonSelect>
              </IonItem>
            :
              ''
        }
            <ChartGeraete />
      </IonContent>
    </IonPage>
  );
};
