import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EinweisungenListen from '../components/table_einweisungen_grid';
import { AppContext } from '../contexts/app_context';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import EinweisungenMatrix from '../components/table_einweisungen_matrix';

export const Einweisungen: React.FC = () => {
  useIonViewDidEnter(() => {
    appViewSwitchChange( false ) ;
  });
  const { appBetriebeId , appBetriebeName , appEinweisungNeuThemaChange , appGeraeteIdChange , appViewSwitch , appViewSwitchChange} = useContext( AppContext ) ;
  const history = useHistory();
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313ivVFHJ' ;
  useEffect(
    () =>
      {
        if( history.location.pathname.includes( '/einweisungen/' ) ) {
          appEinweisungNeuThemaChange( '' ) ;
          appGeraeteIdChange( 'neu' ) ;
        }
      } ,
    [ history.location.pathname ]
  ) ;
    return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie offene und abgeschlossene Einweisungen einsehen und bearbeiten.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Einweisungen { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <EinweisungenMatrix/>
        <EinweisungenListen />
        {/* <EinweisungenListe Status = 'offen' Titel = '.Offen' /> */}
        {/* <EinweisungenListe Status = 'abgeschlossen' Titel = '.Abgeschlossen' /> */}
      </IonContent>
    </IonPage>
  );
};
