/**
 *  _CustomHooks - Zentral benötigter Code
 * 
 */

 import { useContext , useEffect , useState } from 'react' ;
 import { getData } from './_HelperFunctions' ;
 import { AppContext } from '../contexts/app_context' ;
 
//#region useIotGeraete
  export const useIotGeraete = () =>
  {
    const { appKundeId , appIotGeraeteFetchanew , appIotGeraeteFetchanewChange , appIotGeraeteFetchstateChange , appIotGeraeteChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appIotGeraeteFetchanew ) {
          getData(
            'geraete/get_iot_geraete_kunde.php'
              + '?kunde_id=' + appKundeId
            ,
            appIotGeraeteFetchstateChange ,
            appIotGeraeteChange
          ) ;
          appIotGeraeteFetchanewChange( false ) ;
        }
      } ,
      [ appIotGeraeteFetchanew , appKundeId ]
    ) ;
  }
    //#endregion useIotGeraete
//#region useIotMesspunkte
  export const useIotMesspunkte = () =>
  {
    const { appGeraeteId , appGeraetHasIotMessung , appIotMesspunkteFetchanew , appIotMesspunkteFetchanewChange , appIotMesspunkteFetchstateChange , appIotMesspunkteFromDatetime , appIotMesspunkteUntilDatetime ,appIotMesspunkteChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appGeraetHasIotMessung !== 1 ) {
          appIotMesspunkteChange( [] ) ;
        }
        else {
          if( appIotMesspunkteFetchanew ) {
            getData(
              'geraete/get_geraet_iot_messpunkte.php'
                + '?geraete_id=' + appGeraeteId
                + '&begin=' + ( appIotMesspunkteFromDatetime ? appIotMesspunkteFromDatetime : '' )
                + '&end=' + ( appIotMesspunkteUntilDatetime ? appIotMesspunkteUntilDatetime : '' )
              ,
              appIotMesspunkteFetchstateChange ,
              appIotMesspunkteChange
            ) ;
            appIotMesspunkteFetchanewChange( false ) ;
          }
        }
      } ,
      [ appGeraetHasIotMessung , appGeraeteId , appIotMesspunkteFetchanew , appIotMesspunkteFromDatetime , appIotMesspunkteUntilDatetime ]
    ) ;
  }
    //#endregion useIotMesspunkte
//#region useArticleInternDokumente
  export const useArticleDokumente = () =>
  {
    const { appArticleId , appArticleDokumenteChange , appArticleDokumenteFetchanew , appArticleDokumenteFetchanewChange , appArticleDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appArticleDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?artikel=' + appArticleId , appArticleDokumenteFetchstateChange , appArticleDokumenteChange ) ;
          appArticleDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appArticleDokumenteFetchanew , appArticleId ]
    ) ;
  }
    //#endregion useArticleDokumenten
//#region useArticleInternDokumente
  export const useArticleInternDokumente = () =>
  {
    const { appArticleInternId , appArticleInternDokumenteChange , appArticleInternDokumenteFetchanew , appArticleInternDokumenteFetchanewChange , appArticleInternDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appArticleInternDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?aufgaben_id=' + appArticleInternId , appArticleInternDokumenteFetchstateChange , appArticleInternDokumenteChange ) ;
          appArticleInternDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appArticleInternDokumenteFetchanew , appArticleInternId ]
    ) ;
  }
    //#endregion useArticleInternDokumenten
//#region useArticlesGefahrstoffschulungenThemen
  export const useArticlesGefahrstoffschulungenThemen = () =>
  {
    const { appKundeId , appArticlesGefahrstoffschulungenThemenFetchanew, appArticlesGefahrstoffschulungenThemenFetchanewChange , appArticlesGefahrstoffschulungenThemenFetchstateChange , appArticlesGefahrstoffschulungenThemenChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appArticlesGefahrstoffschulungenThemenFetchanew ) {
          getData(
            'themen/get_articles_themen.php?kunde_id=' + appKundeId ,
            appArticlesGefahrstoffschulungenThemenFetchstateChange ,
            appArticlesGefahrstoffschulungenThemenChange
          ) ; 
          appArticlesGefahrstoffschulungenThemenFetchanewChange( false ) ;
        }
      },
      [ appArticlesGefahrstoffschulungenThemenFetchanew , appKundeId ]
    ) ;
  }
    //#endregion useArticlesGefahrstoffschulungenThemen
//#region useGefahrstoffschulungen
  export const useGefahrstoffschulungen = () =>
    {
      const { appBetriebeId , appGefahrstoffschulungenChange , appGefahrstoffschulungenFetchanew , appGefahrstoffschulungenFetchanewChange , appGefahrstoffschulungenFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGefahrstoffschulungenFetchanew ) {
            getData( 'gefahrstoffschulungen/get_gefahrstoffschulungen.php?betriebe_id=' + appBetriebeId , appGefahrstoffschulungenFetchstateChange , appGefahrstoffschulungenChange ) ;
            appGefahrstoffschulungenFetchanewChange( false ) ;
          }
        } ,
        [ appGefahrstoffschulungenFetchanew , appBetriebeId ]
      ) ;
    }
    //#endregion useGefahrstoffschulungen
//#region useGefahrstoffschulungDokumente
  export const useGefahrstoffschulungDokumente = () =>
  {
    const { appGefahrstoffschulungId , appGefahrstoffschulungDokumenteChange , appGefahrstoffschulungDokumenteFetchanew , appGefahrstoffschulungDokumenteFetchanewChange , appGefahrstoffschulungDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appGefahrstoffschulungDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?gefahrstoffschulung=' + appGefahrstoffschulungId , appGefahrstoffschulungDokumenteFetchstateChange , appGefahrstoffschulungDokumenteChange ) ;
          appGefahrstoffschulungDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appGefahrstoffschulungDokumenteFetchanew , appGefahrstoffschulungId ]
    ) ;
  }
    //#endregion useGefahrstoffschulungDokumente
//#region useGefahrstoffschulungenThemaDokumente
  export const useGefahrstoffschulungenThemaDokumente = () =>
  {
    const { appGefahrstoffschulungenThemaId , appGefahrstoffschulungenThemaDokumenteChange , appGefahrstoffschulungenThemaDokumenteFetchanew , appGefahrstoffschulungenThemaDokumenteFetchanewChange , appGefahrstoffschulungenThemaDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appGefahrstoffschulungenThemaDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?gefahrstoffschulungthema=' + appGefahrstoffschulungenThemaId , appGefahrstoffschulungenThemaDokumenteFetchstateChange , appGefahrstoffschulungenThemaDokumenteChange ) ;
          appGefahrstoffschulungenThemaDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appGefahrstoffschulungenThemaDokumenteFetchanew , appGefahrstoffschulungenThemaId ]
    ) ;
  }
    //#endregion useGefahrstoffschulungenThemaDokumente
//#region useGefahrstoffschulungenThemen
  export const useGefahrstoffschulungenThemen = () =>
    {
      const { appKundeId , appGefahrstoffschulungenThemenFetchanew , appGefahrstoffschulungenThemenFetchanewChange , appGefahrstoffschulungenThemenFetchstateChange , appGefahrstoffschulungenThemenChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGefahrstoffschulungenThemenFetchanew ) {
            getData(
              'themen/get_themen.php?kunde_id=' + appKundeId + '&themen_bezug=2',
              appGefahrstoffschulungenThemenFetchstateChange ,
              appGefahrstoffschulungenThemenChange ,
              ( data:any ) => data
                .sort(
                    ( a:any , b:any ) =>
                      {
                        if ( a.SchulungenTitel > b.SchulungenTitel ) { return 1 ; }
                        if ( a.SchulungenTitel < b.SchulungenTitel ) { return -1 ; }
                        return 0 ;
                      }
                )
                .sort(
                  ( a:any , b:any ) => 
                    {
                      if ( a.SchulungenStatus > b.SchulungenStatus ) { return 1 ; }
                      if ( a.SchulungenStatus < b.SchulungenStatus ) { return -1 ; }
                      return 0 ;
                    }
                )
            ) ; 
            appGefahrstoffschulungenThemenFetchanewChange( false ) ;
          }
        },
        [ appGefahrstoffschulungenThemenFetchanew , appKundeId ]
      ) ;
  }
    //#endregion useGefahrstoffschulungenThemen
//#region useArticles
  export const useArticles = () =>
  {
    const { appBetriebeId , appArticlesFetchanew , appArticlesFetchanewChange , appArticlesFetchstateChange , appArticlesChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appArticlesFetchanew ) {
          /** !! erstmal fest verdrahtet zu Kleen Purgatis = lieferanten_id 160 */
          getData(
            'articles/get_articles.php?my_table=artikel&my_scope=' + 160 ,
            appArticlesFetchstateChange ,
            appArticlesChange ,
            ( data:any ) => data
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.bezeichnung > b.bezeichnung ) { return 1 ; }
                  if ( a.bezeichnung < b.bezeichnung ) { return -1 ; }
                  return 0 ;
                } 
              )
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.aktiv > b.aktiv ) { return 1 ; }
                  if ( a.aktiv < b.aktiv ) { return -1 ; }
                  return 0 ;
                } 
              )
          ) ;
          appArticlesFetchanewChange( false ) ;
        }
      },
      [ appArticlesFetchanew , appBetriebeId ]
    ) ;
  }
    //#endregion useArticles
//#region useArticlesIntern
  export const useArticlesIntern = () =>
  {
    const { appBetriebeId , appArticlesInternFetchanew , appArticlesInternFetchanewChange , appArticlesInternFetchstateChange , appArticlesInternChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appArticlesInternFetchanew ) {
          getData(
            'articles/get_articles.php?my_table=interne_artikel&my_scope=' + appBetriebeId ,
            appArticlesInternFetchstateChange ,
            appArticlesInternChange ,
            ( data:any ) => data
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.bezeichnung > b.bezeichnung ) { return 1 ; }
                  if ( a.bezeichnung < b.bezeichnung ) { return -1 ; }
                  return 0 ;
                } 
              )
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.aktiv > b.aktiv ) { return 1 ; }
                  if ( a.aktiv < b.aktiv ) { return -1 ; }
                  return 0 ;
                } 
              )
          ) ;
          appArticlesInternFetchanewChange( false ) ;
        }
      },
      [ appArticlesInternFetchanew , appBetriebeId ]
    ) ;
  }
    //#endregion useArticlesIntern
//#region useDokumenteAufgabe
export const useDokumenteAufgabe = () =>
{
  const { appAufgabenId , appDokumenteAufgabeChange , appDokumenteAufgabeFetchanew , appDokumenteAufgabeFetchanewChange , appDokumenteAufgabeFetchstateChange } = useContext( AppContext ) ;
  useEffect(
    () => {
      if( appDokumenteAufgabeFetchanew ) {
        getData( 'dokumente/get_dokumente.php?aufgaben_id=' + appAufgabenId , appDokumenteAufgabeFetchstateChange , appDokumenteAufgabeChange ) ;
        appDokumenteAufgabeFetchanewChange( false ) ;
      }
    } ,
    [ appDokumenteAufgabeFetchanew , appAufgabenId ]
  ) ;
}
//#endregion useDokumenteEinweisung
//#region useDokumenteEinweisung
export const useDokumenteEinweisung = () =>
{
  const { appEinweisungenId , appDokumenteEinweisungChange , appDokumenteEinweisungFetchanew , appDokumenteEinweisungFetchanewChange , appDokumenteEinweisungFetchstateChange } = useContext( AppContext ) ;
  useEffect(
    () => {
      if( appDokumenteEinweisungFetchanew ) {
        getData( 'dokumente/get_dokumente.php?einweisungen_id=' + appEinweisungenId , appDokumenteEinweisungFetchstateChange , appDokumenteEinweisungChange ) ;
        appDokumenteEinweisungFetchanewChange( false ) ;
      }
    } ,
    [ appDokumenteEinweisungFetchanew , appEinweisungenId ]
  ) ;
}
//#endregion useDokumenteEinweisung
//#region useDokumenteEinweisungsthema
  export const useDokumenteEinweisungsthema = () =>
  {
    const { appSchulungId , appEinweisungsthemaDokumenteChange , appEinweisungsthemaDokumenteFetchanew , appEinweisungsthemaDokumenteFetchanewChange , appEinweisungsthemaDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appEinweisungsthemaDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?einweisungsthema_id=' + appSchulungId , appEinweisungsthemaDokumenteFetchstateChange , appEinweisungsthemaDokumenteChange ) ;
          appEinweisungsthemaDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appEinweisungsthemaDokumenteFetchanew , appSchulungId ]
    ) ;
  }
    //#endregion useDokumenteEinweisungsthema
//#region useDokumenteGeraet
  export const useDokumenteGeraet = () =>
    {
      const { appGeraeteId , appDokumenteGeraetChange , appDokumenteGeraetFetchanew , appDokumenteGeraetFetchanewChange , appDokumenteGeraetFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appDokumenteGeraetFetchanew ) {
            getData( 'dokumente/get_dokumente.php?geraete_id=' + appGeraeteId , appDokumenteGeraetFetchstateChange , appDokumenteGeraetChange ) ;
            appDokumenteGeraetFetchanewChange( false ) ;
          }
        } ,
        [ appDokumenteGeraetFetchanew , appGeraeteId ]
      ) ;
    }
    //#endregion useDokumenteGeraet
//#region useEinweisungenListe
  export const useEinweisungenListe = () =>
    {
      const { appBetriebeId , appEinweisungenListeDataChange , appEinweisungenListeFetchanew , appEinweisungenListeFetchanewChange , appEinweisungenListeFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appEinweisungenListeFetchanew ) {
              getData( 'einweisungen/get_einweisungen.php?betriebe_id=' + appBetriebeId , appEinweisungenListeFetchstateChange , appEinweisungenListeDataChange ) ;
            appEinweisungenListeFetchanewChange( false ) ;
          }
        } ,
        [ appEinweisungenListeFetchanew , appBetriebeId ]
      ) ;
    }
    //#endregion useEinweisungenListe
//#region useEinweisungsThemen
  export const useEinweisungsThemen = () =>
    {
      const { appKundeId , appEinweisungsThemenFetchanew , appEinweisungsThemenFetchanewChange , appEinweisungsThemenFetchstateChange , appEinweisungsThemenListeChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appEinweisungsThemenFetchanew ) {
            getData(
              'themen/get_themen.php?kunde_id=' + appKundeId ,
              appEinweisungsThemenFetchstateChange ,
              appEinweisungsThemenListeChange ,
              ( data:any ) => data
                .sort(
                    ( a:any , b:any ) =>
                      {
                        if ( a.SchulungenTitel > b.SchulungenTitel ) { return 1 ; }
                        if ( a.SchulungenTitel < b.SchulungenTitel ) { return -1 ; }
                        return 0 ;
                      }
                )
                .sort(
                  ( a:any , b:any ) => 
                    {
                      if ( a.SchulungenStatus > b.SchulungenStatus ) { return 1 ; }
                      if ( a.SchulungenStatus < b.SchulungenStatus ) { return -1 ; }
                      return 0 ;
                    }
                )
            ) ; 
            appEinweisungsThemenFetchanewChange( false ) ;
          }
        },
        [ appEinweisungsThemenFetchanew , appKundeId ]
      ) ;
  }
    //#endregion useEinweisungsThemen
//#region useEinweisungGeraeteThemen
export const useEinweisungGeraeteThemen = () =>
{
  const { appKundeId , appEinweisungGeraeteThemenFetchanew, appEinweisungGeraeteThemenFetchanewChange , appEinweisungGeraeteThemenFetchstateChange , appEinweisungGeraeteThemenChange } = useContext( AppContext ) ;
  useEffect(
    () => {
      if( appEinweisungGeraeteThemenFetchanew ) {
        getData(
          'themen/get_geraete_themen.php?kunde_id=' + appKundeId ,
          appEinweisungGeraeteThemenFetchstateChange ,
          appEinweisungGeraeteThemenChange
        ) ; 
        appEinweisungGeraeteThemenFetchanewChange( false ) ;
      }
    },
    [ appEinweisungGeraeteThemenFetchanew , appKundeId ]
  ) ;
}
//#endregion useEinweisungGeraeteThemen
//#region useGeraetegruppenGiven
  export const useGeraetegruppenGiven = () =>
    {
      const { appKundeId , appGeraetegruppenGivenChange , appGeraetegruppenGivenFetchanew , appGeraetegruppenGivenFetchanewChange , appGeraetegruppenGivenFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGeraetegruppenGivenFetchanew ) {
            getData(
              'geraete/get_geraetegruppen.php?kunde_id=' + appKundeId + '&given_only=1',
              appGeraetegruppenGivenFetchstateChange ,
              appGeraetegruppenGivenChange ,
              ( data:any ) => data
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a.GeraeteklassenName > b.GeraeteklassenName ) { return 1 ; }
                      if ( a.GeraeteklassenName < b.GeraeteklassenName ) { return -1 ; }
                      return 0 ;
                    } 
                )
            ) ;
            appGeraetegruppenGivenFetchanewChange( false ) ;
          }
        },
        [ appGeraetegruppenGivenFetchanew , appKundeId ]
      ) ;
    }
    //#endregion useGeraetegruppenGiven
//#region useGeraetegruppenPossible
  export const useGeraetegruppenPossible = () =>
    {
      const { appKundeId , appGeraetegruppenPossibleChange , appGeraetegruppenPossibleFetchanew , appGeraetegruppenPossibleFetchanewChange , appGeraetegruppenPossibleFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGeraetegruppenPossibleFetchanew ) {
            getData(
              'geraete/get_geraetegruppen.php?kunde_id=' + appKundeId ,
              appGeraetegruppenPossibleFetchstateChange ,
              appGeraetegruppenPossibleChange ,
              ( data:any ) => data
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a.GeraeteklassenName > b.GeraeteklassenName ) { return 1 ; }
                      if ( a.GeraeteklassenName < b.GeraeteklassenName ) { return -1 ; }
                      return 0 ;
                    } 
                )
            ) ;
            appGeraetegruppenPossibleFetchanewChange( false ) ;
          }
        },
        [ appGeraetegruppenPossibleFetchanew , appKundeId ]
      ) ;
    }
    //#endregion useGeraetegruppenPossible
//#region useGeraeteklassenListe
  export const useGeraeteklassenListe = () =>
    {
      const { appKundeId , appGeraeteklassenListeChange , appGeraeteklassenListeFetchanew , appGeraeteklassenListeFetchanewChange , appGeraeteklassenListeFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGeraeteklassenListeFetchanew ) {
            getData(
              'geraeteklassen/get_geraeteklassen.php?kunde_id=' + appKundeId ,
              appGeraeteklassenListeFetchstateChange ,
              appGeraeteklassenListeChange ,
              ( data:any ) => data
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a.GeraeteklassenName > b.GeraeteklassenName ) { return 1 ; }
                      if ( a.GeraeteklassenName < b.GeraeteklassenName ) { return -1 ; }
                      return 0 ;
                    } 
                )
            ) ;
            appGeraeteklassenListeFetchanewChange( false ) ;
          }
        },
        [ appGeraeteklassenListeFetchanew , appKundeId ]
      ) ;
    }
    //#endregion useGeraeteklassenListe
//#region useGeraeteListe
  export const useGeraeteListe = () =>
    {
      const { appBetriebeId  , appGeraeteListeDataChange , appGeraeteListeFetchanew, appGeraeteListeFetchanewChange , appGeraeteListeFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appGeraeteListeFetchanew ) {
            getData( 'geraete/get_geraete.php?betriebe_id=' + appBetriebeId , appGeraeteListeFetchstateChange , appGeraeteListeDataChange ) ;
            appGeraeteListeFetchanewChange( false ) ;
          }
        },
        [ appBetriebeId , appGeraeteListeFetchanew ]
      ) ;    
    }
    //#endregion useGeraeteListe
//#region useLieferantenListe
  export const useLieferantenListe = () =>
    {
      const { appKundeId , appLieferantenListeFetchanew , appLieferantenListeFetchanewChange , appLieferantenListeFetchstateChange , appLieferantenListeChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appLieferantenListeFetchanew ) {
            getData(
              'lieferanten/get_lieferanten.php?kunde_id=' + appKundeId ,
              appLieferantenListeFetchstateChange ,
              appLieferantenListeChange ,
              ( data:any ) => data
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a.LieferantenName > b.LieferantenName ) { return 1 ; }
                      if ( a.LieferantenName < b.LieferantenName ) { return -1 ; }
                      return 0 ;
                    } 
                )
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a.LieferantenStatus > b.LieferantenStatus ) { return 1 ; }
                      if ( a.LieferantenStatus < b.LieferantenStatus ) { return -1 ; }
                      return 0 ;
                    } 
                )
            ) ;
            appLieferantenListeFetchanewChange( false ) ;
          }
        },
        [ appLieferantenListeFetchanew , appKundeId ]
      ) ;
    }

    //#endregion useLieferantenListe
//#region useMitarbeiterListe
  export const useMitarbeiterListe = () =>
  {
    const { appBetriebeId , appMitarbeiterListeFetchanew , appMitarbeiterListeFetchanewChange , appMitarbeiterListeFetchstateChange , appMitarbeiterListeChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appMitarbeiterListeFetchanew ) {
          getData(
            'mitarbeiter/get_mitarbeiterinnen.php?betriebe_id=' + appBetriebeId ,
            appMitarbeiterListeFetchstateChange ,
            appMitarbeiterListeChange ,
            ( data:any ) => data
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.MitarbeiterNachname > b.MitarbeiterNachname ) { return 1 ; }
                  if ( a.MitarbeiterNachname < b.MitarbeiterNachname ) { return -1 ; }
                  return 0 ;
                } 
              )
              .sort(
                ( a:any , b:any ) => 
                {
                  if ( a.MitarbeiterAktiv > b.MitarbeiterAktiv ) { return 1 ; }
                  if ( a.MitarbeiterAktiv < b.MitarbeiterAktiv ) { return -1 ; }
                  return 0 ;
                } 
              )
          ) ;
          appMitarbeiterListeFetchanewChange( false ) ;
        }
      },
      [ appMitarbeiterListeFetchanew , appBetriebeId ]
    ) ;
  }
    //#endregion useMitarbeiterListe
//#region useUnterweisungen
  export const useUnterweisungen = () =>
    {
      const { appBetriebeId , appUnterweisungenChange , appUnterweisungenFetchanew , appUnterweisungenFetchanewChange , appUnterweisungenFetchstateChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appUnterweisungenFetchanew ) {
              getData( 'unterweisungen/get_unterweisungen.php?betriebe_id=' + appBetriebeId , appUnterweisungenFetchstateChange , appUnterweisungenChange ) ;
            appUnterweisungenFetchanewChange( false ) ;
          }
        } ,
        [ appUnterweisungenFetchanew , appBetriebeId ]
      ) ;
    }
    //#endregion useUnterweisungen
//#region useUnterweisungsthemen
  export const useUnterweisungsthemen = () =>
    {
      const { appKundeId , appUnterweisungsthemenFetchanew , appUnterweisungsthemenFetchanewChange , appUnterweisungsthemenFetchstateChange , appUnterweisungsthemenChange } = useContext( AppContext ) ;
      useEffect(
        () => {
          if( appUnterweisungsthemenFetchanew ) {
            getData(
              'unterweisungen/get_unterweisungsthemen.php?kunde_id=' + appKundeId ,
              appUnterweisungsthemenFetchstateChange ,
              appUnterweisungsthemenChange ,
              ( data:any ) => data
                .sort(
                    ( a:any , b:any ) =>
                      {
                        if ( a.UnterweisungsthemenTitel > b.UnterweisungsthemenTitel ) { return 1 ; }
                        if ( a.UnterweisungsthemenTitel < b.UnterweisungsthemenTitel ) { return -1 ; }
                        return 0 ;
                      }
                )
            ) ; 
            appUnterweisungsthemenFetchanewChange( false ) ;
          }
        },
        [ appUnterweisungsthemenFetchanew , appKundeId ]
      ) ;
  }
    //#endregion useUnterweisungsthemen
//#region useUnterweisungDokumente
  export const useUnterweisungDokumente = () =>
  {
    const { appUnterweisungId , appUnterweisungDokumenteChange , appUnterweisungDokumenteFetchanew , appUnterweisungDokumenteFetchanewChange , appUnterweisungDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appUnterweisungDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?unterweisung_id=' + appUnterweisungId , appUnterweisungDokumenteFetchstateChange , appUnterweisungDokumenteChange ) ;
          appUnterweisungDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appUnterweisungDokumenteFetchanew , appUnterweisungId ]
    ) ;
  }
    //#endregion useUnterweisungDokumente
//#region useUnterweisungsthemaDokumente
  export const useUnterweisungsthemaDokumente = () =>
  {
    const { appUnterweisungsthemaId , appUnterweisungsthemaDokumenteChange , appUnterweisungsthemaDokumenteFetchanew , appUnterweisungsthemaDokumenteFetchanewChange , appUnterweisungsthemaDokumenteFetchstateChange } = useContext( AppContext ) ;
    useEffect(
      () => {
        if( appUnterweisungsthemaDokumenteFetchanew ) {
          getData( 'dokumente/get_dokumente.php?unterweisungsthema_id=' + appUnterweisungsthemaId , appUnterweisungsthemaDokumenteFetchstateChange , appUnterweisungsthemaDokumenteChange ) ;
          appUnterweisungsthemaDokumenteFetchanewChange( false ) ;
        }
      } ,
      [ appUnterweisungsthemaDokumenteFetchanew , appUnterweisungsthemaId ]
    ) ;
  }
    //#endregion useUnterweisungsthemaDokumente
