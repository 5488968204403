import { IonButtons,  IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { useContext, useState } from 'react';
import TableAufgabe from '../components/table_aufgaben';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const Aufgaben: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeName , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313iUVFHH' ;

  return (
    <IonPage>
      <PageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie offene Aufgaben, Angebote, fällige Wartungen und abgeschlossene Aufgaben einsehen und - abhängig von der Zuordnung von Geräten zu Ihrer Einrichtung oder zu MedMehr - bearbeiten.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Aufgaben { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <TableAufgabe />
      </IonContent>
    </IonPage>
  );
};
