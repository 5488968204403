/**
 * View Lieferant
 * 
 * Einzelnen Lieferant einsehen, bearbeiten oder erstellen
 * 
 */

import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext , useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export function Lieferant() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appAufgabeServicepartnerAddedChange , appGeraetServiceAddedChange , appKundeId, appLieferantId , appLieferantIdChange , appLieferantenListeFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ LieferantenId, setLieferantenId ] = useState( appLieferantId )
  const [ LieferantenStatus, setLieferantenStatus ] = useState( 'aktiv' )
  const [ LieferantenStatusBool, setLieferantenStatusBool ] = useState( true )
  const [ LieferantenName, setLieferantenName ] = useState( '' )
  const [ LieferantenPLZ, setLieferantenPLZ ] = useState( '' )
  const [ LieferantenOrt, setLieferantenOrt ] = useState( '' )
  const [ LieferantenStrasse, setLieferantenStrasse ] = useState( '' )
  const [ LieferantenHausnummer, setLieferantenHausnummer ] = useState( '' )
  const [ LieferantenKundennummer, setLieferantenKundennummer ] = useState( '' )
  const [ LieferantenTelefon, setLieferantenTelefon ] = useState( '' )
  const [ LieferantenFax, setLieferantenFax ] = useState( '' )
  const [ LieferantenEmail, setLieferantenEmail ] = useState( '' )
  const [ LieferantenAnsprechpartner, setLieferantenAnsprechpartner ] = useState( '' )
  const [ LieferantenMobilAnsprechpartner, setLieferantenMobilAnsprechpartner ] = useState( '' )
  const LieferantenStatusStringToBool = ( val:string ) => {
    if( val === 'aktiv' ) {
      setLieferantenStatus( 'aktiv' )
      setLieferantenStatusBool( true )
    } else {
      setLieferantenStatus( 'inaktiv' )
      setLieferantenStatusBool( false )
    }
  }
  const getData = ( LieferantenId:string ) => {
    if (LieferantenId === 'neu') {
      setLieferantenId( 'neu' ) ;
      setLieferantenStatus( 'aktiv' ) ;
      LieferantenStatusStringToBool( 'aktiv' ) ;
      setLieferantenName( '' ) ;
      setLieferantenPLZ( '' ) ;
      setLieferantenOrt( '' ) ;
      setLieferantenStrasse( '' ) ;
      setLieferantenHausnummer( '' ) ;
      setLieferantenKundennummer( '' ) ;
      setLieferantenTelefon( '' ) ;
      setLieferantenFax( '' ) ;
      setLieferantenEmail( '' ) ;
      setLieferantenAnsprechpartner( '' ) ;
      setLieferantenMobilAnsprechpartner( '' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/lieferanten/get_lieferant.php?lieferanten_id=` + LieferantenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((lieferanten: any) => {
          if (lieferanten.LieferantenId === LieferantenId) {
            setLieferantenId( lieferanten.LieferantenId ) ;
            setLieferantenStatus( lieferanten.LieferantenStatus ) ;
            LieferantenStatusStringToBool( lieferanten.LieferantenStatus ) ;
            setLieferantenName( lieferanten.LieferantenName ) ;
            setLieferantenPLZ( lieferanten.LieferantenPLZ ) ;
            setLieferantenOrt( lieferanten.LieferantenOrt ) ;
            setLieferantenStrasse( lieferanten.LieferantenStrasse ) ;
            setLieferantenHausnummer( lieferanten.LieferantenHausnummer ) ;
            setLieferantenKundennummer( lieferanten.LieferantenKundennummer ) ;
            setLieferantenTelefon( lieferanten.LieferantenTelefon ) ;
            setLieferantenFax( lieferanten.LieferantenFax ) ;
            setLieferantenEmail( lieferanten.LieferantenEmail ) ;
            setLieferantenAnsprechpartner( lieferanten.LieferantenAnsprechpartner ) ;
            setLieferantenMobilAnsprechpartner( lieferanten.LieferantenMobilAnsprechpartner ) ;
          }
        })
    });
  }
}
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    appAufgabeServicepartnerAddedChange( LieferantenName ) ;
    appGeraetServiceAddedChange( LieferantenName ) ;
    var raw = JSON.stringify({
      LieferantenId: LieferantenId ,
      LieferantenStatus: LieferantenStatus ,
      LieferantenName: LieferantenName ,
      LieferantenPLZ: LieferantenPLZ ,
      LieferantenOrt: LieferantenOrt ,
      LieferantenStrasse: LieferantenStrasse ,
      LieferantenHausnummer: LieferantenHausnummer ,
      LieferantenKundennummer: LieferantenKundennummer ,
      LieferantenTelefon: LieferantenTelefon ,
      LieferantenFax: LieferantenFax ,
      LieferantenAnsprechpartner: LieferantenAnsprechpartner ,
      LieferantenEmail: LieferantenEmail ,
      LieferantenMobilAnsprechpartner: LieferantenMobilAnsprechpartner ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/lieferanten/set_lieferant.php', requestOptions)
    .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appLieferantIdChange( json.entryId ) ;
           setLieferantenId( json.entryId ) ;
         }
       }
      )
  }
  function onValueLieferantenStatus( e:boolean ) {
    e
      ?
        LieferantenStatusStringToBool( 'aktiv' )
      :
        LieferantenStatusStringToBool( 'inaktiv' )
  }
  function onValueLieferantenName( e:any ) { setLieferantenName( e.target.value ) ; }
  function onValueLieferantenPLZ( e:any ) { setLieferantenPLZ( e.target.value ) ; }
  function onValueLieferantenOrt( e:any ) { setLieferantenOrt( e.target.value ) ; }
  function onValueLieferantenStrasse( e:any ) { setLieferantenStrasse( e.target.value ) ; }
  function onValueLieferantenHausnummer( e:any ) { setLieferantenHausnummer( e.target.value ) ; }
  function onValueLieferantenKundennummer( e:any ) { setLieferantenKundennummer( e.target.value ) ; }
  function onValueLieferantenTelefon( e:any ) { setLieferantenTelefon( e.target.value ) ; }
  function onValueLieferantenFax( e:any ) { setLieferantenFax( e.target.value ) ; }
  function onValueLieferantenEmail( e:any ) { setLieferantenEmail( e.target.value ) ; }
  function onValueLieferantenAnsprechpartner( e:any ) { setLieferantenAnsprechpartner( e.target.value ) ; }
  function onValueLieferantenMobilAnsprechpartner( e:any ) { setLieferantenMobilAnsprechpartner( e.target.value ) ; }
  useEffect(
    () => {
      if( history.location.pathname.includes( '/lieferant/') ) {
        getData( appLieferantId ) ;
      };
    },
    [ appLieferantId, history.location.pathname ]
  );

  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Kontaktdaten für einen Servicepartner einsehen und bearbeiten oder für einen neuen Servicepartner festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Servicepartner { LieferantenName ? LieferantenName : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
            <IonItem lines="none" className='item'>
              <IonLabel slot="">aktiv</IonLabel>
              <IonCheckbox slot="start" checked={ LieferantenStatusBool } onIonChange={e => onValueLieferantenStatus( e.detail.checked ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput required value={ LieferantenName } onIonChange={ ( e )=> onValueLieferantenName( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Straße</IonLabel>
                <IonInput required value={ LieferantenStrasse } onIonChange={ ( e )=> onValueLieferantenStrasse( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Hausnummer</IonLabel>
                <IonInput required value={ LieferantenHausnummer } onIonChange={ ( e )=> onValueLieferantenHausnummer( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">PLZ</IonLabel>
                <IonInput required value={ LieferantenPLZ } onIonChange={ ( e )=> onValueLieferantenPLZ( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Ort</IonLabel>
                <IonInput required value={ LieferantenOrt } onIonChange={ ( e )=> onValueLieferantenOrt( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Kundennumer</IonLabel>
                <IonInput required value={ LieferantenKundennummer } onIonChange={ ( e )=> onValueLieferantenKundennummer( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Telefon</IonLabel>
                <IonInput required value={ LieferantenTelefon } onIonChange={ ( e )=> onValueLieferantenTelefon( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Fax</IonLabel>
                <IonInput required value={ LieferantenFax } onIonChange={ ( e )=> onValueLieferantenFax( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">E-Mail</IonLabel>
                <IonInput required value={ LieferantenEmail } onIonChange={ ( e )=> onValueLieferantenEmail( e ) } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Ansprechpartner</IonLabel>
                <IonInput required value={ LieferantenAnsprechpartner } onIonChange={ ( e )=> onValueLieferantenAnsprechpartner( e )
                    } />
            </IonItem>
            <IonItem className='item'>
                <IonLabel position="stacked">Mobil Ansprechpartner</IonLabel>
                <IonInput required value={ LieferantenMobilAnsprechpartner } onIonChange={ ( e )=>
                    onValueLieferantenMobilAnsprechpartner( e ) } />
            </IonItem>
            <ButtonsSaveActions myExec = { () => { appLieferantenListeFetchanewChange( true) ; sendJSON() ; } } />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
