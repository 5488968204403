/**
 * Messwerte von früher zugeordneten Geräten vs. neues Gerät
 * 
 *  - falls dem aktuellen Gerät früher ein Messgerät zugeordnet war
 * 
 */

import { IonButton, IonCardContent } from "@ionic/react";
import { useContext } from "react";
import { AppContext } from "../../contexts/app_context";




export const Messgeraet_Frueher = () =>
  {
    const {
        appGeraetHasIotMessung ,
        appIotMessgeraeteAktionChange
      } = useContext( AppContext ) ;

    return (
      <IonCardContent>
        Das Gerät ist aktuell mit keinem Messgerät verbunden, es existieren aber frühere Messwerte bis zum  { new Date( appGeraetHasIotMessung ).toLocaleDateString() }
        <br/>
        <IonButton
          onClick = {
            () => appIotMessgeraeteAktionChange( 'old_values' )
          }
        >
          Alte Werte anzeigen
        </IonButton>
        <IonButton
          onClick = {
            () => appIotMessgeraeteAktionChange( 'assign' )
          }
        >
          Messsgerät neu zuordnen
        </IonButton>
      </IonCardContent>
    );
 
  }
