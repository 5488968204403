import { IonButton , IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonLoading , IonSpinner , useIonViewDidEnter , useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext , useEffect , useState } from "react";
import { useHistory } from 'react-router-dom';
import { defColumn , exportTableData } from './_HelperFunctions' ;
import { useArticlesGefahrstoffschulungenThemen } from '../components/_CustomHooks';
import { Hourglass, ThumbsDown , ThumbsUp } from 'react-ionicons';
import { AppContext } from "../contexts/app_context";
import './table_einweisungen_grid.css'

export default function GefahrstoffschulungenMatrix() {
  useIonViewWillEnter( () => setMatrixLoading( true ) ) ;
  useIonViewDidEnter(() => {
    appGefahrstoffschulungenMatrixBuildNewChange( true ) ;
    // setMatrixLoading( true )
    // console.log( 'ionViewDidEnter table_matrix' ) ;
  });
  useIonViewWillLeave(() => {
    setTableData( [] )
    appGefahrstoffschulungNeuTeilnehmerChange( '' ) ;
    appGefahrstoffschulungNeuThemaChange( '' ) 
    // console.log( 'ionViewWillLeave table_matrix' ) ;
  });
  /** Component Environment */
    const { appBetriebeName , appArticlesGefahrstoffschulungenThemen , appGefahrstoffschulungIdChange , appGefahrstoffschulungen , appGefahrstoffschulungenFetchState , appGefahrstoffschulungenMatrixBuildNew ,appGefahrstoffschulungenMatrixBuildNewChange , appGefahrstoffschulungNeuTeilnehmer , appGefahrstoffschulungNeuTeilnehmerChange , appGefahrstoffschulungNeuThema , appGefahrstoffschulungNeuThemaChange , appGefahrstoffschulungenThemen , appMitarbeiterListe , appViewSwitchChange } = useContext( AppContext ) ;
    const [ tableData , setTableData ] = useState( Array() ) ;
    const [ gefahrstoffschulungNeuThemaTitel , setGefahrstoffschulungNeuThemaTitel ] = useState( '' ) ;
    const history = useHistory();
    const [ localGefahrstoffschulungenThemen , setLocalGefahrstoffschulungenThemen ] = useState( appGefahrstoffschulungenThemen ) ;
    const [ matrixLoading , setMatrixLoading ] = useState( true ) ;
    const [pageSize, setPageSize] = useState(50);
      // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  /** Component Helpers */
    const matrixCheckbox = 
      ( gefahrstoffschulungsthemaId , mitarbeiter , gefahrstoffschulungsthemaTitel ) =>
        {
          // alert( 'Aktion: Mitarbeiter ID ' + mitarbeiter + ' zu neuer Gefahrstoffschulung zum Thema ' + gefahrstoffschulungsthemaId + ' hinzufügen ODER entfernen' )
          // alert( 'appGefahrstoffschulungNeuThema: ' + appGefahrstoffschulungNeuThema +'\nappGefahrstoffschulungNeuTeilnehmer:' + appGefahrstoffschulungNeuTeilnehmer )
          //#region Numerische IDs via Prä- und Suffix eindeutig machen
            let myAppGefahrstoffschulungNeuTeilnehmer = ',' + appGefahrstoffschulungNeuTeilnehmer + ',' ;
            let myMitarbeiter =  ',' + mitarbeiter + ',' ;
              //#endregion Numerische IDs via Prä- und Suffix eindeutig machen
          //#region "mitarbeiter" hinzufügen oder entfernen
            if( myAppGefahrstoffschulungNeuTeilnehmer.includes( myMitarbeiter ) ) {
              // alert( '"' + myMitarbeiter + '" IST in "' + myAppGefahrstoffschulungNeuTeilnehmer + '"' ) ;
              /** "mitarbeiter" lokal entfernen, weil noch für "thema" unten benötigt */
                myAppGefahrstoffschulungNeuTeilnehmer = myAppGefahrstoffschulungNeuTeilnehmer.replace( myMitarbeiter , '' ) ;
              appGefahrstoffschulungNeuTeilnehmerChange( myAppGefahrstoffschulungNeuTeilnehmer ) ;
            } else {
              // alert( '"' + myMitarbeiter + '" ist NICHT in "' + myAppGefahrstoffschulungNeuTeilnehmer + '"' ) ;
              /** "mitarbeiter" lokal hinzufügen, weil noch für "thema" unten benötigt */
                myAppGefahrstoffschulungNeuTeilnehmer += ',' + mitarbeiter ;
              appGefahrstoffschulungNeuTeilnehmerChange( myAppGefahrstoffschulungNeuTeilnehmer ) ;
            }
              //#endregion "mitarbeiter" hinzufügen oder entfernen
          //#region "gefahrstoffschulungsthemaId" hinzufügen oder entfernen wenn alle "mitarbeiter" entfernt wurden
            if( myAppGefahrstoffschulungNeuTeilnehmer.replaceAll( ',' , '' ) !== '' ) {
              // alert( 'Mitarbeiter "' + myAppGefahrstoffschulungNeuTeilnehmer + '" => Thema dazu' ) ;
              appGefahrstoffschulungNeuThemaChange( gefahrstoffschulungsthemaId ) ;
              setGefahrstoffschulungNeuThemaTitel ( gefahrstoffschulungsthemaTitel ) ;
            } else {
              // alert( 'Mitarbeiter "' + myAppGefahrstoffschulungNeuTeilnehmer + '" => Thema weg' ) ;
              appGefahrstoffschulungNeuThemaChange( '' ) ;
              setGefahrstoffschulungNeuThemaTitel( '' ) ;
            }
              //#endregion "gefahrstoffschulungsthemaId" hinzufügen oder entfernen wenn alle "mitarbeiter" entfernt wurden
        }
    ;
    const matrixHourglass =
      ( gefahrstoffschulungsthemaId , mitarbeiter , next ) =>
        {
          // ! s. Ticket 27/5/22: Funktionalität zurückgestellt als "Erweiterungs-Feature"
          // alert( 'Aktion: Zeige geplante Gefahrstoffschulung zum Thema ID ' + gefahrstoffschulungsthemaId + ' mit Mitarbeiter ID ' + mitarbeiter ) 
          appViewSwitchChange( true ) ;
          appGefahrstoffschulungIdChange ( next ) ;
          history.push( '/gefahrstoffschulung/' + next ) ;
        }
    ;
    const matrixThumb = 
      ( gefahrstoffschulungsthemaId , mitarbeiter , next ) =>
        {
          // alert( 'Aktion: Zeige Gefahrstoffschulung ID ' + next + ' als neueste Gefahrstoffschulung zum Thema ID ' + gefahrstoffschulungsthemaId + ' für Mitarbeiter ID ' + mitarbeiter ) 
          appViewSwitchChange( true ) ;
          appGefahrstoffschulungIdChange ( next ) ;
          history.push( '/gefahrstoffschulung/' + next ) ;
        }
    ;
    const setColumns = () => {
      const mkGefahrstoffschulungsColumns = ( colsArray )  => 
        {
          localGefahrstoffschulungenThemen
            .forEach(
              ( { SchulungenId , SchulungenTitel } ) =>
                {
                  // if( SchulungenTitel.includes( 'g' ) ) {
                  if(
                    appArticlesGefahrstoffschulungenThemen
                      .find(
                        ( entry ) =>
                          entry.thema === SchulungenId
                          &&
                          entry.artikel !== ''
                      )
                  )
                    {
                      let newCol =
                        {
                          align: 'center' ,
                          field: 'matrixGefahrstoffschulungsthema' + SchulungenId ,
                          flex: 0 ,
                          description: SchulungenTitel ,
                          headerName: SchulungenTitel ,
                          headerClassName: "super-app-theme--header , datagrid-vertical-head",
                          width: 120 ,
                          renderCell: ( params ) => {
                            // return <><IonCheckbox onClick={ () => matrixCheckbox( SchulungenId , params.row.MitarbeiterId ) } />&nbsp;<ThumbsUp color = { params.row.value.split(1)[0] } onClick={ () => matrixThumb( SchulungenId , params.row.MitarbeiterId ) }/>params.row.value.split(1)[1]==''?&nbsp;<Hourglass onClick={ () => matrixHourglass( SchulungenId , params.row.MitarbeiterId , SchulungenTitel) } />:''</>
                            let myData = params.row[ 'matrixGefahrstoffschulungsthema' + SchulungenId ].split( ',' ) ;
                            let mitarbeiterRowInfo = params.row.Personalnummer + ' ' + params.row.Nachname + ', ' + params.row.Vorname + ' (' + params.row.Taetigkeit + ' ' + params.row.Abteilung + ')' ;
                            return (
                              <>
                                { 
                                  myData[0] === 'red'
                                    ? 
                                      <ThumbsDown
                                        color = { myData[0] }
                                        style = { { cursor: 'not-allowed' } }
                                        title = { 'Keine gültige Gefahrstoffschulung gefunden für ' + mitarbeiterRowInfo }
                                      />
                                    :
                                      <ThumbsUp
                                        color = { myData[0] }
                                        onClick = { () => matrixThumb( SchulungenId , params.row.MitarbeiterId , myData[ 1 ] ) }
                                        style = { { cursor: 'pointer' } }
                                        title = { 'zur letzten stattgefundenen Gefahrstoffschulung für ' + mitarbeiterRowInfo }
                                      />
                                }
                                &nbsp;
                                {
                                  myData[ 2 ]
                                    ? 
                                      <Hourglass
                                        onClick = { () => matrixHourglass( SchulungenId , params.row.MitarbeiterId , myData[ 2 ] ) }
                                        style = { { cursor: 'pointer' } }
                                        title = { 'zur nächsten geplanten Gefahrstoffschulung für ' + mitarbeiterRowInfo }
                                      />
                                    :
                                      <IonCheckbox
                                        /** "checked" explizit für Reset bei Re-Rendering */
                                          // checked = { false }
                                        onClick = { () => matrixCheckbox( SchulungenId , params.row.MitarbeiterId , SchulungenTitel ) }
                                        title = { 'Neue Gefahrstoffschulung planen mit ' + mitarbeiterRowInfo }
                                        />
                                }
                              </>
                            )
                          }
                        }
                      colsArray.push( newCol ) ;
                    }
                  ;
                }
            )
        }
      let cols =
        [
          defColumn( 'MitarbeiterId' , 'ID' , 0 ) ,
          defColumn( 'Personalnummer' , 'Personalnummer' , 80 , 0 ) ,
          defColumn( 'Nachname' , 'Nachname' , 150 , 0 ) ,
          defColumn( 'Vorname' , 'Vorname' , 100 , 0 ) ,
          defColumn( 'Geburtsdatum' , 'Geb.datum' , 50 , 0 ) ,
          defColumn( 'Taetigkeit' , 'Tätigkeit' , 200 , 0 ) ,
          defColumn( 'Abteilung' , 'Abteilung' , 200 , 0 ) ,
        ]
      mkGefahrstoffschulungsColumns( cols )
      return cols
    }
    const exportButtonDataEntry = ( entry ) =>
    {
      let labelledEntry =
        {
          Personalnummer: entry.Personalnummer ,
          Nachname: entry.Nachname ,
          Vorname: entry.Vorname ,
          Geburtsdatum: new Date( entry.Geburtsdatum ).toLocaleDateString() ,
          'Tätigkeit': entry.Taetigkeit ,
          Abteilung: entry.Abteilung
        }
      let colsThemen = 
        Object.keys( entry )
        .filter(
          ( thema ) =>
            thema.includes( 'matrixGefahrstoffschulungsthema' )
        )
      colsThemen
        .forEach(
          ( thema ) =>
            {
              labelledEntry[
                localGefahrstoffschulungenThemen
                  .find( 
                    ( { SchulungenId } ) => SchulungenId === thema.substring( 'matrixGefahrstoffschulungsthema'.length )
                  ).SchulungenTitel
              ] =
                entry[ thema ].includes( 'green' )
                  ?
                    'OK'
                  :
                    entry[ thema ].split( ',' )[ 2 ] === ''
                      ?
                        '-'
                      :
                        'geplant'
            }
        )
      // alert( colsThemen ) ;
      return labelledEntry ;
    }
    const exportButton = () =>
      <IonButton
        onClick =
          {
            () =>
              exportTableData(
                tableData.map( ( entry ) => exportButtonDataEntry( entry ) )
                ,
                'Gefahrstoffschulungen ' + appBetriebeName + ' Übersicht'
              )
          }
      >
        Exportieren
      </IonButton>
  /** Component Mount-Update */
    // useGefahrstoffschulungen() ;
    // useGefahrstoffschulungenThemen() ;
    // useMitarbeiterListe() ;
    useArticlesGefahrstoffschulungenThemen() ;
    useEffect(
      () =>
        {
          setLocalGefahrstoffschulungenThemen(
            appGefahrstoffschulungenThemen
              .filter(
                ( entry ) => entry.SchulungenAktiv === 'aktiv'
              )
          ) ;
        } ,
        [ appGefahrstoffschulungenThemen ]
    ) ;
    useEffect(
      () => {
        //#region Startzeit als potentieller Trigger
          /** Startzeit für Durchlauf - evtl. später verwenden für Erzwingung Re-Rendering
           *  - konkret für Zurücksetzen Checkboxen in Matrix ist u.U. ein Re-Rendern nötig,
           *    auch wenn sich die eigentlichen Date nicht geändert haben, deshalb "settime"
           *    dann als künstlich geänderte Daten
           *    - noch unklar: wo kann man "settime" platzieren, ohne dass es zuviel Last
           *      erzeugt? ist eine eigene Spalte viel Last oder verhältnismäßig wenig?
           *    - NB: die Checkboxes liegen in DataGrid, i.e. ohne direkten Zugriff; auch
           *      bei direktem Zugriff würde vermutlich jede Checkbox-Änderung ein separates
           *      Re-Rendern auslösen, deshalb besser Komplett-Re-Rendern
           */
            // let starttime = Date.now() ;
            // console.log( 'useEffect Matrix ' + starttime ) ;
            //#endregion Startzeit als potentieller Trigger
        //#region Helper-Funktion mkMatrtixData
          /** historisch gewachsen als Funktion, evtl. umstellbar zu unmittelbarem Code */
            const mkMatrixData = () => {
              //#region Row-Liste initialisieren
                /** DataGrid erwartet Array */
                  let matrixData = [] ;
                  //#endregion Row-Liste initialisieren
              //#region Row pro Mitarbeiter generieren
                /** muss jeweils komplett lokal (neu) initialisiert werden, damit nicht Referenzen
                 *  auf vorherige Rows statt tatsächlich neuen Rows hinzugefügt werden
                 */
                  appMitarbeiterListe
                    .filter(
                      ( entry ) => entry.MitarbeiterAktiv === 'aktiv'
                    )
                    .forEach(
                      ( entry ) =>
                        {
                          //#region Mitarbeiter-Zeile initialisieren
                            let matrixDataRow = {} ;
                              //#endregion Mitarbeiter-Zeile initialisieren
                          //#region Mitarbeiterdaten
                            /** MitarbeiterId für Weiterverarbeitung und als eindeutige RowId = key für DataGrid */
                              matrixDataRow.MitarbeiterId = entry.MitarbeiterId ;
                            /** Anzuzeigende Daten */
                              matrixDataRow.Personalnummer = entry.MitarbeiterPersonalnummer ;
                              matrixDataRow.Nachname = entry.MitarbeiterNachname ;
                              matrixDataRow.Vorname = entry.MitarbeiterVorname ;
                              matrixDataRow.Geburtsdatum = entry.MitarbeiterGeburtsdatum ;
                              matrixDataRow.Taetigkeit = entry.MitarbeiterTaetigkeit ;
                              matrixDataRow.Abteilung = entry.MitarbeiterAbteilung ;
                              //#endregion Mitarbeiterdaten
                          //#region Gefahrstoffschulungsdaten
                            //#region Spalten für Gefahrstoffschulungsthemen
                              /** Hinzufügen für neue Gefahrstoffschulung, Status letzte Gefahrstoffschulung, evtl. bereits geplante Gefahrstoffschulung */
                              /** Rückgabe für Anzeige + Sortierung: Tupel "colorString,hourglassBool"
                               *    - colorString: 'green' | 'red' | 'yellow'
                               *      - keine Abkürzungen, da diese wieder rechenintensiv ausgewertet werden
                               *        müssten
                               *    - hourglassBool: '1' | nil
                               *      - für (hoffentlich) unaufwändigste Auswertung
                               *      - Logik: "if( tupel.split(1)[1] == '' )" => hourglassTRUE
                               *        => wenn mit "1" als Splitter das Feld 1 nicht "undefined" ist sondern leer,
                               *           dann war eine "1" angegeben
                               *    - Bsp.:
                               *      - "red" = roter Daumen, keine Sanduhr
                               *      - "red1" = roter Daumen und Sanduhr
                               */
                              //#region Vorhandene Gefahrstoffschulungsthemen iterieren
                                  localGefahrstoffschulungenThemen.forEach(
                                    ( { SchulungenId } ) =>
                                      {
                                        let myDates = [] ;
                                        //#region Vorhandene Gefahrstoffschulungstermine iterieren
                                          //#region Reduktion auf Gefahrstoffschulungen zum Thema
                                            myDates = appGefahrstoffschulungen.filter(
                                              ( gefahrstoffschulung ) => gefahrstoffschulung.GefahrstoffschulungenThema === SchulungenId
                                            )
                                            //#region Reduktion auf Gefahrstoffschulungen mit Mitarbeiter als Teilnehmer
                                              myDates = myDates.filter(
                                                ( gefahrstoffschulung ) => ( ( ',' + gefahrstoffschulung.GefahrstoffschulungenMitarbeiterId + ',').includes( ',' + entry.MitarbeiterId + ',' ) ) 
                                              )
                                              // console.log( 'myDates für ' + entry.MitarbeiterNachname + ' betrefffs ' + SchulungenId + ': ' + myDates )
                                              //#region Auswerten
                                                /** Newest: ID für neueste stattgefundene Gefahrstoffschulung zum Thema
                                                 *  - bleibt leer wenn = bislang noch nie Gefahrstoffschulung stattgefunden
                                                 */
                                                  let myNewest = '' ;
                                                /** Daumen: Default "red"
                                                 *  - trifft zu für "überhaupt keine Gefahrstoffschulung vorhanden" und "Gefahrstoffschulung abgelaufen"
                                                 *  - zu überschreiben in allen anderen Fällen
                                                 */
                                                  let myDaumen = 'red' ;
                                                /** Planned: ID für geplante Gefahrstoffschulung zum Thema
                                                 *  - bleibt leer wenn = aktuell keine Gefahrstoffschulung geplant
                                                 */
                                                  let myPlanned = '' ;
                                                /** Gefundene Gefahrstoffschulungen prüfen  */
                                                  if( myDates.length !== 0 ) {
                                                    //#region Abgeschlossene auswerten
                                                      let myDatesAbgeschlossen = myDates.filter(
                                                        ( gefahrstoffschulung ) => gefahrstoffschulung.GefahrstoffschulungenStatus === 'abgeschlossen' 
                                                      )
                                                      if( myDatesAbgeschlossen.length !== 0 ) {
                                                        /** TODO rot vs. gelb vs. grün bezüglich Turnus lt. Gefahrstoffschulungsthema
                                                         *  - i.e. abgelaufen, bald ablaufend (3 Monate!), noch ausreichend gültig
                                                        */
                                                        // MOCK grün
                                                        myDaumen = 'green' ;
                                                        /** ID für neueste gefundene Gefahrstoffschulung */
                                                          let myDatesAbgeschlossenNewest = myDatesAbgeschlossen.sort(
                                                            ( a , b ) =>
                                                              {
                                                                if ( a.GefahrstoffschulungenTermin > b.GefahrstoffschulungenTermin ) { return 1 ; }
                                                                if ( a.GefahrstoffschulungenTermin < b.GefahrstoffschulungenTermin ) { return -1 ; }
                                                                return 0 ;
                                                              }
                                                          )
                                                          myNewest = myDatesAbgeschlossenNewest[ 0 ].GefahrstoffschulungenId ;
                                                      }
                                                        //#endregion Abgeschlossene auswerten
                                                    //#region auf offene prüfen = Sanduhr
                                                      /** Reine Existenzprüfung genügt */
                                                        let myDatesOpen = myDates.filter(
                                                          ( gefahrstoffschulung ) => gefahrstoffschulung.GefahrstoffschulungenStatus === 'offen' 
                                                        )
                                                        if ( myDatesOpen.length !== 0 ) {
                                                          /** ID für neueste gefundene Gefahrstoffschulung */
                                                            let myDatesOpenNext = myDatesOpen.sort(
                                                              ( a , b ) =>
                                                                {
                                                                  if ( a.GefahrstoffschulungenTermin > b.GefahrstoffschulungenTermin ) { return 1 ; }
                                                                  if ( a.GefahrstoffschulungenTermin < b.GefahrstoffschulungenTermin ) { return -1 ; }
                                                                  return 0 ;
                                                                }
                                                            )
                                                          myPlanned = myDatesOpenNext[ 0 ].GefahrstoffschulungenId ;
                                                        }
                                                        //#endregion auf offene prüfen = Sanduhr
                                                  }
                                                /** Resultat setzen  */
                                                  matrixDataRow[ 'matrixGefahrstoffschulungsthema' + SchulungenId ] = myDaumen + ',' + myNewest + ',' + myPlanned ;
                                                  //#endregion Auswerten
                                                //#endregion Reduktion auf Gefahrstoffschulungen mit Mitarbeiter als Teilnehmer
                                              //#region Reduktion auf Gefahrstoffschulungen zum Thema
                                            //#endregion Vorhandene Gefahrstoffschulungstermine iterieren
                                      }
                                  )
                                  //#endregion Vorhandene Gefahrstoffschulungsthemen iterieren
                                //#endregion Spalten für Gefahrstoffschulungsthemen
                              //#endregion Gefahrstoffschulungsdaten
                          matrixData.push( matrixDataRow ) ;
                        }
                    )
                  //#endregion Row-Liste initialisieren
              // console.log( 'async??:', matrixData ) ;
              return matrixData
            }
            //#endregion Helper-Funktion mkMatrtixData
        setTableData( mkMatrixData() )
        appGefahrstoffschulungenMatrixBuildNewChange( false ) ;
        // console.log( 'useEffect Matrix Out ' + Date.now() ) ;
      } ,
      [ appGefahrstoffschulungenMatrixBuildNew , appGefahrstoffschulungen , localGefahrstoffschulungenThemen , appMitarbeiterListe ]
    ) 
/** Component UI */
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Überblick
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {/* <IonButton
            onClick={ () => setMatrixLoading( true ) }
          >
            TEST: Loading-Spinner start
          </IonButton> */}
          <IonLoading
            // cssClass = 'einweisungen_matrix'
            isOpen = { matrixLoading }
            onDidDismiss={() => setMatrixLoading( false ) }
            message = '  .'
            // message = { localGefahrstoffschulungenThemen.length + ', ' + appMitarbeiterListe.length }
            duration = { 
              localGefahrstoffschulungenThemen.length > 0
                ? 
                  localGefahrstoffschulungenThemen.length * ( appMitarbeiterListe.length + 1)  * 3
                :
                  3000
            }
          />
          <Box
              sx={{
                height: 500 ,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(211,211,211)",
                },
              }}
            >
              {
                appGefahrstoffschulungenFetchState === 100 ? (
                  <IonSpinner name="bubbles" />
                ) : (
                  <DataGrid
                    headerHeight = { 120 }
                    // "loading" muss offenbar anderweitig wieder auf "false" gesetzt werden
                    // loading = { true }
                    disableVirtualization
                    /** sorting: appMitarbeiterListe initial nach Nachname sortiert, also auch matrixData */ 
                      // initialState={{
                      //   sorting: {
                      //     sortModel: [ { field: 'Nachname' , sort: 'asc' } ] ,
                      //   },
                      // }}
                    getRowId = { (row) => row.MitarbeiterId }
                    rows={ tableData }
                    columns={ setColumns() } 
                    rowsPerPageOptions={[50,100]}
                    disableSelectionOnClick
                    onCellClick = {
                      ( e ) => { 
                          // appViewSwitchChange( true ) ;
                          // alert( 'Die Funktionalität zum Aktualisieren der Gefahrstoffschulungen pro MitarbeiterIn wird derzeit technisch überarbeitet und steht in Kürze wieder zur Verfügung.\n\nWir danken für Ihr Verständnis!' ) ;
                        // alert( e.field + ': ' + e.value )
                        return ;
                      }
                    }
                    pageSize={pageSize}
                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                    pagination
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                  />
                )
              }
          </Box>
          {
            exportButton()
          }
          {
            appGefahrstoffschulungNeuThema !== ''
              ?
                <IonButton onClick={ () => { appViewSwitchChange( true ) ; appGefahrstoffschulungIdChange( 'neu' ) ; history.push( "/gefahrstoffschulung/neu/" ) ; } }>Neue Gefahrstoffschulung "{ gefahrstoffschulungNeuThemaTitel }"</IonButton>
              :
                <IonButton disabled = { true }>Neue Gefahrstoffschulung</IonButton>
            }
        </IonCardContent>
      </IonCard>
    );
}
