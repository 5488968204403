import { IonButton, IonCard, IonCardContent, IonModal, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../contexts/app_context";
import './table_geraete_grid.css';
import { useMitarbeiterListe } from "./_CustomHooks";
import { ButtonAdd, ButtonInaktive, defColumn , defColumnRenderCell , getLoadErr } from './_HelperFunctions' ;

function MitarbeiterTable() {
  const { appBetriebeId , appMitarbeiterIdChange , appMitarbeiterListe , appMitarbeiterListeChange , appMitarbeiterListeFetchstate , appMitarbeiterListeFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [pageSize, setPageSize] = useState(50);
  const [ importDialog , setImportDialog ] = useState( 'hide')
  const [ importdatei , setImportdatei ]:any = useState() ;
  const [ importstatus , setImportstatus ]:any = useState( '' ) ;
    /** Kopie appMitarbeiterListe für destruktive Filterung */
  const [ localMitarbeiterListe , localMitarbeiterListeChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const [ highlightVertragsEndeInXMonaten, setHighlightVertragsEndeInXMonaten ] = useState( 6 ) ;
  const setColumns = ( tablePurpose:string ):any => {
    if( tablePurpose === 'Mitarbeiter' ) {
      return (
        [
          defColumn( 'MitarbeiterId' , 'Personalnummer' , 0 ) ,
          defColumn( 'MitarbeiterPersonalnummer' , 'Personalnummer' , 40 , 0 ) ,
          defColumn( 'MitarbeiterNachname' , 'Nachname' , 160 , 0 ) ,
          defColumn( 'MitarbeiterVorname' , 'Vorname' , 160 , 0 ) ,
          defColumnRenderCell( 
            defColumn( 'MitarbeiterGeburtsdatum' , 'Geburtsdatum' , 30 , 0 ) ,
            ( data:any ) => new Date( data.row.MitarbeiterGeburtsdatum ).toLocaleDateString().replace( 'Invalid Date' , '' )
          ),
          defColumn( 'MitarbeiterTaetigkeit' , 'Tätigkeit' , 200 , 0 ) ,
          defColumn( 'MitarbeiterAbteilung' , 'Abteilung' , 300 , 0 ) ,
          defColumn( 'MitarbeiterVertragsart' , 'Vertragsart' , 100 , 0 ) ,
          defColumnRenderCell( 
            defColumn( 'MitarbeiterVertragsbeginn' , 'Vertr.beginn' , 30 , 0 ) ,
            ( data:any ) => data.row.MitarbeiterVertragsbeginn ? new Date( data.row.MitarbeiterVertragsbeginn ).toLocaleDateString().replace( 'Invalid Date' , '' ) : ''
          ),
          defColumnRenderCell( 
            defColumn( 'MitarbeiterVertragsende' , 'Vertr.ende' , 30 , 0 ) ,
            ( data:any ) => data.row.MitarbeiterVertragsende ? new Date( data.row.MitarbeiterVertragsende ).toLocaleDateString().replace( 'Invalid Date' , '' ) : ''
          ),
        ]
      )
    }
  }
  useMitarbeiterListe() ;
  
  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localMitarbeiterListeChange( appMitarbeiterListe )
      }
      else {
        localMitarbeiterListeChange(
          appMitarbeiterListe
            .filter(
              ( entry:any ) => entry.MitarbeiterAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      let myMitarbeiterListe = appMitarbeiterListe ;
      myMitarbeiterListe.sort(
        ( a:any , b:any ) => 
          {
            // abwärtskompatibel: nicht definierete Vertragsenden = hinter allen definierten
            let myVal_1 = a.MitarbeiterVertragsende ;
            if( myVal_1 === '0000-00-00' ) {
              myVal_1 = '9999-99-99' ;
            }
            let myVal_2 = b.MitarbeiterVertragsende ;
            if( myVal_2 === '0000-00-00' ) {
              myVal_2 = '9999-99-99' ;
            }
            if ( myVal_1 > myVal_2 ) { return 1 ; }
            if ( myVal_1 < myVal_2 ) { return -1 ; }
            return 0 ;
          } 
      )
      // ! Manipulation "appMitarbeiterListe" in useEffect für "appMitarbeiterListe" geht nur mit In-situ-Operationen wie "sort", sonst Endlos-Schleife
      appMitarbeiterListeChange( myMitarbeiterListe ) ;
      if( appMitarbeiterListe
          .findIndex(
            ( entry:any ) => entry.MitarbeiterAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appMitarbeiterListe ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )

  const exportButton = () =>
    <IonButton
      onClick = {
        () => 
          { 
            window.open( 'https://datamehr-backend.saint-online.de/api/mitarbeiter/export_mitarbeiter.php?betriebe_id=' + appBetriebeId )
          } 
      }
    >
      Exportieren
    </IonButton>

  async function sendImportdatei() {
    const doFetch = async () => {
      setImportstatus( 'running' ) ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://datamehr-backend.saint-online.de/api/dokumente/set_dokument.php', requestOptions)
      .then( response => response.json() )
      .then(
        json =>
          {
            if( json.entryId > 0 ) {
              // alert( 'jetzt https://datamehr-backend.saint-online.de/api/mitarbeiter/import_mitarbeiter.php?dokument_id=' + json.entryId )
              sendImportStart( json.entryId )
            }
          }
        )
    }
    let data = {
      DokumenteDateidatum: new Date( importdatei.lastModified ).toISOString() ,
      DokumenteDateigroesse: importdatei.size ,
      DokumenteDateiname: importdatei.name ,
      DokumenteDateitypKurz: 'XLSX' ,
      DokumenteDateitypMime: importdatei.type ,
      DokumenteEinweisung: 0 ,
      DokumenteEinweisungsthema: 0 ,
      DokumenteGeraet: 0 ,
      DokumenteUnterweisung: 0 ,
      DokumenteUnterweisungsthema: 0 ,
      DokumenteId: 'neu' ,
      DokumenteThema: 'Mitarbeiterliste für Import' ,
      DokumenteUniquename: appBetriebeId + '_' + Date.now().toString() ,
      DokumenteDateiToUpload: '' ,
      betriebeId: appBetriebeId
    }
    if( importdatei ) {
      const reader:any = new FileReader() ;
      reader.readAsDataURL( importdatei ) ;
      reader.onload = () => {
        data.DokumenteDateiToUpload = reader.result.replace(/^data:[^,]+,/, '') ;
        doFetch() ;
      }
    }
    else {
        // hl 22apr13 quick'n'dirty: einmal "doFetch" für asynchronen reader in reader.onload, einmal hier
      doFetch() ;
    }
  }

  async function sendImportStart( importdokumentId:String ) {
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: ''
      };
      await fetch( 'https://datamehr-backend.saint-online.de/api/mitarbeiter/import_mitarbeiter.php?betriebe_id=' + appBetriebeId + '&dokument_id=' + importdokumentId , requestOptions)
        .then(
          response =>
            {
              if( response.status !== 200 ) {
                setImportstatus( 'Fehler' ) ;
              }
              else {
                appMitarbeiterListeFetchanewChange( true ) ;
                setImportstatus( 'done' ) ;
              }
            }
        )
    }
    doFetch() ;
  }

  const mkTable = ( tablePurpose:string , singleGUI:string ) =>  {
    return (
      <>
        <IonModal
          isOpen = { importDialog === 'show' ? true : false }
          backdropDismiss = { false }
        >                            
          <IonCard>
            <IonCardContent>
              <p
                style = {
                  {
                    borderLeft: 'solid 0.4em gray' ,
                    fontSize: '0.8em' ,
                    paddingLeft: '0.5em'
                  }
                }
              >
                Mittels "Importieren" können Sie mehrere Mitarbeiter auf einmal aus einer Excel-Tabelle hinzufügen oder aktualisieren. Hierzu können Sie mit dem Button "Exportieren" eine Excel-Vorlage herunterladen, die Sie nach entsprechenden Hinzufügungen oder Änderungen hier wieder hochladen können.
                <br/>
                { exportButton() }
              </p>
              <br/>
              <input
                id="select_xlsx_dialog"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange = {
                  ( e:any ) => {
                    setImportdatei( e.target.files[0] )
                  }
                }
                style = {
                  {
                    display: 'none'
                  }
                }
              />
              <IonButton
                onClick={
                  () =>
                    {
                      // @ts-ignore
                      document.querySelector( '#select_xlsx_dialog' ).click() ;
                    }
                }
              >
                Zu importierende Excel-Datei { importdatei ? 'ändern' : 'auswählen' }
              </IonButton>
              <div
                style = {
                  {
                    paddingLeft: '1em'
                  }
                }
              >
                {
                  importdatei
                    ?
                      <>
                        <p
                          style = {
                            {
                              fontSize: '1.2em' ,
                              fontWeight: 'bold'
                            }
                          }
                        >
                        { importdatei.name }
                        </p>
                        <IonButton
                          onClick =
                            {
                              () => 
                                {
                                  sendImportdatei() ;
                                }
                            }
                        >
                          Importieren
                        </IonButton>
                      </>
                    :
                      <i>Keine Datei ausgewählt</i>
                }
              </div>
              <br/>
              {
                importstatus === 'Fehler'
                  ?
                    <p style = { { color: 'red' } }>
                      <b>Fehler:</b> Die Daten konnten nicht importiert werden. Prüfen Sie die gewählte Excel-Datei und versuchen Sie ggf. erneut zu importieren
                    </p>
                  :
                    importstatus === 'done'
                      ?
                        <p>
                          Die Daten wurden erfolgreich importiert.
                        </p>
                      :
                        ''
              }
              {
                importstatus === 'running'
                  ?
                    'Daten werden importiert ...'
                  :
                    <IonButton
                      onClick = {
                        () =>
                          {
                            setImportdatei() ;
                            setImportstatus( '' ) ;
                            setImportDialog( 'hide' ) ;
                          }
                      }
                    >
                      {
                        importstatus === 'done'
                          ?
                            'Schließen'
                          :
                            'Abbrechen'
                        }
                    </IonButton>
              }
            </IonCardContent>
          </IonCard>
        </IonModal>
        <Box
          sx={{
            height: 600,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "rgb(211,211,211)",
            },
          }}
        >
          {
            appMitarbeiterListeFetchstate !== 200 && appMitarbeiterListeFetchstate !== 204 ?
            (
              appMitarbeiterListeFetchstate === 100
                ? <IonSpinner name="bubbles" />
                : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                    { getLoadErr( appMitarbeiterListeFetchstate ) }
                  </div> 
            ) : (
              <>
                <DataGrid
                  disableVirtualization 
                  componentsProps = {{
                    toolbar: {
                      tablePurpose: tablePurpose
                    }
                  }}
                  initialState={{
                    sorting: {
                      // sortModel: [ { field: 'MitarbeiterPersonalnummer' , sort: 'asc' } ] ,
                    },
                  }}
                  getRowId={(row:any) => row.MitarbeiterId} 
                  getRowClassName =
                    {
                      ( data:any ) =>
                        {
                          if( data.row.MitarbeiterAktiv === 'inaktiv' ) {
                            return 'eintrag_inaktiv' ;
                          }
                            /** abwärtskompatibel: "MitarbeiterVertragsende" kann "null" sein */
                          if( data.row.MitarbeiterVertragsende !== null ) {
                              /** NB: 2678400000 = 31 Tage in Unixmilliseconds (1000 * 60 * 60 * 24 * 31) */
                            if( new Date( data.row.MitarbeiterVertragsende ).valueOf() < new Date().valueOf() + ( highlightVertragsEndeInXMonaten *  2678400000 ) ) {
                              return 'mitarbeiter_bald_vertragsende' ;
                            }
                          }
                          return '' ;
                        }
                    }
                  rows={ localMitarbeiterListe }
                  columns={ setColumns( tablePurpose ) } 
                  rowsPerPageOptions={[50,100]}
                  disableSelectionOnClick
                  sortingOrder={["desc", "asc"]}
                  onCellClick = {
                    (e) =>
                      {
                        appViewSwitchChange( true ) ;
                        appMitarbeiterIdChange( e.id ) ;
                        history.push( '/' + singleGUI + '/' + e.id + '/' + appBetriebeId ) ; 
                      }
                  }
                  // checkboxSelection
                  pageSize={pageSize}
                  onPageSizeChange={(newPage) => setPageSize(newPage)}
                  //onCellClick={(e) => tryme(e.row)}
                  // rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
                <ButtonInaktive
                  disabled = { existInaktive ? false : true }
                  myExec = {
                    () =>
                      {
                        setShowInaktive( showInaktive ? false : true )
                      }
                   }
                  label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                />
                <ButtonAdd
                  myExec = { () => { appMitarbeiterIdChange( 'neu' ) ; } }
                  routeUrl = { '/' + singleGUI + '/neu/' + appBetriebeId }
                />
                {
                  localMitarbeiterListe.length > 0
                    ?
                      exportButton()
                    :
                      ''
                }
                <IonButton
                  onClick = {
                    () => setImportDialog( 'show' ) 
                  }
                >
                  Importieren
                </IonButton>
                {
                  showInaktive
                    ?
                      <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                        Inaktive Mitarbeiter sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                      </p>
                    :
                      ''
                }
                <div
                  style = {
                    {
                      paddingBottom: '1em' ,
                      paddingLeft: '0.5em' 
                    }
                  }
                >
                  Hervorheben Restvertragslaufzeit weniger als
                  <span
                    style = {
                      {
                        display: 'inline-block' ,
                        fontWeight: 'bold' ,
                        margin: '0 0.5em 0 0.5' ,
                        textAlign: 'center' ,
                        width: '5em' ,
                      }
                    }
                  >
                    <span
                      onClick= {
                        () => setHighlightVertragsEndeInXMonaten( highlightVertragsEndeInXMonaten - 1 ) 
                      }
                      style = {
                        {
                          border: 'thin solid' ,
                          cursor: 'pointer' ,
                          display: 'inline-block' ,
                          fontWeight: 'bold' ,
                          margin: '0' ,
                          textAlign: 'center' ,
                          width: '1em' ,
                        }
                      }
                    >
                      -
                    </span>
                    <span
                      style = {
                        {
                          backgroundColor: 'whitesmoke' ,
                          border: 'thin solid' ,
                          display: 'inline-block' ,
                          fontWeight: 'bold' ,
                          margin: '0' ,
                          textAlign: 'center' ,
                          width: '2em' ,
                        }
                      }
                    >
                      { highlightVertragsEndeInXMonaten }
                    </span>
                    <span
                      onClick= {
                        () => setHighlightVertragsEndeInXMonaten( highlightVertragsEndeInXMonaten + 1 ) 
                      }
                      style = {
                        {
                          border: 'thin solid' ,
                          cursor: 'pointer' ,
                          display: 'inline-block' ,
                          fontWeight: 'bold' ,
                          margin: '0' ,
                          textAlign: 'center' ,
                          width: '1em' ,
                        }
                      }
                    >
                      +
                    </span>
                  </span>
                  Monate
                </div>
              </>
            )
          }
        </Box>
      </>
    )
  }
  return (
    <>
      { mkTable( 'Mitarbeiter' , 'mitarbeiterIn' ) }
    </>
  );
}
export default MitarbeiterTable;
