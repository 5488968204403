/** BRANDING.tsx 
 *  - "Bridge" zu public/env/branding_settings.js
 *    - branding_settings.js soll außerhalb von "src" sein
 *    - bei jedem Zugriff auf JS außerhalb von "src" ein "@ts-ignore" erforderlich, deshalb hier eine
 *      Bridge, mit der nur einmal ein "@ts-ignore" erforderlich, bei Zugriff via exportiertem SETTINGS
 *      hier aber nicht
 */


export let SETTINGS:any ;

if( document.getElementById( 'branding_settings_container' ) ) {
    // @ts-ignore
  SETTINGS = JSON.parse( document.getElementById( 'branding_settings_container' ).value ) ;
}

