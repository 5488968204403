/**
 * handle_browser_reload_close.tsx
 *  - Browser-Reload / -Schließen abfangen
 *  - Reload zur App-Startpage leiten
 *    - statt letzte Page ohne korrekte letzte States (s.u.)
 *  - bei Start erkennen, ob es sich um einen expliziten Reload handelt
 *  - Hintergund: Browser-Reload / -Schließen leert React-States, u.U. agiert der aktuelle View anders
 *    als gedacht
 *    - bei datamehr z.B. konkret "neues Gerät anlegen" statt vorheriges "Gerät XY bearbeiten"
 *    - NB: React-Apps sind SPAs, Neuladen der "Single Page" bedeutet zwangsläufig Neuladen
 *      der kompletten App
 *  - zu beachten
 *    - HTML kennt kein "reload"-Event oder eine Methode, ein Reload bedeutet die Abfolge
 *      "beforeunload" - "unload" - "load"
 *      - bei "beforeunload" kann abgebrochen werden 
 *    - In modernen Browsern kann Neuladen nicht verhindert werden, lediglich Rückfrage an
 *      Nutzer zur Bestätigung / Abbrechen Neuladen möglich
 *    - In modernen Browsern kann der Text der "Neuladen?"-Rückfrage nicht beeinflusst werden,
 *      selbstgestaltete zusätzliche Dialoge sind nicht möglich
 *  - Konzept: Bei bestätigtem Neuladen zurück zur Startseite (Startview / Home)
 *    - Startseite hat festgelegte States (statt situativen States)
 *  - Implementierung: Rückfrage bei "onbeforeunload", Setzen von Marker bei "unload", bei
 *    Existenz Marker bei Laden zu "Start" wechseln
 *  - NB: in iOS- / Android-App sollte es kein Neuladen geben, deshalb pure Browser-Methode ausreichend
 *
 * @param   appShortName    string: App-Bezeichner ohne Leerzeichen, mit dem Aktion eindeutig einer
 *                          bestimmten App zugeordnet werden können (nicht immer via URL möglich)
 * @returns loadMode        string:
 *                            - 'load' = initiales Laden / "echter" Neustart
 *                            - 'reload" = Neustart durch Browser-Reload
 */


/**
 * setReloadConfirmation
 *  - User soll Reload-Wunsch bestätigen (als nicht versehentlich)
 */
const setReloadConfirmation = () =>
  {
    window.onbeforeunload = ( e ) =>  {
      /** NB: preventDefault theoretisch in manchen Browsern erforderlich */
        e.preventDefault() ;
      /** nur wenn nicht ohnehin schon auf Startseite (sonst evtl. Schleife) */
        if( window.location.pathname === '/' ) { return } ;
      /** NB: Dialogtext hier wird normalerweise ignoriert, theoretisch könnte er erforderlich sein */
        return 'Achtung: Neuladen setzt alle aktuellen Abfragen und Eingaben zurück - Fortfahren?'
    } ;
  }


/**
 * setMarkUnloadByBrowser
 *  - Marker setzen, so dass bei Neuladen erkennbar ist, dass ein Reload vorlag (und kein echter Start)
 */
const setMarkUnloadByBrowser = ( appShortName:string ) =>
  {
    window.onunload = ( e ) =>  {
      /** nur wenn nicht ohnehin schon auf Startseite (sonst evtl. Schleife) */
        if( window.location.pathname === '/' ) { return } ;
      /** Marker setzen */
        window.localStorage.setItem( appShortName + '-reload-from-non-start' , '1' ) ;
    } ;
  }
  
/**
 * handle_browser_reload_close
 *  - Abfangen von Browser-Reload initialisieren
 *  - evtl. Status "Neustart nach Browser-Reload" zurücksetzen
 *  - loadMode zurückgeben
 */
export const handle_browser_reload_close = ( appShortName:string ) =>
  {
    setReloadConfirmation() ;
    setMarkUnloadByBrowser( appShortName ) ;
    if( window.localStorage.getItem( appShortName + '-reload-from-non-start' ) === '1' ) {
      /** Marker entfernen */
        window.localStorage.removeItem( appShortName + '-reload-from-non-start' ) ;
      /** zu Startpage (mit reinen HTML-Mitteln) */
        window.location.pathname = '/' ;
      /** loadMode zurückgeben */
        return 'reload' ;
    }
    /** loadMode zurückgeben */
      return 'load' ;
  }
