/**
 * View Dokument Gefahrstoffschulungsthema
 * 
 * Einzelnes Dokument für Gefahrstoffschulungsthema einsehen, bearbeiten oder erstellen
 * 
 */

import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonContent, IonHeader, IonImg ,IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { useContext , useEffect, useState } from 'react';
import { useGefahrstoffschulungenThemaDokumente } from '../components/_CustomHooks';
import { useParams } from 'react-router';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp , PageHelp ,readableFileSize } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import './Dokument.css';

export function DokumentGefahrstoffschulungsthema() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const { appGefahrstoffschulungenThemaDokumenteFetchanewChange , appGefahrstoffschulungenThemaDokumenteId , appGefahrstoffschulungenThemaDokumenteIdChange, appGefahrstoffschulungenThemen , appViewSwitchChange } = useContext( AppContext ) ;
  const { gefahrstoffschulungsthemaId } = useParams<{ gefahrstoffschulungsthemaId: string; }>();
  let tmp:any = window.localStorage.getItem("token") ;
  const betriebeId =  JSON.parse( tmp ).betriebe[0].betriebe_id 
  const [ DokumenteId, setDokumenteId ] = useState( appGefahrstoffschulungenThemaDokumenteId )
  const [ DokumenteAktiv, setDokumenteAktiv ] = useState( 'aktiv' )
  const [ DokumenteAktivBool, setDokumenteAktivBool ] = useState( true )
  const [ DokumenteDateitypMime, setDokumenteDateitypMime ] = useState( '' )
  const [ DokumenteDateitypKurz, setDokumenteDateitypKurz ] = useState( '' )
  const [ DokumenteThema, setDokumenteThema ] = useState( 'Gefahrstoffschulungsthema ' + gefahrstoffschulungsthemaId )
  const [ DokumenteDateiname, setDokumenteDateiname ] = useState( '' )
  const [ DokumenteUniquename, setDokumenteUniquename] = useState( betriebeId + '_' + Date.now().toString() ) ;
  const [ DokumenteDateidatum, setDokumenteDateidatum ] = useState( '' )
  const [ DokumenteDateigroesse, setDokumenteDateigroesse ] = useState( '' )
  const [ DokumenteDateiToUpload, setDokumenteDateiToUpload ]:any = useState()
   const DokumenteAktivStringToBool = ( val:string ) => {
    if( val === 'aktiv' ) {
      setDokumenteAktiv( 'aktiv' )
      setDokumenteAktivBool( true )
    } else {
      setDokumenteAktiv( 'inaktiv' )
      setDokumenteAktivBool( false )
    }
  }
  const getData = ( DokumenteId:string ) => {
    if (appGefahrstoffschulungenThemaDokumenteId === 'neu') {
      DokumenteAktivStringToBool( 'aktiv' ) ;
      setDokumenteDateidatum( '' ) ;
      setDokumenteDateigroesse( '' ) ;
      setDokumenteDateiname( '' ) ;
      setDokumenteDateitypKurz( '' ) ;
      setDokumenteDateitypMime( '' ) ;
      setDokumenteId( 'neu' ) ;
      setDokumenteThema( 'Gefahrstoffschulungsthema ' + gefahrstoffschulungsthemaId ) ;
      if ( Array.isArray( appGefahrstoffschulungenThemen ) ) {
        let myGefahrstoffschulungThema = appGefahrstoffschulungenThemen
          .find(
            ( entry:any) =>
              entry.SchulungenId === gefahrstoffschulungsthemaId
          )
        if( myGefahrstoffschulungThema ) {
          // @ts-ignore
          setDokumenteThema( 'Gefahrstoffschulungsthema "' + myGefahrstoffschulungThema.SchulungenTitel + '"' )
        }
      }
      setDokumenteUniquename( betriebeId + '_' + Date.now().toString() ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/dokumente/get_dokument.php?dokumente_id=` + DokumenteId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((dokumente: any) => {
          if (dokumente.DokumenteId === DokumenteId) {
            DokumenteAktivStringToBool( dokumente.DokumenteAktiv ) ;
            setDokumenteDateidatum( dokumente.DokumenteDateidatum ) ;
            setDokumenteDateigroesse( dokumente.DokumenteDateigroesse ) ;
            setDokumenteDateiname( dokumente.DokumenteDateiname ) ;
            setDokumenteDateitypKurz( dokumente.DokumenteDateitypKurz ) ;
            setDokumenteDateitypMime( dokumente.DokumenteDateitypMime ) ;
            setDokumenteId( dokumente.DokumenteId ) ;
            setDokumenteThema( dokumente.DokumenteThema ) ;
            setDokumenteUniquename( dokumente.DokumenteUniquename ) ;
          }
        })
      });
    }
  }
  async function sendJSON() {
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://datamehr-backend.saint-online.de/api/dokumente/set_dokument.php', requestOptions)
      .then( response => response.json() )
      .then(
        json =>
          {
            if( json.entryId > 0 ) {
              appGefahrstoffschulungenThemaDokumenteIdChange( json.entryId ) ;
            }
          }
        )
      appGefahrstoffschulungenThemaDokumenteFetchanewChange( true ) ;
    }
    let data = {
      DokumenteAktiv: DokumenteAktiv ,
      DokumenteDateidatum: DokumenteDateidatum ,
      DokumenteDateigroesse: DokumenteDateigroesse ,
      DokumenteDateiname: DokumenteDateiname ,
      DokumenteDateitypKurz: DokumenteDateitypKurz ,
      DokumenteDateitypMime: DokumenteDateitypMime ,
      DokumenteGefahrstoffschulungthema: `${gefahrstoffschulungsthemaId}` ,
      DokumenteId: DokumenteId ,
      DokumenteThema: DokumenteThema ,
      DokumenteUniquename: DokumenteUniquename ,
      DokumenteDateiToUpload: '' ,
      betriebeId: betriebeId
    }
    if( DokumenteDateiToUpload ) {
      const reader:any = new FileReader() ;
      reader.readAsDataURL( DokumenteDateiToUpload ) ;
      reader.onload = () => {
        data.DokumenteDateiToUpload = reader.result.replace(/^data:[^,]+,/, '') ;
        doFetch() ;
      }
    }
    else {
        // hl 22apr13 quick'n'dirty: einmal "doFetch" für asynchronen reader in reader.onload, einmal hier
      doFetch() ;
    }
  }
  const MIMETypeShort:any = ( mimetypelong:string ) => 
    {
      const MIMETypeList = [
        [ 'application/acad' , 'AutoCAD' ] ,
        [ 'application/dsptype' , 'TSP' ] ,
        [ 'application/dxf' , 'AutoCAD' ] ,
        [ 'application/gzip' , 'gzip' ] ,
        [ 'application/json' , 'JSON' ] ,
        [ 'application/listenup' , 'Listenup' ] ,
        [ 'application/mac-binhex40' , 'Binär' ] ,
        [ 'application/mbedlet' , 'Mbedlet' ] ,
        [ 'application/mif' , 'FrameMaker' ] ,
        [ 'application/msexcel' , 'Excel' ] ,
        [ 'application/mshelp' , 'Windows Hilfe' ] ,
        [ 'application/mspowerpoint' , 'Powerpoint' ] ,
        [ 'application/msword' , 'Word' ] ,
        [ 'application/octet-stream' , 'Binär' ] ,
        [ 'application/oda' , 'Oda' ] ,
        [ 'application/pdf' , 'PDF' ] ,
        [ 'application/postscript' , 'PostScript' ] ,
        [ 'application/rtc' , 'RTC' ] ,
        [ 'application/rtf' , 'RTF' ] ,
        [ 'application/studiom' , 'Studiom' ] ,
        [ 'application/toolbook' , 'Toolbook' ] ,
        [ 'application/vnd.oasis.opendocument.chart' , 'Diagramm' ] ,
        [ 'application/vnd.oasis.opendocument.formula' , 'Formel' ] ,
        [ 'application/vnd.oasis.opendocument.graphics' , 'Zeichnung' ] ,
        [ 'application/vnd.oasis.opendocument.image' , 'Bild' ] ,
        [ 'application/vnd.oasis.opendocument.presentation' , 'Präsentation' ] ,
        [ 'application/vnd.oasis.opendocument.spreadsheet' , 'Tabellendokument' ] ,
        [ 'application/vnd.oasis.opendocument.text' , 'Text' ] ,
        [ 'application/vocaltec-media-desc' , 'Vocaltec' ] ,
        [ 'application/vocaltec-media-file' , 'Vocaltec' ] ,
        [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' , 'Excel' ] ,
        [ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' , 'Word' ] ,
        [ 'application/xhtml+xml' , 'XHTML' ] ,
        [ 'application/xml' , 'XML' ] ,
        [ 'application/x-bcpio' , 'BCPIO' ] ,
        [ 'application/x-compress' , 'zlib' ] ,
        [ 'application/x-cpio' , 'CPIO' ] ,
        [ 'application/x-csh' , 'C-Shellscript' ] ,
        [ 'application/x-director' , 'Macromedia' ] ,
        [ 'application/x-dvi' , 'DVI' ] ,
        [ 'application/x-envoy' , 'Envoy' ] ,
        [ 'application/x-gtar' , 'TAR' ] ,
        [ 'application/x-hdf' , 'HDF' ] ,
        [ 'application/x-httpd-php' , 'PHP' ] ,
        [ 'application/x-latex' , 'LaTeX' ] ,
        [ 'application/x-macbinary' , 'Binär' ] ,
        [ 'application/x-mif' , 'FrameMaker' ] ,
        [ 'application/x-netcdf' , 'Unidata' ] ,
        [ 'application/x-nschat' , 'Chat' ] ,
        [ 'application/x-sh' , 'Script' ] ,
        [ 'application/x-shar' , 'SHAR' ] ,
        [ 'application/x-shockwave-flash' , 'Flash' ] ,
        [ 'application/x-sprite' , 'Sprite' ] ,
        [ 'application/x-stuffit' , 'Stuffit' ] ,
        [ 'application/x-supercard' , 'Supercard' ] ,
        [ 'application/x-sv4cpio' , 'CPIO' ] ,
        [ 'application/x-sv4crc' , 'CPIO' ] ,
        [ 'application/x-tar' , 'TAR' ] ,
        [ 'application/x-tcl' , 'TCL' ] ,
        [ 'application/x-tex' , 'TeX' ] ,
        [ 'application/x-texinfo' , 'Texinfo' ] ,
        [ 'application/x-troff' , 'TROFF' ] ,
        [ 'application/x-troff-man' , 'TROFF' ] ,
        [ 'application/x-troff-me' , 'TROFF' ] ,
        [ 'application/x-troff-ms' , 'TROFF' ] ,
        [ 'application/x-ustar' , 'TAR' ] ,
        [ 'application/x-wais-source' , 'WAIS' ] ,
        [ 'application/x-www-form-urlencoded' , 'CGI' ] ,
        [ 'application/zip' , 'ZIP' ] ,
        [ 'audio/basic' , 'Sound' ] ,
        [ 'audio/echospeech' , 'Echospeed' ] ,
        [ 'audio/mpeg' , 'MP3' ] ,
        [ 'audio/mp4' , 'MP4' ] ,
        [ 'audio/ogg' , 'OGG' ] ,
        [ 'audio/tsplayer' , 'TS' ] ,
        [ 'audio/voxware' , 'Vox' ] ,
        [ 'audio/wav' , 'WAV' ] ,
        [ 'audio/x-aiff' , 'AIFF' ] ,
        [ 'audio/x-dspeech' , 'Voice' ] ,
        [ 'audio/x-midi' , 'MIDI' ] ,
        [ 'audio/x-mpeg' , 'MPEG' ] ,
        [ 'audio/x-pn-realaudio' , 'RealAudio' ] ,
        [ 'audio/x-pn-realaudio-plugin' , 'RealAudio' ] ,
        [ 'audio/x-qt-stream' , 'Quicktime' ] ,
        [ 'drawing/x-dwf' , 'Drawing' ] ,
        [ 'image/bmp' , 'Bitmap' ] ,
        [ 'image/x-bmp' , 'Bitmap' ] ,
        [ 'image/x-ms-bmp' , 'Bitmap' ] ,
        [ 'image/cis-cod' , 'CIS' ] ,
        [ 'image/cmu-raster' , 'CMU' ] ,
        [ 'image/fif' , 'FIF' ] ,
        [ 'image/gif' , 'GIF' ] ,
        [ 'image/ief' , 'IEF' ] ,
        [ 'image/jpeg' , 'JPEG' ] ,
        [ 'image/png' , 'PNG' ] ,
        [ 'image/svg+xml' , 'SVG' ] ,
        [ 'image/tiff' , 'TIFF' ] ,
        [ 'image/vasa' , 'Vasa' ] ,
        [ 'image/vnd.wap.wbmp' , 'Bitmap' ] ,
        [ 'image/x-freehand' , 'Freehand' ] ,
        [ 'image/x-icon' , 'Icon' ] ,
        [ 'image/x-portable-anymap' , 'PBM' ] ,
        [ 'image/x-portable-bitmap' , 'PBM' ] ,
        [ 'image/x-portable-graymap' , 'PBM' ] ,
        [ 'image/x-portable-pixmap' , 'PBM' ] ,
        [ 'image/x-rgb' , 'RGB' ] ,
        [ 'image/x-windowdump' , 'Dump' ] ,
        [ 'image/x-xbitmap' , 'XBM' ] ,
        [ 'image/x-xpixmap' , 'XPM' ] ,
        [ 'message/external-body' , 'Nachricht' ] ,
        [ 'message/http' , 'HTTP' ] ,
        [ 'message/news' , 'Nachricht' ] ,
        [ 'message/partial' , 'Nachricht' ] ,
        [ 'message/rfc822' , 'Nachricht' ] ,
        [ 'model/vrml' , 'VRML' ] ,
        [ 'text/calendar' , 'iCal' ] ,
        [ 'text/csv' , 'CSV' ] ,
        [ 'text/css' , 'CSS' ] ,
        [ 'text/html' , 'HTML' ] ,
        [ 'text/javascript' , 'JS' ] ,
        [ 'text/markdown' , 'Markdown' ] ,
        [ 'text/plain' , 'Text' ] ,
        [ 'text/richtext' , 'Richtext' ] ,
        [ 'text/rtf' , 'RTF' ] ,
        [ 'text/tab-separated-values' , 'TSV' ] ,
        [ 'text/vnd.wap.wml' , 'WML' ] ,
        [ 'application/vnd.wap.wmlc' , 'WMLC' ] ,
        [ 'text/vnd.wap.wmlscript' , 'WML' ] ,
        [ 'application/vnd.wap.wmlscriptc' , 'WML' ] ,
        [ 'text/xml' , 'XML' ] ,
        [ 'text/xml-external-parsed-entity' , 'XML' ] ,
        [ 'text/x-setext' , 'SeText' ] ,
        [ 'text/x-sgml' , 'SGML' ] ,
        [ 'text/x-speech' , 'Speech' ] ,
        [ 'video/mpeg' , 'MPEG' ] ,
        [ 'video/mp4' , 'MP4' ] ,
        [ 'video/ogg' , 'OGG' ] ,
        [ 'video/quicktime' , 'Quicktime' ] ,
        [ 'video/vnd.vivo' , 'Vivo' ] ,
        [ 'video/webm' , 'Webm' ] ,
        [ 'video/x-msvideo' , 'AVI' ] ,
        [ 'video/x-sgi-movie' , 'Movie' ] ,
        [ 'video/3gpp' , '3GP' ] ,
        [ 'workbook/formulaone' , 'FormulaOne' ] ,
        [ 'x-world/x-3dmf' , '3DMF' ] ,
        [ 'x-world/x-vrml' , 'VRML' ]
      ]
      const MIMETypeHit = MIMETypeList.findIndex( entry => entry[ 0 ] === mimetypelong ) ;
      return MIMETypeHit > 0 ? MIMETypeList[ MIMETypeHit ][ 1 ] : '(N/A)' ;
     }
    function onValueDokumenteAktivChange(e: boolean) {
      e
        ?
          DokumenteAktivStringToBool( 'aktiv' )
        :
          DokumenteAktivStringToBool( 'inaktiv' )
    }
  function onValueDokumenteThema( e:any ) { setDokumenteThema( e.target.value ) ; }
  function onValueDokumenteSelect( e:any ) {
    if( new Date( e.target.files[0].lastModified ).toISOString() !== DokumenteDateidatum ) {
      setDokumenteDateidatum( new Date( e.target.files[0].lastModified ).toISOString() ) ;
      setDokumenteDateigroesse( e.target.files[0].size ) ;
      setDokumenteDateiname( e.target.files[0].name ) ; 
      setDokumenteDateitypKurz( MIMETypeShort( e.target.files[0].type ) ) ;
      setDokumenteDateitypMime( e.target.files[0].type ) ;
      setDokumenteDateiToUpload( e.target.files[0] ) ;
    }
  }
  useEffect(
    () => {
       setDokumenteId( appGefahrstoffschulungenThemaDokumenteId ) ;
       setDokumenteDateiToUpload() ;
       // if( history.location.pathname.includes( '/dokument/') ) {
          /**
           * !! Vgl. "Aufgabe": Problem richtige Dokumente-ID bei jeder Navigation
           *  - ABER: bei "Aufgabe" FALSCHER bzw. nicht verallgemeinerbarer Ansatz
           */
        getData( appGefahrstoffschulungenThemaDokumenteId ) ;
      // };
    },
    // [ history.location.pathname ]
    [ appGefahrstoffschulungenThemaDokumenteId ]
  );
  useGefahrstoffschulungenThemaDokumente() ;

  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für ein Dokument zu einem Gefahrstoffschulungsthema einsehen und bearbeiten oder für ein neues Dokument festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Dokument { DokumenteThema ? DokumenteThema  : 'neu anlegen' }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <input type="file" onChange = { ( e ) => onValueDokumenteSelect( e ) } />
            </IonItem>
             <IonItem lines="none" className='item'>
               <IonLabel slot="">aktiv</IonLabel>
               <IonCheckbox slot="start" checked={ DokumenteAktivBool } onIonChange={e => onValueDokumenteAktivChange( e.detail.checked ) } />
             </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dateiname</IonLabel>
              <IonInput disabled  value={ DokumenteDateiname } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Bezeichnung</IonLabel>
              <IonInput required value={ DokumenteThema } onIonChange={ ( e )=> onValueDokumenteThema( e ) } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dateityp</IonLabel>
              <IonInput disabled  value={ DokumenteDateitypKurz } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dateidatum</IonLabel>
              <IonInput disabled value = { DokumenteDateidatum ? new Date( DokumenteDateidatum ).toLocaleString() : '' } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dateigröße</IonLabel>
              <IonInput disabled  value = { readableFileSize( parseInt( DokumenteDateigroesse , 10 ) ) } />
            </IonItem>
            <ButtonsSaveActions
              myExec = {
                () =>
                  {
                    if( DokumenteDateitypMime ) {
                      sendJSON() ;
                    }
                    else {
                      alert( 'Bitte eine Datei auswählen.' ) ;
                      return false ;
                    }
                  }
              }
            />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            Ansicht gespeichertes Bild
          </IonCardHeader>
          <IonCardContent>
            {
              DokumenteId === 'neu' || DokumenteDateiToUpload
                ?
                  <IonInput disabled style = { { fontSize: '0.8em' } } value = { 'Ansicht erst nach Speicherung möglich' } />
                :
                  DokumenteDateitypMime.includes( 'image/' )
                    ?
                      // @ts-ignore
                      <IonImg class = "preview" src = { 'https://datamehr-backend.saint-online.de/api/dokumente/get_dokument_datei.php?dokumente_id=' + DokumenteId } />
                    :
                      DokumenteDateitypKurz
                        ? 
                          <IonInput disabled style = { { fontSize: '0.8em' } } value = { 'Ansicht nur für Bilder möglich (aktuelle Datei ist "' + DokumenteDateitypKurz + '")'  } />
                        :
                          ''
            }
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

