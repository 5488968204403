import { IonAlert , IonButton, IonCard , IonCardContent , IonModal , IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext , useEffect , useState } from "react";
import { ThumbsDown , ThumbsUp } from 'react-ionicons';
import { useHistory } from "react-router-dom";
import './table_geraete_grid.css';
import { ButtonAdd , ButtonInaktive , defColumn , defColumnRenderCell , getLoadErr } from './_HelperFunctions' ;
import { useGeraeteListe } from './_CustomHooks';
import { AppContext } from '../contexts/app_context';

function TableGeraete() {
  const { appBetriebeId , appGeraeteIdChange , appGeraeteListeData , appGeraeteListeFetchanewChange , appGeraeteListeFetchstate , appUserIstBasic , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [ infoImportToCome , setInfoImportToCome ] = useState( 'hide')
  const [pageSize, setPageSize] = useState(50);
  const [ localGeraeteListeData , localGeraeteListeDataChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const [ importDialog , setImportDialog ] = useState( 'hide')
  const [ importdatei , setImportdatei ] = useState() ;
  const [ importstatus , setImportstatus ] = useState( '' ) ;
  useGeraeteListe() ;

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localGeraeteListeDataChange( appGeraeteListeData )
      }
      else {
        localGeraeteListeDataChange(
          appGeraeteListeData
            .filter(
              ( entry ) => entry.GeraeteGesperrt === '0'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appGeraeteListeData
          .findIndex(
            ( entry ) => entry.GeraeteGesperrt === '1'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appGeraeteListeData ]
  )

  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  const exportButton = () =>
    <IonButton
      onClick = {
        () => 
          { 
            window.open( 'https://datamehr-backend.saint-online.de/api/geraete/export_geraete.php?betriebe_id=' + appBetriebeId )
          } 
      }
    >
      Exportieren
    </IonButton>

  async function sendImportdatei() {
    const doFetch = async () => {
      setImportstatus( 'running' ) ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://datamehr-backend.saint-online.de/api/dokumente/set_dokument.php', requestOptions)
      .then( response => response.json() )
      .then(
        json =>
          {
            if( json.entryId > 0 ) {
              sendImportStart( json.entryId )
            }
          }
        )
    }
    let data = {
      DokumenteDateidatum: new Date( importdatei.lastModified ).toISOString() ,
      DokumenteDateigroesse: importdatei.size ,
      DokumenteDateiname: importdatei.name ,
      DokumenteDateitypKurz: 'XLSX' ,
      DokumenteDateitypMime: importdatei.type ,
      DokumenteEinweisung: 0 ,
      DokumenteEinweisungsthema: 0 ,
      DokumenteGeraet: 0 ,
      DokumenteUnterweisung: 0 ,
      DokumenteUnterweisungsthema: 0 ,
      DokumenteId: 'neu' ,
      DokumenteThema: 'Geräteliste für Import' ,
      DokumenteUniquename: appBetriebeId + '_' + Date.now().toString() ,
      DokumenteDateiToUpload: '' ,
      betriebeId: appBetriebeId
    }
    if( importdatei ) {
      const reader = new FileReader() ;
      reader.readAsDataURL( importdatei ) ;
      reader.onload = () => {
        data.DokumenteDateiToUpload = reader.result.replace(/^data:[^,]+,/, '') ;
        doFetch() ;
      }
    }
    else {
        // hl 22apr13 quick'n'dirty: einmal "doFetch" für asynchronen reader in reader.onload, einmal hier
      doFetch() ;
    }
  }

  async function sendImportStart( importdokumentId ) {
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: ''
      };
      await fetch( 'https://datamehr-backend.saint-online.de/api/geraete/import_geraete.php?betriebe_id=' + appBetriebeId + '&dokument_id=' + importdokumentId , requestOptions)
        .then(
          response =>
            {
              if( response.status !== 200 ) {
                setImportstatus( 'Fehler' ) ;
              }
              else {
                appGeraeteListeFetchanewChange( true ) ;
                setImportstatus( 'done' ) ;
              }
            }
        )
    }
    doFetch() ;
  }

  return (
    <>
      <IonModal
        isOpen = { importDialog === 'show' ? true : false }
        backdropDismiss = { false }
      >                            
        <IonCard>
          <IonCardContent>
            <p
              style = {
                {
                  borderLeft: 'solid 0.4em gray' ,
                  fontSize: '0.8em' ,
                  paddingLeft: '0.5em'
                }
              }
            >
              Mittels "Importieren" können Sie mehrere <b>eigene Geräte</b> auf einmal aus einer Excel-Tabelle hinzufügen oder aktualisieren. Hierzu können Sie mit dem Button "Exportieren" eine Excel-Vorlage herunterladen, die Sie nach entsprechenden Hinzufügungen oder Änderungen hier wieder hochladen können. Beachten Sie, dass Medmehr-Geräte ("MM_") nur von Medmehr geändert oder hinzugefügt werden können.
              <br/>
              { exportButton() }
            </p>
            <br/>
            <input
              id="select_xlsx_dialog"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange = {
                ( e ) => {
                  setImportdatei( e.target.files[0] )
                }
              }
              style = {
                {
                  display: 'none'
                }
              }
            />
            <IonButton
              onClick={
                () =>
                  {
                    // @ts-ignore
                    document.querySelector( '#select_xlsx_dialog' ).click() ;
                  }
              }
            >
              Zu importierende Excel-Datei { importdatei ? 'ändern' : 'auswählen' }
            </IonButton>
            <div
              style = {
                {
                  paddingLeft: '1em'
                }
              }
            >
              {
                importdatei
                  ?
                    <>
                      <p
                        style = {
                          {
                            fontSize: '1.2em' ,
                            fontWeight: 'bold'
                          }
                        }
                      >
                      { importdatei.name }
                      </p>
                      <IonButton
                        onClick =
                          {
                            () => 
                              {
                                sendImportdatei() ;
                              }
                          }
                      >
                        Importieren
                      </IonButton>
                    </>
                  :
                    <i>Keine Datei ausgewählt</i>
              }
            </div>
            <br/>
            {
              importstatus === 'Fehler'
                ?
                  <p style = { { color: 'red' } }>
                    <b>Fehler:</b> Die Daten konnten nicht importiert werden. Prüfen Sie die gewählte Excel-Datei und versuchen Sie ggf. erneut zu importieren
                  </p>
                :
                  importstatus === 'done'
                    ?
                      <p>
                        Die Daten wurden erfolgreich importiert.
                      </p>
                    :
                      ''
            }
            {
              importstatus === 'running'
                ?
                  'Daten werden importiert ...'
                :
                  <IonButton
                    onClick = {
                      () =>
                        {
                          setImportdatei() ;
                          setImportstatus( '' ) ;
                          setImportDialog( 'hide' ) ;
                        }
                    }
                  >
                    {
                      importstatus === 'done'
                        ?
                          'Schließen'
                        :
                          'Abbrechen'
                      }
                  </IonButton>
            }
          </IonCardContent>
        </IonCard>
      </IonModal>
      <Box
        sx={{
          height: 600,
          width: 1,
          "& .super-app-theme--header": {
            backgroundColor: "rgb(211,211,211)",
          },
        }}
      >
        {
          appGeraeteListeFetchstate !== 200 && appGeraeteListeFetchstate !== 204 ?
          (
            appGeraeteListeFetchstate === 100
              ? <IonSpinner name="bubbles" />
              : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                  { getLoadErr( appGeraeteListeFetchstate ) }
                </div> 
          ) : (
            <>
              <IonAlert
                isOpen = { infoImportToCome === 'show' ? true : false }
                backdropDismiss = { true }
                header = '... in Arbeit ...'
                message = 'Hier wird in Kürze die Möglichkeit bestehen, Gerätedaten z.B. aus einer Excel-Tabelle zu importieren'
                buttons = {
                  [
                    {
                      text: 'Verstanden!' ,
                      handler: () => {
                        setInfoImportToCome( '' ) ;
                      }
                    }
                  ]
                }
              />                            
              <DataGrid
                  disableVirtualization 
                  componentsProps = {{
                  toolbar: {
                    tablePurpose: 'Geraete'
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'GeraeteGesperrt' , sort: 'desc' } ] ,
                  },
                }}
                getRowId={(row) => row.geraeteId} 
                rows={ localGeraeteListeData }
                getRowClassName =
                  {
                    ( data ) => data.row.GeraeteGesperrt === '1' ? 'eintrag_inaktiv' : ''
                  }
                /** Columns sollen in chart_geraete und table_geraete gleich sein
                 *  !!!!! Auslagern in zentrale Datei nicht einfach wg. TypeScript, deshalb
                 *        vorerst identische Kopien (bis auf Type-Script-Annotations wg. TSX
                 *        vs. JSX)
                 */
                  columns = {
                    [
                      defColumn( "GeraeteGesperrt" , "aktiv" , 0 ) ,
                      defColumnRenderCell(
                        defColumn( "GeraeteStatus" , "Status" , 60 ) ,
                        ( params ) => {
                          if ( params.row.GeraeteStatus === 0 || params.row.Aufgaben.length === 0 ) {
                            return <ThumbsDown  color="red"  />
                          }
                          else {
                            if ( params.row.GeraeteStatus === 1 ) {
                              return <ThumbsUp  color="green"  />
                            }
                            else {
                              if ( params.row.GeraeteStatus === 2 ) {
                                return <ThumbsUp  color="gold"  />
                              }
                            }
                          }
                        }
                      ) ,       
                      defColumn( "geraeteId" , "ID" , 200 ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteTyp" , "Geräte Gruppe" ) ,
                      defColumn( "GeraeteHersteller" , "Geräte Hersteller" ) ,
                      defColumn( "GeraeteName" , "Geräte Typ" ) ,
                      defColumn( "geraete_seriennummer" , "Seriennr." ) ,
                      defColumn( "geraete_kunde_id1" , "Inventarnr." ) ,
                      defColumn( "baujahr" , "Baujahr" ) ,
                      defColumn( "GeraeteCE" , "CE" ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteStandort" , "Standort" ) ,
                      defColumn( "GeraeteEigentuemer" , "Eigentümer" ) ,
                      defColumn( "GeraeteWartung" , "nächste Wartung" )
                    ]
                  }
                rowsPerPageOptions={[50,100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onCellClick = {
                  ( e ) => 
                    {
                      appViewSwitchChange( true ) ;
                      appGeraeteIdChange( e.id ) ;
                      history.push( '/geraet/' + e.id + '/' + appBetriebeId ) ;
                    }
                }
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
              <ButtonInaktive
                disabled = { existInaktive ? false : true }
                myExec = {
                  () =>
                    {
                      setShowInaktive( showInaktive ? false : true )
                    }
                  }
                label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
              />
              <ButtonAdd
                myExec = { () => { appGeraeteIdChange( 'neu' ) ; } }
                routeUrl = { '/geraet/neu/' + appBetriebeId }
              />
              {/* <IonButton onClick={ () => setInfoImportToCome( 'show' ) }>Importieren</IonButton> */}
              {
                localGeraeteListeData.length > 0
                  ?
                    exportButton()
                  :
                    ''
              }
              {
                appUserIstBasic === 'nein'
                  ?
                    <IonButton
                      onClick = {
                        () => setImportDialog( 'show' ) 
                      }
                    >
                      Importieren
                    </IonButton>
                  :
                    null               
              }
              {
                showInaktive
                  ?
                    <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                      Inaktive Geräte sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                    </p>
                  :
                    ''
              }
            </>
          )
        }
      </Box>
    </>
  );
}
export default TableGeraete;
