/**
 * View Briefpapier
 * 
 * Briefpapier für Kunde einsehen oder hochladen
 * 
 *  - Adaption von Dokument.tsx
 * 
 */

import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonImg ,IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { useContext , useState } from 'react';
import { ButtonCancelBack, ButtonPageHelp , PageHelp ,readableFileSize } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import './Dokument.css';

export function Briefpapier() {
  useIonViewWillEnter(
    () =>
      {
        checkBriefpapierExists( briefpapierUriBase + 'E' )  ;
      }
  ) ;
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const { appKundeId , appKundeName , appViewSwitchChange } = useContext( AppContext ) ;
  const briefpapierUriBase = 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=' + appKundeId + '&output=' ;
  const [ BriefpapierExists , setBriefpapierExists ] = useState( false ) ;
  const [ BriefpapierMode , setBriefpapierMode ] = useState( '' ) ;
  const [ BriefpapierNoCacheUrl , setBriefpapierNoCacheUrl ] = useState( briefpapierUriBase + 'D' + '&cache=' + Date.now().toString() ) ;
  const [ DokumenteDateitypMime, setDokumenteDateitypMime ] = useState( '' )
  const [ DokumenteDateitypKurz, setDokumenteDateitypKurz ] = useState( '' )
    // Default "DokumenteThema" ad hoc => Ziel "GeraeteHersteller GeraetName" erfordert Datenbezug
  const [ DokumenteDateiname, setDokumenteDateiname ] = useState( '' )
  const [ DokumenteDateidatum, setDokumenteDateidatum ] = useState( '' )
  const [ DokumenteDateigroesse, setDokumenteDateigroesse ] = useState( '' )
  const [ DokumenteDateiToUpload, setDokumenteDateiToUpload ]:any = useState()

  const checkBriefpapierExists = async ( uri:string ) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    await fetch( uri , requestOptions )
      .then( response => 
        {
          if( response.status === 200 ) {
            setBriefpapierExists( true ) ;
            setBriefpapierNoCacheUrl( briefpapierUriBase + 'D' + '&cache=' + Date.now().toString() ) ;
            setBriefpapierMode( 'show' ) ;
          }
            /** NB: das ist der 404er vom Backend, "404" im Sinne von (Backend-) URL nicht vorhanden würde ein ".catch" auslösen */
          if( response.status === 404 ) {
            setBriefpapierExists( false ) ;
            setBriefpapierMode( 'upload' ) ;
          }
        }
      )
  }  
  
  async function sendJSON() {
    // return ;
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://datamehr-backend.saint-online.de/api/aufgaben/set_briefpapier.php', requestOptions)
        .then( () => 
          {
            setBriefpapierExists( true ) ;
            setBriefpapierNoCacheUrl( briefpapierUriBase + 'D' + '&cache=' + Date.now().toString() ) ;
            setBriefpapierMode( 'show' ) ;
          }
        )
        .catch(
          () =>
            {
              setBriefpapierExists( false ) ;
              setBriefpapierMode( 'upload' ) ;
              alert( 'Fehler - Die Datei konnte nicht gespeichert werden' ) ;
            }
        )
    }
    let data = {
      DokumenteDateiToUpload: '' ,
      DokumenteDateitypMime: DokumenteDateitypMime ,
      kunde_id: appKundeId
    }
    if( DokumenteDateiToUpload ) {
      const reader:any = new FileReader() ;
      reader.readAsDataURL( DokumenteDateiToUpload ) ;
      reader.onload = () => {
        data.DokumenteDateiToUpload = reader.result.replace(/^data:[^,]+,/, '') ;
        doFetch() ;
      }
    }
    else {
        // hl 22apr13 quick'n'dirty: einmal "doFetch" für asynchronen reader in reader.onload, einmal hier
      doFetch() ;
    }
  }
  const MIMETypeShort:any = ( mimetypelong:string ) => 
    {
      const MIMETypeList = [
        [ 'application/acad' , 'AutoCAD' ] ,
        [ 'application/dsptype' , 'TSP' ] ,
        [ 'application/dxf' , 'AutoCAD' ] ,
        [ 'application/gzip' , 'gzip' ] ,
        [ 'application/json' , 'JSON' ] ,
        [ 'application/listenup' , 'Listenup' ] ,
        [ 'application/mac-binhex40' , 'Binär' ] ,
        [ 'application/mbedlet' , 'Mbedlet' ] ,
        [ 'application/mif' , 'FrameMaker' ] ,
        [ 'application/msexcel' , 'Excel' ] ,
        [ 'application/mshelp' , 'Windows Hilfe' ] ,
        [ 'application/mspowerpoint' , 'Powerpoint' ] ,
        [ 'application/msword' , 'Word' ] ,
        [ 'application/octet-stream' , 'Binär' ] ,
        [ 'application/oda' , 'Oda' ] ,
        [ 'application/pdf' , 'PDF' ] ,
        [ 'application/postscript' , 'PostScript' ] ,
        [ 'application/rtc' , 'RTC' ] ,
        [ 'application/rtf' , 'RTF' ] ,
        [ 'application/studiom' , 'Studiom' ] ,
        [ 'application/toolbook' , 'Toolbook' ] ,
        [ 'application/vnd.oasis.opendocument.chart' , 'Diagramm' ] ,
        [ 'application/vnd.oasis.opendocument.formula' , 'Formel' ] ,
        [ 'application/vnd.oasis.opendocument.graphics' , 'Zeichnung' ] ,
        [ 'application/vnd.oasis.opendocument.image' , 'Bild' ] ,
        [ 'application/vnd.oasis.opendocument.presentation' , 'Präsentation' ] ,
        [ 'application/vnd.oasis.opendocument.spreadsheet' , 'Tabellendokument' ] ,
        [ 'application/vnd.oasis.opendocument.text' , 'Text' ] ,
        [ 'application/vocaltec-media-desc' , 'Vocaltec' ] ,
        [ 'application/vocaltec-media-file' , 'Vocaltec' ] ,
        [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' , 'Excel' ] ,
        [ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' , 'Word' ] ,
        [ 'application/xhtml+xml' , 'XHTML' ] ,
        [ 'application/xml' , 'XML' ] ,
        [ 'application/x-bcpio' , 'BCPIO' ] ,
        [ 'application/x-compress' , 'zlib' ] ,
        [ 'application/x-cpio' , 'CPIO' ] ,
        [ 'application/x-csh' , 'C-Shellscript' ] ,
        [ 'application/x-director' , 'Macromedia' ] ,
        [ 'application/x-dvi' , 'DVI' ] ,
        [ 'application/x-envoy' , 'Envoy' ] ,
        [ 'application/x-gtar' , 'TAR' ] ,
        [ 'application/x-hdf' , 'HDF' ] ,
        [ 'application/x-httpd-php' , 'PHP' ] ,
        [ 'application/x-latex' , 'LaTeX' ] ,
        [ 'application/x-macbinary' , 'Binär' ] ,
        [ 'application/x-mif' , 'FrameMaker' ] ,
        [ 'application/x-netcdf' , 'Unidata' ] ,
        [ 'application/x-nschat' , 'Chat' ] ,
        [ 'application/x-sh' , 'Script' ] ,
        [ 'application/x-shar' , 'SHAR' ] ,
        [ 'application/x-shockwave-flash' , 'Flash' ] ,
        [ 'application/x-sprite' , 'Sprite' ] ,
        [ 'application/x-stuffit' , 'Stuffit' ] ,
        [ 'application/x-supercard' , 'Supercard' ] ,
        [ 'application/x-sv4cpio' , 'CPIO' ] ,
        [ 'application/x-sv4crc' , 'CPIO' ] ,
        [ 'application/x-tar' , 'TAR' ] ,
        [ 'application/x-tcl' , 'TCL' ] ,
        [ 'application/x-tex' , 'TeX' ] ,
        [ 'application/x-texinfo' , 'Texinfo' ] ,
        [ 'application/x-troff' , 'TROFF' ] ,
        [ 'application/x-troff-man' , 'TROFF' ] ,
        [ 'application/x-troff-me' , 'TROFF' ] ,
        [ 'application/x-troff-ms' , 'TROFF' ] ,
        [ 'application/x-ustar' , 'TAR' ] ,
        [ 'application/x-wais-source' , 'WAIS' ] ,
        [ 'application/x-www-form-urlencoded' , 'CGI' ] ,
        [ 'application/zip' , 'ZIP' ] ,
        [ 'audio/basic' , 'Sound' ] ,
        [ 'audio/echospeech' , 'Echospeed' ] ,
        [ 'audio/mpeg' , 'MP3' ] ,
        [ 'audio/mp4' , 'MP4' ] ,
        [ 'audio/ogg' , 'OGG' ] ,
        [ 'audio/tsplayer' , 'TS' ] ,
        [ 'audio/voxware' , 'Vox' ] ,
        [ 'audio/wav' , 'WAV' ] ,
        [ 'audio/x-aiff' , 'AIFF' ] ,
        [ 'audio/x-dspeech' , 'Voice' ] ,
        [ 'audio/x-midi' , 'MIDI' ] ,
        [ 'audio/x-mpeg' , 'MPEG' ] ,
        [ 'audio/x-pn-realaudio' , 'RealAudio' ] ,
        [ 'audio/x-pn-realaudio-plugin' , 'RealAudio' ] ,
        [ 'audio/x-qt-stream' , 'Quicktime' ] ,
        [ 'drawing/x-dwf' , 'Drawing' ] ,
        [ 'image/bmp' , 'Bitmap' ] ,
        [ 'image/x-bmp' , 'Bitmap' ] ,
        [ 'image/x-ms-bmp' , 'Bitmap' ] ,
        [ 'image/cis-cod' , 'CIS' ] ,
        [ 'image/cmu-raster' , 'CMU' ] ,
        [ 'image/fif' , 'FIF' ] ,
        [ 'image/gif' , 'GIF' ] ,
        [ 'image/ief' , 'IEF' ] ,
        [ 'image/jpeg' , 'JPEG' ] ,
        [ 'image/png' , 'PNG' ] ,
        [ 'image/svg+xml' , 'SVG' ] ,
        [ 'image/tiff' , 'TIFF' ] ,
        [ 'image/vasa' , 'Vasa' ] ,
        [ 'image/vnd.wap.wbmp' , 'Bitmap' ] ,
        [ 'image/x-freehand' , 'Freehand' ] ,
        [ 'image/x-icon' , 'Icon' ] ,
        [ 'image/x-portable-anymap' , 'PBM' ] ,
        [ 'image/x-portable-bitmap' , 'PBM' ] ,
        [ 'image/x-portable-graymap' , 'PBM' ] ,
        [ 'image/x-portable-pixmap' , 'PBM' ] ,
        [ 'image/x-rgb' , 'RGB' ] ,
        [ 'image/x-windowdump' , 'Dump' ] ,
        [ 'image/x-xbitmap' , 'XBM' ] ,
        [ 'image/x-xpixmap' , 'XPM' ] ,
        [ 'message/external-body' , 'Nachricht' ] ,
        [ 'message/http' , 'HTTP' ] ,
        [ 'message/news' , 'Nachricht' ] ,
        [ 'message/partial' , 'Nachricht' ] ,
        [ 'message/rfc822' , 'Nachricht' ] ,
        [ 'model/vrml' , 'VRML' ] ,
        [ 'text/calendar' , 'iCal' ] ,
        [ 'text/csv' , 'CSV' ] ,
        [ 'text/css' , 'CSS' ] ,
        [ 'text/html' , 'HTML' ] ,
        [ 'text/javascript' , 'JS' ] ,
        [ 'text/markdown' , 'Markdown' ] ,
        [ 'text/plain' , 'Text' ] ,
        [ 'text/richtext' , 'Richtext' ] ,
        [ 'text/rtf' , 'RTF' ] ,
        [ 'text/tab-separated-values' , 'TSV' ] ,
        [ 'text/vnd.wap.wml' , 'WML' ] ,
        [ 'application/vnd.wap.wmlc' , 'WMLC' ] ,
        [ 'text/vnd.wap.wmlscript' , 'WML' ] ,
        [ 'application/vnd.wap.wmlscriptc' , 'WML' ] ,
        [ 'text/xml' , 'XML' ] ,
        [ 'text/xml-external-parsed-entity' , 'XML' ] ,
        [ 'text/x-setext' , 'SeText' ] ,
        [ 'text/x-sgml' , 'SGML' ] ,
        [ 'text/x-speech' , 'Speech' ] ,
        [ 'video/mpeg' , 'MPEG' ] ,
        [ 'video/mp4' , 'MP4' ] ,
        [ 'video/ogg' , 'OGG' ] ,
        [ 'video/quicktime' , 'Quicktime' ] ,
        [ 'video/vnd.vivo' , 'Vivo' ] ,
        [ 'video/webm' , 'Webm' ] ,
        [ 'video/x-msvideo' , 'AVI' ] ,
        [ 'video/x-sgi-movie' , 'Movie' ] ,
        [ 'video/3gpp' , '3GP' ] ,
        [ 'workbook/formulaone' , 'FormulaOne' ] ,
        [ 'x-world/x-3dmf' , '3DMF' ] ,
        [ 'x-world/x-vrml' , 'VRML' ]
      ]
      const MIMETypeHit = MIMETypeList.findIndex( entry => entry[ 0 ] === mimetypelong ) ;
      return MIMETypeHit > 0 ? MIMETypeList[ MIMETypeHit ][ 1 ] : '(N/A)' ;
     }
  function onValueDokumenteSelect( e:any ) {
    if( new Date( e.target.files[0].lastModified ).toISOString() !== DokumenteDateidatum ) {
      setDokumenteDateidatum( new Date( e.target.files[0].lastModified ).toISOString() ) ;
      setDokumenteDateigroesse( e.target.files[0].size ) ;
      setDokumenteDateiname( e.target.files[0].name ) ; 
      setDokumenteDateitypKurz( MIMETypeShort( e.target.files[0].type ) ) ;
      setDokumenteDateitypMime( e.target.files[0].type ) ;
      setDokumenteDateiToUpload( e.target.files[0] ) ;
    }
  }

  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Ihr festgelegtes Briefpapier für die Erstellung von Aufträgen einsehen oder für ein neues Briefpapier hochladen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Briefpapier { appKundeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          {
            BriefpapierMode === 'upload'
              ?
                <>
                  <IonCardHeader>
                    <IonCardTitle>
                      Neue Datei als Briefpapier hochladen
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      Hochgeladen werden können PNG- und PDF-Dateien. Als Orientierung steht eine Vorlage als PNG- , PDF- und Word-Datei zur Verfügung. Die PNG- und PDF-Vorlagen können nach Ihrer Bearbeitung direkt hier hochgeladen werden, die Word-Vorlage muss nach Bearbeitung zum Hochladen als PDF-Datei gespeichert werden.
                    </p>
                    <IonButton
                      onClick={
                        () =>
                          {
                            window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/download_vorlage_briefpapier.php?filetype=png' ) ;
                          }
                      }
                    >
                      PNG-Vorlage herunterladen
                    </IonButton>
                    <IonButton
                      onClick={
                        () =>
                          {
                            window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/download_vorlage_briefpapier.php?filetype=pdf' ) ;
                          }
                      }
                    >
                      PDF-Vorlage herunterladen
                    </IonButton>
                    <IonButton
                      onClick={
                        () =>
                          {
                            window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/download_vorlage_briefpapier.php?filetype=docx' ) ;
                          }
                      }
                    >
                      Word-Vorlage herunterladen
                    </IonButton>
                  </IonCardContent>
                </>
              :
                ''
            }
          <IonCardContent>
            {
              BriefpapierMode === 'show'
                ?
                  <>
                    <IonButton
                      onClick={
                        () =>
                          {
                            window.open( briefpapierUriBase + 'I' ) ;
                          }
                      }
                    >
                      Vollansicht in neuem Fenster
                    </IonButton>
                    <IonButton
                      onClick={
                        () =>
                          {
                            window.open( BriefpapierNoCacheUrl ) ;
                          }
                      }
                    >
                      Herunterladen
                    </IonButton>
                    <IonButton
                      onClick={
                        () =>
                          {
                            setBriefpapierMode( 'upload' ) ;
                          }
                      }
                    >
                      Neue Datei hochladen
                    </IonButton>
                    <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
                  </>
                :
                  ''
            }
            {
              BriefpapierMode === ''
                ?
                  'lade ...'
                :
                  BriefpapierMode === 'show'
                    ?
                      <>
                        <IonImg
                          style = { { border: '1px dotted lightgray' , width: '100%' } }
                          src = { BriefpapierNoCacheUrl }
                        />
                      </>
                    :
                      <>
                        <IonItem>
                          <input type="file" accept="image/png, application/pdf" onChange = { ( e ) => onValueDokumenteSelect( e ) } />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Dateiname</IonLabel>
                          <IonInput disabled  value={ DokumenteDateiname } />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Dateityp</IonLabel>
                          <IonInput disabled  value={ DokumenteDateitypKurz } />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Dateidatum</IonLabel>
                          <IonInput disabled value = { DokumenteDateidatum ? new Date( DokumenteDateidatum ).toLocaleString() : '' } />
                        </IonItem>
                        <IonItem className='item'>
                          <IonLabel position="stacked">Dateigröße</IonLabel>
                          <IonInput disabled  value = { readableFileSize( parseInt( DokumenteDateigroesse , 10 ) ) } />
                        </IonItem>
                        <IonButton
                          onClick =
                            {
                              () =>
                                {
                                  if( DokumenteDateitypMime ) {
                                    sendJSON() ;
                                  }
                                  else {
                                    alert( 'Bitte eine Datei auswählen.' ) ;
                                    return false ;
                                  }                                                                      
                                }
                            }
                        >
                          Speichern
                        </IonButton>
                        {
                          BriefpapierExists
                            ?
                              <IonButton
                                onClick={
                                  () =>
                                    {
                                      setBriefpapierMode( 'show' ) ;
                                    }
                                }
                              >
                                Abbrechen
                              </IonButton>
                            :
                              <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
                        }
                      </>
            }
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

