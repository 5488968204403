/**
 * Messwerte anzeigen
 * 
 *  - Übersicht aller Messwerte
 * 
 */

import { IonButton, IonCardContent, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useContext , useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AppContext } from "../../contexts/app_context";
import { useIotMesspunkte } from "../_CustomHooks";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { defColumnRenderCell, defColumn, exportTableData } from "../_HelperFunctions";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

/** Helpers */
  /** get_local_datetime
   *  - "IonInput type='datetime-local'" akzeptiert als "value" (für Initialisierung) einen
   *    String "yyyy-mm-dd hh:mm:ss" (oder "T" statt " " zwischen Datum und Zeit), Js-"Date"
   *    liefert z.B. "2022-11-04T11:00:16.774Z" für lokales ""2022-11-04 12:00", i.e. UTC-Zeit
   *    statt regionale Zeit - die Ausgabe von "new Date()" muss nicht nur gekürzt, sondern
   *    "manuell" auf Zeitzone angepasst werden, was mit "getTimezoneOffset()"" und Umweg
   *    über Unixtime (via "valueOf()") möglich ist
   * 
   * @param   range_offset_milliseconds   optional: "Zurück-" oder "Vor-" Zeitraum von aktueller Zeit in Millisekunden
  */
  const get_local_datetime = ( range_offset_milliseconds?:number ) => {
    let now = new Date() ;
    let tz_offset_milliseconds = now.getTimezoneOffset() * 60000 ;
      // "getTimezoneOffset()" ist negativ, wir brauchen "positiv" zum Draufrechnen
    tz_offset_milliseconds = tz_offset_milliseconds * -1 ;
    let range_time = range_offset_milliseconds ? range_offset_milliseconds : 0 ;
    let datetime_local = ( new Date( now.valueOf() + tz_offset_milliseconds + range_time ).toISOString() ).substring( 0 , 19 ) ;
    return datetime_local ;
  }

export const Messwerte = () =>
  {
    const {
        appGeraeteId ,
        appIotMessgeraeteAktion ,
        appIotMessgeraeteAktionChange ,
        appIotMesspunkte ,
        appIotMesspunkteFetchanewChange ,
        appIotMesspunkteFromDatetimeChange ,
        appIotMesspunkteUntilDatetimeChange
      } = useContext( AppContext ) ;

    const [ fromDatetimeChoice , setFromDatetimeChoice ] = useState( get_local_datetime( -86400000 ) ) ;
    const [ messpunktChoice , setMesspunktChoice ] = useState( 0 );
    const [pageSize , setPageSize] = useState( 50 );
    const [ untilDatetimeChoice , setUntilDatetimeChoice ] = useState( get_local_datetime() ) ;

    function onValueFromDatetimeChoice( e:any ) { setFromDatetimeChoice( e.target.value ) } ;
    function onValueMesspunktChoice( e:any ) { setMesspunktChoice( e.target.value ) } ;
    function onValueUntilDatetimeChoice( e:any ) { setUntilDatetimeChoice( e.target.value ) } ;

    const showErr = ( strMsg:string ) =>
      <div
        style =
          {
            {
              fontStyle: 'italic' ,
              margin: '1em'
            }
          }
      >
        { strMsg }
      </div>

    useIotMesspunkte() ;

    useEffect(
      () =>
        {
          appIotMesspunkteFromDatetimeChange( fromDatetimeChoice ) ;
          appIotMesspunkteUntilDatetimeChange( untilDatetimeChoice ) ;
          appIotMesspunkteFetchanewChange( true ) ;
        } ,
      []
    ) ;

    return (
      <IonCardContent>
        <>
          <IonItem className='item'>
            <IonLabel position="stacked">
              von
            </IonLabel>
            <IonInput
              type="datetime-local"
              placeholder="bitte auswählen"
              required
              onIonChange = { ( e ) => onValueFromDatetimeChoice( e ) }
              value ={ fromDatetimeChoice }
            />
          </IonItem>
          <IonItem className='item'>
            <IonLabel position="stacked">
              bis
            </IonLabel>
            <IonInput
              type="datetime-local"
              placeholder="bitte auswählen"
              required
              onIonChange = { ( e ) => onValueUntilDatetimeChoice( e ) }
              value ={ untilDatetimeChoice }
            />
          </IonItem>
          <IonButton
            onClick =
              {
                () =>
                  {
                    appIotMesspunkteFromDatetimeChange( fromDatetimeChoice ) ;
                    appIotMesspunkteUntilDatetimeChange( untilDatetimeChoice ) ;
                    appIotMesspunkteFetchanewChange( true ) ;
                  }
              } 
          >
            Grafik aktualisieren
          </IonButton>
          <IonButton
            onClick = {
              () =>
                {
                  if(
                    window.confirm(
                      'Achtung:'
                      + '\nDas Abrufen der Daten vom Messgerät kann bis zu einer Stunde dauern'
                    )
                  )
                    {
                      let arr_iot_geraete_ttn_ids =
                        appIotMesspunkte
                          .map(
                            ( iot_geraet:any ) => iot_geraet.iot_geraete_ttn_id
                          )
                      arr_iot_geraete_ttn_ids
                        .forEach(
                          ( iot_geraet_id:any ) =>
                            {
                              let myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/json");
                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders
                              };
                              fetch(
                                'https://iot-backend.saint-online.de/api/get_datalog.php'
                                  + '?iot_geraet_ttn_id=' + iot_geraet_id
                                ,
                                requestOptions
                              )
                            }
                        )
                    }
                }
            }
          >
            Messwerte vom Messgerät abrufen
          </IonButton>
          <IonButton
            onClick = {
              () => appIotMessgeraeteAktionChange( 'assign' )
            }
          >
            Zuordnung ändern
          </IonButton>
          {
            appIotMessgeraeteAktion !== 'info'
              ?
                <IonButton
                  onClick =
                    {
                      () =>
                        {
                          appIotMessgeraeteAktionChange( 'info' )
                        }
                    } 
                >
                  Abbrechen
                </IonButton>
              :
                null
          }
          {
            appIotMesspunkte.length === 0
              ?
                showErr( 'Für den ausgewählten Zeitraum ist kein Messgerät ermittelbar' )
              :
                <>
                  <IonItem className='item'>
                    <IonLabel position="stacked">
                      Messpunkt ({ appIotMesspunkte.length } verfügbar)
                    </IonLabel>
                    <IonSelect
                      cancelText="Abbrechen"
                      interface = "action-sheet"
                      onIonChange = { ( e ) => onValueMesspunktChoice( e ) }
                      value ={ messpunktChoice }
                    >
                        {
                          appIotMesspunkte
                            .map(
                              ( entry:any , index:number ) =>
                                <IonSelectOption
                                  key = { 'messpunkt_' + index }
                                  value = { index }
                                >
                                  { entry.bezeichnung }
                                </IonSelectOption>
                            )
                        }
                    </IonSelect>
                  </IonItem>
                  {
                    appIotMesspunkte[ messpunktChoice ].messwerte.rohdaten.length > 0
                      ? 
                        <div
                          style =
                           {
                            {
                              height: '600'
                            }
                           }
                        >
                          <Line
                            style = {
                              {
                                marginTop: '1em'
                              }
                            }
                            options =
                              {
                                {
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      display: false ,
                                    },
                                  }
                                }
                              }
                            data =
                              {
                                {
                                  labels: appIotMesspunkte[ messpunktChoice ].messwerte.chart.labels ,
                                  datasets: [
                                    {
                                      label: appIotMesspunkte[ messpunktChoice ].bezeichnung + ' ' + appIotMesspunkte[ messpunktChoice ].einheit ,
                                      data: appIotMesspunkte[ messpunktChoice ].messwerte.chart.data ,
                                      borderColor: 'rgb(255, 99, 132)',
                                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                    },
                                  ],
                                }
                              }
                          />
                          <Box
                            style = {
                              {
                                marginTop: '1em'
                              }
                            }
                            sx={{
                              height: 600,
                              width: 1,
                              "& .super-app-theme--header": {
                                backgroundColor: "rgb(211,211,211)",
                              },
                            }}
                          >
                            <DataGrid
                              disableSelectionOnClick
                              disableVirtualization 
                              getRowId={(row) => row.id}
                              rows={ appIotMesspunkte[ messpunktChoice ].messwerte.rohdaten }
                              columns = {
                                [
                                  defColumn( "datum" , "Datum") ,
                                  defColumn( "uhrzeit" , "Uhrzeit") ,
                                  defColumn( "wert" , "Messwert" ) ,
                                  defColumnRenderCell(
                                    defColumn( 'einheit' , 'Einheit' ) ,
                                    () => appIotMesspunkte[ messpunktChoice ].einheit
                                  ) ,
                                ]
                              }
                              pageSize={ pageSize }
                              rowsPerPageOptions={[50, 100]}
                              sortingOrder={["desc", "asc"]}
                              onPageSizeChange={(newPage) => setPageSize(newPage)}
                              pagination
                              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}   
                            />
                          </Box>
                          <IonButton
                            onClick =
                              {
                                () =>
                                  exportTableData(
                                    appIotMesspunkte[ messpunktChoice ].messwerte.rohdaten
                                      .map(
                                        ( messwert:any ) =>
                                          {
                                            return { Datum: messwert.datum , Uhrzeit: messwert.uhrzeit , Messwert: messwert.wert , Einheit: appIotMesspunkte[ messpunktChoice ].einheit } ;
                                          }
                                      )
                                    ,
                                    'Messwerte ' + appIotMesspunkte[ messpunktChoice ].bezeichnung + ' Gerät ' + appGeraeteId ,
                                    [ 'Messwerte für:' , appIotMesspunkte[ messpunktChoice ].bezeichnung + ' Gerät ' + appGeraeteId , '' ]
                                  )
                              }
                          >
                            Exportieren
                          </IonButton>
                        </div>
                      :
                        showErr( 'Für den ausgewählten Zeitraum liegen keine Messwerte vor' )
                  }
                </>
          }
        </>
      </IonCardContent>
    );
  }
