import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { useContext, useState } from 'react';
import ChartGeraete from '../components/chart_geraete';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const Dashboard: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeName , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = 'c313iuVFHG'
  return (
    <IonPage>
      <PageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie die Daten Ihrer Mitarbeiterinnen und Mitarbeiter pflegen oder neue Mitarbeiterinnen und Mitarbeiter anlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
    <ChartGeraete />
      </IonContent>
    </IonPage>
  );
};
