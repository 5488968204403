/**
 *  _HelperFunctions - Code, der in mehreren Komponenten benötigt wird
 * 
 */

import axios from 'axios';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon , IonImg, IonLoading, IonModal } from '@ionic/react';
import { FunctionComponent , useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';
import { CheckmarkCircleOutline, CloseCircleOutline } from 'react-ionicons';
import { closeOutline , helpOutline } from 'ionicons/icons';
import Tooltip from '@mui/material/Tooltip';

export const get_geraet_by_id = ( arrGeraete:Array<any> , id:string ) =>
  {
    return arrGeraete
      .find(
        ( geraet:any) => geraet.geraeteId === id
      )
  }

export const mm_angebot_ablehnen = async ( aufgaben_id:string ) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
  var requestOptions = {
    method: 'POST',
    headers: myHeaders
  };
  await fetch(
    'https://datamehr-backend.saint-online.de/api/aufgaben/mm_angebot_ablehnen.php?aufgaben_id=' + aufgaben_id ,
    requestOptions
  )
}

export const mm_angebot_annehmen = async ( aufgaben_id:string , usr_name:string ) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
  var requestOptions = {
    method: 'POST',
    headers: myHeaders
  };
  await fetch(
    'https://datamehr-backend.saint-online.de/api/aufgaben/mm_angebot_annehmen.php'
      + '?id=' + aufgaben_id
      + '&auftraggeber=' + usr_name
    ,
    requestOptions
  )
}

type MM_AngebotAnnehmenAblehnenProps = {
  aufgabenId: string ,
  aufgabenAenderung: string
}
export const MedmehrAngebotAnnehmenAblehnen: FunctionComponent<MM_AngebotAnnehmenAblehnenProps> = ( { aufgabenId } ) => {
  const { appAufgabenGeraetFetchanewChange , appAufgabenListeFetchanewChange } = useContext( AppContext ) ;
  const decideAngebot = ( option:any ) =>
    {
      if(
        window.confirm( '\nSind Sie sicher, dass Sie dieses Angebot ' + option + ' wollen?\n')
      )
        {
          if( option === 'annehmen ') {
            // alert( 'Funktion "annehmen" folgt! - AufgabenId: ' + aufgabenId ) ;
            // @ts-ignore
            mm_angebot_annehmen( aufgabenId , JSON.parse( window.localStorage.getItem('token') ).usr_name )
            .then(
              () => 
                {
                  appAufgabenListeFetchanewChange( true) ;
                  appAufgabenGeraetFetchanewChange( true ) ;
                }
            )
          }
          if( option === 'ablehnen ') {
            // alert(
            //   ' \
            //     "ablehnen" für AufgabenId: ' + aufgabenId + ' => TODO: \
            //     \n\t set_aufgabe mit "AufgabenAenderung" setzen auf vorherigen Wert mit vierstelliger Jahreszahl minus 1000 = 1000 Jahre zurück (1000 Jahre sind 1) sehr leicht verschiebbar (generell müssen Jahre anders als Monate etc. nicht erst in Tage umgerechnet werden), 2) erfüllen "abgelaufen", 3) kennzeichnen explizit willentliches Ablehnen gegenüber einfachem Ablaufen, und 4) können ggf. sehr einfach revidiert werden (einfach wieder 1000 draufzählen bzw. händisch "1" am Anfang des vierstelligen Jahres durch "2" ersetzen)) \
            //   '
            // ) ;
            mm_angebot_ablehnen( aufgabenId )
            .then(
              () => 
                {
                  appAufgabenListeFetchanewChange( true) ;
                  appAufgabenGeraetFetchanewChange( true ) ;
                }
            )
          }
        }

    }
  return(
    <>
      <CheckmarkCircleOutline
        title = "Angebot annehmen"
        onClick =
          {
            () => decideAngebot( 'annehmen ') 
          }
      />
      <CloseCircleOutline
        title = "Angebot ablehnen"
        onClick =
          {
            () => decideAngebot( 'ablehnen ') 
          }
      />
    </>
  )
}

type spinnerViewSwitch = {
}
export const SpinnerViewSwitch: FunctionComponent<spinnerViewSwitch> = () => {
  const { appViewSwitch , appViewSwitchChange } = useContext( AppContext ) ;
  return(
    <IonLoading key="viewSwitchSpinner"
    // cssClass = 'einweisungen_matrix'
    isOpen = { appViewSwitch }
    onDidDismiss={() => appViewSwitchChange( false ) }
    message = '  '
    spinner = 'bubbles'
    // message = { appEinweisungsThemenListe.length + ', ' + appMitarbeiterListe.length }
    duration = { 4000 }
    />
  )
}

type ButtonCancelBackProps = {
  label: String ,
  myExec: Function
}
export const ButtonCancelBack: FunctionComponent<ButtonCancelBackProps> = ( { label , myExec } ) => {
  const { appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  return(
    <>
      <IonButton onClick={ () => { appViewSwitchChange( true ) ; if( myExec() !== false ){ history.goBack() ; } } }>{ label }</IonButton>
    </>
  )
}

type buttonInaktiveProps = {
  disabled: boolean ,
  label: String ,
  myExec: Function
}
export const ButtonInaktive: FunctionComponent<buttonInaktiveProps> = ( { disabled , label , myExec } ) => {
  return(
    <>
      <IonButton disabled = { disabled } onClick={ () => { myExec() } }>{ label }</IonButton>
    </>
  )
}

type buttonAddProps = {
  myExec: Function ,
  routeUrl: any ,
  disabled?: boolean
}
export const ButtonAdd: FunctionComponent<buttonAddProps> = ( { myExec , routeUrl , disabled } ) => {
  const { appUserIstBasic , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  if( appUserIstBasic === 'nein' || routeUrl.includes( '/aufgabe/' ) ) {
    return(
      <>
        <IonButton disabled = { disabled } onClick={ () => {appViewSwitchChange( true ) ;  if( myExec() !== false ){ history.push( routeUrl ) ; } } }>Hinzufügen</IonButton>
      </>
    )
  } else {
    return null ;
  }
}

type buttonsSaveActionsProps = {
  disabled?: any ,
  myExec: Function
}
/** optional: "if( myExec() !== false )", i.e. "myExec()"s, die gar nichts zurückmelden, rutschen einfach durch
 *    - beachte: es wird kein "true" gecheckt, sondern explizit geworferner Fehler
*/
export const ButtonsSaveActions: FunctionComponent<buttonsSaveActionsProps> = ( { disabled = false , myExec } ) => {
  const { appGeraeteId , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  // const label = ( appGeraeteId === 'neu' ) ? 'Anlegen' : 'Speichern' ;
  const label = history.location.pathname.includes( '/geraet/' ) && appGeraeteId === 'neu' ? 'Anlegen' : 'Speichern' ;
  return(
    <>
      <IonButton disabled = { disabled } onClick={ () => { appViewSwitchChange( true ) ; myExec() ; } }>{ label }</IonButton>
      <IonButton disabled = { disabled } onClick={ () => { appViewSwitchChange( true ) ; if( myExec() !== false ){ history.goBack() ; } } }>{ label + ' und Schließen' }</IonButton>
    </>
  )
}

type buttonPageHelpProps = {
  page: string ,
  set: Function ,
  state: boolean
}
export const ButtonPageHelp: FunctionComponent<buttonPageHelpProps> = ( { page , set , state } ) => {
  /** Änderung Konzept 8/22: nicht mehr ein Channel pro Seite, sondern ein einzelner Channel + Sidebar mit Liste Videos pro Seite */
    page = 'c3fu2TVFt4' ;
  return(
    <span
      slot="end"
      title = 
        {
          state
            ?
              'Ansicht Hilfe-Videos schließen'
            :
              page
                ?
                  'Hilfe-Videos anzeigen'
                :
                  'Für diese Seite existiert noch kein Hilfe-Video'
        }
    >
      <IonButton
        disabled = { page ? false : true }
        onClick=
          {
            () =>
              {
                if( ! state ) {
                  window.open(
                    'https://screencast-o-matic.com/channels/' + page + '/show-sidebar' ,
                    'datamehrPageHelpScreencasts'
                  ) ;
                }
                set( state ? false : true ) ;
              }
          }
      >
        <IonIcon
          ios={ state ? closeOutline : helpOutline } 
          md={ state ? closeOutline : helpOutline }
        />
      </IonButton>
    </span>
  )
}

type pageHelpProps = {
  page: string ,
  state: boolean ,
  set: Function ,
  children: any
}

export const PageHelp: FunctionComponent<pageHelpProps> = ( { page, state , set } ) => {
  /** Änderung Konzept 8/22: nicht mehr ein Channel pro Seite, sondern ein einzelner Channel + Sidebar mit Liste Videos pro Seite */
    page = 'c3fu2TVFt4' ;
  return(
    <IonModal css-class = { 'ion' } isOpen = { state } animated = { false } backdrop-dismiss = { true } can-dismiss = { true } onDidDismiss = { () => set( false ) } >
      {
        page
          ?
            <IonCard>              
              <IonCardContent>
                <p>
                  Die Hilfe-Videos wurden in einem separaten Fenster (ggf. Tab) geöffnet, so dass Sie jederzeit zwischen Hilfe-Video und datamehr wechseln können.
                </p>
                <p>
                  Das passende Hilfe-Video zu Ihrer Frage können Sie im separaten Fenster in der Auflistung rechts neben dem Video-Player auswählen.
                </p>
                <IonButton
                  onClick=
                    {
                      () =>
                        {
                          window.open(
                            'https://screencast-o-matic.com/channels/' + page + '/show-sidebar' ,
                            'datamehrPageHelpScreencasts'
                          ) ;
                        }
                    }
                >
                  Zu Hilfe-Videos in separatem Fenster / Tab wechseln
                </IonButton>
                <IonButton
                  onClick = { () => set( false ) }
                >
                  Schließen
                </IonButton>
              </IonCardContent>
            </IonCard>
          :
            <p
              style = {
                {
                  fontStyle: 'italic' ,
                  margin: 'auto'
                }
              }
            >
              Zu dieser Seite ist derzeit keine Hilfe verfügbar.
            </p>
      }
    </IonModal>
  )
}

type FieldHelpCEProps = {
  state: boolean ,
  set: Function
}

export const FieldHelpCE: FunctionComponent<FieldHelpCEProps> = ( { state , set } ) => {
  return(
    <IonModal css-class = { 'ion' } isOpen = { state } animated = { false } backdrop-dismiss = { true } can-dismiss = { true } onDidDismiss = { () => set( false ) } >
      {
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Beispiele für Typenschilder mit CE-Angaben
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonImg
              key="img_3640"
              alt="... Bild wird geladen"
              src="./assets/help/ce/img_3646.jpg"
              style={ { float: 'left' , margin: '1%' , width: '48%' } }
            />
            <IonImg
              key="img_3641"
              alt="... Bild wird geladen"
              src="./assets/help/ce/img_3644.jpg"
              style={ { float: 'left' , margin: '1%' , width: '48%' } }
            />
          </IonCardContent>
          <IonButton
            onClick = { () => set( false ) }
            style={ { clear: 'both' , margin: '2em' } }
          >
            Schließen
          </IonButton>
        </IonCard>
      }
    </IonModal>
  )
}

//#region defColumnX DataGrid-Spalten
  /** Vereinfachte Definition von DataGrid-Spalten
   *    - defColumn()             - "einfache" Spalten ohne renderCell etc.
   *    - defColumnRenderCell()   - Spalten mit Auswertungen / Anpassungen via renderCell
   *    - defColumn_DATAFIELD     - fixe (und komplexe) Spalten-Definition für identische
   *                                Verwendung selbe Daten an verschiedenen Stellen
   *                                - TODO => erst implementierbar mit umfassendem useContext,
   *                                  da Daten-Bezüge involviert sind
   *                                  => Bsp.-Verwendung wäre für "EinweisungenMitarbeiterId",
   *                                  was in mehreren Komponenten an mehreren Stellen verwendet wird
  */
    //#region defColumn()
      /**
       * - für Spalten ohne Besonderheiten wie Daumen-Auswertung
       * 
       * @param field       - Zu verwendendes Feld / Spalte in übergebenen Daten
       * @param headerName  - Anzuzeigender Spaltentitel
       * @param minWidth    - optional: minimale Breite in px (nur Zahl)
       *                      - "0" = Feld verstecken (= "hide: true")
       *                      - Default: 100 (wie implizit bei DataGrid)
       * @param flex        - optional: Flex-Anteil (Zahl)
       *                      - Default: 1
       * @param maxWidth    - optional: maximale Breite in px (nur Zahl)
       *                      - Default: 6000
       * @returns Spalten-Definition für DataGrid
       */
        export const defColumn = ( field:string , headerName:string , minWidth=100 , flex=1 , maxWidth=6000 ) => {
          let hide = minWidth === 0 ? true : false ;
          return {
            description: headerName ,
            field: field ,
            flex: flex ,
            headerName: headerName ,
            headerClassName: "super-app-theme--header" ,
            hide: hide ,
            maxWidth: maxWidth ,
            minWidth: minWidth ,
            renderCell: ( params:any ) => <Tooltip title = { '' + params.row[ field ] } ><div className = "MuiDataGrid-cellContent" >{ params.row[ field ] }</div></Tooltip>
          }
        }
      //#endregion defColumn()
    //#region defColumnRenderCell()
      /**
       * - Konzept: defColumn-Output um "renderCell" ergänzen
       * 
       * @param defColumn 	- von defColumn erzeugtes Objekt
       *                      - ! für TypeScript "any", als "Object" Typen-Konflikte ohne Ende
       *                          (NB: "object" kleingeschrieben hat nichts mit Objekten zu tun)
       *                      - => defColumn-Aufruf einsetzen
       * @param renderCell  - Function für renderCell
       * @returns Spalten-Definition für DataGrid inkl. renderCell
       */
        export const defColumnRenderCell = ( defColumn:any , renderCellFunc:Function ) => {
          defColumn.renderCell = renderCellFunc ;
          return defColumn ;
        }
      //#endregion defColumnRenderCell()
    //#endregion defColumnX DataGrid-Spalten

/**
 *  getDataByApi - Datenbezug von angegebenem Endpoint
 * 
 * @param apiUrl        - z.B. "https://datamehr-backend.saint-online.de/api/" 
 * @param apiCall       - z.B. "lieferanten/get_lieferanten.php?betriebe_id=" 
 * @param setResponse   - Funktion zum Setzen der Fetch-Status-Meldung (typischerweise useState-Funktion)
 * @param setData       - Funktion zum Zwischenspeichern der gefetchten Daten (typischerweise useState-Funktion) 
 * @param sortData      - optional: Funktion zum Sortieren von response.data (in Array mit Objekten)
 *                        - NB: Komponenten wie DataGrid sortieren selbst
 *                        - ! ideal wenn tatsächlich alle Konsumenten die selbe Sortierung haben sollen
 */
 export const getDataByApi = async ( apiUrl:string , apiCall:string , setResponse:Function , setData:Function , sortData:any = '' ) => {
  setResponse( 100 ) ;
  axios(
    {
      method: 'get',
      url: apiUrl + apiCall ,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${ window.localStorage.getItem( 'jwt' ) }`,
      },
    }
  )
  .then( ( response ) =>
    { 
      if( response.data.length === 0 ) {
        setData( [] )
        setResponse( 204 ) ;
      } else {
        sortData === ''
          ?
            setData( response.data )
          :
            setData( sortData( response.data ) )
        setResponse( response.status ) ;
      }
    }
  )
  .catch( error =>
    { 
      setResponse( error.response.status ) ;
    }
  ) ;
}

/**
 *  getData - Datenbezug von datamehr-Endpoint
 * 
 * @param apiCall       - z.B. "lieferanten/get_lieferanten.php?betriebe_id=" 
 * @param setResponse   - Funktion zum Setzen der Fetch-Status-Meldung (typischerweise useState-Funktion)
 * @param setData       - Funktion zum Zwischenspeichern der gefetchten Daten (typischerweise useState-Funktion) 
 * @param sortData      - optional: Funktion zum Sortieren von response.data (in Array mit Objekten)
 *                        - NB: Komponenten wie DataGrid sortieren selbst
 *                        - ! ideal wenn tatsächlich alle Konsumenten die selbe Sortierung haben sollen
 */
 export const getData = ( apiCall:string , setResponse:Function , setData:Function , sortData:any = '' ) => {
  getDataByApi( 'https://datamehr-backend.saint-online.de/api/' , apiCall , setResponse , setData , sortData ) ;
 }

/**
 *  getByWindowOpen - Anzeige / Download via window.open()
 * 
 *  - an manchen Stellen geht es nicht ohne window.open(), aber mit window.open() kann kein Auth-Token
 *    mitgeschickt werden
 *    => zwei aufeinanderfolgende Abrufe:
 *      1. Fetch mit Auth-Header für eine Einmal-ID, mit der für ein paar Sekunden ohne Auth abgerufen werden kann
 *      2. window.open mit Einmal-ID
 * 
 * @param apiEndpoint       - Endpoint **ohne ID**,  z.B. "medmehr/print_angebot.php?id="
 * @param resourceId        - ID der gewünschten Resource
 * 
 */
 export const getByWindowOpen = ( apiCall:string , resourceId:string ) => {
  axios(
    {
      method: 'get',
      url: 'https://datamehr-backend.saint-online.de/api/' + apiCall + resourceId  ,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${ window.localStorage.getItem( 'jwt' ) }`,
      },
    }
  )
  .then( ( response ) =>
    {
      window.open( 'https://datamehr-backend.saint-online.de/api/' + apiCall + response.data ) ;
    }
  )
  .catch( error =>
    { 
      alert( 'Fehler bei Datenabruf: ' + error ) ;
    }
  ) ;
}

/**
 *  getLoadErr - In-situ-Information über Fehler bei Datenbezug
 * 
 * @param errno   - HTTP-Status Code-Nummer
 * @returns In-situ-Hinweis
 */
export const getLoadErr = ( errno:number ) => {
  if( errno === 401 ) {
    return 'Autorisierungsfehler - Möglicherweise müssen Sie sich neu anmelden' ;
  }
  else {
    return 'Technisches Problem - Versuchen Sie die Aktion zu wiederholen, evtl. müssen Sie sich kurz aus- und wieder einloggen, damit zwischenzeitliche Daten-Aktualisierungen wirksam werden.' ;
  }
}

export const readableFileSize = ( bytesize:number ) => {
  if( bytesize > 0 ) { 
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while(bytesize >= 1024) {
        bytesize /= 1024;
        ++i;
    }
    return bytesize.toFixed(1) + ' ' + units[i];
  }
  else {
    return '' 
  }
}

  // CSV: https://stackoverflow.com/a/22792982
export const exportTableData = ( tableArray:Array<any> , tablePurpose:string , Titelzeile?:Array<any> ) => {
  if( tableArray.length > 0 ) {
    /** CSV initialisieren */
      let exportCSV = '' ;  
      if( Titelzeile ) {
        exportCSV += Titelzeile.join( ';' ) + '\n' ;
      }
    /** Zeilennummer für Feldnamen */
      let fieldNamesLine = 0 ;
      if( Titelzeile ) {
        fieldNamesLine = 1 ;
      }
    /** Table-Column-Header aus erstem Eintrag ableiten als Keys für Auswertung Table-Rows */
      let cols = Object.keys( tableArray[ fieldNamesLine ] ) ;
    /** Header-Zeile */
      exportCSV += cols.join( ';' ) + '\n' ;
    /** Zeilen auswerten */
      tableArray.forEach(
        ( row ) => {
          exportCSV += cols.map( col => row[ col ] ).join( ';' ) + '\n' ;
        }
      ) ;
    /** POSIX-Leerzeile am Ende */
      exportCSV += '\n' ;
        /* '\uFEFF' = UTF8-BOM (erforderlich für Excel), vgl. https://stackoverflow.com/a/17879474 */
    /** Datei speichern */
      saveDataAsFile(
        new Blob(
          [ '\uFEFF' + exportCSV ] ,
          { type: 'text/csv; charset=utf-8' }
        ) ,
        tablePurpose ,
        '.csv'
      ) ;
  }
}

export const saveDataAsFile = ( contentBlob:Blob , contentHint:string, fileExtension:string ) => {
    /** Download
     *    - für Browser / Web ("if navigator") aktuell etwas eleganter, für App (nativ iOS / Android) geht evtl. gerade noch so Notlösung
    */
     const url = window.URL.createObjectURL( contentBlob ) ;
     if( navigator) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download' ,
        'datamehr '
          + contentHint
          + ' '
          + new Date().toLocaleDateString()
          + ' '
          + new Date().toLocaleTimeString().substring( 0 , 5).replace( ':' , '_' )
          + fileExtension
      ) ;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      let dlwindow:any = window.open( url ) ;
      dlwindow.document.title = 'Export-Datei wird heruntergeladen ...' ;
    }
    window.URL.revokeObjectURL( url ) ;
}


//#region medmehr-Backend
  export const getMedmehrHostedAngebot = ( angebotId:any ) => {
    getByWindowOpen( 'https://datamehr-backend.saint-online.de/api/aufgaben/print_vorlage.php?aufgaben_id=' , angebotId ) ;
  }
  export const getMedmehrHostedAufgabe = ( aufgabenId:any ) => {
    window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/print_vorlage.php?aufgaben_id=' + aufgabenId )
  }
    //#endregion medmehr-Backend

//#region Data-Export
  export const getDokumentDatei = ( dokumentId:string ) => {
    // const api = axios.create({
    //   responseType: 'blob' ,
    //   baseURL: 'https://datamehr-backend.saint-online.de/api/dokumente/get_dokument_datei.php?dokumente_id=' + dokumentId ,
    //   headers: {
    //     Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
    //   },
    // });
    // api.get( "" )
    //  .then(
    //     ( response ) => {
    //         const url = window.URL.createObjectURL( new Blob( [ response.data ] ) ) ;
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute( 'download' , response.statusText );
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         window.URL.revokeObjectURL( url ) ;
    //       }
    //   )
    // let dlwindow:any = window.open( '' , '' , 'top=300,width=300,height=100' ) ;
    // dlwindow.document.title = 'Dokument wird heruntergeladen ...' ;
    // dlwindow.location.href = 'https://datamehr-backend.saint-online.de/api/dokumente/get_dokument_datei-tmp-tst.php' ;
    let dlwindow:any = window.open( 'https://datamehr-backend.saint-online.de/api/dokumente/get_dokument_datei.php?dokumente_id=' + dokumentId ) ;
    dlwindow.document.title = 'Dokument wird heruntergeladen ...' ;
  }
    //#endregion Data-Export

export const getDokumentDateiBlobUrl = ( dokumentId:string ) => {
  const api = axios.create({
    responseType: 'blob' ,
    baseURL: 'https://datamehr-backend.saint-online.de/api/dokumente/get_dokument_datei.php?dokumente_id=' + dokumentId ,
    headers: {
      Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
    },
  });
  api.get( "" )
   .then(
      ( response ) => {
          alert( 'horch: ' + window.URL.createObjectURL( new Blob( [ response.data ] ) ) ) ;
          return window.URL.createObjectURL( new Blob( [ response.data ] ) ) ;
        }
    )
}
  
export const einweisungstypKeyToTitle = ( key:string ) => {
  if( key === 'befaehigung') { return 'Befähigung durch Hersteller oder befugte Person' }
  if( key === 'beauftragung') { return 'Beauftragung durch Hersteller oder befugte Person' }
  if( key === 'anwender') { return 'Anwendereinweisung' }
}
