/**
 * App - Mutter-Komponente für App-Inhalt
 */

import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Menu from './components/Menu';
import * as Pages from './pages/index'
import './styles/index'
import { AppContext } from './contexts/app_context' ;
import  { mdTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/md.transition.js'
import { handle_browser_reload_close } from './functions/handle_browser_reload_close' ;
import { arrRoutesData } from './settings/arrRoutesData';

//#region Ionic React-Config
  /** s. https://ionicframework.com/docs/react/config */
    setupIonicReact(
      {
        /** mode: Styles / Effekte an bestimmter Plattform orientieren
         *  - s. https://ionicframework.com/docs/theming/platform-styles
         *  - "md"-Mode hat merkwürdige und platzraubende Checkboxen bei Select-Dropdowns
         *    - s. https://ionicframework.com/docs/api/select Bsp.-Handy rechts im Android-Mode bzw.
         *      https://ionicframework.com/docs/demos/api/select/index.html?ionic:mode=md
         *  - "ios" hat nervigen Page-Transition-Effekt, der aber separat via "navAnimation" überschrieben
         *    werden kann (s.u.)
         */
          mode: 'ios' ,
        /** navAnimation: Styles / Effekte beim Wechsel zwischen Seiten
         *  - s. https://ionicframework.com/docs/react/config#config-options
         *  - mit "mode: ios" (s.o.) für Root-Seite ("/") langsames und großräumiges "von rechtem Rand Hineingleiten"
         *    bei Seitenanzeige (wie Swipen von rechts)
         *      - Effekt interessanterweise auch für Gerät Einzel-Ansicht, offenbar wenn "/geraet/:name/:betriebeId"
         *        wg. fehlendem ":betriebeId" zu "/geraet/:name/" wird, also auch auf "/" endet
         *  - mit "mode: md" (Android) dezenteres "von unterem Rand nach oben Hineingleiten", andere Styles in
         *    md-Mode schwierig (s.o.)
         *  - Überschreiben "navAnimation" erfordert eigene Handling-Funktion
         *    - formal mögliche "leere" Funktion "() => { return }" führt zu Console-Errors betrefffs fehlender
         *      "playTransition" und "onFinish"
         *    - Ionic hat importierbare Modulef für Transitions
         *      - s. https://github.com/ionic-team/ionic-framework/blob/main/core/src/utils/transition/
         *      - lokal: node_modules\@ionic\core\dist\collection\utils\transition\
         *      - vgl. https://forum.ionicframework.com/t/page-transition-direction-in-ionic-4/148518/8
         *      - Pferdefuß: nicht ganz offizielles Vorgehen / evtl. Ionic-Versions-abhängig
         */
          navAnimation: mdTransitionAnimation
      }
    );
//#endregion Ionic React-Config

handle_browser_reload_close( 'datamehr' ) ;

const App = () => {
  /**
   * AppContext initialisieren
   *  - ! "*ListeFetchanew" initial "true" aus technischen Gründen ("true" triggert Datenladen auch für App-Start)
   *    => TODO demnächst umbenennen nach "*ListeFetchanew"
   */
    /**
     * "Interne" Context-Props & Setters
     */
     const [ appContextArticleDokumente , setAppContextArticleDokumente ] = useState( [] ) ;
     const [ appContextArticleDokumenteFetchanew , setAppContextArticleDokumenteFetchanew ] = useState( true ) ;
     const [ appContextArticleDokumenteFetchstate , setAppContextArticleDokumenteFetchstate ] = useState( 0 ) ;
     const [ appContextArticleDokumentId , setAppContextArticleDokumentId ] = useState( 'neu' ) ;
     const [ appContextArticleId , setAppContextArticleId ] = useState( 'neu' ) ;
     const [ appContextArticleGefahrstoffschulungenFetchanew , setAppContextArticleGefahrstoffschulungenFetchanew ] = useState( true ) ;
     const [ appContextArticleGefahrstoffschulungenFetchstate , setAppContextArticleGefahrstoffschulungenFetchstate ] = useState( 0 ) ;

     const [ appContextArticleInternGefahrstoffschulungenFetchanew , setAppContextArticleInternGefahrstoffschulungenFetchanew ] = useState( true ) ;
     const [ appContextArticleInternGefahrstoffschulungenFetchstate , setAppContextArticleInternGefahrstoffschulungenFetchstate ] = useState( 0 ) ;
     
     const [ appContextArticlesGefahrstoffschulungenThemen , setAppContextArticlesGefahrstoffschulungenThemen ] = useState( [] ) ;
     const [ appContextArticlesGefahrstoffschulungenThemenFetchanew , setAppContextArticlesGefahrstoffschulungenThemenFetchanew ] = useState( true ) ;
     const [ appContextArticlesGefahrstoffschulungenThemenFetchstate , setAppContextArticlesGefahrstoffschulungenThemenFetchstate ] = useState( 0 ) ;
     
     const [ appContextArticleInternId , setAppContextArticleInternId ] = useState( 'neu' ) ;
     const [ appContextArticleInternDokumente , setAppContextArticleInternDokumente ] = useState( [] ) ;
     const [ appContextArticleInternDokumenteFetchanew , setAppContextArticleInternDokumenteFetchanew ] = useState( true ) ;
     const [ appContextArticleInternDokumenteFetchstate , setAppContextArticleInternDokumenteFetchstate ] = useState( 0 ) ;
     const [ appContextArticleInternDokumentId , setAppContextArticleInternDokumentId ] = useState( 'neu' ) ;
     const [ appContextArticleInternLieferant , setAppContextArticleInternLieferant  ] = useState( '' ) ;
     const [ appContextArticleInternLieferantAdded , setAppContextArticleInternLieferantAdded ] = useState( '' ) ;
     const [ appContextArticlesFetchanew , setAppContextArticlesFetchanew ] = useState( true ) ;
      const [ appContextArticlesFetchstate , setAppContextArticlesFetchstate ] = useState( 0 ) ;
      const [ appContextArticles , setAppContextArticles ] = useState( [] ) ;
     const [ appContextArticlesInternFetchanew , setAppContextArticlesInternFetchanew ] = useState( true ) ;
      const [ appContextArticlesInternFetchstate , setAppContextArticlesInternFetchstate ] = useState( 0 ) ;
      const [ appContextArticlesIntern , setAppContextArticlesIntern ] = useState( [] ) ;

      const [ appContextAufgabenId , setAppContextAufgabenId ] = useState( 'neu' ) ;

      const [ appContextAufgabeFetchanew , setAppContextAufgabeFetchanew ] = useState( true ) ;

      const [ appContextAufgabeServicepartner , setAppContextAufgabeServicepartner  ] = useState( '' ) ;
      const [ appContextAufgabeServicepartnerAdded , setAppContextAufgabeServicepartnerAdded ] = useState( '' ) ;
      const [ appContextAufgabenGeraetFetchanew , setAppContextAufgabenGeraetFetchanew ] = useState( true ) ;
      const [ appContextAufgabenListeFetchanew , setAppContextAufgabenListeFetchanew ] = useState( true ) ;

      const [ appContextBetriebeListe , setAppContextBetriebeListe ] = useState( {} ) ;
      const [ appContextBetriebeId , setAppContextBetriebeId ] = useState( '' ) ;
      const [ appContextBetriebeName , setAppContextBetriebeName ] = useState( '' ) ;
      const [ appContextEinweisungFetchanew , setAppContextEinweisungFetchanew ] = useState( true ) ;
      const [ appContextEinweisungenId , setAppContextEinweisungenId ] = useState( 'neu' ) ;
      const [ appContextEinweisungenGeraetFetchanew , setAppContextEinweisungenGeraetFetchanew ] = useState( true ) ;
      const [ appContextEinweisungenGeraetFetchstate , setAppContextEinweisungenGeraetFetchstate ] = useState( 0 ) ;
      const [ appContextEinweisungenListeData , setAppContextEinweisungenListeData ] = useState( [] ) ;
      const [ appContextEinweisungenListeFetchanew , setAppContextEinweisungenListeFetchanew ] = useState( true ) ;
      const [ appContextEinweisungenListeFetchstate , setAppContextEinweisungenListeFetchstate ] = useState( 0 ) ;
      const [ appContextEinweisungNeuTeilnehmer , setAppContextEinweisungNeuTeilnehmer ] = useState( '' ) ;
      const [ appContextEinweisungNeuThema , setAppContextEinweisungNeuThema ] = useState( '' ) ;

      const [ appContextError , setAppContextError ] = useState( '' ) ;

      const [ appContextGefahrstoffschulungen , setAppContextGefahrstoffschulungen ] = useState( [] ) ;
      const [ appContextGefahrstoffschulungenFetchanew , setAppContextGefahrstoffschulungenFetchanew ] = useState( true ) ;
      const [ appContextGefahrstoffschulungenFetchstate , setAppContextGefahrstoffschulungenFetchstate ] = useState( 0 ) ;
      const [ appContextGefahrstoffschulungFetchanew , setAppContextGefahrstoffschulungFetchanew ] = useState( true ) ;
      const [ appContextGefahrstoffschulungId , setAppContextGefahrstoffschulungId ] = useState( 'neu' ) ;
      
      const [ appContextGefahrstoffschulungenDokumente , setAppContextGefahrstoffschulungenDokumente ] = useState( [] ) ;
      const [ appContextGefahrstoffschulungenDokumenteFetchanew , setAppContextGefahrstoffschulungenDokumenteFetchanew ] = useState( true ) ;
      const [ appContextGefahrstoffschulungenDokumenteFetchstate , setAppContextGefahrstoffschulungenDokumenteFetchstate ] = useState( 0 ) ;
      const [ appContextGefahrstoffschulungenDokumentId , setAppContextGefahrstoffschulungenDokumentId ] = useState( 'neu' ) ;
      
      const [ appContextGefahrstoffschulungenMatrixBuildNew , setAppContextGefahrstoffschulungenMatrixBuildNew ] = useState( true ) ;
      
      const [ appContextGefahrstoffschulungenThemaAdded , setAppContextGefahrstoffschulungenThemaAdded ] = useState( '' ) ;
      const [ appContextGefahrstoffschulungenThemaDokumente , setAppContextGefahrstoffschulungenThemaDokumente ] = useState( [] ) ;
      const [ appContextGefahrstoffschulungenThemaDokumenteFetchstate , setAppContextGefahrstoffschulungenThemaDokumenteFetchstate ] = useState( 0 ) ;
      const [ appContextGefahrstoffschulungenThemaDokumenteFetchanew , setAppContextGefahrstoffschulungenThemaDokumenteFetchanew ] = useState( true ) ;
      const [ appContextGefahrstoffschulungenThemaDokumentId , setAppContextGefahrstoffschulungenThemaDokumentId ] = useState( 'neu' ) ;
      const [ appContextGefahrstoffschulungenThemaId , setAppContextGefahrstoffschulungenThemaId ] = useState( 'neu' ) ;

      const [ appContextGefahrstoffschulungenThemen , setAppContextGefahrstoffschulungenThemen ] = useState( [] ) ;
      const [ appContextGefahrstoffschulungenThemenFetchanew , setAppContextGefahrstoffschulungenThemenFetchanew ] = useState( true ) ;
      const [ appContextGefahrstoffschulungenThemenFetchstate , setAppContextGefahrstoffschulungenThemenFetchstate ] = useState( 0 ) ;
      
      const [ appContextGefahrstoffschulungNeuTeilnehmer , setAppContextGefahrstoffschulungNeuTeilnehmer ] = useState( '' ) ;
      const [ appContextGefahrstoffschulungNeuThema , setAppContextGefahrstoffschulungNeuThema ] = useState( '' ) ;
      
      const [ appContextGeraeteListeFetchanew , setAppContextGeraeteListeFetchanew ] = useState( true ) ;
      const [ appContextGeraetSchulung , setAppContextGeraetSchulung  ] = useState( '' ) ;
      const [ appContextGeraetService , setAppContextGeraetService  ] = useState( '' ) ;
      const [ appContextGeraetServiceAdded , setAppContextGeraetServiceAdded ] = useState( '' ) ;
      const [ appContextGeraetTyp , setAppContextGeraetTyp  ] = useState( '' ) ;
      const [ appContextGeraeteId , setAppContextGeraeteId ] = useState( 'neu' ) ;
      const [ appContextGeraetegruppenGiven , setAppContextGeraetegruppenGiven ] = useState( [] ) ;
      const [ appContextGeraetegruppenGivenFetchanew , setAppContextGeraetegruppenGivenFetchanew ] = useState( true ) ;
      const [ appContextGeraetegruppenGivenFetchstate , setAppContextGeraetegruppenGivenFetchstate ] = useState( 0 ) ;
      const [ appContextGeraetegruppenPossible , setAppContextGeraetegruppenPossible ] = useState( [] ) ;
      const [ appContextGeraetegruppenPossibleFetchanew , setAppContextGeraetegruppenPossibleFetchanew ] = useState( true ) ;
      const [ appContextGeraetegruppenPossibleFetchstate , setAppContextGeraetegruppenPossibleFetchstate ] = useState( 0 ) ;
      const [ appContextGeraeteListeData , setAppContextGeraeteListeData ] = useState( [] ) ;
      const [ appContextGeraeteListeFetchstate , setAppContextGeraeteListeFetchstate ] = useState( 0 ) ;
      const [ appContextGeraeteklassenListe , setAppContextGeraeteklassenListe ] = useState( [] ) ;
      const [ appContextGeraeteklassenListeFetchanew , setAppContextGeraeteklassenListeFetchanew ] = useState( true ) ;
      const [ appContextGeraeteklassenListeFetchstate , setAppContextGeraeteklassenListeFetchstate ] = useState( 0 ) ;
      const [ appContextGeraeteklassenId , setAppContextGeraeteklassenId ] = useState( 'neu' ) ;

      const [ appContextIotGeraete , setAppContextIotGeraete ] = useState( [] ) ;
      const [ appContextIotGeraeteFetchanew , setAppContextIotGeraeteFetchanew ] = useState( true ) ;
      const [ appContextIotGeraeteFetchstate , setAppContextIotGeraeteFetchstate ] = useState( 0 ) ;
      const [ appContextIotMessgeraeteAktion , setAppContextIotMessgeraeteAktion ] = useState( 'info' ) ;
      const [ appContextIotMesspunkte , setAppContextIotMesspunkte ] = useState( [] ) ;
      const [ appContextIotMesspunkteFetchanew , setAppContextIotMesspunkteFetchanew ] = useState( true ) ;
      const [ appContextIotMesspunkteFetchstate , setAppContextIotMesspunkteFetchstate ] = useState( 0 ) ;
      const [ appContextIotMesspunkteFromDatetime , setAppContextIotMesspunkteFromDatetime ] = useState( '' ) ;
      const [ appContextIotMesspunkteUntilDatetime , setAppContextIotMesspunkteUntilDatetime ] = useState( '' ) ;

      const [ appContextKundeId , setAppContextKundeId ] = useState( 0 ) ;
      const [ appContextKundeName , setAppContextKundeName ] = useState( '' ) ;
      const [ appContextKundeDatamehrMail , setAppContextKundeDatamehrMail ] = useState( '' ) ;

      const [ appContextMitarbeiterListeFetchanew , setAppContextMitarbeiterListeFetchanew ] = useState( true ) ;
      const [ appContextMitarbeiterListeFetchstate , setAppContextMitarbeiterListeFetchstate ] = useState( 0 ) ;
      const [ appContextMitarbeiterListe , setAppContextMitarbeiterListe ] = useState( [] ) ;
      const [ appContextMitarbeiterId , setAppContextMitarbeiterId ] = useState( 'neu' ) ;
      const [ appContextLieferantId , setAppContextLieferantId ] = useState( 'neu' ) ;
      const [ appContextLieferantenListe , setAppContextLieferantenListe ] = useState( [] ) ;
      const [ appContextLieferantenListeFetchanew , setAppContextLieferantenListeFetchanew ] = useState( true ) ;
      const [ appContextLieferantenListeFetchstate , setAppContextLieferantenListeFetchstate ] = useState( 0 ) ;
      const [ appContextEinweisungsThemenFetchanew , setAppContextEinweisungsThemenFetchanew ] = useState( true ) ;
      const [ appContextEinweisungsThemenFetchstate , setAppContextEinweisungsThemenFetchstate ] = useState( 0 ) ;
      const [ appContextEinweisungsThemenGeraete , setAppContextEinweisungsThemenGeraete ] = useState( [] ) ;
      const [ appContextEinweisungsThemenGeraeteFetchanew , setAppContextEinweisungsThemenGeraeteFetchanew ] = useState( true ) ;
      const [ appContextEinweisungsThemenGeraeteFetchstate , setAppContextEinweisungsThemenGeraeteFetchstate ] = useState( 0 ) ;
      const [ appContextEinweisungsThemenListe , setAppContextEinweisungsThemenListe ] = useState( [] ) ;
      //#region Dokumente zu einzelnem Einweisungsthema
        const [ appContextEinweisungsthemaDokumentId , setAppContextEinweisungsthemaDokumentId ] = useState( 'neu' ) ;
        const [ appContextEinweisungsthemaDokumente , setAppContextEinweisungsthemaDokumente ] = useState( [] ) ;
        const [ appContextEinweisungsthemaDokumenteFetchstate , setAppContextEinweisungsthemaDokumenteFetchstate ] = useState( 0 ) ;
        const [ appContextEinweisungsthemaDokumenteFetchanew , setAppContextEinweisungsthemaDokumenteFetchanew ] = useState( true ) ;
          //#endregion Dokumente zu einzelnem Einweisungsthema

      const [ appContextDokumenteAufgabeId , setAppContextDokumenteAufgabeId ] = useState( 'neu' ) ;
      const [ appContextDokumenteAufgabe , setAppContextDokumenteAufgabe ] = useState( [] ) ;
      const [ appContextDokumenteAufgabeFetchanew , setAppContextDokumenteAufgabeFetchanew ] = useState( true ) ;
      const [ appContextDokumenteAufgabeFetchstate , setAppContextDokumenteAufgabeFetchstate ] = useState( 0 ) ;

      const [ appContextDokumenteEinweisungId , setAppContextDokumenteEinweisungId ] = useState( 'neu' ) ;
      const [ appContextDokumenteEinweisung , setAppContextDokumenteEinweisung ] = useState( [] ) ;
      const [ appContextDokumenteEinweisungFetchanew , setAppContextDokumenteEinweisungFetchanew ] = useState( true ) ;
      const [ appContextDokumenteEinweisungFetchstate , setAppContextDokumenteEinweisungFetchstate ] = useState( 0 ) ;

      const [ appContextDokumenteGeraetId , setAppContextDokumenteGeraetId ] = useState( 'neu' ) ;
      const [ appContextDokumenteGeraet , setAppContextDokumenteGeraet ] = useState( [] ) ;
      const [ appContextDokumenteGeraetFetchanew , setAppContextDokumenteGeraetFetchanew ] = useState( true ) ;
      const [ appContextDokumenteGeraetFetchstate , setAppContextDokumenteGeraetFetchstate ] = useState( 0 ) ;

      const [ appContextSchulungAdded , setAppContextSchulungAdded ] = useState( '' ) ;
      const [ appContextSchulungId , setAppContextSchulungId ] = useState( 'neu' ) ;
      const [ appContextGeraetetypAdded , setAppContextGeraetetypAdded ] = useState( '' ) ;
      const [ appContextGeraetHasIotMessung , setAppContextGeraetHasIotMessung ] = useState( 0 ) ;
      const [ appContextUserIstBasic , setAppContextUserIstBasic ] = useState( '' ) ;
      const [ appContextUserIstEinweiser , setAppContextUserIstEinweiser ] = useState( '' ) ;
      const [ appContextEinweisungenMatrixBuildNew , setAppContextEinweisungenMatrixBuildNew ] = useState( true ) ;

      //#region Schulungen / Unterweisungen
        /** aus historischen Gründen in DB etc. "schulung" für Einweisungsthema und hier "unterweisung" */
        //#region Themen
          const [ appContextUnterweisungsthemaId , setAppContextUnterweisungsthemaId ] = useState( 'neu' ) ;
          const [ appContextUnterweisungsthemaAdded , setAppContextUnterweisungsthemaAdded ] = useState( '' ) ;
          const [ appContextUnterweisungsthemen , setAppContextUnterweisungsthemen ] = useState( [] ) ;
          const [ appContextUnterweisungsthemenFetchstate , setAppContextUnterweisungsthemenFetchstate ] = useState( 0 ) ;
          const [ appContextUnterweisungsthemenFetchanew , setAppContextUnterweisungsthemenFetchanew ] = useState( true ) ;
            //#endregion Themen
        //#region Dokumente zu einzelnem Schulungs- / Unterweisungsthema
          const [ appContextUnterweisungsthemaDokumentId , setAppContextUnterweisungsthemaDokumentId ] = useState( 'neu' ) ;
          const [ appContextUnterweisungsthemaDokumente , setAppContextUnterweisungsthemaDokumente ] = useState( [] ) ;
          const [ appContextUnterweisungsthemaDokumenteFetchstate , setAppContextUnterweisungsthemaDokumenteFetchstate ] = useState( 0 ) ;
          const [ appContextUnterweisungsthemaDokumenteFetchanew , setAppContextUnterweisungsthemaDokumenteFetchanew ] = useState( true ) ;
            //#endregion Dokumente zu einzelnem Schulungs- / Unterweisungsthema
        //#region Einzelne Schulung / Unterweisung
          const [ appContextUnterweisungId , setAppContextUnterweisungId ] = useState( 'neu' ) ;
          const [ appContextUnterweisungFetchanew , setAppContextUnterweisungFetchanew ] = useState( true ) ;
            //#endregion Einzelne Schulung / Unterweisung
        //#region Dokumente zu einzelner Schulung / Unterweisung
          const [ appContextUnterweisungDokumentId , setAppContextUnterweisungDokumentId ] = useState( 'neu' ) ;
          const [ appContextUnterweisungDokumente , setAppContextUnterweisungDokumente ] = useState( [] ) ;
          const [ appContextUnterweisungDokumenteFetchstate , setAppContextUnterweisungDokumenteFetchstate ] = useState( 0 ) ;
          const [ appContextUnterweisungDokumenteFetchanew , setAppContextUnterweisungDokumenteFetchanew ] = useState( true ) ;
            //#endregion Dokumente zu einzelner Schulung / Unterweisung
        //#region Auflistung Schulungen / Unterweisungen
          const [ appContextUnterweisungen , setAppContextUnterweisungen ] = useState( [] ) ;
          const [ appContextUnterweisungenFetchstate , setAppContextUnterweisungenFetchstate ] = useState( 0 ) ;
          const [ appContextUnterweisungenFetchanew , setAppContextUnterweisungenFetchanew ] = useState( true ) ;
            //#endregion Auflistung Schulungen / Unterweisungen
        //#region Matrix
          const [ appContextUnterweisungenMatrixBuildNew , setAppContextUnterweisungenMatrixBuildNew ] = useState( true ) ;
          const [ appContextUnterweisungNeuTeilnehmer , setAppContextUnterweisungNeuTeilnehmer ] = useState( '' ) ;
          const [ appContextUnterweisungNeuThema , setAppContextUnterweisungNeuThema ] = useState( '' ) ;
            //#endregion Matrix
          //#endregion Schulungen / Unterweisungen

      const [ appContextUserId , setAppContextUserId ] = useState( 0 ) ;
      const [ appContextUserName , setAppContextUserName ] = useState( '' ) ;
    
      const [ appContextViewSwitch , setAppContextViewSwitch ] = useState( true ) ;
      /** "Providable" Zugriffe auf Context */
        // NB: redundante "appContextData"-Struktur könnte dynamisch generiert werden, was aber TypeScript-Checks durcheinander bringt
      let appContextData = {
        appArticleDokumente: appContextArticleDokumente ,
        appArticleDokumenteChange: setAppContextArticleDokumente ,
        appArticleDokumenteFetchanew: appContextArticleDokumenteFetchanew ,
        appArticleDokumenteFetchanewChange: setAppContextArticleDokumenteFetchanew ,
        appArticleDokumenteFetchstate: appContextArticleDokumenteFetchstate ,
        appArticleDokumenteFetchstateChange: setAppContextArticleDokumenteFetchstate ,
        appArticleDokumentId: appContextArticleDokumentId ,
        appArticleDokumentIdChange: setAppContextArticleDokumentId ,
        appArticleId: appContextArticleId ,
        appArticleIdChange: setAppContextArticleId ,
        appArticleGefahrstoffschulungenFetchanew: appContextArticleGefahrstoffschulungenFetchanew ,
        appArticleGefahrstoffschulungenFetchanewChange: setAppContextArticleGefahrstoffschulungenFetchanew ,
        appArticleGefahrstoffschulungenFetchstate: appContextArticleGefahrstoffschulungenFetchstate ,
        appArticleGefahrstoffschulungenFetchstateChange: setAppContextArticleGefahrstoffschulungenFetchstate ,

        appArticleInternGefahrstoffschulungenFetchanew: appContextArticleInternGefahrstoffschulungenFetchanew ,
        appArticleInternGefahrstoffschulungenFetchanewChange: setAppContextArticleInternGefahrstoffschulungenFetchanew ,
        appArticleInternGefahrstoffschulungenFetchstate: appContextArticleInternGefahrstoffschulungenFetchstate ,
        appArticleInternGefahrstoffschulungenFetchstateChange: setAppContextArticleInternGefahrstoffschulungenFetchstate ,
      
        appArticleInternDokumente: appContextArticleInternDokumente ,
        appArticleInternDokumenteChange: setAppContextArticleInternDokumente ,
        appArticleInternDokumenteFetchanew: appContextArticleInternDokumenteFetchanew ,
        appArticleInternDokumenteFetchanewChange: setAppContextArticleInternDokumenteFetchanew ,
        appArticleInternDokumenteFetchstate: appContextArticleInternDokumenteFetchstate ,
        appArticleInternDokumenteFetchstateChange: setAppContextArticleInternDokumenteFetchstate ,
        appArticleInternDokumentId: appContextArticleInternDokumentId ,
        appArticleInternDokumentIdChange: setAppContextArticleInternDokumentId ,
        appArticleInternId: appContextArticleInternId ,
        appArticleInternIdChange: setAppContextArticleInternId ,
        appArticleInternLieferant: appContextArticleInternLieferant ,
        appArticleInternLieferantChange: setAppContextArticleInternLieferant ,
        appArticleInternLieferantAdded: appContextArticleInternLieferantAdded ,
        appArticleInternLieferantAddedChange: setAppContextArticleInternLieferantAdded ,
        appArticles: appContextArticles ,
        appArticlesChange: setAppContextArticles ,
        appArticlesFetchanew: appContextArticlesFetchanew ,
        appArticlesFetchanewChange: setAppContextArticlesFetchanew ,
        appArticlesFetchstate: appContextArticlesFetchstate ,
        appArticlesFetchstateChange: setAppContextArticlesFetchstate ,
        appArticlesIntern: appContextArticlesIntern ,
        appArticlesInternChange: setAppContextArticlesIntern ,
        appArticlesInternFetchanew: appContextArticlesInternFetchanew ,
        appArticlesInternFetchanewChange: setAppContextArticlesInternFetchanew ,
        appArticlesInternFetchstate: appContextArticlesInternFetchstate ,
        appArticlesInternFetchstateChange: setAppContextArticlesInternFetchstate ,

        appAufgabenId: appContextAufgabenId ,
        appAufgabenIdChange: setAppContextAufgabenId ,

        appAufgabeServicepartner: appContextAufgabeServicepartner ,
        appAufgabeServicepartnerChange: setAppContextAufgabeServicepartner ,
        appAufgabeServicepartnerAdded: appContextAufgabeServicepartnerAdded ,
        appAufgabeServicepartnerAddedChange: setAppContextAufgabeServicepartnerAdded ,
        appAufgabeFetchanew: appContextAufgabeFetchanew ,
        appAufgabeFetchanewChange: setAppContextAufgabeFetchanew ,

        appAufgabenGeraetFetchanew: appContextAufgabenGeraetFetchanew ,
        appAufgabenGeraetFetchanewChange: setAppContextAufgabenGeraetFetchanew ,
        appAufgabenListeFetchanew: appContextAufgabenListeFetchanew ,
        appAufgabenListeFetchanewChange: setAppContextAufgabenListeFetchanew ,

        appBetriebeListe: appContextBetriebeListe ,
        appBetriebeListeChange: setAppContextBetriebeListe ,
        appBetriebeId: appContextBetriebeId ,
        appBetriebeIdChange: setAppContextBetriebeId ,
        appBetriebeName: appContextBetriebeName ,
        appBetriebeNameChange: setAppContextBetriebeName ,
        appEinweisungFetchanew: appContextEinweisungFetchanew ,
        appEinweisungFetchanewChange: setAppContextEinweisungFetchanew ,
        appEinweisungenId: appContextEinweisungenId ,
        appEinweisungenIdChange: setAppContextEinweisungenId ,
        appEinweisungenGeraetFetchanew: appContextEinweisungenGeraetFetchanew ,
        appEinweisungenGeraetFetchanewChange: setAppContextEinweisungenGeraetFetchanew ,
        appEinweisungenGeraetFetchstate: appContextEinweisungenGeraetFetchstate ,
        appEinweisungenGeraetFetchstateChange: setAppContextEinweisungenGeraetFetchstate ,
        appEinweisungenListeData: appContextEinweisungenListeData ,
        appEinweisungenListeDataChange: setAppContextEinweisungenListeData ,
        appEinweisungenListeFetchanew: appContextEinweisungenListeFetchanew ,
        appEinweisungenListeFetchanewChange: setAppContextEinweisungenListeFetchanew ,
        appEinweisungenListeFetchstate: appContextEinweisungenListeFetchstate ,
        appEinweisungenListeFetchstateChange: setAppContextEinweisungenListeFetchstate ,
        appEinweisungenMatrixBuildNew: appContextEinweisungenMatrixBuildNew ,
        appEinweisungenMatrixBuildNewChange: setAppContextEinweisungenMatrixBuildNew ,
        appEinweisungNeuTeilnehmer: appContextEinweisungNeuTeilnehmer ,
        appEinweisungNeuTeilnehmerChange: setAppContextEinweisungNeuTeilnehmer ,
        appEinweisungNeuThema: appContextEinweisungNeuThema ,
        appEinweisungNeuThemaChange: setAppContextEinweisungNeuThema ,

        appError: appContextError ,
        appErrorChange: setAppContextError ,

        appArticlesGefahrstoffschulungenThemen: appContextArticlesGefahrstoffschulungenThemen ,
        appArticlesGefahrstoffschulungenThemenChange: setAppContextArticlesGefahrstoffschulungenThemen ,
        appArticlesGefahrstoffschulungenThemenFetchanew: appContextArticlesGefahrstoffschulungenThemenFetchanew ,
        appArticlesGefahrstoffschulungenThemenFetchanewChange: setAppContextArticlesGefahrstoffschulungenThemenFetchanew ,
        appArticlesGefahrstoffschulungenThemenFetchstate: appContextArticlesGefahrstoffschulungenThemenFetchstate ,
        appArticlesGefahrstoffschulungenThemenFetchstateChange: setAppContextArticlesGefahrstoffschulungenThemenFetchstate ,
      
        appGefahrstoffschulungen: appContextGefahrstoffschulungen ,
        appGefahrstoffschulungenChange: setAppContextGefahrstoffschulungen ,
        appGefahrstoffschulungenFetchanew: appContextGefahrstoffschulungenFetchanew ,
        appGefahrstoffschulungenFetchanewChange: setAppContextGefahrstoffschulungenFetchanew ,
        appGefahrstoffschulungenFetchstate: appContextGefahrstoffschulungenFetchstate ,
        appGefahrstoffschulungenFetchstateChange: setAppContextGefahrstoffschulungenFetchstate ,
      
        appGefahrstoffschulungDokumente: appContextGefahrstoffschulungenDokumente ,
        appGefahrstoffschulungDokumenteChange: setAppContextGefahrstoffschulungenDokumente ,
        appGefahrstoffschulungDokumenteFetchanew: appContextGefahrstoffschulungenDokumenteFetchanew ,
        appGefahrstoffschulungDokumenteFetchanewChange: setAppContextGefahrstoffschulungenDokumenteFetchanew ,
        appGefahrstoffschulungDokumenteFetchstate: appContextGefahrstoffschulungenDokumenteFetchstate ,
        appGefahrstoffschulungDokumenteFetchstateChange: setAppContextGefahrstoffschulungenDokumenteFetchstate ,
        appGefahrstoffschulungDokumentId: appContextGefahrstoffschulungenDokumentId ,
        appGefahrstoffschulungDokumentIdChange: setAppContextGefahrstoffschulungenDokumentId ,
      
        appGefahrstoffschulungenMatrixBuildNew: appContextGefahrstoffschulungenMatrixBuildNew ,
        appGefahrstoffschulungenMatrixBuildNewChange: setAppContextGefahrstoffschulungenMatrixBuildNew ,
        appGefahrstoffschulungFetchanew: appContextGefahrstoffschulungFetchanew ,
        appGefahrstoffschulungFetchanewChange: setAppContextGefahrstoffschulungFetchanew ,
        appGefahrstoffschulungId: appContextGefahrstoffschulungId ,
        appGefahrstoffschulungIdChange: setAppContextGefahrstoffschulungId ,
      
        appGefahrstoffschulungenThemen: appContextGefahrstoffschulungenThemen , 
        appGefahrstoffschulungenThemenChange: setAppContextGefahrstoffschulungenThemen , 
        appGefahrstoffschulungenThemenFetchanew: appContextGefahrstoffschulungenThemenFetchanew ,
        appGefahrstoffschulungenThemenFetchanewChange: setAppContextGefahrstoffschulungenThemenFetchanew ,
        appGefahrstoffschulungenThemenFetchstate: appContextGefahrstoffschulungenThemenFetchstate ,
        appGefahrstoffschulungenThemenFetchstateChange: setAppContextGefahrstoffschulungenThemenFetchstate ,
      
        appGefahrstoffschulungenThemaAdded: appContextGefahrstoffschulungenThemaAdded ,
        appGefahrstoffschulungenThemaAddedChange: setAppContextGefahrstoffschulungenThemaAdded ,
        appGefahrstoffschulungenThemaDokumente: appContextGefahrstoffschulungenThemaDokumente ,
        appGefahrstoffschulungenThemaDokumenteChange: setAppContextGefahrstoffschulungenThemaDokumente ,
        appGefahrstoffschulungenThemaDokumenteFetchstate: appContextGefahrstoffschulungenThemaDokumenteFetchstate ,
        appGefahrstoffschulungenThemaDokumenteFetchstateChange: setAppContextGefahrstoffschulungenThemaDokumenteFetchstate ,
        appGefahrstoffschulungenThemaDokumenteFetchanew: appContextGefahrstoffschulungenThemaDokumenteFetchanew ,
        appGefahrstoffschulungenThemaDokumenteFetchanewChange: setAppContextGefahrstoffschulungenThemaDokumenteFetchanew ,
        appGefahrstoffschulungenThemaDokumenteId: appContextGefahrstoffschulungenThemaDokumentId ,
        appGefahrstoffschulungenThemaDokumenteIdChange: setAppContextGefahrstoffschulungenThemaDokumentId ,
        appGefahrstoffschulungenThemaId: appContextGefahrstoffschulungenThemaId ,
        appGefahrstoffschulungenThemaIdChange: setAppContextGefahrstoffschulungenThemaId ,

        appGefahrstoffschulungNeuTeilnehmer: appContextGefahrstoffschulungNeuTeilnehmer ,
        appGefahrstoffschulungNeuTeilnehmerChange: setAppContextGefahrstoffschulungNeuTeilnehmer ,
        appGefahrstoffschulungNeuThema: appContextGefahrstoffschulungNeuThema ,
        appGefahrstoffschulungNeuThemaChange: setAppContextGefahrstoffschulungNeuThema ,
      
        appGeraetSchulung: appContextGeraetSchulung ,
        appGeraetSchulungChange: setAppContextGeraetSchulung ,
        appGeraetService: appContextGeraetService ,
        appGeraetServiceChange: setAppContextGeraetService ,
        appGeraetServiceAdded: appContextGeraetServiceAdded ,
        appGeraetServiceAddedChange: setAppContextGeraetServiceAdded ,
        appGeraetTyp: appContextGeraetTyp ,
        appGeraetTypChange: setAppContextGeraetTyp ,
        appGeraeteListeFetchanew: appContextGeraeteListeFetchanew ,
        appGeraeteListeFetchanewChange: setAppContextGeraeteListeFetchanew ,
        appGeraeteId: appContextGeraeteId ,
        appGeraeteIdChange: setAppContextGeraeteId ,
        appGeraetegruppenGiven: appContextGeraetegruppenGiven ,
        appGeraetegruppenGivenChange: setAppContextGeraetegruppenGiven ,
        appGeraetegruppenGivenFetchanew: appContextGeraetegruppenGivenFetchanew ,
        appGeraetegruppenGivenFetchanewChange: setAppContextGeraetegruppenGivenFetchanew ,
        appGeraetegruppenGivenFetchstate: appContextGeraetegruppenGivenFetchstate ,
        appGeraetegruppenGivenFetchstateChange: setAppContextGeraetegruppenGivenFetchstate ,
        appGeraetegruppenPossible: appContextGeraetegruppenPossible ,
        appGeraetegruppenPossibleChange: setAppContextGeraetegruppenPossible ,
        appGeraetegruppenPossibleFetchanew: appContextGeraetegruppenPossibleFetchanew ,
        appGeraetegruppenPossibleFetchanewChange: setAppContextGeraetegruppenPossibleFetchanew ,
        appGeraetegruppenPossibleFetchstate: appContextGeraetegruppenPossibleFetchstate ,
        appGeraetegruppenPossibleFetchstateChange: setAppContextGeraetegruppenPossibleFetchstate ,

        appIotGeraete: appContextIotGeraete ,
        appIotGeraeteChange: setAppContextIotGeraete ,
        appIotGeraeteFetchanew: appContextIotGeraeteFetchanew ,
        appIotGeraeteFetchanewChange: setAppContextIotGeraeteFetchanew ,
        appIotGeraeteFetchstate: appContextIotGeraeteFetchstate ,
        appIotGeraeteFetchstateChange: setAppContextIotGeraeteFetchstate ,
        appIotMessgeraeteAktion: appContextIotMessgeraeteAktion ,
        appIotMessgeraeteAktionChange: setAppContextIotMessgeraeteAktion ,
        appIotMesspunkte: appContextIotMesspunkte ,
        appIotMesspunkteChange: setAppContextIotMesspunkte ,
        appIotMesspunkteFetchanew: appContextIotMesspunkteFetchanew ,
        appIotMesspunkteFetchanewChange: setAppContextIotMesspunkteFetchanew ,
        appIotMesspunkteFetchstate: appContextIotMesspunkteFetchstate ,
        appIotMesspunkteFetchstateChange: setAppContextIotMesspunkteFetchstate ,
        appIotMesspunkteFromDatetime: appContextIotMesspunkteFromDatetime ,
        appIotMesspunkteFromDatetimeChange: setAppContextIotMesspunkteFromDatetime ,
        appIotMesspunkteUntilDatetime: appContextIotMesspunkteUntilDatetime ,
        appIotMesspunkteUntilDatetimeChange: setAppContextIotMesspunkteUntilDatetime ,

        appGeraeteListeData: appContextGeraeteListeData ,
        appGeraeteListeDataChange: setAppContextGeraeteListeData ,
        appGeraeteListeFetchstate: appContextGeraeteListeFetchstate ,
        appGeraeteListeFetchstateChange: setAppContextGeraeteListeFetchstate ,
        appGeraeteklassenListe: appContextGeraeteklassenListe ,
        appGeraeteklassenListeChange: setAppContextGeraeteklassenListe ,
        appGeraeteklassenListeFetchanew: appContextGeraeteklassenListeFetchanew ,
        appGeraeteklassenListeFetchanewChange: setAppContextGeraeteklassenListeFetchanew ,
        appGeraeteklassenListeFetchstate: appContextGeraeteklassenListeFetchstate ,
        appGeraeteklassenListeFetchstateChange: setAppContextGeraeteklassenListeFetchstate ,
        appGeraeteklassenId: appContextGeraeteklassenId ,
        appGeraeteklassenIdChange: setAppContextGeraeteklassenId ,
        appGeraetetypAdded: appContextGeraetetypAdded ,
        appGeraetetypAddedChange: setAppContextGeraetetypAdded ,
        appGeraetHasIotMessung: appContextGeraetHasIotMessung ,
        appGeraetHasIotMessungChange: setAppContextGeraetHasIotMessung ,

        appKundeId: appContextKundeId ,
        appKundeIdChange: setAppContextKundeId ,
        appKundeName: appContextKundeName ,
        appKundeNameChange: setAppContextKundeName ,
        appKundeDatamehrMail: appContextKundeDatamehrMail ,
        appKundeDatamehrMailChange: setAppContextKundeDatamehrMail ,

        appLieferantId: appContextLieferantId ,
        appLieferantIdChange: setAppContextLieferantId ,
        appLieferantenListe: appContextLieferantenListe ,
        appLieferantenListeChange: setAppContextLieferantenListe ,
        appLieferantenListeFetchanew: appContextLieferantenListeFetchanew ,
        appLieferantenListeFetchanewChange: setAppContextLieferantenListeFetchanew ,
        appLieferantenListeFetchstate: appContextLieferantenListeFetchstate ,
        appLieferantenListeFetchstateChange: setAppContextLieferantenListeFetchstate ,
        appMitarbeiterListe: appContextMitarbeiterListe ,
        appMitarbeiterListeChange: setAppContextMitarbeiterListe ,
        appMitarbeiterListeFetchanew: appContextMitarbeiterListeFetchanew ,
        appMitarbeiterListeFetchanewChange: setAppContextMitarbeiterListeFetchanew ,
        appMitarbeiterListeFetchstate: appContextMitarbeiterListeFetchstate ,
        appMitarbeiterListeFetchstateChange: setAppContextMitarbeiterListeFetchstate ,
        appMitarbeiterId: appContextMitarbeiterId ,
        appMitarbeiterIdChange: setAppContextMitarbeiterId ,

        appDokumenteAufgabeId: appContextDokumenteAufgabeId ,
        appDokumenteAufgabeIdChange: setAppContextDokumenteAufgabeId ,
        appDokumenteAufgabe: appContextDokumenteAufgabe ,
        appDokumenteAufgabeChange: setAppContextDokumenteAufgabe ,
        appDokumenteAufgabeFetchanew: appContextDokumenteAufgabeFetchanew ,
        appDokumenteAufgabeFetchanewChange: setAppContextDokumenteAufgabeFetchanew ,
        appDokumenteAufgabeFetchstate: appContextDokumenteAufgabeFetchstate ,
        appDokumenteAufgabeFetchstateChange: setAppContextDokumenteAufgabeFetchstate ,

        appDokumenteEinweisungId: appContextDokumenteEinweisungId ,
        appDokumenteEinweisungIdChange: setAppContextDokumenteEinweisungId ,
        appDokumenteEinweisung: appContextDokumenteEinweisung ,
        appDokumenteEinweisungChange: setAppContextDokumenteEinweisung ,
        appDokumenteEinweisungFetchanew: appContextDokumenteEinweisungFetchanew ,
        appDokumenteEinweisungFetchanewChange: setAppContextDokumenteEinweisungFetchanew ,
        appDokumenteEinweisungFetchstate: appContextDokumenteEinweisungFetchstate ,
        appDokumenteEinweisungFetchstateChange: setAppContextDokumenteEinweisungFetchstate ,
        //#region Dokumente zu einzelnem Einweisungsthema
          appEinweisungsthemaDokumenteId: appContextEinweisungsthemaDokumentId ,
          appEinweisungsthemaDokumenteIdChange: setAppContextEinweisungsthemaDokumentId ,
          appEinweisungsthemaDokumente: appContextEinweisungsthemaDokumente ,
          appEinweisungsthemaDokumenteChange: setAppContextEinweisungsthemaDokumente ,
          appEinweisungsthemaDokumenteFetchstate: appContextEinweisungsthemaDokumenteFetchstate ,
          appEinweisungsthemaDokumenteFetchstateChange: setAppContextEinweisungsthemaDokumenteFetchstate ,
          appEinweisungsthemaDokumenteFetchanew: appContextEinweisungsthemaDokumenteFetchanew ,
          appEinweisungsthemaDokumenteFetchanewChange: setAppContextEinweisungsthemaDokumenteFetchanew ,
            //#endregion Dokumente zu einzelnem Einweisungsthema

        appDokumenteGeraetId: appContextDokumenteGeraetId ,
        appDokumenteGeraetIdChange: setAppContextDokumenteGeraetId ,
        appDokumenteGeraet: appContextDokumenteGeraet ,
        appDokumenteGeraetChange: setAppContextDokumenteGeraet ,
        appDokumenteGeraetFetchanew: appContextDokumenteGeraetFetchanew ,
        appDokumenteGeraetFetchanewChange: setAppContextDokumenteGeraetFetchanew ,
        appDokumenteGeraetFetchstate: appContextDokumenteGeraetFetchstate ,
        appDokumenteGeraetFetchstateChange: setAppContextDokumenteGeraetFetchstate ,

        appEinweisungsThemenFetchanew: appContextEinweisungsThemenFetchanew ,
        appEinweisungsThemenFetchanewChange: setAppContextEinweisungsThemenFetchanew ,
        appEinweisungsThemenFetchstate: appContextEinweisungsThemenFetchstate ,
        appEinweisungsThemenFetchstateChange: setAppContextEinweisungsThemenFetchstate ,
        appEinweisungGeraeteThemen: appContextEinweisungsThemenGeraete ,
        appEinweisungGeraeteThemenChange: setAppContextEinweisungsThemenGeraete ,
        appEinweisungGeraeteThemenFetchanew: appContextEinweisungsThemenGeraeteFetchanew ,
        appEinweisungGeraeteThemenFetchanewChange: setAppContextEinweisungsThemenGeraeteFetchanew ,
        appEinweisungGeraeteThemenFetchstate: appContextEinweisungsThemenGeraeteFetchstate ,
        appEinweisungGeraeteThemenFetchstateChange: setAppContextEinweisungsThemenGeraeteFetchstate ,
        appEinweisungsThemenListe: appContextEinweisungsThemenListe ,
        appEinweisungsThemenListeChange: setAppContextEinweisungsThemenListe ,
        appSchulungAdded: appContextSchulungAdded ,
        appSchulungAddedChange: setAppContextSchulungAdded ,
        appSchulungId: appContextSchulungId ,
        appSchulungIdChange: setAppContextSchulungId ,
        appUserIstBasic: appContextUserIstBasic ,
        appUserIstEinweiser: appContextUserIstEinweiser ,
        appUserIstEinweiserChange: setAppContextUserIstEinweiser ,
        appViewSwitch: appContextViewSwitch ,

      //#region Schulungen / Unterweisungen
        /** aus historischen Gründen in DB etc. "schulung" für Einweisungsthema und hier "unterweisung" */
        //#region Themen
          appUnterweisungsthemaId: appContextUnterweisungsthemaId ,
          appUnterweisungsthemaIdChange: setAppContextUnterweisungsthemaId ,
          appUnterweisungsthemaAdded: appContextUnterweisungsthemaAdded ,
          appUnterweisungsthemaAddedChange: setAppContextUnterweisungsthemaAdded ,
          appUnterweisungsthemen: appContextUnterweisungsthemen ,
          appUnterweisungsthemenChange: setAppContextUnterweisungsthemen ,
          appUnterweisungsthemenFetchstate: appContextUnterweisungsthemenFetchstate ,
          appUnterweisungsthemenFetchstateChange: setAppContextUnterweisungsthemenFetchstate ,
          appUnterweisungsthemenFetchanew: appContextUnterweisungsthemenFetchanew ,
          appUnterweisungsthemenFetchanewChange: setAppContextUnterweisungsthemenFetchanew ,
          //#region Dokumente zu einzelnem Schulungs / Unterweisungsthema
            appUnterweisungsthemaDokumentId: appContextUnterweisungsthemaDokumentId ,
            appUnterweisungsthemaDokumentIdChange: setAppContextUnterweisungsthemaDokumentId ,
            appUnterweisungsthemaDokumente: appContextUnterweisungsthemaDokumente ,
            appUnterweisungsthemaDokumenteChange: setAppContextUnterweisungsthemaDokumente ,
            appUnterweisungsthemaDokumenteFetchstate: appContextUnterweisungsthemaDokumenteFetchstate ,
            appUnterweisungsthemaDokumenteFetchstateChange: setAppContextUnterweisungsthemaDokumenteFetchstate ,
            appUnterweisungsthemaDokumenteFetchanew: appContextUnterweisungsthemaDokumenteFetchanew ,
            appUnterweisungsthemaDokumenteFetchanewChange: setAppContextUnterweisungsthemaDokumenteFetchanew ,
              //#endregion Dokumente zu einzelnem Schulungs / Unterweisungsthema
            //#endregion Themen
        //#region Einzelne Schulung / Unterweisung
          appUnterweisungId: appContextUnterweisungId ,
          appUnterweisungIdChange: setAppContextUnterweisungId ,
          appUnterweisungFetchanew: appContextUnterweisungFetchanew ,
          appUnterweisungFetchanewChange: setAppContextUnterweisungFetchanew ,
            //#endregion Einzelne Schulung / Unterweisung
        //#region Dokumente zu einzelner Schulung / Unterweisung
          appUnterweisungDokumentId: appContextUnterweisungDokumentId ,
          appUnterweisungDokumentIdChange: setAppContextUnterweisungDokumentId ,
          appUnterweisungDokumente: appContextUnterweisungDokumente ,
          appUnterweisungDokumenteChange: setAppContextUnterweisungDokumente ,
          appUnterweisungDokumenteFetchstate: appContextUnterweisungDokumenteFetchstate ,
          appUnterweisungDokumenteFetchstateChange: setAppContextUnterweisungDokumenteFetchstate ,
          appUnterweisungDokumenteFetchanew: appContextUnterweisungDokumenteFetchanew ,
          appUnterweisungDokumenteFetchanewChange: setAppContextUnterweisungDokumenteFetchanew ,
            //#endregion Dokumente zu einzelner Schulung / Unterweisung
        //#region Auflistung Schulungen / Unterweisungen
          appUnterweisungen: appContextUnterweisungen ,
          appUnterweisungenChange: setAppContextUnterweisungen ,
          appUnterweisungenFetchstate: appContextUnterweisungenFetchstate ,
          appUnterweisungenFetchstateChange: setAppContextUnterweisungenFetchstate ,
          appUnterweisungenFetchanew: appContextUnterweisungenFetchanew ,
          appUnterweisungenFetchanewChange: setAppContextUnterweisungenFetchanew ,
            //#endregion Auflistung Schulungen / Unterweisungen
        //#region Matrix
          appUnterweisungenMatrixBuildNew: appContextUnterweisungenMatrixBuildNew ,
          appUnterweisungenMatrixBuildNewChange: setAppContextUnterweisungenMatrixBuildNew ,
          appUnterweisungNeuTeilnehmer: appContextUnterweisungNeuTeilnehmer ,
          appUnterweisungNeuTeilnehmerChange: setAppContextUnterweisungNeuTeilnehmer ,
          appUnterweisungNeuThema: appContextUnterweisungNeuThema ,
          appUnterweisungNeuThemaChange: setAppContextUnterweisungNeuThema ,
            //#endregion Matrix
          //#endregion Schulungen / Unterweisungen

        appUserId: appContextUserId ,
        appUserIdChange: setAppContextUserId ,
        appUserName: appContextUserName ,
        appUserNameChange: setAppContextUserName ,

        appViewSwitchChange: setAppContextViewSwitch
      } ;
  /** (Ende AppContext init) */
  const [validation, setValidation] = useState(false)
  const [ generatedRoutes , setGeneratedRoutes] = useState() ;
  useEffect( () => { 
    let token = window.localStorage.getItem('token');
    if (token !== null) { 
      if( ! JSON.parse(window.localStorage.getItem('token')).betriebe ) {
        alert( 'Ihrer Benutzerkennung sind keine Einrichtungen zugeordnet - bitte wenden Sie sich an den Support') ;
        return ;
      }
      setValidation(true);
      setAppContextBetriebeListe( JSON.parse(window.localStorage.getItem('token')).betriebe ) ;
      if( JSON.parse(window.localStorage.getItem('token')).betriebe.length === 1 ) {
        setAppContextBetriebeId( JSON.parse(window.localStorage.getItem('token')).betriebe[0].betriebe_id ) ;
        setAppContextBetriebeName( JSON.parse(window.localStorage.getItem('token')).betriebe[0].betriebe_name ) ;
      }
      setAppContextKundeId( JSON.parse(window.localStorage.getItem('token')).kunde ) ;
      setAppContextKundeName( JSON.parse(window.localStorage.getItem('token')).kunde_name ) ;
      setAppContextKundeDatamehrMail( JSON.parse(window.localStorage.getItem('token')).kunde_datamehr_mail ) ;
      setAppContextUserId( JSON.parse(window.localStorage.getItem('token')).usr_id ) ;
      setAppContextUserName( JSON.parse(window.localStorage.getItem('token')).usr_name ) ;
      if( JSON.parse(window.localStorage.getItem('token')).ist_basic === 'ja' ) {
        setAppContextUserIstBasic( 'ja' ) ;
        setAppContextUserIstEinweiser( 'nein' ) ;
      } else {
        setAppContextUserIstBasic( 'nein' ) ;
        setAppContextUserIstEinweiser( JSON.parse(window.localStorage.getItem('token')).ist_einweiser ) ;
      }
    }
  }, [])

  useEffect(
    function generateRoutes()
      {
        let my_routes =
          arrRoutesData
            .map(
              ( entry ) => {
                // console.log( 'generatedRoutes' , entry[ 0 ] ) ;
                return (
                  <Route
                    key = { 'route_' + entry[ 0 ] }
                    component = { Pages[ entry[ 0 ] ] }
                    exact = { true }
                    path = { entry[ 1 ] }
                  />
                )
              }
            )
        ;
        setGeneratedRoutes( my_routes ) ;
      }
    ,
    []
  ) ;

  return (
    <IonApp>
      {
        ! validation
          ?
            <Pages.Login/>
          :
            <IonReactRouter>
              <AppContext.Provider value = { appContextData } >
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route path = "*" >
                    <Pages.FEHLER />
                  </Route>
                  { generatedRoutes }
                </IonRouterOutlet>
              </IonSplitPane>
              </AppContext.Provider>
            </IonReactRouter>
      }
    </IonApp>
  );
}

export default App
