/**
 * View System
 * 
 * System-Einstellungen
 * 
 */

 import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonContent, IonHeader, IonImg ,IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
 import axios from 'axios';
 import { useContext , useEffect, useState } from 'react';
 import { useHistory, useParams } from 'react-router';
 import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp , PageHelp ,readableFileSize } from '../components/_HelperFunctions';
 import { AppContext } from '../contexts/app_context';
 import './Dokument.css';
 
 export function System() {
   useIonViewDidEnter(
     () =>
       {
         appViewSwitchChange( false ) ;  
       }
   ) ;
   const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
   const screencastChannel = '' ;
   const { appDokumenteGeraetFetchanewChange , appDokumenteGeraetId , appDokumenteGeraetIdChange, appKundeId , appKundeName, appViewSwitchChange } = useContext( AppContext ) ;
   const { geraeteId } = useParams<{ geraeteId: string; }>();
   const history = useHistory();
   let tmp:any = window.localStorage.getItem("token") ;
   const betriebeId =  JSON.parse( tmp ).betriebe[0].betriebe_id 
   const [ DokumenteId, setDokumenteId ] = useState( appDokumenteGeraetId )
   const [ DokumenteAktiv, setDokumenteAktiv ] = useState( 'aktiv' )
   const [ DokumenteAktivBool, setDokumenteAktivBool ] = useState( true )
   const [ DokumenteDateitypMime, setDokumenteDateitypMime ] = useState( '' )
   const [ DokumenteDateitypKurz, setDokumenteDateitypKurz ] = useState( '' )
     // Default "DokumenteThema" ad hoc => Ziel "GeraeteHersteller GeraetName" erfordert Datenbezug
   const [ DokumenteThema, setDokumenteThema ] = useState( 'Gerät ' + geraeteId )
   const [ DokumenteDateiname, setDokumenteDateiname ] = useState( '' )
   const [ DokumenteUniquename, setDokumenteUniquename] = useState( betriebeId + '_' + Date.now().toString() ) ;
   const [ DokumenteDateidatum, setDokumenteDateidatum ] = useState( '' )
   const [ DokumenteDateigroesse, setDokumenteDateigroesse ] = useState( '' )
   const [ DokumenteDateiToUpload, setDokumenteDateiToUpload ]:any = useState()
    const DokumenteAktivStringToBool = ( val:string ) => {
     if( val === 'aktiv' ) {
       setDokumenteAktiv( 'aktiv' )
       setDokumenteAktivBool( true )
     } else {
       setDokumenteAktiv( 'inaktiv' )
       setDokumenteAktivBool( false )
     }
   }

  const checkRessourceExist = async ( uri:string ) => {
    let res = undefined ;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    await fetch( uri , requestOptions )
      .then( response => 
        {
          res = response.status ;
        }
      )
    // console.log( 'RES:' , res ) ;
    return res ;
  }
    
    function onValueDokumenteAktivChange(e: boolean) {
       e
         ?
           DokumenteAktivStringToBool( 'aktiv' )
         :
           DokumenteAktivStringToBool( 'inaktiv' )
     }
   function onValueDokumenteThema( e:any ) { setDokumenteThema( e.target.value ) ; }
   function onValueDokumenteSelect( e:any ) {
     if( new Date( e.target.files[0].lastModified ).toISOString() !== DokumenteDateidatum ) {
       setDokumenteDateidatum( new Date( e.target.files[0].lastModified ).toISOString() ) ;
       setDokumenteDateigroesse( e.target.files[0].size ) ;
       setDokumenteDateiname( e.target.files[0].name ) ; 
       setDokumenteDateitypMime( e.target.files[0].type ) ;
       setDokumenteDateiToUpload( e.target.files[0] ) ;
     }
   }
   return (
     <IonPage>
       <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
         <p>
            Auf dieser Seite können Sie Systemeinstellungen vornehmen.
         </p>
       </PageHelp>
       <IonHeader>
         <IonToolbar>
           <IonButtons slot="start">
             <IonMenuButton />
           </IonButtons>
           <IonTitle slot=""  >
            Systemeinstellungen { appKundeName }
           </IonTitle>
           <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
         </IonToolbar>
       </IonHeader>
       <IonContent fullscreen>
         <IonCard>
           <IonCardHeader>
              <IonToolbar>
              <IonTitle slot="">
                Briefpapier für Aufträge
              </IonTitle>
              </IonToolbar>
           </IonCardHeader>
           <IonCardContent>
              <IonButton
                onClick = 
                  {
                    () =>
                      {
                        history.push( '/briefpapier/' + appKundeId + '/' + appKundeName )
                      }
                  }
              >
                Briefpapier für Aufträge
              </IonButton>
                       {/* <IonImg
                        id = 'myImg'
                        src = { 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=D' }
                        style = { { border: '1px dotted lightgray' , width: '50%' } }
                      />
                      <div>
                        irgendwas
                      </div>
                       <IonImg
                        id = 'myImg'
                        src = { 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=D' }
                        style = { { border: '1px dotted lightgray' , width: '50%' } }
                      />
                      {
                        document.getElementById( 'myImg' )
                          ?
                            <h1>Schrobbas</h1>
                          :
                            <h1>Palunde</h1>
                      }
                      <IonButton
                        onClick={
                          () =>
                            {
                              window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=I' ) ;
                            }
                        }
                      >
                        Vollansicht in neuem Fenster
                      </IonButton>
                      <IonButton
                        onClick={
                          () =>
                            {
                              window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=D' ) ;
                            }
                        }
                      >
                        Herunterladen
                      </IonButton>
                      <IonButton
                        onClick =
                          {
                            async () =>
                              {
                                console.log( 'hugga2:' , await checkRessourceExist( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=E' ) ) ;
                                // console.log( 'haha' ) ;
                                // console.log( await checkRessourceExist( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=E' ) ) ;
                                if( await checkRessourceExist( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=vorlage&output=E' ) ) {
                                  console.log( 'Vorhanden!' )
                                } else {
                                  console.log( 'nix gefunden' )
                                }
                                // console.log( 'hoho' ) ;
                              }
                          }
                      >
                        CHECK
                      </IonButton>
                      {
                        // @ts-ignore
                        checkRessourceExist( 'https://datamehr-backend.saint-online.de/api/aufgaben/get_briefpapier.php?kunde_id=xvorlage&output=E' )
                          ?
                            'HUUUUU'
                          :
                            'BUUUU'                        
                      } */}
           </IonCardContent>
         </IonCard>
       </IonContent>
     </IonPage>
   );
 };
 
 