import { IonButton, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext , useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import './table_geraete_grid.css';
import { ButtonAdd, ButtonInaktive , defColumn , exportTableData , getLoadErr  } from './_HelperFunctions' ;
import { AppContext } from '../contexts/app_context';
import { useLieferantenListe } from "./_CustomHooks";

function LieferantenTable() {
  const { appKundeId , appLieferantIdChange , appLieferantenListeFetchstate , appLieferantenListe , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [pageSize, setPageSize] = useState(50);
  const [ localLieferantenListe , localLieferantenListeChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const setColumns = ( tablePurpose:string ):any => {
    if( tablePurpose === 'Lieferanten' ) {
      return (
        [
          defColumn( 'LieferantenId' , 'ID' , 0 ) ,
          defColumn( 'LieferantenName' , 'Name' , 150 , 0 ) ,
          defColumn( 'LieferantenPLZ' , 'PLZ' , 80 , 0 ) ,
          defColumn( 'LieferantenOrt' , 'Ort' , 110 , 0 ) ,
          defColumn( 'LieferantenStrasse' , 'Straße' , 150 , 0 ) ,
          defColumn( 'LieferantenHausnummer' , 'HNr' , 40 , 0 ) ,
          defColumn( 'LieferantenKundennummer' , 'Kundennr.' , 100 , 0 ) ,
          defColumn( 'LieferantenTelefon' , 'Telefon' , 140 , 0 ) ,
          defColumn( 'LieferantenEmail' , 'E-Mail' , 200 , 0 ) ,
          defColumn( 'LieferantenFax' , 'Fax' , 140 , 0 ) ,
          defColumn( 'LieferantenAnsprechpartner' , 'Ansprechpartner' , 200 , 0 ) ,
          defColumn( 'LieferantenMobilAnsprechpartner' , 'Mobil Ansprechpartner' , 140 , 1 )
        ]
      )
    }
  }
  useLieferantenListe() ;

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localLieferantenListeChange( appLieferantenListe )
      }
      else {
        localLieferantenListeChange(
          appLieferantenListe
            .filter(
              ( entry:any ) => entry.LieferantenStatus === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appLieferantenListe
          .findIndex(
            ( entry:any ) => entry.LieferantenStatus === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appLieferantenListe ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )

    const gotoSingleView_Lieferant = ( targetId:any ) =>
      {
        appLieferantIdChange( targetId ) ;
        gotoSingleView_X( 'lieferant' , targetId )
      }

  //#region gotoSingleView_X
    /** nicht zentral auslagerbar wg. history = useHistory = Hook
     *  - als Annäherung an zentrale Wartbarkeit: redundante Funktionaliät isoliert in einer
     *    Funktion, die uniform über alle Kontexte hinweg komplett durch eine geänderte Version
     *    ausgetauscht werden kann
     */
      const gotoSingleView_X = ( singleView:string , targetId:any ) =>
        {
          history.push( '/' + singleView + '/' + targetId + '/' + appKundeId )
        }
      //#region gotoSingleView_X

  const mkTable = ( tablePurpose:string , singleGUI:string , betriebeId:string ) =>  {
    return (
      <Box
        sx={{
          height: 600,
          width: 1,
          "& .super-app-theme--header": {
            backgroundColor: "rgb(211,211,211)",
          },
        }}
      >
        {
          appLieferantenListeFetchstate !== 200 && appLieferantenListeFetchstate !== 204 ?
          (
            appLieferantenListeFetchstate === 100
              ? <IonSpinner name="bubbles" />
              : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                  { getLoadErr( appLieferantenListeFetchstate ) }
                </div> 
          ) : (
            <>
              <DataGrid
                disableVirtualization 
                componentsProps = {{
                  toolbar: {
                    tablePurpose: tablePurpose
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'LieferantenName' , sort: 'asc' } ] ,
                  },
                }}
              getRowId={(row:any) => row.LieferantenId} 
                getRowClassName =
                  {
                    ( data:any ) => data.row.LieferantenStatus === 'inaktiv' ? 'eintrag_inaktiv' : ''
                  }
                rows={localLieferantenListe}
                columns={ setColumns( tablePurpose ) } 
                rowsPerPageOptions={[50,100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onCellClick = {
                  ( e ) => 
                    {
                      appViewSwitchChange( true ) ;
                      gotoSingleView_Lieferant( e.id ) ;
                    }
                }
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
              <ButtonInaktive
                disabled = { existInaktive ? false : true }
                myExec = {
                  () =>
                    {
                      setShowInaktive( showInaktive ? false : true )
                    }
                  }
                label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
              />
              <ButtonAdd
                myExec = { () => { appLieferantIdChange( 'neu' ) ; } }
                routeUrl = { '/lieferant/neu/' + appKundeId }
              />
              {
                localLieferantenListe.length > 0
                  ?
                    <IonButton onClick={ () => exportTableData( localLieferantenListe , 'Servicepartner' ) }>Exportieren</IonButton>
                  :
                    ''
              }
              {
                showInaktive
                  ?
                    <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                      Inaktive Servicepartner sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                    </p>
                  :
                    ''
              }
            </>
          )
        }
      </Box>
    )
  }
  return (
    <>
      { mkTable( 'Lieferanten' , 'lieferant' , appKundeId ) }
    </>
  );
}
export default LieferantenTable;
