//#region Modules
  import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonSpinner, IonToolbar, useIonViewDidEnter } from '@ionic/react';
  import Box from "@mui/material/Box";
  import { DataGrid , deDE } from "@mui/x-data-grid";
  import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
  import { useContext, useEffect, useState } from 'react';
  import { Doughnut } from 'react-chartjs-2';
  import { ThumbsDown , ThumbsUp } from 'react-ionicons';
  import { useHistory } from 'react-router-dom';
  import { AppContext } from '../contexts/app_context';
  import { useGeraeteklassenListe, useGeraeteListe } from './_CustomHooks';
  import { ButtonAdd, defColumn , defColumnRenderCell , exportTableData , getLoadErr } from './_HelperFunctions' ;
    //#endregion Modules


//#region Chart.js-Doughnut-Einstellungen
  /** Registrierungen für Webpack-Tree-Shaking */
    ChartJS.register( ArcElement , Tooltip , Legend) ;
  /** Farbpalette
   *  - einmal fix mit Zufallsfarbgenerator erzeugt
   *    - vorher bei jedem Re-Render neu
   *      - in Komponente:  `const farben = [] ; for (let i = 0; i < 100; i++) { farben.push(randomColorGenerator()) }`
   *      - in Datei: `var randomColorGenerator = function () { return '#' + (Math.random().toString(16) + '0000000').slice(2, 8); };`
   */ 
    const farben = [
      '#E78502' , '#483FCD' , '#028C76' , '#D60046' , '#0068C8' ,
      '#FEA530' , '#756CFA' , '#30839F' , '#FF4782' , '#2E9F7C' ,
      '#FEB95E' , '#938DFB' , '#5CC2B4' , '#FF75A1' , '#73B8FD' ,
      '#FECD8C' , '#C1BDFD' , '#8CD5C9' , '#FFA3C0' , '#A2D0FD' ,
      '#D17700' , '#3831A0' , '#007765' , '#B60839' , '#0052A1' ,
      '#BE660F' , '#282372' , '#02594C' , '#960032' , '#003873' ,
      '#9C540D' , '#201C5B' , '#013C33' , '#740127' , '#082B54' ,
      '#FEE1BA' , '#DFDEFE' , '#D1EEE9' , '#FFD1DF' , '#D0E7FE' ,
      '#FE9202' , '#574CFA' , '#02A38A' , '#EB004C' , '#0080FC' ,
      "#52e9f4", "#2c791b", "#79dd74", "#44b3a8", "#0c8c9c", "#e105a7", "#27cae3", "#92d708", "#830bf4", "#7be362", "#751421", "#ec3ab7", "#cea5e8", "#aba777", "#a8930d", "#abea0d", "#962641", "#683402", "#e4aa08", "#303713", "#2dc8fb", "#c48d73", "#5113f9", "#14c9e9", "#81b7c5", "#669605", "#f488de", "#4dda7b", "#8fc0ba", "#9a2462", "#8cdedd", "#9cb955", "#620448", "#6ad6af", "#7b049c", "#346f54", "#135c50", "#3d7516", "#1f7ca8", "#fce697", "#ad3216", "#d67933", "#26f07c", "#cd29f8", "#8e89a2", "#aa5c43", "#31edc6", "#bc12b6", "#8e7806", "#456670", "#17bca9", "#909989", "#0ae4f3", "#8b7c68", "#86b5ec", "#b30bed", "#3cc762", "#87b9b7", "#334895", "#8a05ae", "#4dfba3", "#d61956", "#1c3b6d", "#e11010", "#36e467", "#7bdbf7", "#449bd4", "#df4b71", "#ad73ea", "#7fa4b6", "#03bfb5", "#7fa532", "#ed6c7d", "#a7fd1c", "#e12c33", "#49f4bc", "#9010c6", "#42030d", "#ab4112", "#2121b1", "#cc832c", "#349d21", "#844ada", "#59fb59", "#b619ae", "#a72cba", "#ce614f", "#87b3ef", "#08f26e", "#929cb0", "#79d673", "#5da37f", "#da1a39", "#ec1b32", "#7a08a2", "#86ba5c", "#93154e", "#e78cbb", "#57a826", "#c3a3a2" ] ;
      //#region Chart.js-Doughnut-Einstellungen

//#region React-Komponente
  function ChartGeraete() {
    //#region State-Management
      //#region useContext
        const { appBetriebeId , appBetriebeIdChange , appBetriebeListe ,  appBetriebeNameChange , appGeraeteklassenListe , appGeraeteIdChange , appGeraeteListeData , appGeraeteListeFetchstate , appViewSwitchChange } = useContext( AppContext ) ;
          //#endregion useContext
      //#region Hilfsfunktionen
        const filterAktive = () =>
          {
            // console.log('appGeraeteListeData',appGeraeteListeData)
            return appGeraeteListeData
                    .filter(
                      ( entry:any ) => entry.GeraeteGesperrt === '0'
                    )
          }
          //#endregion Hilfsfunktionen
      //#region Doughnut-Daten
        /** Kopie Geräte-Daten für destruktive Filterung */
          const [ localGeraeteListeData , localGeraeteListeDataChange ] = useState( Array() ) ;
        /** Einrichtungen: Übersetzen ID <=> Name */
          const [ betriebeIdsVsNames , setbetriebeIdsVsNames ] = useState( {} )
        /** Gerätegruppen aus appGeraeteklassenListe */
          const [ geraeteklassenListeTypen , setgeraeteklassenListeTypen ] = useState( Array() )
          const [ geraetegrupenFarben , setGeraetegruppenFarben ] = useState( [] ) ;
        /** Gerätekategorienfarben aus appGeraeteklassenListe */
          const [ deDupedGeraeteklassenFarben , setDeDupedGeraeteklassenFarben ] = useState( [] ) ;
          const [ geraeteklassenNamenFarben , setGeraeteklassenNamenFarben ] = useState( [] ) ;
        //#region Einzel-Doughnuts
          //#region Anzuwendende Filter
            /** Als States erforderlich wg. Rerendering durch Filter */
              const [ filterEinrichtungen , setfilterEinrichtungen ] = useState( '' ) ;
              const [ filterGeraeteklasse , setfilterGeraeteklasse ] = useState( '' ) ;
              const [ filterGeraetetyp , setfilterGeraetetyp ] = useState( '' ) ;
              const [ filterGeraetestatus , setfilterGeraetestatus ] = useState(  -1 ) ;
              const [ filterGeraeteeigentuemer , setfilterGeraeteeigentuemer ] = useState( -1 ) ;
              const [ filterGeraetealter , setfilterGeraetealter ] = useState( -1 ) ;
              const [ filterMpgKlasse , setfilterMpgKlasse ] = useState( -1 ) ;
            /** Filteranwendung durch Rerendering via useeffect
             *    - Hier die Filtermethoden, getriggert durch State pro Filter, welche durch Doughnut-Clicks gesetzt = aktiviert / deaktiviert werden
            */
              useEffect(
                () => {
                  let filtered = filterAktive() ;
                  if( filterEinrichtungen !== '' ) {
                    filtered = filtered.filter(
                        // @ts-ignore
                        ( entry:any ) => entry.betriebeId === betriebeIdsVsNames[ filterEinrichtungen ]
                      ) 
                  }
                  if( filterGeraeteklasse !== '' ) {
                    if( filterGeraeteklasse === 'unbekannt' ) {
                      filtered = filtered.filter(
                          ( entry:any ) => ! geraeteklassenListeTypen.includes( entry.GeraeteTyp )
                        ) 
                    } else {
                      filtered = filtered.filter(
                          ( entry:any ) =>
                              {
                                if( 
                                  appGeraeteklassenListe
                                    .find(
                                      ( item:any) =>
                                        item.GeraeteklassenName === filterGeraeteklasse
                                        &&
                                        item.GeraeteklassenGeraetetyp === entry.GeraeteTyp
                                    )
                                )
                                  {
                                    return true ;
                                  } 
                              }
                        ) 
                    }
                  }
                  if( filterGeraetetyp !== '' ) {
                    filtered = filtered.filter(
                        ( entry:any ) => entry.GeraeteTyp === filterGeraetetyp
                      ) 
                  }
                  if( filterGeraetestatus > -1 ) {
                    filtered = filtered.filter(
                        ( entry:any ) => {
                          if(
                            ( entry.GeraeteStatus === 1 && filterGeraetestatus === 0 ) 
                            ||
                            ( entry.GeraeteStatus === 2 && filterGeraetestatus === 1 )
                            ||
                            (( entry.GeraeteStatus === 0 && filterGeraetestatus === 2))
                          ) {
                              return true ;
                            }
                        }
                      ) 
                  }
                  if( filterGeraeteeigentuemer > -1 ) {
                    filtered = filtered.filter(
                        ( item:any ) => {
                          if(
                            ( filterGeraeteeigentuemer === 0 && ( item.GeraeteEigentuemer === "Haus" || item.GeraeteEigentuemer === "" || item.GeraeteEigentuemer === null ) )
                            ||
                            ( filterGeraeteeigentuemer === 1 && item.GeraeteEigentuemer !== "Haus" && item.GeraeteEigentuemer !== "" && item.GeraeteEigentuemer !== null )
                          ) {
                              return true ;
                            }
                        }
                      ) 
                  }
                  if( filterGeraetealter > -1 ) {
                    filtered = filtered.filter(
                        ( entry:any ) => {
                          if(
                            ( entry.baujahr >= 2019 && filterGeraetealter === 0 ) 
                            ||
                            ( entry.baujahr >= 2014 && entry.baujahr < 2019 && filterGeraetealter === 1 ) 
                            ||
                            ( entry.baujahr >= 2007 && entry.baujahr < 2014 && filterGeraetealter === 2 ) 
                            ||
                            ( entry.baujahr <2007 && filterGeraetealter === 3 ) 
                          ) {
                              return true ;
                            }
                        }
                      ) 
                  }
                  if( filterMpgKlasse> -1 ) {
                    filtered = filtered.filter(
                        ( entry:any ) => {
                          if(
                            ( entry.GeraeteMPGKlasse === 'keine' && filterMpgKlasse === 0 ) 
                            ||
                            ( entry.GeraeteMPGKlasse === 'I' && filterMpgKlasse === 1 ) 
                            ||
                            ( entry.GeraeteMPGKlasse === 'IIa' && filterMpgKlasse === 2 ) 
                            ||
                            ( entry.GeraeteMPGKlasse === 'IIb' && filterMpgKlasse === 3 ) 
                            ||
                            ( entry.GeraeteMPGKlasse === 'III' && filterMpgKlasse === 4 ) 
                          ) {
                              return true ;
                            }
                        }
                      ) 
                  }
                  localGeraeteListeDataChange( filtered ) ;
                } ,
                [ filterEinrichtungen , filterGeraeteklasse , filterGeraetetyp , filterGeraetestatus , filterGeraeteeigentuemer , filterGeraetealter , filterMpgKlasse ]
              )
              //#endregion Anzuwendende Filter
          //#region Einrichtungen
            const [ einrichtungenZahlen , seteinrichtungenZahlen ] = useState(Array)
            const [ einrichtungenChartDataLabel , seteinrichtungenChartDataLabel ] = useState( [ 'Einrichtungen' ] )
            const [ einrichtungenChartDataValue , seteinrichtungenChartDataValue ] = useState( Array() )
              //#endregion Einrichtungen
        //#region Gerätekategorien
            const [ geraeteKlassenZahlen , setgeraeteKlassenZahlen ] = useState(Array)
            const [ klassenChartDataLabel , setklassenChartDataLabel ] = useState( [ 'Geräte' ] )
            const [ klassenChartDataValue , setklassenChartDataValue ] = useState( [ 123 ] )
              //#endregion Gerätekategorien
          //#region Gerätegruppen
            const [ geraeteTypenZahlen , setgeraeteTypenZahlen ] = useState(Array)
            const [ typenChartDataLabel , settypenChartDataLabel ] = useState( [ 'Geräte' ] )
            const [ typenChartDataValue , settypenChartDataValue ] = useState( [ 123 ] )
              //#endregion Gerätegruppen
          //#region Gerätestatus
            const [ statusChartDataValue , setstatusChartDataValue ] = useState( [ 1 , 1 , 1 ] )
              //#endregion Gerätestatus
          //#region Eigentümer
            const [ eigentumChartDataValue , seteigentumChartDataValue ] = useState( [ 3 , 5 ] )
              //#endregion Eigentümer
          //#region Alter
            const [ alterChartDataValue , setalterChartDataValue ] = useState( [ 1 , 2 , 3 , 5 ] )
              //#endregion Alter
          //#region MPG-Klasse
          const [ MpgKlasseChartDataValue , setMpgKlasseChartDataValue ] = useState( [ 1 , 3 , 5 , 7 , 9 ] )
          //#endregion MPG-Klasse
        //#endregion Einzel-Doughnuts
          //#endregion Doughnut-Daten
      //#region DataGrid
        const [pageSize , setPageSize] = useState( 50 );
          //#endregion DataGrid
      //#region Routing
        const history = useHistory();
          //#endregion Routing
        //#endregion State-Management
    //#region Data-Fetches
      useGeraeteklassenListe() ;
      //#region Gerätekategorien-Liste auswerten
        useEffect(
          () => {
            setgeraeteklassenListeTypen( 
              appGeraeteklassenListe
                .map(
                  ( entry:any ) => entry.GeraeteklassenGeraetetyp
                )
            )
          } ,
          [ appGeraeteklassenListe ]
        )
        useEffect(
          () =>
            {
              const tmpSeparator = '______XXXXX______' ;
              /** Klassenfarben */
                /** Eindeutige Liste für alle definierten Gerätekategorien */
                  let myDeDupedGeraeteklassenFarben = {} ;
                  Array.from(
                    new Set(
                      appGeraeteklassenListe
                      .map(
                        ( entry:any ) => entry.GeraeteklassenName + tmpSeparator + entry.GeraeteklassenNameFarbe
                      )
                      .sort()
                    )
                  )
                  .forEach(
                    ( entry:any ) => 
                      {
                        // @ts-ignore
                        myDeDupedGeraeteklassenFarben[ entry.split( tmpSeparator )[ 0 ] ] = entry.split( tmpSeparator )[ 1 ] ;
                      }
                  )
                  // @ts-ignore
                  setDeDupedGeraeteklassenFarben( myDeDupedGeraeteklassenFarben ) ;
                /** Liste für tatsächlich vorhandene Geräte */
                  let myGeraeteklassenNamenFarben:any = [] ;
                  klassenChartDataLabel
                    .forEach(
                      ( entry:any ) =>
                        {
                          // @ts-ignore
                          if( myDeDupedGeraeteklassenFarben[ entry ] ) {
                            myGeraeteklassenNamenFarben
                              .push(
                                // @ts-ignore
                                myDeDupedGeraeteklassenFarben[ entry ]
                              )
                          }
                        }
                    )
                  /** Farbe für "unbekannt" dazu
                   *  - #F5F5F5 = "white smoke" wie "leer", aber vom weißen Hintergrund abgehoben
                  */
                    myGeraeteklassenNamenFarben.push( '#F5F5F5' ) ;
                  setGeraeteklassenNamenFarben( myGeraeteklassenNamenFarben ) ;
              /** Gruppenfarben */
                /** Eindeutige Liste für alle definierten Gerätegruppen */
                  let myDeDupedGeraetegruppenFarben = {} ;
                  Array.from(
                    new Set(
                      appGeraeteklassenListe
                      .map(
                        ( entry:any ) => entry.GeraeteklassenGeraetetyp + tmpSeparator + entry.GeraeteklassenGeraetetypFarbe
                      )
                      .sort()
                    )
                  )
                  .forEach(
                    ( entry:any ) => 
                      {
                        // @ts-ignore
                        myDeDupedGeraetegruppenFarben[ entry.split( tmpSeparator )[ 0 ] ] = entry.split( tmpSeparator )[ 1 ] ;
                      }
                  )
                /** Liste für tatsächlich vorhandene Geräte */
                  let myGeraetegruppenFarben:any = [] ;
                  typenChartDataLabel
                    .forEach(
                      ( entry:any ) =>
                        {
                          // @ts-ignore
                          if( myDeDupedGeraetegruppenFarben[ entry ] ) {
                            // @ts-ignore
                            myGeraetegruppenFarben.push( myDeDupedGeraetegruppenFarben[ entry ] ) ;
                          } else {
                            // @ts-ignore
                            myGeraetegruppenFarben.push( '#000000' ) ;
                          }
                        }
                    )
                  /** Farbe für "unbekannt" dazu
                   *  - #F5F5F5 = "white smoke" wie "leer", aber vom weißen Hintergrund abgehoben
                  */
                    myGeraeteklassenNamenFarben.push( '#F5F5F5' ) ;
                  setGeraetegruppenFarben( myGeraetegruppenFarben ) ;
            } ,
          [ appGeraeteklassenListe , klassenChartDataLabel ]
        )
      //#endregion Gerätekategorien-Liste auswerten
      useGeraeteListe() ;
      //#region Kopie Geräte-Liste für destruktive Filterung
        useEffect(
          () => {
            localGeraeteListeDataChange( filterAktive() ) ;
          } ,
          [ appGeraeteListeData ]
        )
          //#endregion Kopie Geräte-Liste für destruktive Filterung
    //#region GeraeteklassenGeraetetypFarben
          //#endregion GeraeteklassenGeraetetypFarben
            //#endregion Data-Fetches
      //#region Doughnuts Settings und Funktionen
      //#region Doughnut Einrichtungen
      useEffect(
        () =>
          {
            let myBetriebeIdsVsNames = betriebeIdsVsNames ;
            appBetriebeListe
              .forEach(
                ( entry:any ) =>
                    {
                      // @ts-ignore
                      myBetriebeIdsVsNames[ entry.betriebe_id ] = entry.betriebe_name ;
                      // @ts-ignore
                      myBetriebeIdsVsNames[ entry.betriebe_name ] = entry.betriebe_id ;
                    }
              )
            setbetriebeIdsVsNames( myBetriebeIdsVsNames ) ;
          } ,
        []
      )
      const dataEinrichtungen = {
        labels: einrichtungenChartDataLabel,
        datasets: [
          {
            label: 'Einrichtungen',
            data: einrichtungenChartDataValue,
            backgroundColor: farben,
            borderColor: [
            ],
            borderWidth: 1,
          }
        ],
      };
      const createChartDataEinrichtungen = () => {
        const counts: Array<number> = []
        localGeraeteListeData.forEach((geraete: any) => {
          var typ = geraete.betriebeId ;
          counts[typ] = counts[typ] ? counts[typ] + 1 : 1;
          seteinrichtungenZahlen(counts)
        })
      };
      useEffect(() => {
        let labels = [] ;
        let values: Array<number> = [] ;
        for (const [key, value] of Object.entries( einrichtungenZahlen ).sort() ) {
          // @ts-ignore
          labels.push( betriebeIdsVsNames[ key ] );
          values.push(value as number);
          seteinrichtungenChartDataLabel(labels)
          seteinrichtungenChartDataValue(values)
        }
      }, [ einrichtungenZahlen, seteinrichtungenChartDataValue ]);
        //#endregion Doughnut Einrichtungen
  //#region Doughnut Gerätekategorien
        const dataGerätekategorien = {
          labels: klassenChartDataLabel,
          datasets: [
            {
              label: 'Gerätekategorien',
              data: klassenChartDataValue,
              backgroundColor: geraeteklassenNamenFarben ,
              borderColor: [
              ],
              borderWidth: 1,
            }
          ],
        };
        const createChartDataKlassen = () => {
          /** Liste "Gerätekategorie: Zahl" initialisieren */
            const assocNumberTypesToClasses:any = [] ;
            appGeraeteklassenListe
              .forEach(
                ( entry:any ) =>
                  {
                    assocNumberTypesToClasses[ entry.GeraeteklassenName ] = 0 ;
                  }
              )
            // @ts-ignore: Irgendwann mal ordentlich typen ...
              assocNumberTypesToClasses[ 'unbekannt' ] = 0 ;
          /** Gerätegruppen in localGeraeteListeData nach Zuordnung in appGeraeteklassenListe zählen */
            let geraeteKlassenEntry:any ;
            localGeraeteListeData
              .forEach(
                ( geraet:any ) => {
                  geraeteKlassenEntry = appGeraeteklassenListe.find( ( entry:any ) => entry.GeraeteklassenGeraetetyp === geraet.GeraeteTyp ) ;
                  geraeteKlassenEntry
                    ?
                      assocNumberTypesToClasses[ geraeteKlassenEntry.GeraeteklassenName ] += 1
                    :
                      // @ts-ignore: Irgendwann mal ordentlich typen ...
                      assocNumberTypesToClasses[ 'unbekannt' ] += 1 ;
                  }
              )
              setgeraeteKlassenZahlen( assocNumberTypesToClasses ) ;
        };
        useEffect(() => {
          let labels = [] ;
          let values: Array<number> = [] ;
          for (const [key, value] of Object.entries( geraeteKlassenZahlen ).sort() ) {
            if( value !== 0 ) {
              labels.push(key);
              values.push(value as number);
              setklassenChartDataLabel(labels)
              setklassenChartDataValue(values)
            }
          }
        }, [ appGeraeteklassenListe , geraeteKlassenZahlen,setklassenChartDataLabel,setklassenChartDataValue]);
          //#endregion Doughnut Gerätekategorien
      //#region Doughnut Gerätegruppen
        const dataGeräteTypen = {
          labels: typenChartDataLabel,
          datasets: [
            {
              label: 'Gerätegruppen',
              data: typenChartDataValue,
              backgroundColor: geraetegrupenFarben ,
              borderColor: [
              ],
              borderWidth: 1,
            }
          ],
        };
        const createChartDataTyp = () => {
          const counts: Array<number> = []
          localGeraeteListeData.forEach((geraete: any) => {
            var typ = geraete.GeraeteTyp;
            counts[typ] = counts[typ] ? counts[typ] + 1 : 1;
            setgeraeteTypenZahlen(counts)
          })
        };
        useEffect(() => {
          let labels = [] ;
          let values: Array<number> = [] ;
          for (const [key, value] of Object.entries( geraeteTypenZahlen ).sort() ) {
            labels.push(key);
            values.push(value as number);
            settypenChartDataLabel(labels)
            settypenChartDataValue(values)
          }
        }, [geraeteTypenZahlen,settypenChartDataLabel,setklassenChartDataValue]);
          //#endregion Doughnut Gerätegruppen
      //#region Doughnut Gerätestatus
        const dataGeraeteStatus = {
          labels: ['ok','leichte Mängel','stillgelegt'],
          datasets: [
            {
              label: 'Gerätestatus',
              data: statusChartDataValue,
              backgroundColor: [
                'lightgreen',
                'orange',
                'lightgrey'
              ],
              borderColor: [
              ],
              borderWidth: 1,
            }
          ],
        };
        const createChartDataStatus = () => {
          const counts: Array<number>=[]
          counts[0]=0;
          counts[1]=0;
          counts[2]=0;
          localGeraeteListeData.forEach((geraete:any) => {
              var Status=geraete.GeraeteStatus;
              if(Status === 1){
                counts[0]=counts[0]+1
              }
              if(Status === 2){
                counts[1]=counts[1]+1
              }
              if(Status === 0){
                counts[2]=counts[2]+1
              }
              setstatusChartDataValue(counts)
          })
        };
          //#endregion Doughnut Gerätestatus
      //#region Doughnut Geräteeigentümer
        const dataGeräteEigentum = {
          labels: ['Haus','Fremd'],
          datasets: [
            {
              label: 'Gerätestatus',
              data: eigentumChartDataValue,
              backgroundColor: [
                'lightgreen',
                'orange'
              ],
              borderColor: [
              ],
              borderWidth: 1,
            }
          ],
        };
        const createChartDataEigentum = () => {
          const counts: Array<number>=[]
          counts[0]=0;
          counts[1]=0;
          localGeraeteListeData.forEach((geraete:any) => {
            var eigentuemer=geraete.GeraeteEigentuemer;
            if(eigentuemer === "Haus" || eigentuemer === "" || eigentuemer === null ){
              counts[0]=counts[0]+1
            }
            else{
              counts[1]=counts[1]+1
            }
            seteigentumChartDataValue(counts)
        })
        };
          //#endregion Doughnut Geräteeigentümer
      //#region Doughnut Gerätealter
        const dataGeräteAlter = {
          labels: ['<3 Jahre','<8 Jahre','<15 Jahre','>15 Jahre'],
          datasets: [
            {
              label: 'Gerätestatus',
              data: alterChartDataValue,
              backgroundColor: [
                'lightgreen',
                'green',
                'orange',
                'red'
              ],
              borderColor: [
              ],
              borderWidth: 1,
            }
          ],
        };
        const createChartDataAlter = () => {
          const counts: Array<number>=[]
          counts[0]=0;
              counts[1]=0;
              counts[2]=0;
              counts[3]=0;
          localGeraeteListeData.forEach((geraete:any) => {
              var baujahr=geraete.baujahr;
              if(baujahr>=2019){
                counts[0]=counts[0]+1
              }
              if(baujahr>=2014 && baujahr<2019){
                counts[1]=counts[1]+1
              }
              if(baujahr>=2007 && baujahr<2014){
                counts[2]=counts[2]+1
              }
              if(baujahr<2007){
                counts[3]=counts[3]+1
              }
              setalterChartDataValue(counts)
          })
        };
          //#endregion Doughnut Gerätealter
      //#region Doughnut MpgKlasse
      const dataMpgKlasse = {
        labels: [ 'kein Medizinprodukt' , 'I' ,'IIa' , 'IIb' , 'III' ],
        datasets: [
          {
            label: 'MPG-Klasse',
            data: MpgKlasseChartDataValue,
            backgroundColor: [
              'lightgrey' ,
              'Gold' ,
              'orange' ,
              'LightCoral',
              'red',
            ],
            borderColor: [
            ],
            borderWidth: 1,
          }
        ],
      };
      const createChartDataMpgKlasse = () => {
        const counts: Array<number>=[]
        counts[0]=0;
        counts[1]=0;
        counts[2]=0;
        counts[3]=0;
        counts[4]=0;
        localGeraeteListeData.forEach((geraete:any) => {
          switch( geraete.GeraeteMPGKlasse ) {
            case 'keine':
              counts[ 0 ] = counts[ 0 ] + 1 ;
              break ;
            case 'I':
              counts[ 1 ] = counts[ 1 ] + 1 ;
              break ;
            case 'IIa':
              counts[ 2 ] = counts[ 2 ] + 1 ;
              break ;
            case 'IIb':
              counts[ 3 ] = counts[ 3 ] + 1 ;
              break ;
            case 'III':
              counts[ 4 ] = counts[ 4 ] + 1 ;
              break ;
          }
          setMpgKlasseChartDataValue(counts)
      })
      };
        //#endregion Doughnut MpgKlasse
      //#endregion Doughnuts Settings und Funktionen
      //#region Doughnut-Aktualisierung
        useEffect(() => {
          createChartDataEinrichtungen()
          createChartDataTyp()
          createChartDataKlassen()
          createChartDataStatus()
          createChartDataEigentum()
          createChartDataAlter()
          createChartDataMpgKlasse()
        }, [localGeraeteListeData]);
          //#endregion Doughnut-Aktualisierung
    //#region Hilfsfunktionen
      const exportButton = () =>
      <IonButton
        onClick = {
          () => 
            { 
              let betriebe_liste_ids = appBetriebeId ;
              if( betriebe_liste_ids === '' ) {
                betriebe_liste_ids =
                  appBetriebeListe
                    .map(
                      ( entry:any ) =>
                        {
                          return entry.betriebe_id
                        }
                    )
                    .join( ',' )
                ;
              }
              window.open( 'https://datamehr-backend.saint-online.de/api/geraete/export_geraete.php?betriebe_id=' + betriebe_liste_ids )
            } 
        }
      >
        Exportieren
      </IonButton>
        //#endregion Hilfsfunktionen
    //#region React-Render
      return localGeraeteListeData.length === 0 ?
        (
          appGeraeteListeFetchstate === 200
            ?
              <IonSpinner name="bubbles" />
            :
              appGeraeteListeFetchstate === 204
                ?
                  <IonCard>
                    <IonCardContent>
                      <h2>
                        Keine Geräte gefunden
                      </h2>
                      <p>
                        Verwenden Sie den Punkt "Geräte" im Menü links, um Geräte hinzuzufügen.
                      </p>
                    </IonCardContent>
                  </IonCard>
                :
                  appGeraeteListeFetchstate === 100
                    ? 
                      <IonSpinner name="bubbles" />
                    :
                      <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                        { getLoadErr( appGeraeteListeFetchstate ) }
                      </div> 
        ) : (
          <>
            <IonButton
              disabled = {
                filterEinrichtungen
                || filterGeraeteklasse
                || filterGeraetetyp
                || filterGeraetestatus > -1
                || filterGeraeteeigentuemer > -1
                || filterGeraetealter > -1
                || filterMpgKlasse > -1
                  ?
                    false
                  :
                    true
              }
              onClick={ () =>
                {
                  setfilterEinrichtungen( '' ) ;
                  setfilterGeraeteklasse( '' ) ;
                  setfilterGeraetetyp( '' ) ;
                  setfilterGeraetestatus( -1 ) ;
                  setfilterGeraeteeigentuemer( -1 ) ;
                  setfilterGeraetealter( -1 ) ;
                  setfilterMpgKlasse( -1 ) ;
                }
              }
              style = {
                {
                  display: 'block' ,
                  margin: '1em auto 0 auto' ,
                  width: '12em'
                }
              }
              title = { 'Aufheben aller durch Klick in die Grafiken aktivierten Gerätelisten-Filter' }
            >
              Alle Filter aufheben
            </IonButton>
            <IonGrid>
              <IonRow>
                <IonCol size="4">
                  <IonCard style = { { margin: '0' } }>
                    <IonCardHeader>
                      <IonCardTitle>Einrichtungen</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style = { { backgroundColor: filterEinrichtungen ? 'whitesmoke' : 'unset' } }>
                      <Doughnut data={dataEinrichtungen} options={{
                        onClick: function (evt: any, element: any) {
                          filterEinrichtungen
                            ?
                              setfilterEinrichtungen( '' )
                            :
                              setfilterEinrichtungen( einrichtungenChartDataLabel[element[0].index] )
                        }
                      }}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="4">
                  <IonCard style = { { margin: '0' } }>
                    <IonCardHeader>
                      <IonCardTitle>Gerätekategorien</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style = { { backgroundColor: filterGeraeteklasse ? 'whitesmoke' : 'unset' } }>
                      <Doughnut data={dataGerätekategorien} options={{
                        onClick: function (evt: any, element: any) {
                          filterGeraeteklasse
                            ?
                              setfilterGeraeteklasse( '' )
                            :
                              setfilterGeraeteklasse( klassenChartDataLabel[element[0].index] )
                        }
                      }}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="4">
                  <IonCard style = { { margin: '0' } }>
                    <IonCardHeader>
                      <IonCardTitle>Gerätegruppen</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style = { { backgroundColor: filterGeraetetyp ? 'whitesmoke' : 'unset' } }>
                      <Doughnut data={dataGeräteTypen} options={{
                        onClick: function (evt: any, element: any) {
                          filterGeraetetyp
                            ?
                              setfilterGeraetetyp( '' )
                            :
                              setfilterGeraetetyp( typenChartDataLabel[element[0].index] )
                        }
                      }}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="3">
                  <IonCard style = { { margin: '0' } }>
                    <IonCardHeader>
                      <IonCardTitle>Gerätestatus</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style = { { backgroundColor: filterGeraetestatus > -1 ? 'whitesmoke' : 'unset' } }>
                      <Doughnut data={dataGeraeteStatus} options={{
                        onClick: function (evt: any, element: any) {
                          filterGeraetestatus > -1
                            ?
                              setfilterGeraetestatus( -1 )
                            :
                              setfilterGeraetestatus( element[0].index )
                        }
                      }} />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="3">
                <IonCard style = { { margin: '0' } }>
                  <IonCardHeader>
                    <IonCardTitle>Eigentümer</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent style = { { backgroundColor: filterGeraeteeigentuemer > -1 ? 'whitesmoke' : 'unset' } }>
                    <Doughnut data={dataGeräteEigentum} options={{
                      onClick: function (evt: any, element: any) {
                          filterGeraeteeigentuemer > -1
                          ?
                            setfilterGeraeteeigentuemer( -1 )
                          :
                            setfilterGeraeteeigentuemer( element[0].index )
                      }
                    }} />
                  </IonCardContent>
                </IonCard></IonCol>
                <IonCol size="3">
                  <IonCard style = { { margin: '0' } }>
                    <IonCardHeader>
                      <IonCardTitle>Alter</IonCardTitle>
                    </IonCardHeader>
                  <IonCardContent style = { { backgroundColor: filterGeraetealter > -1 ? 'whitesmoke' : 'unset' } }>
                      <Doughnut data={dataGeräteAlter} options={{
                        onClick: function (evt: any, element: any) {
                          filterGeraetealter > -1
                          ?
                            setfilterGeraetealter( -1 )
                          :
                            setfilterGeraetealter( element[0].index )
                        }
                      }} />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="3">
                <IonCard style = { { margin: '0' } }>
                  <IonCardHeader>
                    <IonCardTitle>MPG-Klasse</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent style = { { backgroundColor: filterMpgKlasse > -1 ? 'whitesmoke' : 'unset' } }>
                    <Doughnut data={dataMpgKlasse} options={{
                      onClick: function (evt: any, element: any) {
                          filterMpgKlasse > -1
                          ?
                            setfilterMpgKlasse( -1 )
                          :
                            setfilterMpgKlasse( element[0].index )
                      }
                    }} />
                  </IonCardContent>
                </IonCard></IonCol>
              </IonRow>
            </IonGrid>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(211,211,211)",
                },
              }}
            >
              <DataGrid
                disableVirtualization 
                componentsProps = {{
                  toolbar: {
                    tablePurpose: 'Geraete'
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'geraeteId' , sort: 'asc' } ] ,
                  },
                }}
                getRowId={(row) => row.geraeteId}
                rows={ localGeraeteListeData }
                /** Columns sollen in chart_geraete und table_geraete gleich sein
                 *  !!!!! Auslagern in zentrale Datei nicht einfach wg. TypeScript, deshalb
                 *        vorerst identische Kopien (bis auf Type-Script-Annotations wg. TSX
                 *        vs. JSX)
                 */
                  columns = {
                    [
                      defColumnRenderCell(
                        defColumn( 'betriebeId' , 'Einrichtung' , ( ! appBetriebeId ) && appBetriebeListe.length > 1 ? 200 : 0 ) ,
                        ( params:any ) =>
                          {
                            if( ( ! appBetriebeId ) && appBetriebeListe.length > 1 ) {
                              let match = appBetriebeListe
                                .find(
                                  ( entry:any ) => entry.betriebe_id === params.row.betriebeId
                                )
                              if( match ) {
                                params.row.betriebe_name = match.betriebe_name ;
                                return match.betriebe_name
                              }
                              else {
                                return '[Fehler]'
                              }
                            } 
                          }
                      ) ,
                      defColumnRenderCell(
                        defColumn( "GeraeteStatus" , "Status" , 60 ) ,
                        ( params:any ) => {
                          if ( params.row.GeraeteStatus === 0 || params.row.Aufgaben.length === 0 ) {
                            return <ThumbsDown  color="red"  />
                          }
                          else {
                            if ( params.row.GeraeteStatus === 1 ) {
                              return <ThumbsUp  color="green"  />
                            }
                            else {
                              if ( params.row.GeraeteStatus === 2 ) {
                                return <ThumbsUp  color="gold"  />
                              }
                            }
                          }
                        }
                      ) ,       
                      defColumn( "geraeteId" , "ID" , 200 ) ,
                      defColumn( "geraete_kunde_id1" , "KundenId" ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteTyp" , "Geräte Gruppe" ) ,
                      defColumn( "GeraeteHersteller" , "Geräte Hersteller" ) ,
                      defColumn( "GeraeteName" , "Geräte Typ" ) ,
                      defColumn( "baujahr" , "Baujahr" ) ,
                      defColumn( "GeraeteCE" , "CE" ) ,
                      defColumn( "GeraeteService" , "Servicepartner" ) ,
                      defColumn( "GeraeteStandort" , "Standort" ) ,
                      defColumn( "GeraeteEigentuemer" , "Eigentümer" ) ,
                      defColumn( "GeraeteWartung" , "nächste Wartung" )
                    ]
                  }
                pageSize={ pageSize }
                rowsPerPageOptions={[50, 100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                onCellClick = {
                  ( e ) =>
                    {
                      appViewSwitchChange( true ) ;
                      appBetriebeIdChange( e.row.betriebeId ) ;
                      appBetriebeNameChange( e.row.betriebe_name ) ;
                      appGeraeteIdChange( e.id ) ;
                      history.push( '/geraet/' + e.id + '/' + e.row.betriebeId ) ;
                     }
                }
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
            {
              appBetriebeId
                ?
                  <ButtonAdd
                    myExec = { () => { appGeraeteIdChange( 'neu' ) ; } }
                    routeUrl = { '/geraet/neu/' + appBetriebeId }
                  />
                :
                  ''
            }
            {
              localGeraeteListeData.length > 0
                ?
                  exportButton()
                :
                  ''
            }
          </>
      )
  }
        //#endregion React-Render
    //#endregion React-Komponente

export default ChartGeraete;
