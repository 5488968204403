import { IonButton, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../contexts/app_context";
import './table_geraete_grid.css';
import { useEinweisungsThemen } from "./_CustomHooks";
import { ButtonAdd , ButtonInaktive, defColumn , exportTableData , getLoadErr } from './_HelperFunctions' ;

function EinweisungsthemenTable() {
  const { appKundeId , appEinweisungsThemenFetchstate , appEinweisungsThemenListe , appSchulungIdChange , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [pageSize, setPageSize] = useState(50);
  const [ localEinweisungsThemenListe , localEinweisungsThemenListeChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const setColumns = ( tablePurpose:string ):any => {
    if( tablePurpose === 'Einweisungsthemen' ) {
      return (
        [
          defColumn( 'SchulungenId' , 'ID' , 0 ) ,
          defColumn( 'SchulungenTitel' , 'Thema' , 300 ) ,
          defColumn( 'SchulungenAktiv' , 'aktiv' , 80 , 0 ) ,
          defColumn( 'SchulungenPflicht' , 'Pflicht' , 80 , 0 ) ,
          {
            field: "SchulungenTurnus",
            headerName: "Turnus",
            headerClassName: "super-app-theme--header",
            minWidth: 200 ,
            flex: 0 ,
            renderCell: ( params:any ) => {
              if ( params.row.SchulungenTurnus === "0" ) {
                return 'nicht festgelegt'
              }
              if ( params.row.SchulungenTurnus === "1" ) {
                return 'Ersteinweisung'
              }
              if ( params.row.SchulungenTurnus === "90" ) {
                return 'vierteljährlich'
              }
              if ( params.row.SchulungenTurnus === "180" ) {
                return 'halbjährlich'
              }
              if ( params.row.SchulungenTurnus === "365" ) {
                return 'jährlich'
              }
              if ( params.row.SchulungenTurnus === "730" ) {
                return 'zwei Jahre'
              }
            }
          } ,
        ]
      )
    }
  }
  useEinweisungsThemen() ;

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localEinweisungsThemenListeChange( appEinweisungsThemenListe )
      }
      else {
        localEinweisungsThemenListeChange(
          appEinweisungsThemenListe
            .filter(
              ( entry:any ) => entry.SchulungenAktiv === 'aktiv'
            )        
        )
      }
    }

    useEffect(
      () => {
        if( appEinweisungsThemenListe
            .findIndex(
              ( entry:any ) => entry.SchulungenAktiv === 'inaktiv'
            ) > -1
        )
          {
            setExistInaktive( true )
          }
        else
          {
            setExistInaktive( false )
          }
        handleInaktive() ;
      } ,
      [ appEinweisungsThemenListe ]
    )
    
    useEffect(
      () => {
        handleInaktive() ;
      } ,
      [ showInaktive ]
    )
    
      const mkTable = ( tablePurpose:string , singleGUI:string ) =>  {
    return (
      <Box
        sx={{
          height: 600,
          width: 1,
          "& .super-app-theme--header": {
            backgroundColor: "rgb(211,211,211)",
          },
        }}
      >
        {
          appEinweisungsThemenFetchstate !== 200 && appEinweisungsThemenFetchstate !== 204 ?
          (
            appEinweisungsThemenFetchstate === 100
              ? <IonSpinner name="bubbles" />
              : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                  { getLoadErr( appEinweisungsThemenFetchstate ) }
                </div> 
          ) : (
            <>
              <DataGrid
                componentsProps = {{
                  toolbar: {
                    tablePurpose: tablePurpose
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'SchulungenTitel' , sort: 'asc' } ] ,
                  },
                }}
                getRowId={(row:any) => row.SchulungenId} 
                getRowClassName =
                  {
                    ( data:any ) => data.row.SchulungenAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                  }
                rows={localEinweisungsThemenListe}
                columns={ setColumns( tablePurpose ) } 
                disableVirtualization 
                rowsPerPageOptions={[50,100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onCellClick = {
                  ( e ) =>
                    {
                      appViewSwitchChange( true ) ;
                      appSchulungIdChange( e.id ) ; 
                      history.push( '/' + singleGUI + '/' + e.id + '/' + appKundeId ) ;
                    }
                }
                // checkboxSelection
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                //onCellClick={(e) => tryme(e.row)}
                // rowsPerPageOptions={[5, 10, 20]}
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
              <ButtonInaktive
                disabled = { existInaktive ? false : true }
                myExec = {
                  () =>
                    {
                      setShowInaktive( showInaktive ? false : true )
                    }
                  }
                label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
              />
              <ButtonAdd
                myExec = { () => { appSchulungIdChange( 'neu' ) ; } }
                routeUrl = { '/' + singleGUI + '/neu/' + appKundeId }
              />
              {
                localEinweisungsThemenListe.length > 0
                  ?
                    <IonButton onClick={ () => exportTableData( appEinweisungsThemenListe , 'Einweisungsthemen' ) }>Exportieren</IonButton>
                  :
                    ''
              }
              {
                showInaktive
                  ?
                    <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                      Inaktive Themen sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                    </p>
                  :
                    ''
              }
            </>
          )
        }
      </Box>
    )
  }
  return (
    <>
      { mkTable( 'Einweisungsthemen' , 'schulung' ) }
    </>
  );
}
export default EinweisungsthemenTable;
