/**
 * View FEHLER = Unbekannte Fehler
 * 
 */

import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { FunctionComponent, useContext , useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPageHelp , PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import { VersionInfo } from './Welcome';

export const FEHLER = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  let history = useHistory();
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const { appViewSwitchChange } = useContext( AppContext ) ;

  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Diese Seite erscheint, wenn keine zum Aufruf passende Seite gefunden wurde, was auf einen unbekannten Fehler hindeutet
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            ! Unerwarteter Fehler !
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle
              style =
                {
                  {
                    color: 'red'
                  }
                }
            >
              Ein unerwarteter Fehler ist aufgetreten
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <ul>
              <li>
                Fehler bei Aufruf "{ history.location.pathname }"
              </li>
              <li>
                Es konnte keine zum Aufruf passende Seite gefunden werden. 
              </li>
              <li>
                Eine automatische Fehler-Ermittlung war nicht erfolgreich.
              </li>
            </ul>
            <p>
              Versuchen Sie, den geplanten Vorgang zu wiederholen, evtl. nachdem Sie sich einmal ausgeloggt und neu eingeloggt haben.
            </p>
            <p>
              Bei wiederholtem Aufruf des Problems wenden Sie sich bitte mit einer möglichst genauen Beschreibung der Situation an den Support, d.h.
            </p>
            <ul>
              <li>
                welches Gerät / Browser verwenden Sie
              </li>
              <li>
                mit welcher Kennung waren Sie angemeldet
              </li>
              <li>
                bei welcher Aktion tritt der Fehler auf
              </li>
              <li>
                welche Aktionen hatten Sie vorher erfolgreich ausgeführt
              </li>
              <li>
                wie lange dauerte Ihre Sitzung insgesamt
              </li>
              <li>
                die vorliegende Programmversion: <VersionInfo/>
              </li>
            </ul>
            <IonButton
              onClick =
                {
                  () => history.goBack()
                }
            >
              Zurück zu vorheriger Seite
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

