/**
 * View Geraetegruppe
 * 
 * Einzelne Gerätegruppe **nur** anlegen
 * 
 */

import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGeraeteklassenListe } from '../components/_CustomHooks';
import { ButtonCancelBack, ButtonsSaveActions, ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';
import { HexColorInput, HexColorPicker } from "react-colorful";

export function Geraetegruppe() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;
      }
  ) ;
  const { appBetriebeId , appKundeId , appGeraetetypAddedChange , appGeraeteklassenId , appGeraeteklassenIdChange , appGeraeteklassenListe , appGeraeteklassenListeFetchanewChange , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ GeraeteklassenId, setGeraeteklassenId ] = useState( appGeraeteklassenId )
  const [ GeraeteklassenGeraetetyp, setGeraeteklassenGeraetetyp ] = useState( '' )
  const [ GeraeteklassenGeraetetypExists, setGeraeteklassenGeraetetypExists ] = useState( false ) ;
  const [ GeraeteklassenName, setGeraeteklassenName ] = useState( '' )
  const [ GeraeteklassenGeraetetypFarbe, setGeraeteklassenGeraetetypFarbe ] = useState("#000000") ;
  const [ GeraeteklassenNameFarbe, setGeraeteklassenNameFarbe ] = useState("#000000") ;
  const [ deDupedGeraeteGeraetegruppen , setDeDupedGeraeteGeraetegruppen ] = useState( [] ) ;
  const [ deDupedGeraeteklassenFarben , setDeDupedGeraeteklassenFarben ] = useState( [] ) ;
  // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  const [ fetchState , setFetchState ] = useState( 0 ) ;
  const [ geraeteklassenFromData , setGeraeteklassenFromData ] = useState( [] ) ;
  const getDataDB = ( GeraeteklassenId:string ) => {
    if (appGeraeteklassenId === 'neu') {
      setGeraeteklassenId( 'neu' ) ;
      setGeraeteklassenGeraetetyp( '' ) ;
      setGeraeteklassenGeraetetypFarbe( '#000000' )
      setGeraeteklassenName( '' ) ;
      setGeraeteklassenNameFarbe( '#000000' )
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/geraeteklassen/get_geraeteklasse.php?geraeteklassen_id=` + GeraeteklassenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((geraeteklassen: any) => {
          if (geraeteklassen.GeraeteklassenId === appGeraeteklassenId ) {
            setGeraeteklassenId( geraeteklassen.GeraeteklassenId ) ;
            setGeraeteklassenGeraetetyp( geraeteklassen.GeraeteklassenGeraetetyp ) ;
            setGeraeteklassenGeraetetypFarbe( geraeteklassen.GeraeteklassenGeraetetypFarbe )
            setGeraeteklassenName( geraeteklassen.GeraeteklassenName ) ;
            setGeraeteklassenNameFarbe( geraeteklassen.GeraeteklassenNameFarbe )
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    appGeraetetypAddedChange( GeraeteklassenGeraetetyp ) ;
    var raw = JSON.stringify({
      GeraeteklassenId: GeraeteklassenId ,
      GeraeteklassenGeraetetyp: GeraeteklassenGeraetetyp ,
      GeraeteklassenGeraetetypFarbe: GeraeteklassenGeraetetypFarbe ,
      GeraeteklassenName: GeraeteklassenName || "sonstiges" ,
      GeraeteklassenNameFarbe: GeraeteklassenNameFarbe ,
      betriebeId: appBetriebeId ,
      kundeId: appKundeId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    await fetch('https://datamehr-backend.saint-online.de/api/geraeteklassen/set_geraeteklasse.php', requestOptions)
      .then( response => response.json() )
    .then(
      json =>
       {
         if( json.entryId > 0 ) {
           // history.replace( history.location.pathname.replace( 'neu' , json.entryId ) ) ;
           appGeraeteklassenIdChange( json.entryId ) ;
           setGeraeteklassenId( json.entryId ) ;
         }
       }
      )
      appGeraeteklassenListeFetchanewChange( true ) ;
  }
  function onValueGeraeteklassenGeraetetyp( e:any ) { setGeraeteklassenGeraetetyp( e.target.value ) ; }
  function onValueGeraeteklassenGeraetetypCheck( e:any ) {
    // @ts-ignore
    if( deDupedGeraeteGeraetegruppen.indexOf( e.target.value) > -1 ) {
      // @ts-ignore
      setGeraeteklassenGeraetetypExists( true ) ;
      alert( 'Es existiert bereits eine Gruppe mit dem Namen "' + e.target.value + '"' ) ;
    } else {
      setGeraeteklassenGeraetetypExists( false ) ;
    }
  }
  function onValueGeraeteklassenName( e:any ) {
    setGeraeteklassenName( e.target.value ) ;
    // @ts-ignore
    if( deDupedGeraeteklassen.indexOf( e.target.value) > -1 ) {
      // @ts-ignore
      setGeraeteklassenNameFarbe( deDupedGeraeteklassenFarben[ e.target.value ] ) ;
      // @ts-ignore
    } else {
      setGeraeteklassenNameFarbe( '#000000' ) ;
    }
  }
  useGeraeteklassenListe() ;
  useEffect(
    () =>
      {
        setDeDupedGeraeteGeraetegruppen (
          Array.from(
            new Set(
              appGeraeteklassenListe
              .map(
                ( entry:any ) => entry.GeraeteklassenGeraetetyp
              )
              .sort()
            )
          )
        ) ;
        let myDeDupedGeraeteklassenFarben = {} ;
        const tmpSeparator = '______XXXXX______' ;
          Array.from(
            new Set(
              appGeraeteklassenListe
              .map(
                ( entry:any ) => entry.GeraeteklassenName + tmpSeparator + entry.GeraeteklassenNameFarbe
              )
              .sort()
            )
          )
          .forEach(
            ( entry:any ) => 
              {
                // @ts-ignore
                myDeDupedGeraeteklassenFarben[ entry.split( tmpSeparator )[ 0 ] ] = entry.split( tmpSeparator )[ 1 ] ;
              }
          )
          // @ts-ignore
        setDeDupedGeraeteklassenFarben( myDeDupedGeraeteklassenFarben ) ;
      } ,
      [ appGeraeteklassenListe ]
  ) ;
  const deDupedGeraeteklassen = Array.from(
    new Set(
      appGeraeteklassenListe
      .map(
        ( entry:any ) => entry.GeraeteklassenName
      )
      .sort()
    )
  )
  // const MOCK_GeraeteklassenGeraetetypFarbe:any = {
  //   'Hilfsmittel': '#0000FF' /* blue */ ,
  //   'Hygienetechnik': '#FFFF00' /* yellow */ ,
  //   'Medizinische Kleingeräte' : '#FF0000	' /* red */ ,
  //   'Patientenhandling' : '#00FF00	' /* lime */ ,
  //   'Rehatechnik' : '#000080	' /* navy */ ,
  //   'sonstiges' : '#800000	' /* maroon */ ,
  // }
  // useEffect(
  //   () => {
  //       /** Fallback ""#F5F5F5" (white smoke) für leeren "GeraeteklassenGeraetetyp" = neuer Eintrag */
  //     setGeraeteklassenGeraetetypFarbe( MOCK_GeraeteklassenGeraetetypFarbe[ GeraeteklassenGeraetetyp ] || '#F5F5F5' ) ;
  //   },
  //   [ GeraeteklassenGeraetetyp ]
  // );
  useEffect(
    () => {
      setDeDupedGeraeteklassenFarben( [] ) ;
      getDataDB( appGeraeteklassenId ) ;
      appGeraeteklassenListeFetchanewChange( true ) ;
    },
    [ appGeraeteklassenId ]
  );
  return (
    <IonPage>
      <PageHelp page = 'Gerätegruppe' state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für eine Gerätegruppe einsehen und bearbeiten oder für eine neue Gerätegruppe festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Gerätegruppe anlegen
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardContent>
            <IonItem className='item'>
              <IonLabel position="stacked">Gerätegruppe</IonLabel>
              <IonInput required value={ GeraeteklassenGeraetetyp } onIonChange={ ( e )=> onValueGeraeteklassenGeraetetyp( e ) } onBlur = { ( e ) => { onValueGeraeteklassenGeraetetypCheck( e ) } } onFocus = { () => setGeraeteklassenGeraetetypExists( false ) } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Gerätekategorie</IonLabel>
                <IonSelect cancelText="Abbrechen" interface = 'action-sheet' placeholder="sonstiges" value = { GeraeteklassenName } onIonChange={(e) => onValueGeraeteklassenName(e)} >
                  {
                    deDupedGeraeteklassen   
                      .map(
                        ( entry:any ) =>
                          {
                            return <IonSelectOption key = { entry } value = { entry } >{ entry }</IonSelectOption>
                          }
                      )
                  }
                </IonSelect>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Farben für Grafiken</IonLabel>
              <IonGrid>
                <IonRow>
                  <IonCol style = { { margin: '0 1em 0 1em' } }>
                    <span><span style={ { backgroundColor: GeraeteklassenGeraetetypFarbe , border: 'thin solid gray' , marginRight: '1em' , paddingRight: '3em' } } ></span>  { GeraeteklassenGeraetetyp || '(Gruppe)' }</span>
                    <HexColorPicker color = { GeraeteklassenGeraetetypFarbe } onChange = { setGeraeteklassenGeraetetypFarbe } />
                    <HexColorInput
                      color = { GeraeteklassenGeraetetypFarbe }
                      onChange = { setGeraeteklassenGeraetetypFarbe }
                      style = {
                        {
                          border: 'thin solid gray' ,
                          height: '1.5em' ,
                          marginTop: '0.5em' ,
                          width: '5em' 
                        }
                      }
                    />
                  </IonCol>
                  <IonCol
                    style = { { margin: '0 1em 0 1em' } }
                  >
                    <span>
                      <span
                        style = {
                          {
                            backgroundColor: GeraeteklassenNameFarbe ,
                            border: 'thin solid gray' ,
                            marginRight: '1em' ,
                            paddingRight: '3em'
                          }
                        }
                      >
                    </span>
                      { GeraeteklassenName || '(Klasse)' }
                    </span>
                    <HexColorPicker
                      color = { GeraeteklassenNameFarbe }
                      onChange = { setGeraeteklassenNameFarbe }
                    />
                    <HexColorInput
                      color = { GeraeteklassenNameFarbe }
                      onChange = { setGeraeteklassenNameFarbe }
                      style = {
                        {
                          border: 'thin solid gray' ,
                          height: '1.5em' ,
                          marginTop: '0.5em' ,
                          width: '5em' 
                        }
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <ButtonsSaveActions
              disabled = { GeraeteklassenGeraetetypExists }
              myExec = {
                () => {  sendJSON() ; }
              }
            />
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => {} } />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
