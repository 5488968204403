import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useState } from 'react';
import ArticlesTable from '../components/table_articles_grid';
// import ArticlesInternTable from '../components/table_articles_intern_grid';
import { ButtonPageHelp, PageHelp } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export const ArticlesIntern: React.FC = () => {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeName , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
    // const screencastChannel = 'c313iuVFHG'
  const screencastChannel = 'c313ixVFHL'
  return (
    <IonPage>
      <PageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } >
      <p>
           Auf dieser Seite erhalten Sie eine Liste der Artikel Ihrer Einrichtung. Für Details können Sie von hier direkt zu Einzel-Ansichten wechseln.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Artikel { appBetriebeName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* 
          ERSTMAL für Demo Gefahrstoffschulungen "Direktzugriff" auf Articles (saint_article) statt ArticelsIntern (saint_interne_artikel)
          => ! zu klären:
            - wo sollen Gefahrstoffthemen dranhängen? saint_artikel oder saint_interne_artikel?
          <ArticlesInternTable />
        */}
        <ArticlesTable />
      </IonContent>
    </IonPage>
  );
};
