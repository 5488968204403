/**
 * View Einweisung
 * 
 * Einzelnen Einweisung einsehen, bearbeiten oder erstellen
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDokumenteEinweisung , useEinweisungsThemen, useMitarbeiterListe } from '../components/_CustomHooks';
import { ButtonAdd, ButtonCancelBack, ButtonInaktive , ButtonsSaveActions, defColumn, defColumnRenderCell, einweisungstypKeyToTitle , exportTableData, getDokumentDatei , ButtonPageHelp, PageHelp,readableFileSize, SpinnerViewSwitch } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export function Einweisung() {
  // useIonViewWillEnter(
  //   () =>
  //     {
  //       appDokumenteEinweisungFetchanewChange( true ) ;  
  //     }
  // ) ;
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appDokumenteEinweisungFetchanewChange , appDokumenteEinweisung , appDokumenteEinweisungId , appDokumenteEinweisungIdChange , appEinweisungFetchanew ,appEinweisungFetchanewChange ,appEinweisungenId , appEinweisungenGeraetFetchanewChange , appEinweisungGeraeteThemen , appEinweisungenIdChange , appEinweisungenListeFetchanewChange , appEinweisungsThemenListe , appEinweisungsThemenFetchanewChange , appEinweisungenMatrixBuildNewChange , appEinweisungNeuTeilnehmer , appEinweisungNeuTeilnehmerChange , appEinweisungNeuThema , appEinweisungNeuThemaChange , appErrorChange , appGeraeteId , appGeraetSchulung , appMitarbeiterListe , appMitarbeiterListeFetchanewChange , appViewSwitch , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ EinweisungenId, setEinweisungenId ] = useState( appEinweisungenId )
  const [ EinweisungenBetriebeId, setEinweisungenBetriebeId ] = useState( appBetriebeId )
  const [ EinweisungenTyp, setEinweisungenTyp ] = useState( '' )
  const [ EinweisungenAnmerkungen, setEinweisungenAnmerkungen ] = useState( '' )
  const [ EinweisungenTermin, setEinweisungenTermin ] = useState( '' )
  const [ EinweisungenDozent, setEinweisungenDozent ] = useState( '' )
  const [ EinweisungenThema, setEinweisungenThema ] = useState( appEinweisungNeuThema )
  const [ EinweisungenMitarbeiterId, setEinweisungenMitarbeiterId ] = useState( appEinweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='') )
  const [ EinweisungenStatus, setEinweisungenStatus ] = useState( 'offen' )
  const [ disableEdit, setDisableEdit ] = useState( false )
  const [ addAllMitarbeiter , setAddAllMitarbeiter ] = useState( false ) ;
  /** ! "lastAddedMitarbeiter" auch als Trigger, damit Ionic re-rendert */
    const [ lastAddedMitarbeiter , setLastAddedMitarbeiter ] = useState( '' ) ;
  /** ! "anzahlTeilnehmer" auch als Trigger, damit Ionic re-rendert */
    const [ anzahlTeilnehmer , setAnzahlTeilnehmer ] = useState( appEinweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='').length ) ;
    const [ tableData , settableData ] = useState( Array() ) ;
    //#region Dialog-Dokument
    const [ localDokumenteEinweisung , localDokumenteEinweisungChange ] = useState( Array() ) ;
    const [ existInaktive , setExistInaktive ] = useState( false ) ;
    const [ showInaktive , setShowInaktive ] = useState( false ) ;
      /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
      //#endregion Dialog-Dokument
  //#region Listen-DataGrids für Attachments
    const [pageSize, setPageSize] = useState(10);
      //#endregion Listen-DataGrids für Attachments

  const mkMyEinweisungsThemenListe = ( generalList:any ) =>
    {
      let myList = 
        generalList
          .filter(
            ( entry:any ) => entry.SchulungenAktiv === 'aktiv'
          )
          .filter(
            ( entry:any ) => entry.SchulungenId !== EinweisungenThema
          )
      if( appGeraeteId !== 'neu' ) {
        myList =
          myList
            .filter(
              ( entry:any ) =>
                  appEinweisungGeraeteThemen
                    .findIndex(
                      ( geraetethema:any ) =>
                        geraetethema.GeraetId === appGeraeteId
                        &&
                        geraetethema.ThemaId === entry.SchulungenId
                    ) > -1
            )
      }
      return myList ;
    }

  const getDataEinweisungen = () => {
    if (appEinweisungenId === 'neu') {
      setEinweisungenId( 'neu' ) ;
      setEinweisungenBetriebeId( appBetriebeId ) ;
      setEinweisungenTyp( '' ) ;
      setEinweisungenThema( appEinweisungNeuThema ) ;
      setEinweisungenMitarbeiterId( appEinweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='') ) ;
      setAnzahlTeilnehmer( appEinweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='').length ) ;
      setEinweisungenTermin( '' ) ;
      setEinweisungenAnmerkungen( '' ) ;
      setEinweisungenDozent( '' ) ;
      setEinweisungenStatus( 'offen' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/einweisungen/get_einweisung.php?einweisungen_id=` + appEinweisungenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((einweisungen: any) => {
          if (einweisungen.EinweisungenId === EinweisungenId) {
            setEinweisungenId( einweisungen.EinweisungenId ) ;
            setEinweisungenBetriebeId( einweisungen.EinweisungenBetriebeId ) ;
            setEinweisungenTyp( einweisungen.EinweisungenTyp ) ;
            setEinweisungenThema( einweisungen.EinweisungenThema ) ;
            let myEinweisungenMitarbeiterId = einweisungen.EinweisungenMitarbeiterId.split(',').filter((e:any)=>e!=='') ;
            setEinweisungenMitarbeiterId( myEinweisungenMitarbeiterId ) ;
            setAnzahlTeilnehmer( myEinweisungenMitarbeiterId.length ) ;
            setEinweisungenTermin( einweisungen.EinweisungenTermin ) ;
            setEinweisungenAnmerkungen( einweisungen.EinweisungenAnmerkungen ) ;
            setEinweisungenDozent( einweisungen.EinweisungenDozent ) ;
            setEinweisungenStatus( einweisungen.EinweisungenStatus ) ;
            if( einweisungen.EinweisungenStatus === 'abgeschlossen' ) {
              setDisableEdit( true ) ;
            }
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var raw = JSON.stringify({
      EinweisungenId: EinweisungenId ,
      EinweisungenBetriebeId: EinweisungenBetriebeId ,
      EinweisungenTyp: EinweisungenTyp ,
      EinweisungenThema: EinweisungenThema || ' ',
      EinweisungenMitarbeiterId: EinweisungenMitarbeiterId.toString() || '' ,
      EinweisungenTermin: EinweisungenTermin || '0000-00-00' ,
      EinweisungenAnmerkungen: EinweisungenAnmerkungen || '' ,
      EinweisungenDozent: EinweisungenDozent || '' ,
      EinweisungenStatus: EinweisungenStatus
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    await fetch('https://datamehr-backend.saint-online.de/api/einweisungen/set_einweisung.php', requestOptions)
    .then( response => response.json() )
    .then(
      json =>
        {
          if( json.entryId > 0 ) {
            appEinweisungenIdChange( json.entryId ) ;
          }
        }
      )
    appEinweisungNeuTeilnehmerChange( '') ;
    appEinweisungNeuThemaChange( '' ) ;
    appEinweisungFetchanewChange( true ) ;
    appEinweisungenListeFetchanewChange( true ) ;
    appEinweisungenGeraetFetchanewChange( true ) ;
  }
  //#region chkFieldData
    /** field:string , shouldNotBe:any
     * @param fieldsAndVals   Array aus Arrays: zu prüfende Felder
     *                          - Array pro zu prüfendes Feld: [ feldTitel , feldShouldNotBe , feldVal ]
     *                            - z.B. [ 'Termin' , '' , EinweisungenTermin ]
     *                          - ggf. pro Feld mehrere Prüf-Anweisungen
     *                                
     */
      const chkFieldData = ( fieldsAndVals:any ) =>
        {
          let stopMessage = '' ;
          fieldsAndVals.forEach(
            ( entry:any ) =>
              {
                if( entry[ 1 ] === entry[ 2 ] ) {
                  let valAttribute = ( entry[ 1 ] !== '' ) ? 'gültigen ' : '' ;
                  stopMessage += '\n\n- Feld "' + entry[ 0 ] + '"\n      Bitte geben Sie einen ' + valAttribute + 'Wert ein' ;
                }
              }
          );
          if( stopMessage ) {
            appErrorChange( 'Einweisung ' + appEinweisungenId + ': ungültige oder fehlende Eingaben' ) ;
            alert( '\nEingaben können nicht verarbeitet werden:' +  stopMessage ) ;
            return false ;
          }
          else {
            appErrorChange( '' ) ;
            return true ;
          }

        }
      //#endregion chkFieldData
  function onValueEinweisungenTyp( e:any ) { setEinweisungenTyp( e.target.value ) ; }
  function onValueEinweisungenThema( e:any ) { setEinweisungenThema( e.target.value ) ; }
  function onValueEinweisungenStatus( e:any ) { setEinweisungenStatus( e.target.value ) ; }
  function onValueTeilnehmerListe( mitarbeiterId:any ) { 
    let myEinweisungenMitarbeiterId = EinweisungenMitarbeiterId ;
    if( mitarbeiterId === 'alle' ) {
      myEinweisungenMitarbeiterId = [] ;
      setLastAddedMitarbeiter( '' ) ;
    } else {
      myEinweisungenMitarbeiterId = myEinweisungenMitarbeiterId.filter( ( entry:any ) => entry !== mitarbeiterId ) ;
    }
    setEinweisungenMitarbeiterId( myEinweisungenMitarbeiterId ) ;
    setAnzahlTeilnehmer( myEinweisungenMitarbeiterId.length ) ;
    setAddAllMitarbeiter( false ) ;
  }
  function onValueTeilnehmerDazu( mitarbeiterId:any , mitarbeiterName:string ) { 
    let myEinweisungenMitarbeiterId = EinweisungenMitarbeiterId ;
    if( mitarbeiterId === 'alle' ) {
      setAddAllMitarbeiter( true ) ;
    } else {
      /** vermutlich wg. State / Rerender wird die Anweisung hier mehrfach durchlaufen, deshalb Check auf "schon gesetzt" */
        if( ! myEinweisungenMitarbeiterId.includes( mitarbeiterId ) ) {
          myEinweisungenMitarbeiterId.push( mitarbeiterId ) ;
        }
        setLastAddedMitarbeiter( mitarbeiterName ) ;
    }
    setEinweisungenMitarbeiterId( myEinweisungenMitarbeiterId ) ;
    setAnzahlTeilnehmer( myEinweisungenMitarbeiterId.length ) ;
  }
  function onValueEinweisungenTermin( e:any ) { setEinweisungenTermin( e.target.value ) ; }
    // "Thema"-Dropdown aus Einweisungsthemen-DB (Einstellungen)
  useEinweisungsThemen() ;

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteEinweisungChange( appDokumenteEinweisung )
      }
      else {
        localDokumenteEinweisungChange(
          appDokumenteEinweisung
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appDokumenteEinweisung
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appDokumenteEinweisung ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
    // "Teilnehmer"-Dropdown aus Mitarbeiter-DB (Einstellungen)
  useMitarbeiterListe() ;
    // "Dokumente"-Liste
  useDokumenteEinweisung() ;
  useEffect(
    () => {
      // if( history.location.pathname.includes( '/einweisung/') ) {
       setEinweisungenId( appEinweisungenId ) ;
       appEinweisungFetchanewChange( true ) ;
      // }
    },
    // [ history.location.pathname ]
    [ appEinweisungenId ]
  ) ;
  useEffect(
    () => {
      if( appEinweisungFetchanew ) {
        appEinweisungFetchanewChange( false ) ;
        if( appEinweisungenId !== 'neu' ) {
          appDokumenteEinweisungFetchanewChange( true ) ;
          appEinweisungsThemenFetchanewChange( true ) ;
          appMitarbeiterListeFetchanewChange( true ) ;
        }
        getDataEinweisungen() ;
        /** EinweisungsThema-Default auf appGeraeteSchulung setzen */
          if( ( EinweisungenThema + 'leer' === 'leer' ) && ( appGeraetSchulung !== '' ) ) {
            setEinweisungenThema( appGeraetSchulung )
          }
      }
    },
    [ appEinweisungFetchanew ]
  ) ;
  function onValueEinweisungenDozent(e: any) {
    setEinweisungenDozent(e.target.value); 
  }
  function onValueEinweisungenAnmerkungen(e: any) {
    setEinweisungenAnmerkungen(e.target.value); 
  }
  const getInhaltForEinweisungsThema = ( themaId:any ) =>
    {
      let themaMatch = appEinweisungsThemenListe
        .find(
          ( thema:any ) => thema.SchulungenId === themaId
      )
      if( themaMatch ) {
        return themaMatch.SchulungenInhalt ;
      }
    }
  const getTitleForEinweisungsThema = ( themaId:any ) =>
    {
      // themaId = 1135516
      // console.log( 'check' , themaId , 'in' , appEinweisungsThemenListe ) ;
      let themaMatch = appEinweisungsThemenListe
        .find(
          ( thema:any ) => thema.SchulungenId === themaId
      )
      // console.log( themaMatch ) ;
      if( themaMatch ) {
        return '"' + themaMatch.SchulungenTitel + '"' ;
      }
      else {
        return '' ;
      }
    }
  return (
    <IonPage>
      <SpinnerViewSwitch />
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für eine Einweisung einsehen und bearbeiten oder für eine neue Einweisung festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
              Einweisung { ' ' }
              {
                EinweisungenId === 'neu'
                  ?
                    '(neu)'
                  :
                     getTitleForEinweisungsThema( EinweisungenThema )
              }
              {
                EinweisungenId !== 'neu'
                  ?
                    EinweisungenTermin !== '0000-00-00'
                      ?
                        ' Termin ' + new Date( EinweisungenTermin ).toLocaleDateString()
                      :
                        ' Termin offen'
                  :
                    ''
              }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
          <IonItem className='item'>
              <IonLabel position="stacked">Thema</IonLabel>
              <IonSelect disabled = { disableEdit } cancelText="Abbrechen" interface = 'action-sheet' value={ EinweisungenThema } onIonChange={ ( e )=> onValueEinweisungenThema( e ) } >
                { EinweisungenThema
                    ? 
                      appEinweisungsThemenListe
                        .filter(
                          ( { SchulungenId }:any ) => SchulungenId === EinweisungenThema 
                        )
                        .map(
                          ( entry:any ) =>
                            {
                              let disabled = false ;
                              let disabledNote = '' ;
                              if( entry.SchulungenAktiv !== 'aktiv' )
                                {
                                  disabled = true ;
                                  disabledNote = ' (inaktiv )' ;
                                }
                                return <IonSelectOption key = { entry.SchulungenTitel + entry.SchulungenId } disabled = { disabled } value = { entry.SchulungenId } >{ entry.SchulungenTitel + disabledNote }</IonSelectOption>
                              }
                        )
                    : 
                      ''
                }
                {
                  mkMyEinweisungsThemenListe( appEinweisungsThemenListe )
                  .map(
                      ( entry:any ) =>
                        {
                          return <IonSelectOption key = { entry.SchulungenTitel + entry.SchulungenId } value = { entry.SchulungenId } >{ entry.SchulungenTitel }</IonSelectOption>
                        }
                    )
                }
              </IonSelect>
          </IonItem>
          <IonItem>
              <IonLabel position="stacked">Inhalt</IonLabel>
              <div>
                { getInhaltForEinweisungsThema( EinweisungenThema ) }
              </div>
            </IonItem>
          <IonItem>
              <IonLabel position="stacked">Typ</IonLabel>
              <IonSelect disabled = { disableEdit } cancelText="Abbrechen" interface = 'action-sheet' placeholder="bitte auswählen" value={ EinweisungenTyp } onIonChange={ ( e )=> onValueEinweisungenTyp( e ) } >
                <IonSelectOption key = { 'befaehigung' } value = { 'befaehigung' } > { einweisungstypKeyToTitle( 'befaehigung' ) } </IonSelectOption>
                <IonSelectOption key = { 'beauftragung' } value = { 'beauftragung' } > { einweisungstypKeyToTitle( 'beauftragung' ) } </IonSelectOption>
                <IonSelectOption key = { 'anwender' } value = { 'anwender' } > { einweisungstypKeyToTitle( 'anwender' ) } </IonSelectOption>
              </IonSelect>
            </IonItem>
          <IonItem>
            <IonLabel position="stacked">Anmerkungen</IonLabel>
            <IonInput disabled = { disableEdit } required value = { EinweisungenAnmerkungen } onIonChange = { ( e ) => onValueEinweisungenAnmerkungen( e ) } />
          </IonItem>
          <IonItem>
              <IonLabel position="stacked">Status</IonLabel>
              <IonSelect disabled = { disableEdit } cancelText="Abbrechen" interface = 'action-sheet' placeholder="bitte auswählen" value={ EinweisungenStatus } onIonChange={ ( e )=> onValueEinweisungenStatus( e ) } >
                <IonSelectOption key = { 'offen' } value = { 'offen' } >offen</IonSelectOption>
                <IonSelectOption key = { 'abgeschlossen' } value = { 'abgeschlossen' } >abgeschlossen</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Termin</IonLabel>
              <IonInput disabled = { disableEdit } type="date" placeholder="bitte auswählen" required value={ EinweisungenTermin }  onIonChange={(e) => onValueEinweisungenTermin(e)}/>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dozent/in</IonLabel>
              <IonInput disabled = { disableEdit } required value = { EinweisungenDozent } onIonChange = { ( e ) => onValueEinweisungenDozent( e ) } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Teilnehmerliste ( { anzahlTeilnehmer } Teilnehmer)</IonLabel>
              <div style={ { height: '200px' , overflowY: 'scroll' , width: '100%' } }>
                {
                  ( EinweisungenMitarbeiterId.length > 0 && ! disableEdit )
                    ?
                      <IonItem lines="none" className='item'>
                        <IonLabel slot="">alle entfernen</IonLabel>
                        <IonCheckbox disabled = { disableEdit } slot="start" key = 'alle' checked={ false } onIonChange={() => onValueTeilnehmerListe( 'alle' ) } />
                      </IonItem>
                    :
                      ''
                }
                {
                  appMitarbeiterListe
                    .filter(
                        ( entry:any ) => EinweisungenMitarbeiterId.includes( entry.MitarbeiterId )
                    )
                    .map(
                      ( entry:any ) =>
                          {
                            /* !!! Für "neu" inaktive Mitarbeiter ausblenden, für existierende Einweisungen als inaktiv markieren */
                              let disabledNote = '' ;
                              if( entry.MitarbeiterAktiv === 'inaktiv' )
                                {
                                  if( EinweisungenStatus === 'abgeschlossen' ) {
                                    disabledNote = ' (inaktiv )' ;
                                  } else {
                                    return '' ;
                                  }
                                }
                            // return <IonSelectOption key = { entry.MitarbeiterId } value = { entry.MitarbeiterId } >{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonSelectOption>
                            return (
                              <IonItem lines="none" className='item'>
                                <IonLabel slot="">{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonLabel>
                                <IonCheckbox disabled = { disableEdit } slot="start" key = { entry.MitarbeiterId + '_onList' } checked={ anzahlTeilnehmer > 0 ? true : false } onIonChange={() => onValueTeilnehmerListe( entry.MitarbeiterId ) } />
                              </IonItem>
                            )
                          }
                      )
                  }
              </div>
            </IonItem>
            {
               disableEdit
                ?
                  ''
                :
                  <IonItem className='item'>
                      <IonLabel position="stacked">Teilnehmer hinzufügen { lastAddedMitarbeiter !== '' ? '(zuletzt hinzugefügt:' + lastAddedMitarbeiter + ')' : '' }</IonLabel>
                        <div style={ { height: '200px' , overflowY: 'scroll' , width: '100%' } }>
                          {
                            ! addAllMitarbeiter
                              ?
                                <IonItem lines="none" className='item'>
                                  <IonLabel slot="">alle hinzufügen</IonLabel>
                                  <IonCheckbox disabled = { disableEdit } slot="start" key = 'alle' checked={ false } onIonChange={() => onValueTeilnehmerDazu( 'alle' , '' ) } />
                                </IonItem>
                              :
                                ''
                          }
                          {
                            appMitarbeiterListe
                            .filter(
                              ( entry:any ) => ! EinweisungenMitarbeiterId.includes( entry.MitarbeiterId )
                            )
                            .map(
                              ( entry:any ) =>
                                  {
                                    /* !!! Für "neu" inaktive Mitarbeiter ausblenden, für existierende Einweisungen als inaktiv markieren */
                                      let disabledNote = '' ;
                                      if( entry.MitarbeiterAktiv === 'inaktiv' )
                                        {
                                          if( EinweisungenStatus === 'abgeschlossen' ) {
                                            disabledNote = ' (inaktiv )' ;
                                          } else {
                                            return '' ;
                                          }
                                        }
                                    // return <IonSelectOption key = { entry.MitarbeiterId } value = { entry.MitarbeiterId } >{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonSelectOption>
                                    return (
                                      <IonItem lines="none" className='item'>
                                        <IonLabel slot="">{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonLabel>
                                        <IonCheckbox disabled = { disableEdit } slot="start" key = { entry.MitarbeiterId + '_offList' } checked={ addAllMitarbeiter } onIonChange={() => onValueTeilnehmerDazu( entry.MitarbeiterId , entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer ) } />
                                      </IonItem>
                                    )
                                  }
                              )
                          }
                        </div>
                  </IonItem>
            }
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardContent>
            {
              ! disableEdit
                ?
                  <>
                    <ButtonsSaveActions
                      myExec = {
                        () =>
                          {
                            if(
                              chkFieldData(
                                [
                                  [ 'Thema' , '' , EinweisungenThema ]  ,
                                  [ 'Termin' , '' , EinweisungenTermin ]
                                ]
                              )
                            )
                              {
                                sendJSON() ; }
                            else
                              {
                                return false ;
                              }
                          }
                      }
                    />
                      {
                        appEinweisungenId !== 'neu'
                          ?
                            <IonButton
                              onClick = {
                                () => 
                                  { 
                                    sendJSON() ;
                                    window.open( 'https://datamehr-backend.saint-online.de/api/einweisungen/print_einweisung_unterschriftenliste.php?einweisungen_id=' + appEinweisungenId )
                                  } 
                              }
                            >
                              Unterschriftenliste
                            </IonButton>
                          :
                            ''
                      }
                  </>
                :
                  ''
            }
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => { appEinweisungNeuTeilnehmerChange( '') ; appEinweisungNeuThemaChange( '' ) ; } } />
          </IonCardContent>
        </IonCard>
        {
            appEinweisungenId !== 'neu'
              ?
                <IonCard class="Dokumente">
                  <IonAlert
                    isOpen = { dokumentToActOn ? true : false }
                    backdropDismiss = { true }
                    header = 'Gewählte Datei ...'
                    message = ''
                    buttons = {
                      [
                        {
                          text: 'Herunterladen' ,
                          handler: () => {
                            getDokumentDatei( dokumentToActOn ) ;
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Listeneintrag bearbeiten' ,
                          handler: () => {
                            appViewSwitchChange( true ) ;
                            appDokumenteEinweisungIdChange( dokumentToActOn ) ;
                            history.push( '/dokument-einweisung/' + dokumentToActOn + '/' + appEinweisungenId )
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Abbrechen' ,
                          handler: () => {
                            setDokumentToActOn( '' ) ;
                          }
                        }
                      ]
                    }
                  />
                  <IonCardHeader>
                    <IonCardTitle>Dokumente</IonCardTitle>
                  </IonCardHeader>
                  {
                    appDokumenteEinweisung.length > 0
                      ?
                        <Box width="100vh"
                          sx={{
                            height: 300,
                            width: 1,
                            "& .super-app-theme--header": {
                              backgroundColor: "rgb(211,211,211)",
                            },
                          }}
                        >
                          <DataGrid
                            disableVirtualization 
                            initialState={{
                              sorting: {
                                sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                              },
                            }}
                            getRowId={(row) => row.DokumenteId}
                            getRowClassName =
                            {
                              ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                            }
                            rows = { localDokumenteEinweisung }
                            columns = {
                              [
                                defColumn( "DokumenteId" , "Id" , 0 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                  ( data:any ) =>
                                    new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                ) ,
                                defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                  ( data:any ) =>
                                    readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                ) ,
                                defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                              ]
                            } 
                            pageSize={pageSize}
                            rowsPerPageOptions={[10,50,100]}
                            disableSelectionOnClick
                            onCellClick =
                              {
                                ( e:any ) =>
                                  {
                                    /** Browser / Web vs. App nativ */
                                    if( navigator ) {
                                      setDokumentToActOn( e.id ) ;
                                    } else {
                                      appDokumenteEinweisungIdChange( e.id ) ;
                                      appViewSwitchChange( true ) ;
                                      history.push( '/dokument-einweisung/' + e.id + '/' + appEinweisungenId )
                                    }
                                  }
                              }
                            sortingOrder={["desc", "asc"]}
                            onPageSizeChange={(newPage) => setPageSize(newPage)}
                            pagination
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                          />
                        </Box>
                      :
                        ""
                  }
                  <ButtonInaktive
                    disabled = { existInaktive ? false : true }
                    myExec = {
                      () =>
                        {
                          setShowInaktive( showInaktive ? false : true )
                        }
                    }
                    label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                  />
                  <ButtonAdd
                    myExec = { () => { appDokumenteEinweisungIdChange( 'neu' ) ; } }
                    routeUrl = { '/dokument-einweisung/neu/' + appEinweisungenId }
                  />
                  {
                    tableData.length > 0
                      ?
                        <IonButton onClick={ () => exportTableData( appDokumenteEinweisung , 'Dokumente Einweisung ' + appEinweisungenId ) }>Exportieren</IonButton>
                      :
                        ''
                  }
                </IonCard>
              :
                ''
          }
      </IonContent>
    </IonPage>
  );
};

