import { IonButton, IonSpinner } from "@ionic/react";
import Box from "@mui/material/Box";
import { DataGrid , deDE } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../contexts/app_context";
import './table_geraete_grid.css';
import { useGeraetegruppenGiven , useGeraeteklassenListe } from "./_CustomHooks";
import { ButtonAdd, ButtonInaktive , defColumn , defColumnRenderCell , exportTableData , getLoadErr  } from './_HelperFunctions' ;

function GeraeteklassenTable( { betriebeId }:{ betriebeId:string } ) {
  const { appKundeId , appGeraetegruppenGiven , appGeraetegruppenGivenFetchanewChange , appGeraeteklassenListeFetchstate , appGeraeteklassenIdChange , appGeraeteklassenListe , appViewSwitchChange } = useContext( AppContext ) ;
  const history = useHistory();
  const [pageSize, setPageSize] = useState(50);
    /** Kopie appGeraeteklassenListe für destruktive Filterung */
  const [ localGeraeteklassenListe , localGeraeteklassenListeChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const setColumns = ( tablePurpose:string ):any => {
    if( tablePurpose === 'Geraeteklassen' ) {
      return (
        [
          // Id-Feld als eindeutiger Key für Sortierungen => KLAPPT NICHT
          {
            field: 'GeraeteklassenId' ,
            hide: true
          } ,
          defColumnRenderCell( 
            defColumn( 'GeraeteklassenName' , 'Gerätekategorie' , 200 ) ,
            ( data:any ) => <span><span style={ { backgroundColor: data.row.GeraeteklassenAktiv === 'aktiv' ? data.row.GeraeteklassenNameFarbe : 'lightgray' , marginRight: '1em' , paddingRight: '1em' } } ></span>  { data.row.GeraeteklassenName }</span>
          ) ,
          defColumnRenderCell( 
            defColumn( 'GeraeteklassenGeraetetyp' , 'Gerätegruppe', 200 ) ,
            ( data:any ) => <span><span style={ { backgroundColor: data.row.GeraeteklassenAktiv === 'aktiv' ? data.row.GeraeteklassenGeraetetypFarbe : 'lightgray' , marginRight: '1em' , paddingRight: '1em' } } ></span>  { data.row.GeraeteklassenGeraetetyp }</span>
          ) ,
        ]
      )
    }
  }
  const filterByGivenGeraetegruppen =
    ( list:any ) =>
      list
        .filter(
          ( entry:any ) => appGeraetegruppenGiven.includes( entry.GeraeteklassenGeraetetyp )
        )
  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localGeraeteklassenListeChange( appGeraeteklassenListe )
      }
      else {
        localGeraeteklassenListeChange(
          appGeraeteklassenListe
            .filter(
              ( entry:any ) => entry.GeraeteklassenAktiv === 'aktiv'
            )        
        )
      }
    }

  useGeraeteklassenListe() ;
  useGeraetegruppenGiven() ;
  useEffect(
    () =>
      {
        appGeraetegruppenGivenFetchanewChange( true ) ;
      } ,
      [ appGeraeteklassenListe ]
  ) ;
  // const MOCK_GeraeteklassenFarbe:any = {
  //   'Hilfsmittel': '#0000FF' /* blue */ ,
  //   'Hygienetechnik': '#FFFF00' /* yellow */ ,
  //   'Medizinische Kleingeräte' : '#FF0000	' /* red */ ,
  //   'Patientenhandling' : '#00FF00	' /* lime */ ,
  //   'Rehatechnik' : '#000080	' /* navy */ ,
  //   'sonstiges' : '#800000	' /* maroon */ ,
  // }
  useEffect(
    () => {
      if( appGeraeteklassenListe
          .findIndex(
            ( entry:any ) => entry.GeraeteklassenAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appGeraeteklassenListe ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  const mkTable = ( tablePurpose:string , singleGUI:string , appKundeId:string ) =>  {
    return (
      <Box
        sx={{
          height: 600,
          width: 1,
          "& .super-app-theme--header": {
            backgroundColor: "rgb(211,211,211)",
          },
        }}
      >
        {
          appGeraeteklassenListeFetchstate !== 200 && appGeraeteklassenListeFetchstate !== 204 ?
          (
            appGeraeteklassenListeFetchstate === 100
              ? <IonSpinner name="bubbles" />
              : <div style={{ margin: '30%' , opacity: '40%' , textAlign: 'center' }}>
                  { getLoadErr( appGeraeteklassenListeFetchstate ) }
                </div> 
          ) : (
            <>
              <DataGrid
                disableVirtualization 
                componentsProps = {{
                  toolbar: {
                    tablePurpose: tablePurpose
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [ { field: 'GeraeteklassenName' , sort: 'asc' } ] ,
                  },
                }}
                getRowId={(row:any) => row.GeraeteklassenId} 
                getRowClassName =
                  {
                    ( data:any ) => data.row.GeraeteklassenAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                  }
                rows={ filterByGivenGeraetegruppen( localGeraeteklassenListe ) }
                columns={ setColumns( tablePurpose ) } 
                rowsPerPageOptions={[50,100]}
                disableSelectionOnClick
                sortingOrder={["desc", "asc"]}
                onCellClick = {
                  (e) => 
                    {
                      appViewSwitchChange( true ) ;
                      appGeraeteklassenIdChange( e.id ) ; 
                      history.push( '/' + singleGUI + '/' + e.id + '/' + appKundeId ) ;
                    }
                }
                // checkboxSelection
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                //onCellClick={(e) => tryme(e.row)}
                // rowsPerPageOptions={[5, 10, 20]}
                pagination
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
              <ButtonInaktive
                disabled = { existInaktive ? false : true }
                myExec = {
                  () =>
                    {
                      setShowInaktive( showInaktive ? false : true )
                    }
                  }
                label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
              />
              <ButtonAdd
                myExec = { () => { appGeraeteklassenIdChange( 'neu' ) ; } }
                routeUrl = { '/' + singleGUI + '/neu/' + appKundeId }
              />
              {
                filterByGivenGeraetegruppen( appGeraeteklassenListe ).length > 0
                  ?
                    <IonButton onClick={ () => exportTableData( filterByGivenGeraetegruppen( appGeraeteklassenListe ) , 'Gerätekategorien' ) }>Exportieren</IonButton>
                  :
                    ''
              }
              {
                showInaktive
                  ?
                    <p style = { { fontSize: '0.8em' , fontWeight: 'bold' , marginLeft: '0.5em' , marginTop: '0' } } >
                      Inaktive Zuordnungen sind <span className = "eintrag_inaktiv">hellgrau</span> markiert
                    </p>
                  :
                    ''
              }
            </>
          )
        }
      </Box>
    )
  }
  return (
    <>
      { mkTable( 'Geraeteklassen' , 'geraetekategorie' , appKundeId ) }
    </>
  );
}
export default GeraeteklassenTable;
