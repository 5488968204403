import { IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
export const Logout: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    window.localStorage.removeItem( 'token' )  ;
    window.localStorage.removeItem( 'jwt' )  ;
    history.push( "/" ) ;
    window.location.reload() ;
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Auf Wiedersehen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Logout</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard class="welcome-card">
          <IonCardContent>
      Wir wünschen einen Guten Tag
    </IonCardContent>
  </IonCard>
      </IonContent>
    </IonPage>
  );
};
