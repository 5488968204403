/**
 * View Aufgabe
 * 
 * Einzelne Aufgabe einsehen, bearbeiten oder erstellen
 * 
 *  - Nur für in datamehr-DB gepflegte Aufgaben
 *    - "MM_"-Aufgaben = in medmehr-DB gepflegte Aufgaben bekommen externe Links
 *    - ! in datamehr-DB auch Aufgaben für "MM_"-Geräte möglich, die Aufgaben-IDs dann aber ohne "MM_"
 * 
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter } from '@ionic/react';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDokumenteAufgabe, useLieferantenListe } from '../components/_CustomHooks';
import { ButtonAdd, ButtonCancelBack, ButtonInaktive , defColumn, defColumnRenderCell, exportTableData, getDokumentDatei, ButtonPageHelp, PageHelp,readableFileSize } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export function Aufgabe() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appAufgabeServicepartner , appAufgabeServicepartnerChange , appAufgabeServicepartnerAdded , appAufgabeServicepartnerAddedChange , appBetriebeName , appAufgabeFetchanew , appAufgabeFetchanewChange , appAufgabenListeFetchanewChange , appAufgabenGeraetFetchanewChange , appAufgabenId , appAufgabenIdChange , appGeraeteId , appDokumenteAufgabeFetchanewChange , appDokumenteAufgabe , appDokumenteAufgabeIdChange , appKundeDatamehrMail , appGeraeteListeFetchanewChange , appKundeId , appLieferantenListe , appUserIstBasic , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ geraeteId, setgeraeteId ] = useState( appGeraeteId )
  const [ GeraeteName, setGeraeteName ] = useState( '' )
  const [ AufgabenProblem, setAufgabenProblem ] = useState( '' )
  const [ AufgabenLoesung, setAufgabenLoesung ] = useState( '' )
  const [ AufgabenUser, setAufgabenUser ] = useState( '' )
  const [ AufgabenTyp, setAufgabenTyp ] = useState( '' )
  const [ disableEditBeauftragt, setDisableEditBeauftragt ] = useState( false )
  const [ AufgabenServicepartner, setAufgabenServicepartner ] = useState( '' )
  const [ AufgabenStatus, setAufgabenStatus ] = useState( 0 )
  const [ AufgabenGesamt, setAufgabenGesamt ] = useState( 2 )
  const [ AufgabenErsteller, setAufgabenErsteller ]:any = useState()
  const [ AufgabenErstellung, setAufgabenErstellung ] = useState( new Date().toISOString().substring( 0 , 10 ) )
  const [ AufgabenAenderung, setAufgabenAenderung ] = useState( new Date().toISOString().substring( 0 , 10 ) )
  const [ AufgabenErledigung, setAufgabenErledigung ] = useState( '0000-00-00' )
  const [ AufgabenAngebotsnummer	, setAufgabenAngebotsnummer	 ] = useState( '' )
  const [ localDokumenteAufgabe , localDokumenteAufgabeChange ] = useState( Array() ) ;
  const [ existInaktive , setExistInaktive ] = useState( false ) ;
  const [ showInaktive , setShowInaktive ] = useState( false ) ;
  const [ emailSendDialog , setEmailSendDialog ] = useState( 'hidden' ) ;
  const [ emailSendOption , setEmailSendOption ] = useState( 'manuell' ) ;
  const [ emailSendStandardMailerKickedOff , setEmailSendStandardMailerKickedOff ] = useState( false ) ;
  const [ emailAufgabenServicepartner, setEmailAufgabenServicepartner ] = useState( '' )
  const [ sendAufgabeSendenArtLabel, setSendAufgabeSendenArtLabel ] = useState( 'Angebot / Auftrag' ) ;
  const [ sendAufgabeSendenAktionLabel, setSendAufgabeSendenAktionLabel ] = useState( 'anfordern / beauftragen / versenden' ) ;
  const [ sendAufgabeState, setSendAufgabeState ] = useState( 0 ) ;
  const [ sendAufgabeMsg, setsendAufgabeMsg ] = useState( '' ) ;
  const [ reEditToCorrect, setReEditToCorrect ] = useState( false ) ;
  const [ fromAngebot , setFromAngebot ] = useState( false ) ;
  /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
  const [pageSize, setPageSize] = useState(10);
    // NB: "fetchState" bzw. "setFetchState" momentan nur für Syntax getDate()
  const [ fetchState , setFetchState ] = useState( 0 ) ;
  const capitalize = ( word:String ) => word[ 0 ].toUpperCase() + word.substring( 1 ) ;
  const adjustAufgabeSendenAktionLabel =
    ( type:String ) =>
      {
        if( sendAufgabeSendenArtLabel !== 'Angebot' ) {
          return sendAufgabeSendenAktionLabel ;
        } else {
          if( type.includes( 'b' ) ) {
            return sendAufgabeSendenAktionLabel.split( ' / ' )[ 1 ]
          } else {
            return sendAufgabeSendenAktionLabel.split( ' / ' )[ 0 ]
          }
        }
      }
  const adjustAufgabeSendenAktionObject =
    ( type:String , kasus = 0 ) =>
      {
        if( sendAufgabeSendenArtLabel !== 'Angebot' ) {
          let articles = [ '' , 'der ' , 'des ' , 'dem ' , 'den ' ]
          return articles[ kasus ] + 'Auftrag' ;
        } else {
          if( type.includes( 'b' ) ) {
            let articles = [ '' , 'der ' , 'des ' , 'dem ' , 'den ' ]
            return articles[ kasus ] + 'Auftrag'
          } else {
            let articles = [ '' , 'die ' , 'der ' , 'der ' , 'die ' ]
            return articles[ kasus ] + 'Bitte um ein Angebot'
          }
        }
      }
  const getDataAufgaben = ( AufgabenId:string ) => {
    if (appAufgabenId === 'neu') {
      setGeraeteName( '' ) ;
      setAufgabenErsteller( JSON.parse( '' + window.localStorage.getItem("token") ).usr_name ) ;
      setAufgabenErstellung( new Date().toISOString().substring( 0 , 10 ) ) ;
      setAufgabenAenderung( new Date().toISOString().substring( 0 , 10 ) ) ;
      setAufgabenErledigung( '0000-00-00' ) ;
      setAufgabenAngebotsnummer( '' ) ;
      setAufgabenProblem( '' ) ;
      setAufgabenLoesung( '' ) ;
      setAufgabenStatus( 0 ) ;
      setAufgabenGesamt( 2 ) ;
      setAufgabenTyp( '' ) ;
      if( ( appGeraeteId + '').substring(0, 3) === 'MM_' ) {
        setAufgabenServicepartner( 'medmehr' )
      } else {
        setAufgabenServicepartner( '' )
      }
      if (appAufgabeServicepartnerAdded === '') {
        appAufgabeServicepartnerChange( '' ) ;
      }
      setAufgabenUser( '' ) ;
      setgeraeteId( appGeraeteId ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/aufgaben/get_aufgabe.php?aufgaben_id=` + AufgabenId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((aufgaben: any) => { 
          if (aufgaben.AufgabenId === appAufgabenId) {
            setGeraeteName( aufgaben.GeraeteName ) ;
            setAufgabenErsteller( aufgaben.AufgabenErsteller  ) ;
            setAufgabenErstellung( aufgaben.AufgabenErstellung  ) ;
            setAufgabenAenderung( aufgaben.AufgabenAenderung  ) ;
            setAufgabenErledigung( aufgaben.AufgabenErledigung  ) ;
            if( aufgaben.AufgabenAngebotsnummer !== null ) {
              setAufgabenAngebotsnummer( aufgaben.AufgabenAngebotsnummer  ) ;
            }
            setAufgabenProblem( aufgaben.AufgabenProblem ) ;
            setAufgabenLoesung( aufgaben.AufgabenLoesung ) ;
            setAufgabenStatus( aufgaben.AufgabenStatus ) ;
            setAufgabenGesamt( aufgaben.AufgabenGesamt ) ;
            setAufgabenTyp( aufgaben.AufgabenTyp ) ;
            if( [ '4b' , '5b' , '9b' ].includes( aufgaben.AufgabenTyp ) ) {
              setDisableEditBeauftragt( true ) ;
            }
            if( ( appGeraeteId + '').substring(0, 3) === 'MM_' ) {
              setAufgabenServicepartner( 'medmehr' )
            } else {
              setAufgabenServicepartner( aufgaben.AufgabenServicepartner )
            }
            if (appAufgabeServicepartnerAdded === '') {
              appAufgabeServicepartnerChange( aufgaben.AufgabenServicepartner ) ;
            }
            setAufgabenUser( aufgaben.AufgabenUser ) ;
            setgeraeteId( aufgaben.geraeteId ) ;
          }
      })
      })
    }
  }
  function sendJSON( myAufgabenStatus:any = 0 , goBack:boolean = true , myAufgabenTyp:any = AufgabenTyp ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var raw = JSON.stringify({
      // AufgabenId: `${ AufgabenId }`,
      AufgabenId: appAufgabenId ,
      AufgabenLoesung: `${AufgabenLoesung}` || '(nicht angegeben)' ,
      AufgabenProblem: `${AufgabenProblem}` || '(nicht angegeben)' ,
      AufgabenStatus: myAufgabenStatus === 999 ? 100 : myAufgabenStatus ,
      AufgabenGesamt: `${AufgabenGesamt}` || '(nicht angegeben)' ,
      AufgabenTyp: myAufgabenTyp || '(nicht angegeben)' ,
      AufgabenServicepartner: appAufgabeServicepartner || '(nicht angegeben)' ,
      AufgabenUser: `${AufgabenUser}` || '(nicht angegeben)' ,
      AufgabenGeraet: geraeteId ,
      AufgabenErsteller: `${AufgabenErsteller}` || '(nicht angegeben)' ,
      AufgabenErstellung: AufgabenErstellung || new Date().toISOString().substring( 0 , 10 ) ,
      AufgabenErledigung: AufgabenErledigung.replace( '0000-00-00' , '' ) || ( myAufgabenStatus === 100 ? new Date().toISOString().substring( 0 , 10 ) : '0000-00-00' ) ,
      AufgabenAngebotsnummer: `${ AufgabenAngebotsnummer }` || '' ,
      AufgabenAenderung:
        myAufgabenStatus === 999
          ?
            AufgabenErledigung
          :
            AufgabenStatus === myAufgabenStatus ? AufgabenAenderung : new Date().toISOString().substring( 0 , 10 )
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://datamehr-backend.saint-online.de/api/aufgaben/set_aufgabe.php', requestOptions)
      .then( response => response.json() )
      .then(
        json =>
          {
            if( json.entryId > 0 ) {
              appAufgabenIdChange( json.entryId ) ;
            }
          }
        )
      .then(
        () =>
          {
            if( myAufgabenStatus !== 999 && AufgabenStatus - 100 === 0 ) { 
              setAufgabenStatus( 0 ) ;
              // history.replace( history.location.pathname ) ;
            }
            else {
              if( goBack ) {
                history.goBack()
              }
           }  
          }  
        )
  }
  const sendAufgabe = async () =>
    { 
      setSendAufgabeState( 100 ) ;
      // @ts-ignore
      const user_id = JSON.parse( window.localStorage.getItem( 'token' ) ).usr_id ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders
      };
      await fetch(
        'https://datamehr-backend.saint-online.de/api/aufgaben/send_auftrag.php?aufgaben_id=' + appAufgabenId + '&user_id=' + user_id + '&output=F&kunden_id=' + appKundeId , requestOptions
      )
        .then(
          ( response ) =>
            {
              setSendAufgabeState( response.status ) ;
            }
        )
    } 

  function onValueAufgabenTyp(e: any) {
    setAufgabenTyp(e.target.value);
    switch( e.target.value.replace( 'b' , '' ) ) {
      case '5':
        setSendAufgabeSendenArtLabel( 'Auftrag' ) ;
        setSendAufgabeSendenAktionLabel( 'versenden' ) ;
        break ;
      case '4':
        setSendAufgabeSendenArtLabel( 'Auftrag' ) ;
        setSendAufgabeSendenAktionLabel( 'versenden' ) ;
        break ;
      case '9':
        setSendAufgabeSendenArtLabel( 'Angebot' ) ;
        setSendAufgabeSendenAktionLabel( 'anfordern / beauftragen' ) ;
        break ;
      default:
        setSendAufgabeSendenArtLabel( 'Angebot / Auftrag' ) ;
        setSendAufgabeSendenAktionLabel( 'anfordern / beauftragen / versenden' ) ;
    }
  }
  function onValueAufgabenServicepartner(e: any) {
    /** Servicepartner neu anlegen oder auswählen
     *  @param  appAufgabeServicepartner        Wörtlicher Wert für "Servicepartner" in Formular und sendJSON
     *                                    - app-State für garantierte Aktualisierung
     *  @param  appAufgabeServicepartnerAdded   Wörtlicher Wert für "Servicepartner" nach Neuanlegen in
     *                                  Servicepartner-Einzel-Ansicht
     *                                    - wenn leer, dann Neuanlegen Servicepartner z.B. abgebrochen
    */
    if ( e.target.value === '<neu anlegen>' ) {
      appAufgabeServicepartnerAddedChange( '' ) ;
      /**
       * FIXME
       * - !!! die "Rückkehr" von "Lieferant" (Servicepartner) funktionierte bislang nur aufgrund eines Fehlers,
       * d.h. nur scheinbar: es wurde kein neuer Lieferant angelegt, sondern der zuletzt ausgewählte geändert !!!
       * - durch diverse Refactorings (exakte Änderung unklar) wird jetzt richtigerweise ein neuer Servicepartner
       * angelegt, für die Rückkehr muss aber zweimal auf "Speichern und Zurück" gedrückt werden und der neue
       * Lieferant wird nicht automatisch hier als gewählt eingesetzt
       */
      history.push( '/lieferant/neu/' + appKundeId ) ;
    }
    else {
      if ( appAufgabeServicepartnerAdded !== '' ) {
        appAufgabeServicepartnerChange( appAufgabeServicepartnerAdded )
        setAufgabenServicepartner( appAufgabeServicepartnerAdded )
      } else {
        appAufgabeServicepartnerChange( e.target.value )
        setAufgabenServicepartner( e.target.value )
      }
    }
  }
  function onValueAufgabenProblem(e: any) {
    setAufgabenProblem(e.target.value);
  }
  function onValueAufgabenLoesung(e: any) {
    setAufgabenLoesung(e.target.value);
  }
  function onValueAufgabenUser(e: any) {
    setAufgabenUser(e.target.value);
  }
  function onValueAufgabenGesamt(e: any) {
    setAufgabenGesamt( e.target.value );
  }
  function onValueEmailSendOption(e: any) {
    setEmailSendOption( e.target.value );
  }
  useDokumenteAufgabe();

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localDokumenteAufgabeChange( appDokumenteAufgabe )
      }
      else {
        localDokumenteAufgabeChange(
          appDokumenteAufgabe
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appDokumenteAufgabe
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appDokumenteAufgabe ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  useEffect(
    () => {
      // if( history.location.pathname.includes( '/aufgabe/') ) {
      appAufgabeFetchanewChange( true ) ;
      // };
    },
    // [ appAufgabenId , history.location.pathname ]
    [ appAufgabenId ]
  );
  useEffect(
    () => {
      if( appUserIstBasic === 'ja' ) {
        appAufgabeServicepartnerChange( 'medmehr' ) ;
      }
      if( appAufgabeFetchanew ) {
        if( appAufgabenId !== 'neu' ) {
          appDokumenteAufgabeFetchanewChange( true ) ;
        }
        getDataAufgaben( appAufgabenId ) ;
        appAufgabeFetchanewChange( false ) ;
      };
    },
    [ appAufgabeFetchanew ]
  );
  useLieferantenListe() ;
  function onValueAufgabenErledigung( e: any ) {
    setAufgabenErledigung( e.target.value );
  }
  function onValueAufgabenAngebotsnummer( e: any ) {
    setAufgabenAngebotsnummer( e.target.value );
  }

  return (
    <IonPage>
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details zu einer bestehenden Aufgabe einsehen und bearbeiten oder für eine neue Aufgabe festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
            Aufgabe { appAufgabenId } zu Gerät { geraeteId } { GeraeteName }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardContent>
            <IonItem className='item'>
              <IonLabel position="stacked">Aufgabentyp</IonLabel>
              {
                [ '4b' , '5b' , '9b' ].includes( AufgabenTyp )
                  ?
                    <IonSelect disabled = { AufgabenStatus === 100 ? true : false } cancelText="Abbrechen" interface="action-sheet" placeholder="bitte auswählen" value={AufgabenTyp} onIonChange={(e) => onValueAufgabenTyp(e)}>
                      <IonSelectOption key="5b" value="5b">Wartung (beauftragt)</IonSelectOption>
                      <IonSelectOption key="4b" value="4b">Reparatur (beauftragt)</IonSelectOption>
                    </IonSelect>
                  :
                    <IonSelect disabled = { AufgabenStatus === 100 ? true : false } cancelText="Abbrechen" interface="action-sheet" placeholder="bitte auswählen" value={AufgabenTyp} onIonChange={(e) => onValueAufgabenTyp(e)}>
                      <IonSelectOption key="5" value="5">Wartung</IonSelectOption>
                      <IonSelectOption key="4" value="4">Reparatur</IonSelectOption>
                      <IonSelectOption key="9" value="9">Angebot</IonSelectOption>
                    </IonSelect>
              }
              </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">erstellt am</IonLabel>
              { new Date( AufgabenErstellung ).toLocaleDateString() }
            </IonItem>
            {
              appUserIstBasic === 'nein'
                ?
                  <IonItem className='item'>
                    <IonLabel position="stacked">Servicepartner</IonLabel>
                      {
                        ( appGeraeteId + '').substring(0, 3) === 'MM_'
                          ?
                            'medmehr'
                          :
                            <IonSelect disabled = { disableEditBeauftragt || AufgabenStatus === 100 } cancelText="Abbrechen" interface="action-sheet" placeholder="bitte auswählen" value = { appAufgabeServicepartner } onIonChange={(e) => onValueAufgabenServicepartner(e)}>
                              <IonSelectOption key="<neu anlegen>" value="<neu anlegen>">&lt;neu anlegen&gt;</IonSelectOption>
                                {/* !! Hier schnell gestrickt - appAufgabeServicepartner / GeraeteServicePartner könnten auch in Liste "listeLieferanten" sein = doppelt erscheinen */}
                              { appAufgabeServicepartner
                                  ? 
                                    <IonSelectOption key={ appAufgabeServicepartner } value={ appAufgabeServicepartner }>{ appAufgabeServicepartner }</IonSelectOption>
                                  : 
                                    ''
                              }
                              {
                                appLieferantenListe
                                  .filter(
                                    ( { LieferantenName }:any ) =>
                                      (
                                        LieferantenName !== appAufgabeServicepartner
                                        &&
                                        LieferantenName !== ''
                                      )
                                  )
                                  .map(
                                    ( entry:any ) =>
                                      {
                                        let disabled = false ;
                                        let disabledNote = '' ;
                                        if( entry.LieferantenStatus === 'inaktiv' )
                                          {
                                            disabled = true ;
                                            disabledNote = ' (inaktiv )' ;
                                          }
                                        return <IonSelectOption key = { entry.LieferantenName + entry.LieferantenId }  disabled = { disabled } value = { entry.LieferantenName } >{ entry.LieferantenName + disabledNote }</IonSelectOption>
                                      }
                                  )
                              }
                            </IonSelect>
                      }
                      </IonItem>
                :
                  null
            }
            <IonItem className='item'>
              <IonLabel position="stacked">Durchgeführt von</IonLabel>
              <IonInput disabled = { AufgabenStatus === 100 && ! reEditToCorrect } required value={AufgabenUser} onIonChange={(e) => onValueAufgabenUser(e)} />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Problem</IonLabel>
              <IonInput disabled = { disableEditBeauftragt || AufgabenStatus === 100 } required value={AufgabenProblem} onIonChange={(e) => onValueAufgabenProblem(e)} />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Durchgeführte Arbeiten</IonLabel>
              <IonInput disabled = { AufgabenStatus === 100 && ! reEditToCorrect ? true : false } required value={AufgabenLoesung} onIonChange={(e) => onValueAufgabenLoesung(e)} />
            </IonItem>
            {
              [ '4' , '4b' , '5' , '5b' ].includes( AufgabenTyp )
                ? 
                  <IonItem className='item'>
                    <IonLabel position="stacked">Ergebnis { AufgabenStatus === 0 ? '(vorläufig)' : '' }</IonLabel>
                    <IonSelect disabled = { AufgabenStatus === 100 && ! reEditToCorrect ? true : false } cancelText="Abbrechen" interface="action-sheet" placeholder = "bitte auswählen" value = { AufgabenGesamt } onIonChange = { ( e ) => onValueAufgabenGesamt( e ) } >
                      <IonSelectOption key="1" value = { 1 } >in Ordnung</IonSelectOption>
                      <IonSelectOption key="2" value = { 2 } >mit leichten Mängeln</IonSelectOption>
                      <IonSelectOption key="0" value = { 0 } >stillgelegt</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                :
                  ''
            }
            <IonItem>
              <IonLabel position="stacked">abgeschlossen am { AufgabenStatus === 0 ? '(vorläufig)' : '' }</IonLabel>
              <IonInput disabled = { AufgabenStatus === 100 ? true : false } type="date" placeholder = "bitte auswählen" required value = { AufgabenErledigung } onIonChange = { ( e ) => onValueAufgabenErledigung( e ) } />
            </IonItem>
            {
              appKundeDatamehrMail
                ?
                  <>
                  <IonModal
                    isOpen = { emailSendDialog === 'show' ? true : false }
                    backdropDismiss = { false }
                  >
                    <IonCard>
                      <IonCardContent>
                        {
                          ! emailAufgabenServicepartner
                            ?
                              <p>
                                Fehler: Keine E-Mail-Adresse hinterlegt für Servicepartner { AufgabenServicepartner }
                              </p>
                            :
                              <>
                                <p
                                  style = {
                                    {
                                      marginBottom: '1em' ,
                                      fontSize: '1em' ,
                                      fontWeight: 'bold'
                                    }
                                  }
                                >
                                  {
                                    ( appGeraeteId + '').substring(0, 3) === 'MM_'
                                      ?
                                        sendAufgabeSendenArtLabel
                                        + ' versendet'
                                      :
                                        sendAufgabeSendenArtLabel
                                        + ' ' + emailSendOption
                                        + ' ' + adjustAufgabeSendenAktionLabel( AufgabenTyp )
                                  }
                                </p>
                                {
                                  ( appGeraeteId + '').substring(0, 3) === 'MM_'
                                    ?
                                      <div>
                                        <p>
                                          Der Auftrag wurde an die medmehr GmbH übermittelt.
                                        </p>
                                      </div>
                                    :
                                      emailSendOption === 'manuell'
                                        ?
                                          <>
                                            <div
                                              style = {
                                                {
                                                  borderLeft: 'solid 0.4em gray' ,
                                                  fontSize: '0.8em' ,
                                                  marginBottom: '1em' ,
                                                  paddingLeft: '0.5em'
                                                }
                                              }
                                            >
                                              <p>
                                                Zum <i>manuellen { capitalize( adjustAufgabeSendenAktionLabel( AufgabenTyp ) ) }</i> laden Sie eine PDF mit { adjustAufgabeSendenAktionObject( AufgabenTyp , 3 ) } herunter und fügen diese einer von Ihnen gestalteten E-Mail als Anhang hinzu.
                                              </p>
                                              <p>
                                                Für <i>automatisches { capitalize( adjustAufgabeSendenAktionLabel( AufgabenTyp ) ) }</i>, bei dem direkt eine PDF an den angegebenen Servicepartner geschickt wird, ändern Sie die Option "{ sendAufgabeSendenArtLabel } { sendAufgabeSendenAktionLabel }" nach "automatisch" und führen "{ sendAufgabeSendenArtLabel } { adjustAufgabeSendenAktionLabel( AufgabenTyp ) }" erneut aus.
                                              </p>
                                            </div>
                                            <IonButton
                                              onClick = {
                                                () => 
                                                  { 
                                                    // @ts-ignore
                                                    const user_id = JSON.parse( window.localStorage.getItem( 'token' ) ).usr_id ;
                                                    window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/print_auftrag.php?aufgaben_id=' + appAufgabenId + '&user_id=' + user_id + '&output=D' )
                                                  } 
                                              }
                                            >
                                              { adjustAufgabeSendenAktionObject( AufgabenTyp ) } als PDF herunterladen
                                            </IonButton>
                                            <br/>
                                            {
                                              ! emailSendStandardMailerKickedOff
                                                ?
                                                  <IonButton
                                                    onClick = {
                                                      () => 
                                                        { 
                                                          window.open(
                                                            'mailto:'
                                                              + emailAufgabenServicepartner
                                                              + '?subject=' + adjustAufgabeSendenAktionObject( AufgabenTyp ) + ' ' + appBetriebeName
                                                              + '&body='
                                                              + 'Sehr geehrte Damen und Herren ...'
                                                              + '%0A%0A... Details entnehmen Sie der PDF im Anhang ...'
                                                          ) ;

                                                          setEmailSendStandardMailerKickedOff( true ) ;
                                                        } 
                                                    }
                                                  >
                                                    Standard-E-Mail-Programm mit Daten des Servicepartners starten
                                                  </IonButton>
                                                :
                                                  <div>
                                                    <p>
                                                      Das Standard-E-Mail-Programm wurde gestartet.
                                                    </p>
                                                    <p>
                                                      datamehr kann nicht automatisch ermitteln, ob das Versenden mit einem externen E-Mail-Programm erfolgreich war.
                                                    </p>
                                                    <p>
                                                      Wenn Sie erfolgreich waren, dann Schließen, sonst Abbrechen ...
                                                    </p>
                                                  </div>
                                            }
                                          </>
                                        :
                                          <>
                                            <div
                                              style = {
                                                {
                                                  borderLeft: 'solid 0.4em gray' ,
                                                  fontSize: '0.8em' ,
                                                  marginBottom: '1em' ,
                                                  paddingLeft: '0.5em'
                                                }
                                              }
                                            >
                                              <p>
                                                Bei <i>automatischem { capitalize( adjustAufgabeSendenAktionLabel( AufgabenTyp ) ) }</i> wird direkt eine PDF mit { adjustAufgabeSendenAktionObject( AufgabenTyp , 3 ) } an den angegebenen Servicepartner geschickt. In der automatischen E-Mail-Adresse sind Ihre persönlichen Kontaktdaten angegeben (
                                                  {
                                                    // @ts-ignore
                                                    JSON.parse( window.localStorage.getItem( 'token' ) ).usr_email
                                                  }
                                                ), eine Kopie der E-Mail geht an Ihre persönliche E-Mail-Adresse
                                                  "{
                                                    // @ts-ignore
                                                    JSON.parse( window.localStorage.getItem( 'token' ) ).usr_email
                                                  }".
                                              </p>
                                              <p>
                                                Zum <i>manuellen { capitalize( adjustAufgabeSendenAktionLabel( AufgabenTyp ) ) }</i>, bei dem Sie eine PDF mit { adjustAufgabeSendenAktionObject( AufgabenTyp , 3 ) } herunterladen und diese einer von Ihnen gestalteten E-Mail als Anhang hinzufügen, ändern Sie die Option "{ sendAufgabeSendenArtLabel } { sendAufgabeSendenAktionLabel }" nach "manuell" und führen "{ sendAufgabeSendenArtLabel } { sendAufgabeSendenAktionLabel }" erneut aus.
                                              </p>
                                            </div>
                                            <IonButton
                                              onClick = {
                                                () => 
                                                  { 
                                                    // @ts-ignore
                                                    const user_id = JSON.parse( window.localStorage.getItem( 'token' ) ).usr_id ;
                                                    window.open( 'https://datamehr-backend.saint-online.de/api/aufgaben/print_auftrag.php?aufgaben_id=' + appAufgabenId + '&user_id=' + user_id + '&output=I' )
                                                  } 
                                              }
                                            >
                                              { adjustAufgabeSendenAktionObject( AufgabenTyp ) } anzeigen
                                            </IonButton>
                                            <br/>
                                            {
                                              sendAufgabeState === 0
                                                ?
                                                  <IonButton
                                                    onClick = {
                                                      () => 
                                                        { 
                                                          sendAufgabe() ;
                                                        } 
                                                    }
                                                  >
                                                    { adjustAufgabeSendenAktionObject( AufgabenTyp ) } automatisch an Servicepartner verschicken
                                                  </IonButton>
                                                :
                                                  sendAufgabeState === 100
                                                    ?
                                                      <p>{ adjustAufgabeSendenAktionObject( AufgabenTyp ) } wird gesendet ...</p>
                                                    :
                                                      sendAufgabeState === 200
                                                        ?
                                                          <div style = { { fontWeight: 'bold' , marginTop: '2em'} }>{ capitalize( adjustAufgabeSendenAktionObject( AufgabenTyp , 1 ) ) } wurde an den Servicepartner { appAufgabeServicepartner } versendet mit Kopie an {
                                                            // @ts-ignore
                                                            JSON.parse( window.localStorage.getItem( 'token' ) ).usr_email
                                                          }.</div>
                                                        :
                                                          <p style = { { color: 'red' } }>Es ist ein Fehler aufgetreten</p>
                                            }
                                          </>
                                }
                              </>  
                        }
                        <br/>
                        {
                          ( appGeraeteId + '').substring(0, 3) === 'MM_' || sendAufgabeState === 200 || emailSendStandardMailerKickedOff
                            ?
                              <IonButton
                                onClick =
                                  {
                                    () =>
                                      {
                                        // sendJSON( 100 , false ) ;
                                        setEmailSendDialog( 'hidden' )
                                        setEmailSendStandardMailerKickedOff( false ) ;
                                        setSendAufgabeState( 0 ) ;
                                      }
                                  }
                              >
                                Schließen
                              </IonButton>
                            :
                              ''
                        }
                        {
                          ( appGeraeteId + '').substring(0, 3) === 'MM_' || sendAufgabeState === 200 || ! emailSendStandardMailerKickedOff
                            ?
                              ''
                            :
                              <IonButton
                                onClick =
                                  {
                                    () =>
                                      {
                                        let myAufgabenTyp = fromAngebot ? '9b' : AufgabenTyp ;
                                        setAufgabenTyp( myAufgabenTyp.replace( 'b' , '' ) ) ;
                                        setDisableEditBeauftragt( false ) ;
                                        sendJSON( 0 , false , myAufgabenTyp.replace( 'b' , '' ) ) ;
                                        setEmailSendDialog( 'hidden' )
                                        setEmailSendStandardMailerKickedOff( false ) ;
                                        setSendAufgabeState( 0 ) ;
                                        appAufgabenListeFetchanewChange( true ) ;
                                        appDokumenteAufgabeFetchanewChange( true ) ;
                                        appAufgabenGeraetFetchanewChange( true ) ;
                                      }
                                  }
                              >
                                Abbrechen
                              </IonButton>
                        }
                      </IonCardContent>
                    </IonCard>
                  </IonModal>
                  {
                    ( appGeraeteId + '').substring(0, 3) !== 'MM_' && ( ! disableEditBeauftragt ) && ( AufgabenStatus !== 100 )
                      ?
                        <IonItem>
                          <IonLabel position="stacked">{ sendAufgabeSendenArtLabel } { sendAufgabeSendenAktionLabel }</IonLabel>
                            <IonSelect
                              cancelText="Abbrechen"
                              interface = 'action-sheet'
                              placeholder = "bitte auswählen"
                              value = { emailSendOption }
                              onIonChange =
                                {
                                  ( e ) => onValueEmailSendOption( e )
                                }
                            >
                              <IonSelectOption
                                key = { 'manuell' }
                                value = { 'manuell' }
                              >
                                manuell (lokales E-Mail-Programm)
                              </IonSelectOption>
                              <IonSelectOption
                                key = { 'automatisch' }
                                value = { 'automatisch' }
                              >
                                automatisch (datamehr-Server)
                              </IonSelectOption>
                            </IonSelect>
                          </IonItem>
                      :
                        ''
                    }
                  {
                    AufgabenAngebotsnummer !== '' || [ '9' , '9b' ].includes( AufgabenTyp )
                      ?
                        <IonItem className='item'>
                          <IonLabel position="stacked">Angebotsnummer für Beauftragung</IonLabel>
                          <IonInput disabled = { disableEditBeauftragt || AufgabenStatus === 100 } required value={AufgabenAngebotsnummer} onIonChange={(e) => onValueAufgabenAngebotsnummer(e)} />
                        </IonItem>
                      :
                        ''
                  }
                  {
                    ( ! disableEditBeauftragt ) && ( AufgabenStatus !== 100 )
                      ?
                        <IonButton
                          disabled =
                            {
                              [ '4' , '4b' , '5' , '5b' , '9' , '9b' ].includes( AufgabenTyp )
                                ?
                                  false
                                :
                                  true
                            }
                          onClick = {
                            () => 
                              { 
                                setEmailAufgabenServicepartner(
                                  () => 
                                    {
                                      if( ( appGeraeteId + '').substring(0, 3) === 'MM_' ) {
                                        return 'info@medmehr.de' ;
                                      }
                                      let tmpVal = appLieferantenListe
                                          .find(
                                            ( entry:any ) => entry.LieferantenName === appAufgabeServicepartner
                                          )
                                      return tmpVal && tmpVal.LieferantenEmail ? tmpVal.LieferantenEmail : '' ;
                                    }
                                )
                                if( AufgabenTyp === '4' || AufgabenTyp === '5' ) {
                                  setAufgabenTyp( AufgabenTyp + 'b' ) ;
                                  setDisableEditBeauftragt( true ) ;
                                  sendJSON( 0 , false , AufgabenTyp + 'b' ) ;
                                  appAufgabenListeFetchanewChange( true ) ;
                                  appDokumenteAufgabeFetchanewChange( true ) ;
                                  appAufgabenGeraetFetchanewChange( true ) ;
                                } else {
                                  sendJSON( AufgabenStatus , false ) ;
                                }
                                setEmailSendDialog( 'show' ) ;
                              } 
                          }
                        >
                          { sendAufgabeSendenArtLabel } { adjustAufgabeSendenAktionLabel( AufgabenTyp ) }
                        </IonButton>
                      :
                        ''
                  }
                  </>
                :
                  ''
            }
            {
              AufgabenStatus !== 100
                ?
                  AufgabenTyp === '9'
                    ?
                      <IonButton
                        onClick = {
                          () => 
                              {
                                if( AufgabenAngebotsnummer === '' ) {
                                  if(
                                    ! window.confirm( '\nDas Feld "Angebotsnummer für Beauftragung" ist leer.\n\nOhne Angebotsnummer beauftragen?\n\n')
                                  )
                                    {
                                      return ;
                                    }
                                }
                                setEmailAufgabenServicepartner(
                                  () => 
                                    {
                                      if( ( appGeraeteId + '').substring(0, 3) === 'MM_' ) {
                                        return 'info@medmehr.de' ;
                                      }
                                      let tmpVal = appLieferantenListe
                                          .find(
                                            ( entry:any ) => entry.LieferantenName === appAufgabeServicepartner
                                          )
                                      return tmpVal && tmpVal.LieferantenEmail ? tmpVal.LieferantenEmail : '' ;
                                    }
                                )
                                setFromAngebot( true ) ;
                                setAufgabenTyp( '4b' ) ;
                                setDisableEditBeauftragt( true ) ;
                                sendJSON( 0 , false , '4b' ) ;
                                setEmailSendDialog( 'show' ) ;
                                appAufgabenListeFetchanewChange( true ) ;
                                appDokumenteAufgabeFetchanewChange( true ) ;
                                appAufgabenGeraetFetchanewChange( true ) ;
                            }
                        }
                      >
                        Angebot beauftragen
                      </IonButton>
                    :
                      <IonButton
                        onClick = {
                          () => 
                            {
                              if(
                                window.confirm( '\nAchtung: Nach dem Beenden der Aufgabe kann diese nicht mehr bearbeitet werden.\n\n\nSoll die Aufgabe jetzt beendet werden?\n\n')
                              )
                                {
                                  sendJSON( 100 ) ;
                                  appAufgabenListeFetchanewChange( true ) ;
                                  appAufgabenGeraetFetchanewChange( true ) ;
                                  /** Geräteliste neu laden wg. Status-Berechnung */
                                    appGeraeteListeFetchanewChange( true ) ;
                                }
                            }
                        }
                      >
                        Aufgabe beenden
                      </IonButton>
                :
                  ''
          }
          {
              AufgabenStatus !== 100
                ?
                  <IonButton
                    onClick = {
                      () => 
                        {
                          sendJSON( AufgabenStatus ) ;
                          appAufgabeServicepartnerChange('');
                          appAufgabeServicepartnerAddedChange( '' ) ;
                          appAufgabenListeFetchanewChange( true ) ;
                          appAufgabenGeraetFetchanewChange( true ) ;
                        }
                    }
                  >
                    Änderungen speichern
                  </IonButton>
                :
                  reEditToCorrect
                    ?
                      <>
                        <IonButton
                          onClick = {
                            () => 
                              {
                                // setAufgabenStatus( 100 ) ;
                                setReEditToCorrect( false ) ;
                                sendJSON( 999 , false ) ;
                                appAufgabenListeFetchanewChange( true ) ;
                                appAufgabenGeraetFetchanewChange( true ) ;
                              }
                          }
                        >
                          Korrekturen speichern
                        </IonButton>
                        <IonButton
                        onClick = {
                          () => 
                            {
                              setReEditToCorrect( false ) ;
                            }
                        }
                        >
                          Korrekturen verwerfen
                        </IonButton>
                      </>
                  :
                      <IonButton
                        onClick = {
                          () => 
                            {
                              setReEditToCorrect( true ) ;
                            }
                        }
                      >
                        Ergebnisse korrigieren
                      </IonButton>
            }
            {
              ! reEditToCorrect
                ?
                  <ButtonCancelBack
                    label = { AufgabenStatus === 100 ? 'Schließen' : 'Abbrechen' }
                    myExec =
                      {
                        () =>
                          {
                            setReEditToCorrect( false ) ;
                            appAufgabeServicepartnerChange('');
                            appAufgabeServicepartnerAddedChange('');
                          }
                      }
                  />
                :
                  ''
            }
            <br/>
          </IonCardContent>
        </IonCard>
        {
            appAufgabenId !== 'neu'
              ?
                <IonCard class="Dokumente">
                  <IonAlert
                    isOpen = { dokumentToActOn ? true : false }
                    backdropDismiss = { true }
                    header = 'Gewählte Datei ...'
                    message = ''
                    buttons = {
                      [
                        {
                          text: 'Herunterladen' ,
                          handler: () => {
                            getDokumentDatei( dokumentToActOn ) ;
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Listeneintrag bearbeiten' ,
                          handler: () => {
                            appViewSwitchChange( true ) ;
                            appDokumenteAufgabeIdChange( dokumentToActOn ) ;
                            history.push( '/dokument-aufgabe/' + dokumentToActOn + '/' + appAufgabenId )
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Abbrechen' ,
                          handler: () => {
                            setDokumentToActOn( '' ) ;
                          }
                        }
                      ]
                    }
                  />
                  <IonCardHeader>
                    <IonCardTitle>Dokumente</IonCardTitle>
                  </IonCardHeader>
                  {
                    appDokumenteAufgabe.length > 0
                      ?
                        <Box width="100vh"
                          sx={{
                            height: 300,
                            width: 1,
                            "& .super-app-theme--header": {
                              backgroundColor: "rgb(211,211,211)",
                            },
                          }}
                        >
                          <DataGrid
                            disableVirtualization 
                            initialState={{
                              sorting: {
                                sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                              },
                            }}
                            getRowId={(row) => row.DokumenteId}
                            getRowClassName =
                            {
                              ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                            }
                            rows = { localDokumenteAufgabe }
                            columns = {
                              [
                                defColumn( "DokumenteId" , "Id" , 0 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                  ( data:any ) =>
                                    new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                ) ,
                                defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                  ( data:any ) =>
                                    readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                ) ,
                                defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                          ]
                            } 
                            pageSize={pageSize}
                            rowsPerPageOptions={[10,50,100]}
                            disableSelectionOnClick
                            onCellClick =
                              {
                                ( e:any ) =>
                                  {
                                    /** Browser / Web vs. App nativ */
                                    if( navigator ) {
                                      setDokumentToActOn( e.id ) ;
                                    } else {
                                      appDokumenteAufgabeIdChange( e.id ) ;
                                      appViewSwitchChange( true ) ;
                                      history.push( '/dokument-aufgabe/' + e.id + '/' + appAufgabenId )
                                    }
                                  }
                              }
                            sortingOrder={["desc", "asc"]}
                            onPageSizeChange={(newPage) => setPageSize(newPage)}
                            pagination
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                          />
                        </Box>
                      :
                        ""
                  }
                  <ButtonInaktive
                    disabled = { existInaktive ? false : true }
                    myExec = {
                      () =>
                        {
                          setShowInaktive( showInaktive ? false : true )
                        }
                    }
                    label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                  />
                  <ButtonAdd
                    myExec = { () => { appDokumenteAufgabeIdChange( 'neu' ) ; } }
                    routeUrl = { '/dokument-aufgabe/neu/' + appAufgabenId }
                  />
                  {
                    appDokumenteAufgabe.length > 0
                      ?
                        <IonButton onClick={ () => exportTableData( appDokumenteAufgabe , 'Dokumente Aufgabe ' + appAufgabenId ) }>Exportieren</IonButton>
                      :
                        ''
                  }
                </IonCard>
              :
                ''
          }
      </IonContent>
    </IonPage>
  );
};
