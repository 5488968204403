/**
 * View Unterweisung
 * 
 * Einzelne Unterweisung einsehen, bearbeiten oder erstellen
 * 
 */

import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar , useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { Box } from '@mui/material';
import { DataGrid, deDE } from '@mui/x-data-grid';
import axios from 'axios';
import { helpOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useUnterweisungDokumente , useUnterweisungsthemen, useMitarbeiterListe } from '../components/_CustomHooks';
import { ButtonAdd, ButtonCancelBack, ButtonInaktive , ButtonsSaveActions, defColumn, defColumnRenderCell, exportTableData, getDokumentDatei , ButtonPageHelp, PageHelp,readableFileSize, SpinnerViewSwitch } from '../components/_HelperFunctions';
import { AppContext } from '../contexts/app_context';

export function Unterweisung() {
  useIonViewDidEnter(
    () =>
      {
        appViewSwitchChange( false ) ;  
      }
  ) ;
  const { appBetriebeId , appKundeId , appUnterweisungDokumenteFetchanewChange , appUnterweisungDokumente , appUnterweisungDokumenteId , appUnterweisungDokumentIdChange , appUnterweisungFetchanew ,appUnterweisungFetchanewChange ,appUnterweisungId , appUnterweisungIdChange , appUnterweisungenFetchanewChange , appUnterweisungsthemen , appUnterweisungsthemenFetchanewChange , appUnterweisungenMatrixBuildNewChange , appUnterweisungNeuTeilnehmer , appUnterweisungNeuTeilnehmerChange , appUnterweisungNeuThema , appUnterweisungNeuThemaChange , appMitarbeiterListe , appMitarbeiterListeFetchanewChange , appViewSwitch , appViewSwitchChange } = useContext( AppContext ) ;
  const [ pageHelpShow , setPageHelpShow ] = useState( false ) ;
  const screencastChannel = '' ;
  const history = useHistory();
  const [ UnterweisungId, setUnterweisungId ] = useState( appUnterweisungId )
  const [ UnterweisungBetriebeId, setUnterweisungBetriebeId ] = useState( appBetriebeId )
  const [ UnterweisungAnmerkungen, setUnterweisungAnmerkungen ] = useState( '' )
  const [ UnterweisungTermin, setUnterweisungTermin ] = useState( '' )
  const [ UnterweisungDozent, setUnterweisungDozent ] = useState( '' )
  const [ UnterweisungThema, setUnterweisungThema ] = useState( appUnterweisungNeuThema )
  const [ UnterweisungMitarbeiter, setUnterweisungMitarbeiter ] = useState( appUnterweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='') )
  const [ UnterweisungStatus, setUnterweisungStatus ] = useState( 'offen' )
  const [ disableEdit, setDisableEdit ] = useState( false )
  const [ addAllMitarbeiter , setAddAllMitarbeiter ] = useState( false ) ;
  /** ! "lastAddedMitarbeiter" auch als Trigger, damit Ionic re-rendert */
    const [ lastAddedMitarbeiter , setLastAddedMitarbeiter ] = useState( '' ) ;
  /** ! "anzahlTeilnehmer" auch als Trigger, damit Ionic re-rendert */
    const [ anzahlTeilnehmer , setAnzahlTeilnehmer ] = useState( appUnterweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='').length ) ;
    const [ tableData , settableData ] = useState( Array() ) ;
    const [ localUnterweisungDokumente , localUnterweisungDokumenteChange ] = useState( Array() ) ;
    const [ existInaktive , setExistInaktive ] = useState( false ) ;
    const [ showInaktive , setShowInaktive ] = useState( false ) ;
    //#region Dialog-Dokument
    /** Eintrag bearbeiten vs. Dokument herunterladen */
    const [ dokumentToActOn , setDokumentToActOn ] = useState( '' ) ;
      //#endregion Dialog-Dokument
  //#region Listen-DataGrids für Attachments
    const [pageSize, setPageSize] = useState(10);
      //#endregion Listen-DataGrids für Attachments

  const getDataUnterweisung = () => {
    if (appUnterweisungId === 'neu') {
      setUnterweisungId( 'neu' ) ;
      setUnterweisungBetriebeId( appBetriebeId ) ;
      setUnterweisungThema( appUnterweisungNeuThema ) ;
      setUnterweisungMitarbeiter( appUnterweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='') ) ;
      setAnzahlTeilnehmer( appUnterweisungNeuTeilnehmer.split(',').filter((e:any)=>e!=='').length ) ;
      setUnterweisungTermin( '' ) ;
      setUnterweisungAnmerkungen( '' ) ;
      setUnterweisungDozent( '' ) ;
      setUnterweisungStatus( 'offen' ) ;
    } else {
      const api = axios.create({
        baseURL: `https://datamehr-backend.saint-online.de/api/unterweisungen/get_unterweisung.php?unterweisung_id=` + appUnterweisungId ,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${ window.localStorage.getItem('jwt') }`,
        },
      });
      api.get( "" )
      .then((res) => {
        let data = res.data;
        data.forEach((unterweisungen: any) => {
          if (unterweisungen.UnterweisungenId === UnterweisungId) {
            setUnterweisungId( unterweisungen.UnterweisungenId ) ;
            setUnterweisungBetriebeId( unterweisungen.UnterweisungenBetriebeId ) ;
            setUnterweisungThema( unterweisungen.UnterweisungenThema ) ;
            let myUnterweisungMitarbeiter = unterweisungen.UnterweisungenMitarbeiter.split(',') ;
            setUnterweisungMitarbeiter( myUnterweisungMitarbeiter ) ;
            setAnzahlTeilnehmer( myUnterweisungMitarbeiter.length ) ;
            setUnterweisungTermin( unterweisungen.UnterweisungenTermin ) ;
            setUnterweisungAnmerkungen( unterweisungen.UnterweisungenAnmerkungen ) ;
            setUnterweisungDozent( unterweisungen.UnterweisungenDozent ) ;
            setUnterweisungStatus( unterweisungen.UnterweisungenStatus ) ;
            if( unterweisungen.UnterweisungenStatus === 'abgeschlossen' ) {
              setDisableEdit( true ) ;
            }
          }
        })
      });
    }
  }
  async function sendJSON() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append( 'Authorization' , `Bearer ${ window.localStorage.getItem('jwt') }` ) ;
    var raw = JSON.stringify({
      UnterweisungenId: UnterweisungId ,
      UnterweisungenBetriebeId: UnterweisungBetriebeId ,
      UnterweisungenKundeId: appKundeId ,
      UnterweisungenThema: UnterweisungThema || ' ',
      UnterweisungenMitarbeiter: UnterweisungMitarbeiter.toString() || '' ,
      UnterweisungenTermin: UnterweisungTermin || '0000-00-00' ,
      UnterweisungenAnmerkungen: UnterweisungAnmerkungen || '' ,
      UnterweisungenDozent: UnterweisungDozent || '' ,
      UnterweisungenStatus: UnterweisungStatus
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    await fetch('https://datamehr-backend.saint-online.de/api/unterweisungen/set_unterweisung.php', requestOptions)
    .then( response => response.json() )
    .then(
      json =>
        {
          if( json.entryId > 0 ) {
            appUnterweisungIdChange( json.entryId ) ;
          }
        }
      )
      appUnterweisungNeuTeilnehmerChange( '') ;
      appUnterweisungNeuThemaChange( '' ) ;
      appUnterweisungenFetchanewChange( true ) ; 
  }
  function onValueUnterweisungThema( e:any ) { setUnterweisungThema( e.target.value ) ; }
  function onValueUnterweisungStatus( e:any ) { setUnterweisungStatus( e.target.value ) ; }
  function onValueTeilnehmerListe( mitarbeiterId:any ) { 
    let myUnterweisungMitarbeiter = UnterweisungMitarbeiter ;
    if( mitarbeiterId === 'alle' ) {
      myUnterweisungMitarbeiter = [] ;
      setLastAddedMitarbeiter( '' ) ;
    } else {
      myUnterweisungMitarbeiter = myUnterweisungMitarbeiter.filter( ( entry:any ) => entry !== mitarbeiterId ) ;
    }
    setUnterweisungMitarbeiter( myUnterweisungMitarbeiter ) ;
    setAnzahlTeilnehmer( myUnterweisungMitarbeiter.length ) ;
    setAddAllMitarbeiter( false ) ;
  }
  function onValueTeilnehmerDazu( mitarbeiterId:any , mitarbeiterName:string ) { 
    let myUnterweisungMitarbeiter = UnterweisungMitarbeiter ;
    if( mitarbeiterId === 'alle' ) {
      setAddAllMitarbeiter( true ) ;
    } else {
      /** vermutlich wg. State / Rerender wird die Anweisung hier mehrfach durchlaufen, deshalb Check auf "schon gesetzt" */
        if( ! myUnterweisungMitarbeiter.includes( mitarbeiterId ) ) {
          myUnterweisungMitarbeiter.push( mitarbeiterId ) ;
        }
        setLastAddedMitarbeiter( mitarbeiterName ) ;
    }
    setUnterweisungMitarbeiter( myUnterweisungMitarbeiter ) ;
    setAnzahlTeilnehmer( myUnterweisungMitarbeiter.length ) ;
  }
  function onValueUnterweisungTermin( e:any ) { setUnterweisungTermin( e.target.value ) ; }
    // "Thema"-Dropdown aus Unterweisungsthemen-DB (Einstellungen)
  useUnterweisungsthemen() ;
    // "Teilnehmer"-Dropdown aus Mitarbeiter-DB (Einstellungen)
  useMitarbeiterListe() ;
    // "Dokumente"-Liste
  useUnterweisungDokumente() ;

  const handleInaktive = () =>
    {
      if( showInaktive ) {
        localUnterweisungDokumenteChange( appUnterweisungDokumente )
      }
      else {
        localUnterweisungDokumenteChange(
          appUnterweisungDokumente
            .filter(
              ( entry:any ) => entry.DokumenteAktiv === 'aktiv'
            )        
        )
      }
    }

  useEffect(
    () => {
      if( appUnterweisungDokumente
          .findIndex(
            ( entry:any ) => entry.DokumenteAktiv === 'inaktiv'
          ) > -1
      )
        {
          setExistInaktive( true )
        }
      else
        {
          setExistInaktive( false )
        }
      handleInaktive() ;
    } ,
    [ appUnterweisungDokumente ]
  )
  
  useEffect(
    () => {
      handleInaktive() ;
    } ,
    [ showInaktive ]
  )
  
  useEffect(
    () => {
      // if( history.location.pathname.includes( '/unterweisung/') ) {
        setUnterweisungId( appUnterweisungId ) ;
        appUnterweisungFetchanewChange( true ) ;
      // }
    },
    // [ history.location.pathname ]
    [ appUnterweisungId ]
  ) ;
  useEffect(
    () => {
      if( appUnterweisungFetchanew ) {
        appUnterweisungFetchanewChange( false ) ;
        if( appUnterweisungId !== 'neu' ) {
          appUnterweisungDokumenteFetchanewChange( true ) ;
          appUnterweisungsthemenFetchanewChange( true ) ;
          appMitarbeiterListeFetchanewChange( true ) ;
        }
        getDataUnterweisung() ;
      }
    },
    [ appUnterweisungFetchanew ]
  ) ;
  function onValueUnterweisungDozent(e: any) {
    setUnterweisungDozent(e.target.value); 
  }
  function onValueUnterweisungAnmerkungen(e: any) {
    setUnterweisungAnmerkungen(e.target.value); 
  }
  const getInhaltForUnterweisungsThema = ( themaId:any ) =>
    {
      let themaMatch = appUnterweisungsthemen
        .find(
          ( thema:any ) => thema.UnterweisungsthemenId === themaId
      )
      if( themaMatch ) {
        return themaMatch.UnterweisungsthemenInhalt ;
      }
    }
  const getTitleForUnterweisungsThema = ( themaId:any , quotes = '' ) =>
    {
      // themaId = 1135516
      // console.log( 'check' , themaId , 'in' , appUnterweisungsthemen ) ;
      let themaMatch = appUnterweisungsthemen
        .find(
          ( thema:any ) => thema.UnterweisungsthemenId === themaId
      )
      // console.log( themaMatch ) ;
      if( themaMatch ) {
        return quotes + themaMatch.UnterweisungsthemenTitel + quotes ;
      }
      else {
        return '' ;
      }
    }
  return (
    <IonPage>
      <SpinnerViewSwitch />
      <PageHelp page = { screencastChannel }  state = { pageHelpShow } set = { setPageHelpShow } >
        <p>
           Auf dieser Seite können Sie Details für eine Schulung einsehen und bearbeiten oder für eine neue Schulung festlegen.
        </p>
      </PageHelp>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot=""  >
              Schulung { ' ' }
              {
                UnterweisungId === 'neu'
                  ?
                    '(neu)'
                  :
                     getTitleForUnterweisungsThema( UnterweisungThema, '"' )
              }
              {
                UnterweisungId !== 'neu'
                  ?
                    UnterweisungTermin !== '0000-00-00'
                      ?
                        ' Termin ' + new Date( UnterweisungTermin ).toLocaleDateString()
                      :
                        ' Termin offen'
                  :
                    ''
              }
          </IonTitle>
          <ButtonPageHelp page = { screencastChannel } state = { pageHelpShow } set = { setPageHelpShow } />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
          <IonItem className='item'>
              <IonLabel position="stacked">Thema</IonLabel>
              <IonSelect disabled = { disableEdit } cancelText="Abbrechen" interface = 'action-sheet' value={ UnterweisungThema } onIonChange={ ( e )=> onValueUnterweisungThema( e ) } >
                { UnterweisungThema
                    ? 
                      appUnterweisungsthemen
                        .filter(
                          ( { UnterweisungsthemenId }:any ) => UnterweisungsthemenId === UnterweisungThema 
                        )
                        .map(
                          ( entry:any ) =>
                            {
                              let disabled = false ;
                              let disabledNote = '' ;
                              if( entry.UnterweisungsthemenAktiv !== 'aktiv' )
                                {
                                  disabled = true ;
                                  disabledNote = ' (inaktiv )' ;
                                }
                              return <IonSelectOption key = { entry.UnterweisungsthemenTitel } disabled = { disabled } value = { entry.UnterweisungsthemenId } >{ entry.UnterweisungsthemenTitel + disabledNote }</IonSelectOption>
                            }
                        )
                    : 
                      ''
                }
                {
                  appUnterweisungsthemen
                  .filter(
                    ( entry:any ) => entry.UnterweisungsthemenAktiv === 'aktiv'
                  )
                  .filter(
                    ( entry:any ) => entry.UnterweisungsthemenId !== UnterweisungThema
                  )
                  .map(
                      ( entry:any ) =>
                        {
                          return <IonSelectOption key = { entry.UnterweisungsthemenTitel } value = { entry.UnterweisungsthemenId } >{ entry.UnterweisungsthemenTitel }</IonSelectOption>
                        }
                    )
                }
              </IonSelect>
          </IonItem>
          <IonItem>
              <IonLabel position="stacked">Inhalt</IonLabel>
              <div>
                { getInhaltForUnterweisungsThema( UnterweisungThema ) }
              </div>
            </IonItem>
          <IonItem>
            <IonLabel position="stacked">Anmerkungen</IonLabel>
            <IonInput disabled = { disableEdit }required value = { UnterweisungAnmerkungen } onIonChange = { ( e ) => onValueUnterweisungAnmerkungen( e ) } />
          </IonItem>
          <IonItem>
              <IonLabel position="stacked">Status</IonLabel>
              <IonSelect disabled = { disableEdit } cancelText="Abbrechen" interface = 'action-sheet' placeholder="bitte auswählen" value={ UnterweisungStatus } onIonChange={ ( e )=> onValueUnterweisungStatus( e ) } >
                <IonSelectOption key = { 'offen' } value = { 'offen' } >offen</IonSelectOption>
                <IonSelectOption key = { 'abgeschlossen' } value = { 'abgeschlossen' } >abgeschlossen</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Termin</IonLabel>
              <IonInput disabled = { disableEdit }type="date" placeholder="bitte auswählen" required value={ UnterweisungTermin }  onIonChange={(e) => onValueUnterweisungTermin(e)}/>
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Dozent/in</IonLabel>
              <IonInput disabled = { disableEdit }required value = { UnterweisungDozent } onIonChange = { ( e ) => onValueUnterweisungDozent( e ) } />
            </IonItem>
            <IonItem className='item'>
              <IonLabel position="stacked">Teilnehmerliste ( { anzahlTeilnehmer } Teilnehmer)</IonLabel>
              <div style={ { height: '200px' , overflowY: 'scroll' , width: '100%' } }>
                {
                  ( UnterweisungMitarbeiter.length > 0 && ! disableEdit )
                    ?
                      <IonItem lines="none" className='item'>
                        <IonLabel slot="">alle entfernen</IonLabel>
                        <IonCheckbox disabled = { disableEdit } slot="start" key = 'alle' checked={ false } onIonChange={() => onValueTeilnehmerListe( 'alle' ) } />
                      </IonItem>
                    :
                      ''
                }
                {
                  appMitarbeiterListe
                    .filter(
                        ( entry:any ) => UnterweisungMitarbeiter.includes( entry.MitarbeiterId )
                    )
                    .map(
                      ( entry:any ) =>
                          {
                            /* !!! Für "neu" inaktive Mitarbeiter ausblenden, für existierende Schulungen als inaktiv markieren */
                              let disabledNote = '' ;
                              if( entry.MitarbeiterAktiv === 'inaktiv' )
                                {
                                  if( UnterweisungStatus === 'abgeschlossen' ) {
                                    disabledNote = ' (inaktiv )' ;
                                  } else {
                                    return '' ;
                                  }
                              }
                            // return <IonSelectOption key = { entry.MitarbeiterId } value = { entry.MitarbeiterId } >{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonSelectOption>
                            return (
                              <IonItem lines="none" className='item'>
                                <IonLabel slot="">{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonLabel>
                                <IonCheckbox disabled = { disableEdit } slot="start" key = { entry.MitarbeiterId + '_onList' } checked={ anzahlTeilnehmer > 0 ? true : false } onIonChange={() => onValueTeilnehmerListe( entry.MitarbeiterId ) } />
                              </IonItem>
                            )
                          }
                      )
                  }
              </div>
            </IonItem>
            {
               disableEdit
                ?
                  ''
                :
                  <IonItem className='item'>
                      <IonLabel position="stacked">Teilnehmer hinzufügen { lastAddedMitarbeiter !== '' ? '(zuletzt hinzugefügt:' + lastAddedMitarbeiter + ')' : '' }</IonLabel>
                        <div style={ { height: '200px' , overflowY: 'scroll' , width: '100%' } }>
                          {
                            ! addAllMitarbeiter
                              ?
                                <IonItem lines="none" className='item'>
                                  <IonLabel slot="">alle hinzufügen</IonLabel>
                                  <IonCheckbox disabled = { disableEdit } slot="start" key = 'alle' checked={ false } onIonChange={() => onValueTeilnehmerDazu( 'alle' , '' ) } />
                                </IonItem>
                              :
                                ''
                          }
                          {
                            appMitarbeiterListe
                            .filter(
                              ( entry:any ) => ! UnterweisungMitarbeiter.includes( entry.MitarbeiterId )
                            )
                            .map(
                              ( entry:any ) =>
                                  {
                                    /* !!! Für "neu" inaktive Mitarbeiter ausblenden, für existierende Schulungen als inaktiv markieren */
                                      let disabledNote = '' ;
                                      if( entry.MitarbeiterAktiv === 'inaktiv' )
                                        {
                                          if( UnterweisungStatus === 'abgeschlossen' ) {
                                            disabledNote = ' (inaktiv )' ;
                                          } else {
                                            return '' ;
                                          }
                                        }
                                    // return <IonSelectOption key = { entry.MitarbeiterId } value = { entry.MitarbeiterId } >{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonSelectOption>
                                    return (
                                      <IonItem lines="none" className='item'>
                                        <IonLabel slot="">{ entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer + disabledNote }</IonLabel>
                                        <IonCheckbox disabled = { disableEdit } slot="start" key = { entry.MitarbeiterId + '_offList' } checked={ addAllMitarbeiter } onIonChange={() => onValueTeilnehmerDazu( entry.MitarbeiterId , entry.MitarbeiterNachname + ' PN ' + entry.MitarbeiterPersonalnummer ) } />
                                      </IonItem>
                                    )
                                  }
                              )
                          }
                        </div>
                  </IonItem>
            }
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardContent>
          {
              ! disableEdit
                ?
                  <>
                    <ButtonsSaveActions
                      myExec = {
                        () => { sendJSON() ; }
                      }
                    />
                      {
                        appUnterweisungId !== 'neu'
                          ?
                            <IonButton
                              onClick = {
                                () => 
                                  { 
                                    sendJSON() ;
                                    window.open( 'https://datamehr-backend.saint-online.de/api/unterweisungen/print_unterweisung_unterschriftenliste.php?unterweisungen_id=' + appUnterweisungId )
                                  } 
                              }
                            >
                              Unterschriftenliste
                            </IonButton>
                          :
                            ''
                      }
                  </>
                :
                  ''
            }
            <ButtonCancelBack label = 'Abbrechen' myExec = { () => { appUnterweisungNeuTeilnehmerChange( '') ; appUnterweisungNeuThemaChange( '' ) ; } } />
          </IonCardContent>
        </IonCard>
        {
            appUnterweisungId !== 'neu'
              ?
                <IonCard class="Dokumente">
                  <IonAlert
                    isOpen = { dokumentToActOn ? true : false }
                    backdropDismiss = { true }
                    header = 'Gewählte Datei ...'
                    message = ''
                    buttons = {
                      [
                        {
                          text: 'Herunterladen' ,
                          handler: () => {
                            getDokumentDatei( dokumentToActOn ) ;
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Listeneintrag bearbeiten' ,
                          handler: () => {
                            appViewSwitchChange( true ) ;
                            appUnterweisungDokumentIdChange( dokumentToActOn ) ;
                            history.push( '/dokument-unterweisung/' + dokumentToActOn + '/' + appUnterweisungId )
                            setDokumentToActOn( '' ) ;
                          }
                        } ,
                        {
                          text: 'Abbrechen' ,
                          handler: () => {
                            setDokumentToActOn( '' ) ;
                          }
                        }
                      ]
                    }
                  />
                  <IonCardHeader>
                    <IonCardTitle>Dokumente</IonCardTitle>
                  </IonCardHeader>
                  {
                    appUnterweisungDokumente.length > 0
                      ?
                        <Box width="100vh"
                          sx={{
                            height: 300,
                            width: 1,
                            "& .super-app-theme--header": {
                              backgroundColor: "rgb(211,211,211)",
                            },
                          }}
                        >
                          <DataGrid
                            disableVirtualization 
                            initialState={{
                              sorting: {
                                sortModel: [ { field: 'DokumenteDateidatum' , sort: 'desc' } ] ,
                              },
                            }}
                            getRowId={(row) => row.DokumenteId}
                            getRowClassName =
                            {
                              ( data:any ) => data.row.DokumenteAktiv === 'inaktiv' ? 'eintrag_inaktiv' : ''
                            }
                            rows = { localUnterweisungDokumente }
                            columns = {
                              [
                                defColumn( "DokumenteId" , "Id" , 0 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateidatum" , "Datum" , 150 , 0 ) ,
                                  ( data:any ) =>
                                    new Date( data.row.DokumenteDateidatum ).toLocaleString()
                                ) ,
                                defColumn( "DokumenteThema" , "Bezeichnung" , 300 ) ,
                                defColumnRenderCell(
                                  defColumn( "DokumenteDateigroesse" , "Größe" , 80 , 1 , 80 ) ,
                                  ( data:any ) =>
                                    readableFileSize( parseInt( data.row.DokumenteDateigroesse , 10 ) )
                                ) ,
                                defColumn( "DokumenteDateiname" , "Dateiname" , 300 )
                              ]
                            } 
                            pageSize={pageSize}
                            rowsPerPageOptions={[10,50,100]}
                            disableSelectionOnClick
                            onCellClick =
                              {
                                ( e:any ) =>
                                  {
                                    /** Browser / Web vs. App nativ */
                                    if( navigator ) {
                                      setDokumentToActOn( e.id ) ;
                                    } else {
                                      appUnterweisungDokumentIdChange( e.id ) ;
                                      appViewSwitchChange( true ) ;
                                      history.push( '/dokument-unterweisung/' + e.id + '/' + appUnterweisungId )
                                    }
                                  }
                              }
                            sortingOrder={["desc", "asc"]}
                            onPageSizeChange={(newPage) => setPageSize(newPage)}
                            pagination
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                          />
                        </Box>
                      :
                        ""
                  }
                  <ButtonInaktive
                    disabled = { existInaktive ? false : true }
                    myExec = {
                      () =>
                        {
                          setShowInaktive( showInaktive ? false : true )
                        }
                    }
                    label = { showInaktive ? 'Inaktive ausblenden' : 'Inaktive anzeigen' }
                  />
                  <ButtonAdd
                    myExec = { () => { appUnterweisungDokumentIdChange( 'neu' ) ; } }
                    routeUrl = { '/dokument-unterweisung/neu/' + appUnterweisungId }
                  />
                  {
                    tableData.length > 0
                      ?
                        <IonButton onClick={ () => exportTableData( appUnterweisungDokumente , 'Dokumente Schulung ' + appUnterweisungId ) }>Exportieren</IonButton>
                      :
                        ''
                  }
                </IonCard>
              :
                ''
          }
      </IonContent>
    </IonPage>
  );
};

